import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Badge, Form } from 'react-bootstrap';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import PageTitle from "../../layouts/PageTitle"
import { MdOutlineAccessTime } from "react-icons/md";
import { FaRegFolderOpen } from "react-icons/fa";

function AssignmentList() {
  const token=localStorage.getItem("token");
  const [connectUser, setConnectUser] = useState("");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
 const [assignments, setAssignments] = useState([]);
 const [companyid, setCompanyid] = useState("");

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    setConnectUser(currentUser)
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
 
    getUserCompanyID().then((compId) => {
      setCompanyid(compId)
      axios.get( `${process.env.REACT_APP_APP_DOMAIN}/api/assignment/getAssOfApp/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          console.log("ass:",response.data)
          if (compId != 1) {
            const filteredTests = response.data.assignments.filter(test => test.user.companyID === compId || test.user.companyID === 1);
            setAssignments(filteredTests);
          } else if(compId === 1 && currentUser.userRole===1){

            setAssignments(response.data.assignments);
          }else if(compId === 1 && currentUser.userRole===3){
            const filteredTests = response.data.assignments.filter(test => test.user.companyID === compId);
            setAssignments(filteredTests);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des tests:', error);
        });
    });
  }, []);

  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title: lang.menu.deleteTitle,
      text: lang.menu.deleteTextAssignment,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
  
        try {
          await axios
            .delete(
              `${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${userId}/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(async (res) => {
              console.log(res);
              swal(lang.menu.deleteSuccessAssignment, {
                icon: "success",
              });
              window.location.reload();
             
            });
        } catch (err) {
          console.error(err);
          console.log("err :",err.response)     
     if (
            err.response &&
            err.response.status === 400 &&
            err.response.data.message.includes("linked to a job profile")
          ) {
            swal(
              lang.menu.deleteLinkedAssignmentTitle,
              lang.menu.deleteLinkedAssignmentText,
              "warning"
            );
          } else {
            swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
          }
        }
      } else {
        // User canceled the delete action
        swal(lang.menu.deletedCnacledAssignment, {
          icon: "info",
        });
      }
    });
  };
  

  

  //search for skill
  const [searchWord, setSearchWord] = useState("");

    const searchForAssignment = (assignment)=>{
      return assignment.name.toLowerCase().includes(searchWord.toLowerCase());
    }

  const onChangeSearchWord = (e) =>{
    setSearchWord(e.target.value);
  }

  return (
    <div className="job-profile-container">
       <PageTitle
        activeMenu={lang.menu.AssignmentList}
        motherMenu={lang.menu.Assignments}
      />
      <br />

      {/*<div className="profile-list-header">
        <div>
        <Form.Control 
          type="search"
          className="left-align-container smallscreen"
          placeholder="Search"
          onChange={onChangeSearchWord} />
        </div>
        <div>
        <Link to="/create-assignment" className="btn btn-primary test-list-button">
          <i className="fas fa-plus"></i>
          {lang.menu.AddAssignments}
        </Link>
        </div>
      </div>*/}

      <Row className="mb-3 ">
  <Col xs={7}>
    <Form.Group controlId="formBasicSearch">
      <Form.Control
        type="search"
        placeholder={lang.menu.serachJob}
        onChange={onChangeSearchWord}
      />
    </Form.Group>
  </Col>
  <Col xs={1} className="d-flex offset-md-1">
    
  </Col>
  <Col xs={2} className="d-flex align-items-end justify-content-end offset-md-1">
  <Link to="/create-assignment" className="btn btn-primary test-list-button">
          <i className="fas fa-plus"></i>
          {lang.menu.AddAssignments}
        </Link>
  </Col>
</Row>

      
        
      <Container>
      <Row className="g-4">
  {assignments.filter(assignment => searchForAssignment(assignment)).map((assignment) => (
    <Col key={assignment.id} xs={12} sm={6} md={4}>
      <Card className="shadow-sm border-0 h-100" style={{ borderRadius: "15px" }}>
        <Card.Body style={{ fontFamily: "Poppins", padding: "20px" }}>
          <Card.Title style={{
            fontSize: "1.5rem",
            fontWeight: "700",
            color: "#333",
            textAlign: "center",
            marginBottom: "15px"
          }}>
            {assignment.name}
          </Card.Title>
          <Card.Text style={{ fontSize: "1rem", color: "#555" }}>
            <strong>{lang.menu.description} :</strong> {assignment.description}
          </Card.Text>

          <div className="badge-container" style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "15px"
          }}>
            <span className="badge" style={{
              background: "#43DAC2",
              color: "#fff",
              padding: "8px 12px",
              borderRadius: "12px",
              fontSize: "0.9em",
              display: "flex",
              alignItems: "center",
              gap: "5px"
            }}>
              <MdOutlineAccessTime />
              {assignment.duration > 0 && `${assignment.duration} `}{lang.menu.jour}s
            </span>
            <span className="badge" style={{
              background: "#43DAC2",
              color: "#fff",
              padding: "8px 12px",
              borderRadius: "12px",
              fontSize: "0.9em",
              display: "flex",
              alignItems: "center"
            }}>
              {assignment.difficulty}
            </span>
            <span
              className="badge"
              style={{
                background: "#43DAC2",
                color: "#fff",
                padding: "8px 12px",
                borderRadius: "12px",
                fontSize: "0.9em",
                display: "flex",
                alignItems: "center",
                gap: "5px",
                cursor: "pointer"
              }}
              onClick={() => window.open(assignment.repository_recruiter, "_blank")}
            >
              <FaRegFolderOpen />
              Repository Link
            </span>
          </div>

          {(connectUser.userRole === 1 || (assignment.user.companyID === companyid && companyid !== 1) || (connectUser.localId === assignment.user.uid && companyid === 1)) && (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", gap: "20px" }}>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "#FF4C4C",
                  cursor: "pointer",
                  fontSize: "1.2rem"
                }}
                onClick={() => handleDelete(assignment.id)}
                title="Delete"
              >
                <i className="fas fa-trash-alt"></i>
              </button>
              <Link
                to={`/update-assignment/${assignment.id}`}
                className="test-details-link"
                title="Edit"
                style={{ color: "#3B82F6", fontSize: "1.2rem" }}
              >
                <i className="fas fa-pencil-alt"></i>
              </Link>
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  ))}
</Row>

    </Container>
     
    </div>
  );
}

export default AssignmentList;