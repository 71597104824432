import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button , Badge ,Form,Image} from 'react-bootstrap';
import { FiEye } from 'react-icons/fi';
import translations from "../AppsMenu/Candidate/mulitlingue";
import { VscRunAll } from "react-icons/vsc";

const JobRoleList = () => {
  const [jobs, setJobs] = useState([]);

  const [scoreList, setScoreList] = useState({});
  const [skillList, setSkillList] = useState({});
  const token=localStorage.getItem("token");
  const [passTest,setPassTest] = useState([]);
  const [connectUser, setConnectUser] = useState("");
 const [companyid, setCompanyid] = useState("");
 const [currentPage, setCurrentPage] = useState(1);
 const itemsPerPage = process.env.REACT_APP_PAGINATION ;
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setConnectUser(currentUser)

        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setPassTest(
            data.some((permission) => permission.title === "pass-test-from-job")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    

    getUserCompanyID().then((compId) => {
      setCompanyid(compId)

      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          if (compId!=1) { 
            const filteredJobs = response.data.filter(job => job.Status==="Active" && job.archived===0);
            setJobs(filteredJobs);
          } else {
            const filteredJobs = response.data.filter(job =>  job.Status==="Active" && job.archived===0);

            setJobs(filteredJobs);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des emplois:', error);
        });
    });
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
/*
  useEffect(() => {
  
    jobs.forEach(job =>{ setSkills(prevSkills => ({
      ...prevSkills,
      [job.id]: {"Python", "Java", "Reactjs"}
    }));
    })
  
    
    const userId = JSON.parse(localStorage.getItem("userDetails")).localId

    const getSkills = async (idJob) => {
      const responseC = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      console.log(responseC)

      const skillll = Object.keys(JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels)
      const level = Object.values(JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels)
      console.log("skill", skillll , "value", level)

      return skillll;

    };
    


    if (jobs.length>0){
      jobs.forEach(job => {const skillsFetch = getSkills(job.id);
        console.log("fetch" , skillsFetch)
        setSkills(prevSkills => ({
        ...prevSkills,
        [job.id]: skillsFetch
      
      })); })

    }
    
  },[jobs]);

  */
  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userDetails")).localId;

    const getUserSkills = async () => {
      const responseC = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const userSkills = Object.keys(JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels);
      const lev = JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels;
      console.log("userSkills", userSkills, "lev" , lev)

      return  userSkills;
    };

    const getJobSkills = async () => {
        const jobSkills = {};
        const responseC = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        console.log("response", responseC.data);
        const jobs = responseC.data;
        console.log(jobs)
        jobs.forEach(job => {
          const skil = job.skills;
          console.log(skil)
          jobSkills[job.id] = skil;
        })

        console.log(jobSkills)
      
        return jobSkills;
    };

    
    const scoring = async () => {
      try {
  

        const userSkills = await getUserSkills();
        console.log("userskill", userSkills)
        const jobSkills = await getJobSkills();
        
        const cc = {}
        const jskill = {}
        for (const jobId in jobSkills) {
          const jobSkillValues = jobSkills[jobId];
    
          const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/score`, {
            skills: userSkills,
            rec_skill: jobSkillValues,
            seuil: 2
          }	,{
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          console.log(response)
          cc[jobId] = response.data
      
          
    
      
          
          console.log("score", response.data);


        }

        setScoreList(cc);


      
      } catch (error) {
        console.error("Error calculating score", error);
      }
    };

    scoring()

  }, []);
  //////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userDetails")).localId;

    const getUserSkills = async () => {
      const responseC = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const userSkills = Object.keys(JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels);
      const lev = JSON.parse(responseC.data[0].skills).TechnicalSkills.proficiencyLevels;
      console.log("userSkills", userSkills, "lev", lev);
      return userSkills;
    };

    const getJobSkills = async () => {
      const jobSkills = {};
      const responseC = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      console.log("response", responseC.data);
      const jobs = responseC.data;

      jobs.forEach(job => {
        const skil = job.skills;
        console.log(skil);
        jobSkills[job.id] = skil;
      });

      console.log(jobSkills);
      return jobSkills;
    };

    const scor = async () => {
      try {
        const userSkills = await getUserSkills();
        console.log("userSkills", userSkills);
        const jobSkills = await getJobSkills();
        
        const cleanedJobSkills = {};

        for (const jobId in jobSkills) {
          const jobSkillValues = jobSkills[jobId];
          console.log("jobskill2", jobSkillValues)

          const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/cleaning`, {
            rec_skill: jobSkillValues,
          },	{
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          console.log("respo", response)
          const k = Object.keys(response.data)

          cleanedJobSkills[jobId] = k; 
          console.log("cleaned",cleanedJobSkills )
        }

        const newSkillList = {};

        for (const jobId in cleanedJobSkills) {
          const matchedSkills = userSkills.filter(skill => cleanedJobSkills[jobId].includes(skill));
          newSkillList[jobId] = matchedSkills;
        }

        setSkillList(newSkillList);
        console.log("matchedSkills", newSkillList);

      } catch (error) {
        console.error("Error fetching skills", error);
      }
    };

    scor();

  }, []);
  

  



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  

  const showTest = async (id)=>{
    try {
      localStorage.setItem('currentStep', '1');
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;

      const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/candidateJob/test/${userId}/${id}`,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json"
        }
      })
      console.log("ressss :",response.data)

      const redirectionPath = `/JobRoleTests/${id}`;

      console.log("redirectionPath", redirectionPath);    
      window.location.href = redirectionPath;
    } catch (error) {
      console.log(error);
    }
  }
  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return jobs.filter(job => job.JobTitle.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
    // Gestion de la pagination
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentJobs = filterTestByTitle().slice(
      indexOfFirstItem,
      indexOfLastItem
    );
  
    const totalPages = Math.ceil(filterTestByTitle().length / itemsPerPage);
  
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataCmp = await response.json();
        setCompanies(dataCmp);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  const getCompanyLogo = (companyId) => {
    const company = companies.find((comp) => comp.companyid === companyId);
    console.log("company :",company)
    let pathImage=`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
    return company
      ? pathImage
      : null;
  };
  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title>{lang.menu.listJob} </Card.Title>
         
        </Card.Header>
        <Row>
<Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
      </Row>
      </Card>

      <Row>
      {currentJobs.map(job => (

          <Col key={job.id} xs={12} sm={6} md={4}>
            <Card>
              <Card.Header>
              <Card.Title style={{ fontSize: "180%", color: "#000",textAlign:"center",fontWeight:"700" }}>{job.JobTitle}</Card.Title>
              {job.companyid!=null && getCompanyLogo(job.companyid) ? (
  <Image
    src={getCompanyLogo(job.companyid)}
    alt="Company Logo"
    style={{ width: "50px", height: "50px", borderRadius: "50%" }}
  />
) : (
  <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>
)}

              </Card.Header>
              <Card.Body style={{ fontFamily: "Poppins" }}>
                <Card.Text style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}>
  {job.JobDescription}</Card.Text>
                <Row>
                  <Col><span style={{ fontSize: "120%",fontWeight:"700" }}>{lang.menu.experience} :</span> {job.Experience}</Col>
                  <Col><span style={{ fontSize: "120%",fontWeight:"700" }}>{lang.menu.OfferedSalary} :</span> {job.OfferedSalary}</Col>
                </Row>
                <Row>
                  <Col> <span style={{ fontSize: "120%",fontWeight:"700" }}>{lang.menu.JobType}:</span> {job.JobType}</Col>
                  <Col><span style={{ fontSize: "120%",fontWeight:"700" }}>{lang.menu.Country} :</span> {job.Country}</Col>
                </Row>
                <Row>
                  
  <span style={{ fontSize: "120%", fontWeight: "700" }}>{lang.menu.matchedSkills} :</span>{skillList[job.id] && skillList[job.id].map((skill) => (
    <Col>
    <Badge variant="primary" key={skill}>
      {skill}
    </Badge>
    </Col>
  ))}
</Row><Row>

                  <Col><span style={{ fontSize: "120%",fontWeight:"700" }}>{lang.menu.note}</span> {scoreList[job.id]}</Col>
                </Row>
              </Card.Body>
              <Card.Footer  style={{justifyContent: "space-between"}}> 
                <Row>
                <Col xl={8}>
                <Button variant="primary"  href={`details/${job.id}`} >
                    <FiEye />{lang.menu.viewDetails}
                  </Button>
                </Col>

                {passTest ? (
  (connectUser.userRole === 1 ||
    (job.companyid === companyid && companyid !== 1) ||
    (connectUser.localId === job.creator_uid && companyid === 1)) && (
      <Col>
        {console.log("job:", job)}
        <Button
          variant="success light"
          onClick={() => {
            showTest(job.id);
          }}
        >
          <VscRunAll size={16} />
        </Button>
      </Col>
  )
) : (
  <></>
)}



                </Row>
              

              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
          <div className="d-flex justify-content-end align-items-center mt-4">
        <Button
          variant="primary"
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          {lang.menu.previous}
        </Button>
        <span className="mx-3">
          Page {currentPage} {lang.menu.sur} {totalPages}
        </span>
        <Button
          variant="primary"
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {lang.menu.next}
        </Button>
      </div>
    </>
  );
};

export default JobRoleList;
