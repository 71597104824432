import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button,Col,Row } from "react-bootstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import Select from "react-select";
import data from "../data";
import swal from "sweetalert";
import Swal from "sweetalert2";

function UpdateJobProfile() {
  const token=localStorage.getItem("token");

  const { id } = useParams();
  const history = useHistory();
  const [persoTest,setPersoTest] = useState([]);
  const [technicalTest,setTechnicalTest] = useState([]);
  const [psychoTest,setPsychoTest] = useState([]);
  const [psycho,setPsycho] = useState("");
  const [perso,setPerso ]= useState("");
  const [selectedTech, setSelectedTech] = useState(technicalTest);
  const [allTests,setAllTests] = useState([]);
  const [testsTechDetails,setTestsTechDetails] = useState([]);
  const [testsPsychoDetails,setTestsPsychoDetails] = useState([]);
  const [testsPersoDetails,setTestsPersoDetails] = useState([]);
  const [nonAssignment, setNonAssignment ]= useState("");
  const [newSpecialism, setNewSpecialism] = useState("");
  const [options, setOptions] = useState(data.SpecialismOptions);
  useEffect(() => {
    
    axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/post-job/getAllTests`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then((res) => {
        
        // Filter tests by type
        const technicalTests = res.data.filter(
          (test) => test.test_source === "TechnicalTest"
        );
        setAllTests(res.data)
        setTechnicalTest(technicalTests)


        const personalityTests = res.data.filter(
          (test) => test.test_source === "PersonalityTest"
        );
        setPersoTest(personalityTests)

        const psychoTests = res.data.filter(
          (test) => test.test_source === "Psychoest"
        );
        setPsychoTest(psychoTests)

        // Map them to options
        const updatedTestsOptions = [
          {
            label: "-----------Technical Test-------------",
            options: technicalTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Personality Test-----------",
            options: personalityTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Personality Test",
              test_source: test.test_source,
            })),
          },
          {
            label: "-----------Psychotechnical Test-------",
            options: psychoTests.map((test) => ({
              value: test.Id,
              label: test.name,
              type: "Technical Test",
              test_source: test.test_source,
            })),
          },
        ];

        setTestsOptions(updatedTestsOptions);
    
      })
      .catch((err) => console.log(err));
  }, []);

  const [height, setHeight] = useState("200px");
  function handleChangeStyle(e) {
    e.preventDefault();
    setHeight("300px");
    let newHeight = e.target.scrollHeight + "px";
    setHeight(newHeight);
  }
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  //Style Select
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderRadius: "15px",
      boxShadow: "none",
      border: "none",
      "&:hover": {
        border: "none",
      },
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {},
    }),
    menu: (base) => ({
      ...base,
      borderRadius: "15px",
      marginTop: "-1px",
      boxShadow: "none",
      border: "none",
      "&:before": {
        display: "none",
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#555",
    }),
  };
  const [jobProfileData, setJobProfileData] = useState({
    title: "",
    description: "",
    requiredQualifications: [],
    languages: [],
    Tests: [],
    testsUpdate: [],
    testsToDisplay: [],
    personality: "",
    technical: "",
    psycho: "",
    assignment: null
  });

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/update-coefficient/getprofilebyId/` +
          id, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
      )
      .then((res) => {
        const parsedRequiredQualifications = res.data[0].requiredQualifications
          .replace(/[\[\]']+/g, "") 
          .split(/\), /) 
          .map((qualification, index) => {
            const [skill, count] = qualification.split(", ");
            const cleanedSkill = skill.replace(/[\(\)]/g, "").trim();
            const cleanCoefficient = count.replace(/[\(\)]/g, "").trim();

            return {
              skill: cleanedSkill,
              count: cleanCoefficient,
            };
          });
        const testsUpdateData = JSON.parse(res.data[0].testsUpdate);
        const filteredTestsPsychoUp = testsUpdateData.filter(test => test.test_source === "Psychoest");

        const personalityTests = testsUpdateData
          .filter((test) => test.test_source === "PersonalityTest")
          .map((test) => test.value);
          setPersoTest(personalityTests)

        const technicalTests = testsUpdateData
          .filter((test) => test.test_source === "TechnicalTest")
          .map((test) => test.value);
          setTechnicalTest(technicalTests)

        const psychoTests = testsUpdateData
          .filter((test) => test.test_source === "Psychoest")
          .map((test) => test.value);
          setPsychoTest(psychoTests)

        const technicalTestIds = Object.values(technicalTests).map(id => parseInt(id, 10));
        const technicalTestList=allTests.filter(
          (test) => test.test_source === "TechnicalTest"
        );

        const testDetailsTech = technicalTestList.filter(test => technicalTestIds.includes(test.Id));
        setTestsTechDetails(testDetailsTech)

        const psychotechnicalTestIds = Object.values(psychoTests).map(id => parseInt(id, 10));
        const psychotechnicalTestList=allTests.filter(
          (test) => test.test_source === "Psychoest"
        );
        const testDetailsPsycho = psychotechnicalTestList.filter(test => psychotechnicalTestIds.includes(test.Id));
        setTestsPsychoDetails(testDetailsPsycho)

        const persoTestIds = Object.values(personalityTests).map(id => parseInt(id, 10));
        const persoTestList=allTests.filter(
          (test) => test.test_source === "PersonalityTest"
        );

        const testDetailsPerso= persoTestList.filter(test => persoTestIds.includes(test.Id));
        setTestsPersoDetails(testDetailsPerso)






        setJobProfileData({
          ...jobProfileData,
          title: res.data[0].title,
          description: res.data[0].description,
          requiredQualifications: parsedRequiredQualifications,
          languages: JSON.parse(res.data[0].languages),
          testsUpdate: JSON.parse(res.data[0].testsUpdate),
          personality: personalityTests,
          technical:technicalTests,
          psycho: psychoTests,
          
        });
      })
      .catch((err) => console.log(err));
  }, [id,allTests]);
  useEffect(() => {
    console.log("jobProfileData 0:",jobProfileData)

  }, [jobProfileData,testsTechDetails])
 
  const [TestsOptions, setTestsOptions] = useState([]);
   
  const normalizeString = (str) => {
    return str.toLowerCase().replace(/\s+/g, '');
  }
  const handleAddSpecialism = (e) => {
    e.preventDefault();
    const normalizedNewSpecialism = normalizeString(newSpecialism);
  
    const similarSpecialism = options.find(
      option => {
        const normalizedOptionValue = normalizeString(option.value);
        return normalizedOptionValue.includes(normalizedNewSpecialism) || normalizedNewSpecialism.includes(normalizedOptionValue);
      }
    );
  
    if (similarSpecialism) {
      Swal.fire({
        title: `${lang.menu.exist} "${similarSpecialism.value}".`,
        text: `${lang.menu.swalAddQualification1} "${newSpecialism}", ${lang.menu.swalAddQualification2}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        cancelButtonText: `${lang.menu.Discard}`
      }).then((result) => {
        if (result.isConfirmed) {
          addNewSpecialism();
        } else {
          setNewSpecialism(""); 
        }
      });
    } else {
      addNewSpecialism();
    }
  };
  
  const addNewSpecialism = () => {
    const updatedSpecialismOptions = [
      ...options,
      { value: newSpecialism, label: newSpecialism },
    ];
    data.SpecialismOptions = updatedSpecialismOptions;
    setOptions(updatedSpecialismOptions);
    setNewSpecialism("");
  };
  

  const updateAssignment = async ()=>{
    try {
      
      const idjob = id;
      if(nonAssignment){
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
        const response = await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/del/${idjob}`,
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
      }else{
        const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/updateAssignmentByjobprofile/${idjob}/${jobProfileData.assignment.value}`,{}, 
          {
            headers: {
            Authorization: `Bearer ${token}`,
            }});
      }
      
      

    } catch (error) {
      console.error(error);
    }
  }
  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    await updateAssignment();
    localStorage.setItem("updateProfile",1);
console.log("jobProfileData:",jobProfileData)
    await axios
      .put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/update-job-profile/updatebyId/` +
          id,
        jobProfileData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((res) => {
        const newProfileId = res.data.id;
        console.log("jobProfileData up :",res.data)

        swal(lang.menu.updateMsgSucc, {
          icon: "success",
        });
        history.push(`/update-coefficient/${newProfileId}`);
      })
      .catch((err) => {
        swal(
          lang.menu.deleteTitleWrong,
          lang.menu.deleteMsgProfileJob,
          "error"
        );
        console.log(err);
      });
  };

  const handleTechChange = (selectedOption) => {
    setSelectedTech(selectedOption);
  
    const selectedValues = selectedOption.map(option => option.value);
    const filteredTestsUp = jobProfileData.testsUpdate.filter(test => test.test_source !== "TechnicalTest");
  
    const testTech = selectedOption.map(option => {
      const filteredTest = allTests.find(test => (
        test.Id === option.value &&
        test.test_source === "TechnicalTest" &&
        test.name === option.label
      ));
  
      return filteredTest ? {
        value: filteredTest.Id,
        label: filteredTest.name,
        type: "Technical Test",
        test_source: filteredTest.test_source
      } : null;
    }).filter(Boolean);
  
    const combinedTests = [...filteredTestsUp, ...testTech];
    const uniqueTestsArray = Array.from(new Set(combinedTests.map(JSON.stringify))).map(JSON.parse);
    const technicalTestList=allTests.filter(
      (test) => test.test_source === "TechnicalTest"
    );
    const testDetailsTech = technicalTestList.filter(test => selectedValues.includes(test.Id));
    setTestsTechDetails(testDetailsTech)
    setJobProfileData(prevData => ({
      ...prevData,
      testsUpdate: uniqueTestsArray,
      technical: selectedValues
    }));
  };


  
  
  
  
  
  
  
  
  
  
  
  





  const handleDropdownSelect12 = (selectedOption) => {  
    if (selectedOption) {
    setPsycho(selectedOption);

    const testUp = jobProfileData.testsUpdate;
    const filteredTestsUp = testUp.filter(test => test.test_source !== "Psychoest");
    const filteredTestUpDetails = allTests.filter(test => test.test_source === "Psychoest" && test.Id===selectedOption.value);
    const filteredTest = allTests.find(test => (
      test.Id === selectedOption.value &&
      test.test_source === "Psychoest" &&
      test.name === selectedOption.label
    ));
  
    if (filteredTest) {
      const testSelect = {
        value: filteredTest.Id,
        label: filteredTest.name,
        type: "Psychoest",
        test_source: filteredTest.test_source
      };
  
      const combinedTests = [...filteredTestsUp, testSelect];
      const uniqueTests = new Set(combinedTests.map(JSON.stringify));
      const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);
  
      setJobProfileData(prevData => ({
        ...prevData,
        testsUpdate: uniqueTestsArray,
        psycho: selectedOption.value
      }));
    }
    setTestsPsychoDetails(filteredTestUpDetails)
    }
    else {
      const uniqueTestsArray = (jobProfileData.testsUpdate || []).filter(
        test => test.test_source !== "Psychoest"
      );
  
      setPsycho(null);
      setTestsPsychoDetails([]);
      setJobProfileData(prevData => ({
        ...prevData,
        testsUpdate: uniqueTestsArray,
        psycho: null
      }));
    }
  };
  


//***Perso */
const handleDropdownSelect22 = (selectedOption) => {  
  if (selectedOption) {
    setPerso(selectedOption);

    const testUp = jobProfileData.testsUpdate || [];
    const filteredTestsUp = testUp.filter(test => test.test_source !== "PersonalityTest");

    const filteredTestUpDetails = allTests.filter(test => 
      test.test_source === "PersonalityTest" && 
      test.Id === selectedOption.value
    );

    const filteredTest = allTests.find(test => (
      test.Id === selectedOption.value &&
      test.test_source === "PersonalityTest" &&
      test.name === selectedOption.label
    ));

    if (filteredTest) {
      const testSelect = [{
        value: filteredTest.Id,
        label: filteredTest.name,
        type: "PersonalityTest",
        test_source: filteredTest.test_source
      }];

      const combinedTests = [...filteredTestsUp, ...testSelect];
      const uniqueTests = new Set(combinedTests.map(JSON.stringify));
      const uniqueTestsArray = Array.from(uniqueTests).map(JSON.parse);

      setJobProfileData(prevData => ({
        ...prevData,
        testsUpdate: uniqueTestsArray,
        personality: selectedOption.value
      }));
    }

    setTestsPersoDetails(filteredTestUpDetails);
  } else {
    const uniqueTestsArray = (jobProfileData.testsUpdate || []).filter(
      test => test.test_source !== "PersonalityTest"
    );

    setPerso(null);
    setTestsPersoDetails([]);
    setJobProfileData(prevData => ({
      ...prevData,
      testsUpdate: uniqueTestsArray,
      personality: null
    }));
  }
};


//assignment

  const [allAssignments, setAllAssignments] = useState([]);
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
  
    getUserCompanyID().then((compId) => {
      axios.get( `${process.env.REACT_APP_APP_DOMAIN}/api/assignment/getAssOfApp/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          console.log("ass:",response.data)
          if (compId != 1) {
            const filteredTests = response.data.assignments.filter(test => test.user.companyID === compId || test.user.companyID === 1);
            setAllAssignments(filteredTests);
          } else if(compId === 1 && currentUser.userRole===1){
  
            setAllAssignments(response.data.assignments);
          }else if(compId === 1 && currentUser.userRole===3){
            const filteredTests = response.data.assignments.filter(test => test.user.companyID === compId);
            setAllAssignments(filteredTests);
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des tests:', error);
        });
    });
  }, []);

  useEffect(()=>{
    const getAssignment = async ()=>{
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/findAssignmentByjobprofile/${id}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        if(allAssignments.length > 0){
          setNonAssignment(false)
          console.log("response", response.data.assignment_id, "filter",allAssignments.filter(assignment => assignment.id === response.data.assignment_id)[0]);
          setJobProfileData({
          ...jobProfileData,
          assignment: allAssignments.map(test => ({ value: test.id, label: test.name })).filter(assignment => assignment.value === response.data.assignment_id)[0],
        });}

      } catch (error) {
        console.error(error);
      }
    }

    getAssignment();
  },[allAssignments])


  const handleAssignment = (selectedOptions)=>{
    if (selectedOptions) {
      setNonAssignment(false)

    setJobProfileData({
      ...jobProfileData,
      assignment: selectedOptions,
    });}else{
      setNonAssignment(true)
      setJobProfileData({
        ...jobProfileData,
        assignment: null,
      });
    }
  }

  
  return (
    <>
      <h1>{lang.menu.UpdateJobProfile} </h1>
      <br />
      <div className="max-widht">
        <form onSubmit={handleUpdateProfile}>
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block">
                  <h3 className="left-align-container ">
                    {lang.menu.jobTitle}
                  </h3>

                  <div className="input-group ">
                    <input
                      value={jobProfileData.title}
                      required
                      type="text"
                      className={`form-control `}
                      placeholder=" Title"
                      maxLength={128} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          title: e.target.value,
                        })
                      }
                    />
                  </div>
                  <br />
                  <h3 className="left-align-container smallscreen">
                    {lang.menu.description}
                  </h3>
                  <div>
                    <textarea
                      value={jobProfileData.description}
                      required
                      style={{ height: height }}
                      maxLength={1000} 
                      onChange={(e) =>
                        setJobProfileData({
                          ...jobProfileData,
                          description: e.target.value,
                        })
                      }
                      type="text"
                      className={`form-control`}
                      placeholder=" Job Description"
                    />
                  </div>
                  <br />

                  <Row>
                    <Col> <div className="select-wrapper-left">
                      <h4 className="smallscreen">
                        {lang.menu.RequiredQualifications}
                      </h4>
                      <Select
                        className={`select-multi`}
                        required
                        isMulti
                        options={data.SpecialismOptions}
                        styles={selectStyles}
                        value={jobProfileData.requiredQualifications.map(
                          (item) => ({
                            value: item.skill, 
                            label: `${item.skill}`,
                          })
                        )}
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map((option) => {
                            const existingSkill = jobProfileData.requiredQualifications.find(
                              (item) => item.skill === option.value
                            );
                        
                            return {
                              skill: option.value,
                              count: existingSkill ? existingSkill.count : 1,
                            };
                          });
                        
                          setJobProfileData({
                            ...jobProfileData,
                            requiredQualifications: selectedValues,
                          });
                        }}
                        
                      />
                    </div></Col>
                   
                    <Col>
                    <div className="buttonAline">
                      
                      <h4 className="smallscreen">  {lang.menu.AddNeededQualification} </h4>
                      
                     
                      <div className="tooltip-container">
                      <button
                        id="btn"
                        type="button"
                        className="informationScore">i
                      </button>
                      <div className="tooltip-message">
                     {lang.menu.iJobProfile}                        </div>
                    </div>
                    </div>
                    <div className="input-button-container">
                      <input
                        type="text"
                        className={`form-control`}
                        value={newSpecialism}
                        onChange={(e) => setNewSpecialism(e.target.value)}
                      />
                 <button
  className="buttonSkills"
  type="button" // Empêche la soumission par défaut
  onClick={handleAddSpecialism}
>
  <i className="fas fa-plus"></i>
</button>

                    </div>
                    </Col>
                  
                  </Row>
                  <br />
                  <div className="select-container">
                    <div className="select-wrapper-right">
                      <h4>{lang.menu.language}</h4>
                      <Select
                        isMulti
                        className={`form-control`}
                        required
                        options={data.Languages}
                        styles={selectStyles}
                        value={
                          jobProfileData.languages &&
                          Array.isArray(jobProfileData.languages) // Check if it's an array
                            ? jobProfileData.languages.map((item) => ({
                                value: item,
                                label: item,
                              }))
                            : JSON.parse(jobProfileData.languages).map(
                                (item) => ({
                                  value: item,
                                  label: item,
                                })
                              )
                        }
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions.map(
                            (option) => option.value
                          );

                          setJobProfileData({
                            ...jobProfileData,
                            languages: selectedValues,
                          });
                        }}
                      />
                    </div>
                  </div>

                  {/**   <div className="select-container">
                <div className="select-wrapper-right">
                  <h4>{lang.menu.TestsTypes}</h4>
                  <Select
  required
  isMulti
  className={`form-control`}
  options={data.TestsTypes}
  styles={selectStyles}
  value={
    jobProfileData.Tests &&
    typeof jobProfileData.Tests === "string"
      ? JSON.parse(jobProfileData.Tests).map((item) => ({
          value: item,
          label: item,
        }))
      : jobProfileData.Tests.map((item) => ({
          value: item,
          label: item,
        }))
  }
  onChange={(selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    setJobProfileData({
      ...jobProfileData,
      Tests:selectedValues
    });
  }}
/>

                </div>
              </div>*/}
                  <br />
                
                  <Row>

                  <Col>
                    <label><strong>{lang.menu.Assignment}:</strong> </label>
                    <br/>
                    <Select
options={allAssignments.map(assignment => ({ value: assignment.id, label: assignment.name }))}
value={jobProfileData.assignment ? jobProfileData.assignment : null}
    isClearable={true}  
    isSearchable={true} 
    onChange={
      handleAssignment
    }
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  />
                <br/>
                    </Col>

                <Col className="form-group">
                    <label><strong>{lang.menu.testTechnique}:</strong> </label>
                    <br/>
                    <Select
  options={allTests.filter(test => test.test_source === "TechnicalTest").map(test => ({ value: test.Id, label: test.name }))}
  value={testsTechDetails.map(test => ({ value: test.Id, label: test.name }))}
    onChange={handleTechChange}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  isMulti
/>
 <br/>
                    
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.testPsychoTechnique}:</strong> </label>
                    <br/>
                    <Select
  options={allTests.filter(test => test.test_source === "Psychoest").map(test => ({ value: test.Id, label: test.name }))}
  onChange={handleDropdownSelect12}
  isClearable={true}  
  isSearchable={true} 
  value={testsPsychoDetails.map(test => ({ value: test.Id, label: test.name }))}
  styles={{
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
      lineHeight: "40px",
      borderRadius: "0",
    }),
  }}
/>
                <br/>
                    </Col>
                    <Col>
                    <label><strong>{lang.menu.perTest}:</strong> </label>
                    <br/>
                    <Select
    options={allTests.filter(test => test.test_source === "PersonalityTest").map(test => ({ value: test.Id, label: test.name }))}
    isClearable={true}  
    isSearchable={true} 
    onChange={
      handleDropdownSelect22
    }
    value={testsPersoDetails.map(test => ({ value: test.Id, label: test.name }))}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: "40px",
        lineHeight: "40px",
        borderRadius: "0",
      }),
    }}
  /> <br/>
                    </Col>
                    </Row>
                </div>

                <div className="card-footer border-0">
                  <div className="d-flex justify-content-end">
                    <Button
                      type="submit"
                      className="btn btn-primary btn sweet-confirm"
                    >
                      {lang.menu.PostNow}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default UpdateJobProfile;
