import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card, Button, Nav, Tab ,Badge, Modal, Form, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { SiVisualstudiocode } from 'react-icons/si';
import { MdOutlineQuiz } from 'react-icons/md';
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import swal from "sweetalert";
import { AiOutlinePieChart } from "react-icons/ai";
import PageTitle from "../../layouts/PageTitle"

const toastOptions = {
  autoClose: 5000, // Duration in milliseconds
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
function TechList() {
  const token=localStorage.getItem("token");

  const [techList, setTechList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
   const [companyid, setCompanyid] = useState("");
   const [connectUser, setConnectUser] = useState("");
   const [isDisable, setIsDisable] = useState("");
   const [quotaMax, setQuotaMax] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    type: "Coding",
    codingTests: [],
    qcmTests: [],
  });
  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setConnectUser(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setTechList(
            data.some((permission) => permission.title === "tech-list")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 
  const [uniqueQcmTestIds, setUniqueQcmTestIds] = useState(new Set());

  useEffect(() => {
    if (!techList) {
      setShowAccessDeniedModal(true); 
    }
  }, [techList]);
  const handleCodingTestsChange = (selectedOptions) => {
    if (formData.type === "Coding" && selectedOptions.length > 5) {
      return; 
    }
    if (formData.type === "Both" && (selectedOptions.length + formData.qcmTests.length) > 5) {
      return; 
    }
    const selectedTestIds = selectedOptions.map((option) => parseInt(option.value));
  
    // Update formData with the new selected coding tests
    setFormData(prevFormData => ({
      ...prevFormData,
      codingTests: selectedTestIds,
      availableCodingTests: codingTestOptions.filter(option => !selectedTestIds.includes(option.value))
    }));
  
    setSelectedTest(prevSelectedTest => ({
      ...prevSelectedTest,
      codingTest: codingTests.filter(test => selectedTestIds.includes(test.id))
    }));
  };
  

  const handleQcmTestsChange = (selectedOptions) => {
    const selectedTestIds = selectedOptions.map((option) => parseInt(option.value));

    if (formData.type === "QCM" && selectedTestIds.length > 5) {
      return; 
    }
    if (formData.type === "Both" && (selectedTestIds.length + formData.codingTests.length) > 5) {
      return; 
    }
  
    // Update formData with the new selected QCM tests
    setFormData(prevFormData => ({
      ...prevFormData,
      qcmTests: selectedTestIds,
      availableQcmTests: qcmTestOptions.filter(option => !selectedTestIds.includes(option.value))
    }));
    setSelectedTest(prevSelectedTest => ({
      ...prevSelectedTest,
      techQCM: qcmTests.filter(test => selectedTestIds.includes(test.test_id))
    }));
  };
  const [tests, setTests] = useState([]);
  const [deletetest, setDeletetest] = useState([]);
  const [activeTab, setActiveTab] = useState("Coding");

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
  
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
 
    getUserCompanyID().then((compId) => {
      setCompanyid(compId)
      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/getTestDetailsNew`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(async response => {
          console.log("tes 2:",response.data)
          if (compId != 1) {
            const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === 1);
            setTests(filteredTests);
            const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
            const subscriptionData = await subscriptionResponse.json();
            console.log("comp id sub:", subscriptionData);
            if(subscriptionResponse.status===200){
              setQuotaMax(subscriptionData[7])
              const isDisabled = subscriptionData[7].numberquota_max <=  subscriptionData[7].quotaAssessment;
              setIsDisable(isDisabled)
            }
  
          } else if(compId === 1 && currentUser.userRole===1){

            setTests(response.data);
          }else if(compId === 1 && currentUser.userRole===3){
            const filteredTests = response.data.filter(test => test.companyID === compId);
            setTests(filteredTests);
            const subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
            const subscriptionData = await subscriptionResponse.json();
            console.log("comp id sub:", subscriptionData);
            if(subscriptionResponse.status===200){
              setQuotaMax(subscriptionData[7])
              const isDisabled = subscriptionData[7].numberquota_max <=  subscriptionData[7].quotaAssessment;
              setIsDisable(isDisabled)
            }
      
          }
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des tests:', error);
        });
    });
  }, []);

  const handleDelete = (testId, type) => {
    if (testId) {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/deleteTestTechbyId/${testId}/${type}/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {

          if (response.data.message === "Impossible de supprimer ce test car il a été utilisé.") {
            swal(lang.menu.suppImpo, {
              icon: "error",
            });
          } else {
            setTests(prevTests => prevTests.filter(test => test.testId !== testId));
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };
  
  const [codingTests, setCodingTests] = useState([]);
  const [qcmTests, setQcmTests] = useState([]);
  useEffect(() => {
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    const fetchCodingTests = async () => {
      try {
        const compId = await getUserCompanyID();

        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getAllTopics`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        if (compId!=1) {
          const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === 1|| test.companyID === null  );
          setCodingTests(filteredTests); 
        } else {
          setCodingTests(response.data);
        }      } catch (error) {
        console.error(error);
      }
    };

    const fetchQcmTests = async () => {
      try {
        const compId = await getUserCompanyID();
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getAllTestsByComp/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
    setQcmTests(response.data); 

      } catch (error) {
        console.error('Erreur lors de la récupération des tests:', error);
      }
    };
    fetchCodingTests();
    fetchQcmTests();
  }, []);
  const codingTestOptions = codingTests.map((test) => ({ value: test.id, label: test.name }));
  const qcmTestOptions = qcmTests
  .reduce((uniqueTests, test) => {
    const existingTest = uniqueTests.find((uniqueTest) => uniqueTest.value === test.test_id);

    if (!existingTest) {
      uniqueTests.push({ value: test.test_id, label: test.test_name });
    }

    return uniqueTests;
  }, []);
  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");

    deleteButtons.forEach(deleteButton => {
      deleteButton.addEventListener('click', () => handleDelete(deleteButton.dataset.testId, deleteButton.dataset.type));
    });

    return () => {
      deleteButtons.forEach(deleteButton => {
        deleteButton.removeEventListener('click', () => handleDelete(deleteButton.dataset.testId, deleteButton.dataset.type));
      });
    };
  }, []);

  const handleUpdateClick = (test) => {
    const updatedTechQCM = test.techQCM.map(item => ({
      ...item,
      test_id: item.id,       // Rename id to test_id
      test_name: item.name,    // Rename name to test_name
    }));
  
    // Remove the old keys
    updatedTechQCM.forEach(item => {
      delete item.id;
      delete item.name;
    });
  
    // Update the test object with modified techQCM
    const updatedTest = {
      ...test,
      techQCM: updatedTechQCM,
    };
  
  
    setSelectedTest(updatedTest);
    setShowModal(true);
   
    const QCM = updatedTest.techQCM.map(t => t);
    const cod = updatedTest.codingTest.map(t => parseInt(t.id));

    const availableQcmTests = qcmTestOptions.filter(option => !QCM.includes(option.id));
    const availableCodingTests = codingTestOptions.filter(option => !cod.includes(option.test_id));

    setFormData({
      ...updatedTest,
      qcmTests: QCM,
      codingTests: cod,
      availableQcmTests,
      availableCodingTests,
    });
};

  const handleUpdate = async (e) => {
    e.preventDefault();
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    try {

      const requestData = {
        name: formData.name,
        type: formData.type,
        description: formData.description,
        coefQCM: formData.coefQCM,
        coefCoding: formData.coefCoding,
        codingTests: formData.codingTests,
        qcmTests: Array.isArray(formData.qcmTests) && formData.qcmTests.length > 0 && typeof formData.qcmTests[0] === 'object'
        ? formData.qcmTests.map(test => test.test_id)
        : formData.qcmTests,
        allowCopyPaste:formData.allowCopyPaste === null ? false : formData.allowCopyPaste,
        takeSnapshots:formData.takeSnapshots === null ? false : formData.takeSnapshots,
        takeScreenshots:formData.takeScreenshots === null ? false : formData.takeScreenshots,
        numberOfScreenShots: parseInt(formData.numberOfScreenShots),
        numberOfSnapShots: parseInt(formData.numberOfSnapShots),
        level: formData.level
      };

      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/update/${selectedTest.testId}/${userId}`, requestData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      swal("Test Updated successfully.", {
        icon: "success",
      });
      
      window.location.reload()
    } catch (error) {
      swal('Failed to submit the form.', {
        icon: "error",
      });
    }
  };
    const [searchTerm, setSearchTerm] = useState('');
  
  const filterAssessmentByTitle = () => {
    return tests.filter(test => test.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const renderFilteredTests = () => {
    let filteredTests = tests;
    if (activeTab === "Coding") {
      filteredTests = tests.filter(test => test.type === "Coding");
    } else if (activeTab === "QCM") {
      filteredTests = tests.filter(test => test.type === "QCM");
    } else if (activeTab === "Both") {
      filteredTests = tests.filter(test => test.type === "Both");
    }

    const testGroups = [];
    for (let i = 0; i < filteredTests.length; i += 3) {
      testGroups.push(filteredTests.slice(i, i + 3));
    }

    return testGroups.map((group, index) => (
      <div className="row mb-4" key={index}>
        {group.map(test => (
          <div className="col-md-4" key={test.id}>
            <Card style={{ color: "black", fontFamily: "Poppins" }}>
              <Card.Header>
              <Card.Title>
                {console.log("test:",test)}
  {test.name.length > 16 ? `${test.name.substring(0, 16)}...` : test.name}
</Card.Title>

                <Badge variant="primary light">{test.type} {"    "}</Badge>   
              </Card.Header>
              <Card.Body>
                <Card.Text>{test.description}</Card.Text>
                <div>
                  {test.techQCM.length > 0 && (
                    <div>
                      <h6 style={{ fontSize: "120%", color: "#74188D" }}><MdOutlineQuiz />{" "} {lang.menu.testTechniqueMCQ}:</h6>
                      <ul>
                        {test.techQCM.map((item) => (
                          <ol key={item.id}>
                            <Link to={`/TechQCMDetails/${item.id}`}>{item.name}</Link>
                          </ol>
                        ))}
                      </ul>
                    </div>
                  )}

                  {test.codingTest.length > 0 && (
                    <div>
                      <h6 style={{ fontSize: "120%", color: "#74188D" }}> <SiVisualstudiocode /> {" "}{lang.menu.codTest}:</h6>
                      <ul>
                        {test.codingTest.map((item) => (
                          <ol key={item.id}>{item.name}</ol>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </Card.Body>
              {(connectUser.userRole === 1 || (test.companyID === companyid && companyid !== 1) || (connectUser.localId === test.creator_uid && companyid === 1)) && (

              <Card.Footer className="d-sm-flex justify-content-between align-items-center">
                {deletetest ? (
                  <>
                    <Button
                      className="me-2"
                      variant="danger"
                      onClick={() => handleDelete(test.testId, test.type)}
                    >
                      {lang.menu.delete}
                    </Button>
                    <Button
                      className="me-2"
                      variant="primary"
                      onClick={() => handleUpdateClick(test)}
                    >
                      {lang.menu.Update}
                    </Button>
                  </>
                ) : <></>}
              </Card.Footer>
              )}
            </Card>
          </div>
        ))}
      </div>
    ));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  
  return (
    <> {techList ? (
      <div>
        <PageTitle
        activeMenu={lang.menu.testTechniqueList}
        motherMenu={lang.menu.assessment}
        pageContent="Assessment"
      />
<br />
      <Row className="mb-3">
          <Col xs={7}>
                  <Form.Group controlId="formBasicSearch">
                    <Form.Control
                      type="text"
                      placeholder={lang.menu.serachJob}
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </Form.Group>
                </Col>
              <Col xs={1} className="d-flex offset-md-1">
              {quotaMax ? (
                <Badge className="d-flex align-items-center justify-content-center badge-rounded" 
                variant="outline-danger"      style={{ color: quotaMax.quotaAssessment >=  quotaMax.numberquota_max ? 'red' : '#74188D' }}>
                  <AiOutlinePieChart size={20} />{' '}
                  {lang.menu.quotaUsed} : {quotaMax.quotaAssessment}/{quotaMax.numberquota_max}
                </Badge>
              ) : null}
            </Col>
              <Col xs={2} className="d-flex align-items-end justify-content-end offset-md-1">
            
              {companyid && companyid!==1 ? (
              <Link
              to={isDisable ? "#" : "/CreateTestForm"}
              className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
              onClick={e => isDisable && e.preventDefault()}
              >
            <i className="fas fa-plus"></i> {lang.menu.newTest}
          </Link>
        ):

        companyid && companyid===1 && connectUser &&connectUser.userRole===3  ? (
          <Link
          to={isDisable ? "#" : "/CreateTestForm"}
          className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
          onClick={e => isDisable && e.preventDefault()}
          >
        <i className="fas fa-plus"></i> {lang.menu.newTest}
      </Link>)
        
        :(
        <Link to="/CreateTestForm" className="btn btn-primary test-list-button">
            <i className="fas fa-plus"></i> {lang.menu.newTest}
          </Link>)}
          </Col>
          </Row>
        <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
          <Nav variant="tabs" className="mb-4">
            <Nav.Item>
              <Nav.Link eventKey="Coding">{lang.menu.coding}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="QCM">{lang.menu.QCM}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Both">{lang.menu.Both}</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Coding">
              {renderFilteredTests()}
            </Tab.Pane>
            <Tab.Pane eventKey="QCM">
              {renderFilteredTests()}
            </Tab.Pane>
            <Tab.Pane eventKey="Both">
              {renderFilteredTests()}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header >
            <Modal.Title>{lang.menu.Update}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedTest && (
              <Card>
              <Form onSubmit={handleUpdate}>

                              <Form.Group controlId="name">
          <Form.Label> <strong>{lang.menu.name} <span style={{ color: 'red' }}>*</span> :</strong> </Form.Label>
          <Form.Control
            type="text"
            name="name"
            defaultValue={selectedTest.name} 
            //value={formData.name}
            maxLength={60}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label><strong>{lang.menu.description} <span style={{ color: 'red' }}>*</span> :</strong> </Form.Label>
          
          <textarea
                      required
                      style={{ height: "100px" }}
                      defaultValue={selectedTest.description} 
                      onChange={handleInputChange}
                      type="text"
                      name="description"
                      //value={formData.description}
                      className={`form-control`}
                      placeholder="Description"
                      maxLength={1000}
                    />
        </Form.Group>
                <Row>
            <Col className="col-6">
        <Form.Group controlId="type">
          <Form.Label><strong>Type <span style={{ color: 'red' }}>*</span> :</strong>  </Form.Label>
          <Form.Control
            as="select"
            name="type"
            value={selectedTest.type}
            onChange={handleInputChange}
            required
          >
           
            <option value="Coding">{lang.menu.coding}</option>
            <option value="QCM">{lang.menu.QCM}</option>
            <option value="Both">{lang.menu.Both}</option>
          </Form.Control>
        </Form.Group>
        </Col>

        <Col className="col-6">
        <Form.Group controlId="level">
          <Form.Label><strong>{lang.menu.level} <span style={{ color: 'red' }}>*</span> :</strong>  </Form.Label>
          <Form.Control
            as="select"
            name="level"
            defaultValue={selectedTest.level} 
            onChange={handleInputChange}
            required
          >
            
            <option value="Beginner">{lang.menu.Beginner}</option>
            <option value="Intermediate">{lang.menu.Intermediate}</option>
            <option value="Advanced">{lang.menu.Advanced}</option>
          </Form.Control>
        </Form.Group>
        </Col>
        </Row>



        {selectedTest.type === "Coding" || selectedTest.type === "Both" ? (
          <>
          <br/>
          <Row>
            <Col className="col-6">
              <Form.Group controlId="codingTests">
              <div className="row">
      <div className="col-md-9">
        <Form.Label>
        <strong>{lang.menu.codTest} <span style={{ color: 'red' }}>*</span> :</strong>         </Form.Label>
      </div>
      <div className="col-md-3">
        <Link to="/TopicsByCandidate" className="buttonSkills">
      <i className="fas fa-plus"></i>
    </Link>
      </div>
    </div>                <Select
                  options={formData.availableCodingTests}
                  isMulti
                  value={selectedTest.codingTest.map(test => ({ label: test.name, value: test.id }))} 

                  onChange={handleCodingTestsChange}
                  required
                  isOptionDisabled={() => formData.codingTests.length >= 5 || (formData.type === "Both" && (formData.codingTests.length + formData.qcmTests.length) >= 5)}

                />
              </Form.Group> 
            </Col>
            <Col className="col-6">
              <Form.Group controlId="coefCoding">
                <Form.Label><strong>{lang.menu.coefCoding} <span style={{ color: 'red' }}>*</span> :</strong> </Form.Label>
                <Form.Control
                  type="number"
                  name="coefCoding"
                  //value={formData.coefCoding}
                  onChange={handleInputChange}
                  placeholder="Please enter the coefficient"
                  defaultValue={selectedTest.coefCoding} 
                  min={1}
                  max={9}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          
          

        
          </>
        ) : null}
 {selectedTest.type === "QCM" || selectedTest.type === "Both" ? (
          <>
                    <br/>

          <Row>
            <Col className="col-6">
              <Form.Group controlId="qcmTests">
              <div className="row">
      <div className="col-md-9">
        <Form.Label>
        <strong>{lang.menu.testTechniqueMCQ} <span style={{ color: 'red' }}>*</span> :</strong>         </Form.Label>
      </div>
      <div className="col-md-3">
      <Link to="/TechQCM" className="buttonSkills">
      <i className="fas fa-plus"></i>
    </Link>
      </div>
    </div>                
          <Select
                  options={formData.availableQcmTests}
                  isMulti
                  onChange={handleQcmTestsChange}
                  value={selectedTest.techQCM.map(test => ({ label: test.test_name, value: test.test_id }))} 

                  required
                  isOptionDisabled={() => formData.qcmTests.length >= 5 || (formData.type === "Both" && (formData.codingTests.length + formData.qcmTests.length) >= 5)}

                />
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group controlId="coefQCM">
                <Form.Label><strong>{lang.menu.coefQCM} <span style={{ color: 'red' }}>*</span> :</strong> </Form.Label>
                <Form.Control
                  type="number"
                  name="coefQCM"
                  onChange={handleInputChange}
                  defaultValue={selectedTest.coefQCM} 

                  placeholder={lang.menu.plzCoef}
                  required
                  min={1}
                  max={9}
                />
              </Form.Group>
            </Col>
            </Row>
          </>
        ) : null}






{selectedTest.type && 
          <>
          <Row style={{marginTop: "2%"}}>
              <Col className="col-4">
              <Form.Group controlId="allowCopyPaste">
  <Form.Check
    type="checkbox"
    label={lang.menu.AllowCopyPaste}
    name="allowCopyPaste"
    checked={formData.allowCopyPaste}
    onChange={handleCheckboxChange}
  />
</Form.Group>
          </Col>

          <Col className="col-4">
        <Form.Group controlId="takeSnapshots">
  <Form.Check
    type="checkbox"
    label={lang.menu.takeSnapshots}
    name="takeSnapshots"
    checked={formData.takeSnapshots}
    onChange={handleCheckboxChange}
  />

              {selectedTest.takeSnapshots === 1 && 
              <Form.Group controlId="numberOfSnapShots">
              <Form.Label><strong>{lang.menu.numberOfSnapShots} <span style={{ color: 'red' }}>*</span> :</strong> </Form.Label>
              <Form.Control
                          type="number"
                          name="numberOfSnapShots"
                          defaultValue={selectedTest.numberOfSnapShots} 
                          onChange={handleInputChange}
                          min="0"
                          required
                        />
            </Form.Group>}

            
        </Form.Group>
        </Col>
        <Col className="col-4">
        <Form.Group controlId="takeScreenshots">
          
  <Form.Check
    type="checkbox"
    label={lang.menu.takeScreenshots}
    name="takeScreenshots"
    checked={formData.takeScreenshots}
    onChange={handleCheckboxChange}
  />
              {selectedTest.takeScreenshots === 1 && 
              <Form.Group controlId="numberOfScreenShots">
              <Form.Label><strong>{lang.menu.numberOfScreenShots} <span style={{ color: 'red' }}>*</span> :</strong>  </Form.Label>
              <Form.Control
                          type="number"
                          name="numberOfScreenShots"
                          defaultValue={selectedTest.numberOfScreenShots} 
                          onChange={handleInputChange}
                          min="0"
                          required
                          
                        />
            </Form.Group>}
              
        </Form.Group>
        </Col>
        </Row>
        </>
        }
        <br/>
<Row>

<Col><Button variant="secondary" onClick={() => setShowModal(false)}>
              {lang.menu.cancel}
            </Button></Col>
<Col>
<Button variant="primary" type="submit">
              {lang.menu.SaveChanges}
            </Button>
</Col>

</Row>

        


              </Form></Card>
            )}
          </Modal.Body>
   
        </Modal>
        <ToastContainer />

      </div>
    ) : (
      <Modal show={showAccessDeniedModal}>
        <Modal.Header>
          <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lang.menu.YoudontHavePerm}
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    )}</>
  );
}

export default TechList;
