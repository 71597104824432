import React, { useMemo, useState, useEffect, Fragment } from "react";
import { useTable, useFilters, useSortBy, useGlobalFilter, usePagination } from "react-table";
import axios from "axios";
import { Card, Modal } from "react-bootstrap";
import translations from "../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { FaSort } from "react-icons/fa";
import { useLocation } from "react-router-dom";


function CandidatesPerApp() {
  const [candidates, setCandidates] = useState([]);
  const [currentCandidates, setCurrentCandidates] = useState( process.env.REACT_APP_PAGINATION);
  const location = useLocation();
  const [email, setEmail] = useState('');

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");

    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const token = localStorage.getItem("token");

  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    return data[0].companyID;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
        const compId = await getUserCompanyID();

        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAllCandidatesWithRepetitions/${compId}/${userId}/${currentCandidates}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCandidates(response.data.candidates);
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchData();
    if(currentCandidates!=="All"){
      setCurrentCandidates("All")
    }
  }, [token]);

  const data = useMemo(() => candidates, [candidates]);

  const columns = useMemo(
    () => [
      {
        Header: lang.menu.email,
        accessor: "email",
        Cell: ({ value }) => (
          <a href="RadarGlobal" onClick={() => localStorage.setItem("emailSelected", JSON.stringify(value))}>
            {value}
          </a>
        ),
      },
      {
        Header: lang.menu.PreselectedOffers,
        accessor: "nbre_repetition",
      },
      {
        Header: lang.menu.CompletedTestOffers,
        accessor: "nbre_repetition_done",
      },
      {
        Header: lang.menu.PendingTestOffers,
        accessor: "nbre_repetition_not_yet",
      },
    ],
    [lang]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const emailParam = urlParams.get('email');


    if (emailParam) {
        setEmail(emailParam);
        setGlobalFilter(emailParam);
    }

}, [location,globalFilter]);
  return (
    <Fragment>
      <PageTitle activeMenu={lang.menu.candPerApp} motherMenu={lang.menu.dashboard} />
      <Card>
        <Card.Body><span>
      
      <input
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        placeholder={lang.menu.Search}
        style={{
          marginBottom: "10px",
          padding: "10px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #ddd"
        }}
      />
    </span>
          <div className="table-responsive">
            <table {...getTableProps()} className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead style={{ backgroundColor: "#8cd6c8" }}>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                          {!(column.isSorted) && <FaSort />}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()} style={{ fontSize: "14px" }}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}

export default CandidatesPerApp;
