import React, { useState, useEffect } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import { Button, Card, Row, Col, Modal } from 'react-bootstrap';
import axios from 'axios';
import { TbClockQuestion, TbDeviceDesktopCode } from 'react-icons/tb';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import { languageOptions } from "../TechnicalTest/constants/languageOptions";
import { languageOptions1 } from '../Jobs/OneCompiler/constants/languageOptions1';
   import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { TfiTimer } from "react-icons/tfi";
  import { SiLevelsdotfyi } from "react-icons/si";
function JobRoleTests() {
  const [access, setAccess] = useState(1);
  const [steps, setSteps]= useState([]);
  const token=localStorage.getItem("token");
  const [stepsF, setStepsF]= useState([]);

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(async () => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobRole/getStepsForJobProfile/${idjob}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
  console.log("step 1;",response.data)
    setStepsF(response.data.steps)
  }, []);
  
 
  useEffect(() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const showInterface = async (idjob, userId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findOneCandidateJob/${userId}/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setAccess(response.data[0].candAccept);
      } catch (error) {
        console.error("Error fetching Access:", error);
      }
    }
    showInterface(idjob, userId);
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false);
  useEffect(() => {
    if (access === 2) {
      setShowAccessDeniedModal(true);
    }
  }, [access]);
 
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    const storedStep = localStorage.getItem('currentStep');
 
    if (storedStep) {
        setCurrentStep(parseInt(storedStep));
    } else {
        setCurrentStep(1);
        localStorage.setItem('currentStep', '1');
    }
}, []);
  const [missingIds, setMissingIds] = useState([]);
  const [missingIdsPer, setMissingIdsPer] = useState([]);
  const [selectedJobRoleTests, setSelectedJobRoleTests] = useState([]);
  const [selectedJobRoleTestsPer, setSelectedJobRoleTestsPer] = useState([]);
  const history = useHistory();
  const [testDetails, setTestDetails] = useState(null);
  const [testPerDetails, setTestPerDetails] = useState(null);
  const [allTestsPassed, setAllTestsPassed] = useState(false);
  const [allTestsCompleted, setAllTestsCompleted] = useState(false);
 
  const [allTestsPerPassed, setAllTestsPerPassed] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentTechnicalTestStep, setCurrentTechnicalTestStep] = useState(1);
  const [testDetailsQCM, setTestDetailsQCM] = useState([]);
  const [testDetailsCoding, setTestDetailsCoding] = useState([]);
  const [tesTechDetails, setTesTechDetails] = useState([]);
  const [missingIdsQCM, setMissingIdsQCM] = useState([]);
  const [missingIdsCoding, setMissingIdsCoding] = useState([]);
  const [selectedJobRoleTestsTech, setSelectedJobRoleTestsTech] = useState([]);
  const [qcmTestsDisabled, setQcmTestsDisabled] = useState(false);
  const [codingTestsDisabled, setCodingTestsDisabled] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const [jobDesc, setJobDesc] = useState("");
  const [level, setLevel]= useState();

    //assignment
  const [assignments, setAssignments]=useState([]);
  const getLanguageName = (languageId) => {

    const languageOption = languageOptions.find(
      (option) => option.id === languageId
    );


    return languageOption ? languageOption.name : "";
  };
  // Function to get language name by languageId
  const getLanguageName1 = (languageId) => {
    const languageOption = languageOptions1.find(
      (option) => option.id === languageId
    );
    return languageOption ? languageOption.label : "";
  };

  useEffect(()=>{
    const getHasAssignment = async()=>{
      try {
        const pathname = window.location.pathname;
        const jobid = pathname.split("/").pop();
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/hasAssignment/${jobid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        if(response.data.hasAssignment){
          setAssignments(response.data.assignment);
        }
        
      } catch (error) {
        console.error(error);
      }
    }

    getHasAssignment();
  }, [])
 
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
 
  useEffect(() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    const showJobRoleTitle = async (idjob) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setJobTitle(response.data.JobTitle);
        setJobDesc(response.data.JobDescription);
      } catch (error) {
        console.error("Error fetching jobRole tests:", error);
      }
    }
    showJobRoleTitle(idjob);
  }, []);
 
  useEffect(() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    localStorage.setItem('jobRoleId', idjob);
 
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const localId = userDetails ? userDetails.localId : null;
 
    const showJobRoleTests = async (idjob) => {
      try {
        const response1 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestPsychoIds/${localId}/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const missingTestIds = response1.data.missingTestIds;
        setMissingIds(missingTestIds);
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobroleById/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const tests = response.data.testPsychoIds;
        setSelectedJobRoleTests(tests);
      } catch (error) {
        console.error("Error fetching jobRole tests:", error);
      }
    };
    showJobRoleTests(idjob);
  }, []);
 
  useEffect(() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    localStorage.setItem('jobRoleId', idjob);
 
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const localId = userDetails ? userDetails.localId : null;
 
    const showJobRoleTestsPer = async (idjob) => {
      try {
        const response1 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestPerIds/${localId}/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const missingTestIds = response1.data.missingTestIds;
        setMissingIdsPer(missingTestIds);
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobroleByIdPer/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const tests = response.data.testPerIds;
        setSelectedJobRoleTestsPer(tests);
      } catch (error) {
        console.error("Error fetching jobRole tests:", error);
      }
    };
    showJobRoleTestsPer(idjob);
  }, []);
 
  useEffect(() => {
    if (testDetails && testDetails.length > 0) {
      const allPassed = testDetails.every(test => !missingIds.includes(test.testId));
      setAllTestsPassed(allPassed);
    } else {
      setAllTestsPassed(true);
    }
  }, [missingIds, testDetails]);
 
  useEffect(() => {
    if (testDetailsQCM[0] && testDetailsQCM[0].length > 0) {

      const allPassed = testDetailsQCM[0].every(test => !missingIdsQCM.includes(test.id));
      setQcmTestsDisabled(allPassed);
    } else {
      setQcmTestsDisabled(true);
    }
  }, [missingIdsQCM, testDetailsQCM]);
 
  useEffect(() => {
    if (testDetailsCoding[0] && testDetailsCoding[0].length > 0) {

      const allPassed = testDetailsCoding[0].every(test => !missingIdsCoding.includes(parseInt(test.id, 10)));
      setCodingTestsDisabled(allPassed);
    } else {
      setCodingTestsDisabled(true);
    }
  }, [missingIdsCoding, testDetailsCoding]);
 
  useEffect(() => {
    if (testPerDetails && testPerDetails.length > 0) {
      const allPassed = testPerDetails.every(test => !missingIdsPer.includes(test[0].Id));
      setAllTestsPerPassed(allPassed);
    } else {
      setAllTestsPerPassed(true);
    }
  }, [missingIdsPer, testPerDetails]);
 
  const fetchTestDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestWithQuestionsById/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      localStorage.setItem('questionsNbrPsycho', response.data.question_globale);
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
 
  const loadTestDetails = async () => {
    try {
      const details = [];
      for (const testId of selectedJobRoleTests) {
        const testDetail = await fetchTestDetails(testId);
        if (testDetail) {
          details.push(testDetail);
        }
      }
      setTestDetails(details);
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };
 
  const fetchTestPerDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/getTestPerById/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
 
  const loadTestPerDetails = async () => {
    try {
      const details = [];
      for (const testId of selectedJobRoleTestsPer) {
        const testDetail = await fetchTestPerDetails(testId);
        if (testDetail) {
          details.push(testDetail);
        }
      }
      setTestPerDetails(details);
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };
  const fetchTestDetailsTech = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testTech/getTestDetailsById/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
       localStorage.setItem("testId", response.data.testId);
       setLevel(response.data.level);
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
  const loadTesTechDetails = async () => {
    try {
      const details = [];
      let totalCoding = 0;
      let totalTechQCM = 0;
 
      for (const testId of selectedJobRoleTestsTech) {
        const testDetail = await fetchTestDetailsTech(testId);
        if (testDetail) {
          details.push(testDetail);
        }
        if (testDetail && testDetail.codingTest && testDetail.codingTest.length > 0) {
          totalCoding += testDetail.codingTest.length;
 
        }
        if (testDetail && testDetail.techQCM && testDetail.techQCM.length > 0) {
          totalTechQCM += testDetail.techQCM.length;
 
        }
       
      }
      setTotal(totalTechQCM+totalCoding)
      setTesTechDetails(details);
    } catch (error) {
      console.error("Error fetching TesTech test details:", error);
    }
  };
  const renderTestPsycho = () => (
    <>
{testDetails && testDetails.length > 0 && testDetails.map((test, index) => {
          const isMissing = missingIds.includes(test.testId);
        return (
          <Card key={index} style={{ border: "2px solid #b0caf2", fontFamily: "Gabarito" }}>
            <Card.Header style={{ justifyContent: "center" }}>
              <h2 style={{ color: "#8CD6C8" }}>
                <strong>{test.test_name}</strong>
              </h2>
            </Card.Header>
            <Card.Body style={{ textAlign: "left" }}>
              <Card.Text>
                <strong style={{ color: "purple" }}>{lang.menu.description}:</strong> {test.description.length <= 1000 ? test.description : `${test.description.substring(0, 1000)}...`}
              </Card.Text>
              <Row style={{ marginTop: "2%" }}>
                <Col>
                  <h4 style={{ display: "inline-block" }}>
                    <strong style={{ color: "purple" }}>{lang.menu.questionPerCat}:</strong>
                  </h4> &nbsp;
                  <span style={{ display: "inline-block" }}>{test.test_questionNbre}</span>
                </Col>
                <Col>
                  <h4 style={{ display: "inline-block" }}>
                    <strong style={{ color: "purple" }}>{lang.menu.overallQuestion}:</strong>
                  </h4> &nbsp;
                  <span style={{ display: "inline-block" }}>{test.question_globale}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4 style={{ display: "inline-block" }}>
                    <strong style={{ color: "purple" }}>{lang.menu.timePerQuestion}:</strong>
                  </h4> &nbsp;
                  <span style={{ display: "inline-block" }}>{test.timePerQuestion} {lang.menu.seconds}</span>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  if (isMissing) {
                    window.location.href = `/TestPsychotechniqueCandidat/${test.testId}`;
                    localStorage.setItem('timePerQuestionPsycho', test.timePerQuestion);
                  }
                }}
                disabled={!isMissing}
              >
                {isMissing ? lang.menu.passTest : lang.menu.testPassed}
              </Button>
            </Card.Footer>
          </Card>
        );
      })}
    </>
  );
 
  const renderTestPersonality = () => (
    <>
    {testPerDetails && testPerDetails.length > 0 && testPerDetails.map((test, index) => {
        const isMissingPer = missingIdsPer.includes(test[index].Id);
        return (
          <Card key={index} style={{ border: "2px solid #b0caf2", fontFamily: "Gabarito" }}>
          <Card.Header style={{ fontSize: "150%", backgroundColor: "#b0caf2" }}>{test[index].name}</Card.Header>
          <Card.Body>
            <Card.Text>
              <strong>{lang.menu.description}: </strong> {test[index].description}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              onClick={() => {
                if (isMissingPer) {
                  window.location.href = `/page-test-candidate/${test[index].Id}`;
                }
              }}
              disabled={!isMissingPer}
            >
              {isMissingPer ? lang.menu.passTest : lang.menu.testPassed}
            </Button>
          </Card.Footer>
        </Card>
        );
      })}
    </>
  );
  function getColorByLevel(level) {
    if (!level || typeof level !== "string") {
      return "#000000"; 
    }
  
    switch (level.toLowerCase()) {
      case "beginner":
        return "#00d4d4";
      case "intermediate":
        return "#ffaa00";
      case "advanced":
        return "#ff0000";
      default:
        return "#000000"; 
    }
  }
  const renderTestTech = () => (
    <>
           

      {tesTechDetails && tesTechDetails.length > 0 && tesTechDetails[0].name && (
        <Card style={{ border: "2px solid #b0caf2", fontFamily: "Gabarito" }}>
                    <Card.Header>{ tesTechDetails[0].name}</Card.Header>

          <Card.Body>
            <div className="form-wizard">
              <Stepper className="nav-wizard" activeStep={currentTechnicalTestStep - 1}>
                {testDetailsQCM[0] && testDetailsQCM[0].length > 0 && (
                  <Step className="nav-link" label={<><TbClockQuestion />{lang.menu.QCM}</>} />
                )}
                {testDetailsCoding[0] && testDetailsCoding[0].length > 0 && (
                  <Step className="nav-link" label={<><TbDeviceDesktopCode />{lang.menu.coding}</>} />
                )}
              </Stepper>
            </div>
 
            {currentTechnicalTestStep === 1 && (
              <div>
                {testDetailsQCM[0] && testDetailsQCM[0].length > 0 ? (
                  <>
  {testDetailsQCM[0].map((testDetail, index) => {
    console.log("testDetail :",testDetail)
                    const isMissingQCMTest = missingIdsQCM.includes(testDetail.id);
                    return (
                      <Card key={index} style={{ border: "2px solid #b0caf2" }}>
                        <Card.Header style={{ justifyContent: "center" }}>
                          <h2 style={{ color: "#8CD6C8" }}>
                            <strong>{testDetail.name}</strong>
                          </h2>
                        </Card.Header>
                        <Card.Body style={{ textAlign: "left" }}>
                          <Card.Text>
                            <h4 style={{ display: "inline-block" }}>
                              <strong style={{ color: "purple" }}>Type:</strong>
                            </h4> &nbsp;
                            <span style={{ display: "inline-block", color: "black" }}>{lang.menu.QCM}</span>
                          </Card.Text>
                          <Card.Text>
                            <h4>
                              <strong style={{ color: "purple" }}>{lang.menu.description}:</strong>
                            </h4>
                            {testDetail.description.length <= 1000 ? testDetail.description : `${testDetail.description.substring(0, 1000)}...`}
                          </Card.Text>
                          <Row style={{ marginTop: "2%" }}>
                            <Col>
                              <h4 style={{ display: "inline-block" }}>
                                <strong style={{ color: "purple" }}>{lang.menu.questionPerSkill}:</strong>
                              </h4> &nbsp;
                              <span style={{ display: "inline-block" }}>{testDetail.questionNbre}</span>
                            </Col>
                            <Col>
                              <h4 style={{ display: "inline-block" }}>
                                <strong style={{ color: "purple" }}>{lang.menu.overallQuestion}:</strong>
                              </h4> &nbsp;
                              <span style={{ display: "inline-block" }}>{testDetail.nbre_question_globale}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h4 style={{ display: "inline-block" }}>
                                <strong style={{ color: "purple" }}>{lang.menu.timePerQuestion}:</strong>
                              </h4> &nbsp;
                              <span style={{ display: "inline-block" }}>{testDetail.timePerQuestion} {lang.menu.seconds}</span>
                            </Col>
                          </Row>
                        </Card.Body>
                        <Card.Footer>
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (isMissingQCMTest) {
                                window.location.href = `/TestTechniqueCandidat/${testDetail.id}`;
                                localStorage.setItem('questionsNbr', testDetail.nbre_question_globale);
                                localStorage.setItem('timePerQuestion', testDetail.timePerQuestion);
                              }
                            }}
                            disabled={!isMissingQCMTest}
                          >
                            {isMissingQCMTest ? lang.menu.passTest : lang.menu.testPassed}
                          </Button>
                        </Card.Footer>
                      </Card>
                    );
                  })}
                  </>
                ) : (
                  <>
                    {testDetailsCoding[0] && testDetailsCoding[0].length > 0 && (
                      <div>
                        <h4>{lang.menu.coding}</h4>
                        {testDetailsCoding && testDetailsCoding.length > 0 && testDetailsCoding[0].map((testDetail, index) => {
                          const testId = parseInt(testDetail.id, 10);
                          const isMissingCodingTest = missingIdsCoding.includes(testId);
                          return (
                            <Card key={index} style={{ border: "2px solid #b0caf2" }}>
 {console.log("testDetail:",testDetail)}
                              <Card.Header style={{ backgroundColor: "#b0caf2" }}>{testDetail.name}</Card.Header>
                              <Card.Body>
                           
                                <Row>
                                <Col>
  <strong>{lang.menu.language}:</strong> 
  {testDetail.compiler === "oneCompiler"
    ? getLanguageName1(parseInt(testDetail.languageId, 10)) || "Unknown Language"
    : getLanguageName(parseInt(testDetail.languageId, 10)) || "Unknown Language"}
</Col>
                                  <Col>
                                    <strong> <TfiTimer />{"  "}{lang.menu.timer}:</strong> {testDetail.Timer >= 60
      ? `${Math.floor(testDetail.Timer / 60)} min`
      : ""}
    {testDetail.Timer % 60 > 0 || testDetail.Timer >= 60
      ? ` ${testDetail.Timer % 60} s`
      : ""}
                                  </Col>
                                  <Col>
                                  <strong ><SiLevelsdotfyi />{"  "} {lang.menu.level}:</strong><span style={{ color: getColorByLevel(testDetail.level) }}>{testDetail.level}</span>
                                  </Col>
                                </Row>
                              </Card.Body>
                              <Card.Footer>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    if (isMissingCodingTest) {
                                      const redirectURL = testDetail.compiler === "judge0" ? `/technical/${testId}` : `/technical2/${testId}`;
                                      window.location.href = redirectURL;
                                    }
                                  }}
                                  disabled={!isMissingCodingTest}
                                >
                                  {isMissingCodingTest ? lang.menu.passTest : lang.menu.testPassed}
                                </Button>
                              </Card.Footer>
                            </Card>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
 
            {currentTechnicalTestStep > 1 && (
              <>
                {testDetailsCoding[0] && testDetailsCoding[0].length > 0 && (
                  <div>
                    <h4>{lang.menu.coding}</h4>
                    {testDetailsCoding && testDetailsCoding.length > 0 && testDetailsCoding[0].map((testDetail, index) => {
                      const testId = parseInt(testDetail.id, 10);
                      console.log("missingIdsCoding .",missingIdsCoding)
                      console.log("testId .",testId)
                      const isMissingCodingTest = missingIdsCoding.includes(testId);
                      return (
                        <Card key={index} style={{ border: "2px solid #b0caf2" }}>
 
                          <Card.Header style={{ backgroundColor: "#b0caf2" }}>{testDetail.name}</Card.Header>
                          <Card.Body>
                      
                            <Row>
                              <Col>
                                <strong>{lang.menu.language}:</strong> {testDetail.languageId}
                              </Col>
                              <Col>
                                <strong>{lang.menu.timer}:</strong> {testDetail.Timer} {lang.menu.seconds}
                              </Col>
                            </Row>
                          </Card.Body>
                          <Card.Footer>
                            <Button
                              variant="primary"
                              onClick={() => {
                                if (isMissingCodingTest) {
                                  const redirectURL = testDetail.compiler === "judge0" ? `/technical/${testId}` : `/technical2/${testId}`;
                                  window.location.href = redirectURL;
                                }
                              }}
                              disabled={!isMissingCodingTest}
                            >
                              {isMissingCodingTest ? lang.menu.passTest : lang.menu.testPassed}
                            </Button>
                          </Card.Footer>
                        </Card>
                      );
                    })}
                  </div>
                )}
                <Button
                  className="me-2"
                  variant="success light"
                  onClick={() => setCurrentTechnicalTestStep(currentTechnicalTestStep - 1)}
                >
                  {lang.menu.previousInter}
                </Button>
              </>
            )}
 
            {currentTechnicalTestStep < 2 && testDetailsCoding[0] && testDetailsCoding[0].length > 0 && testDetailsQCM[0] && testDetailsQCM[0].length > 0 && (
              <Button
                className="me-2"
                variant="primary light"
                onClick={() => setCurrentTechnicalTestStep(currentTechnicalTestStep + 1)}
                disabled={currentTechnicalTestStep === 1 && testDetailsQCM[0] && testDetailsQCM[0].length > 0 && !qcmTestsDisabled}
              >
                {lang.menu.nextInter}
              </Button>
            )}
            <p></p>
          </Card.Body>
        </Card>
      )}
    </>
  );

  
  
 
  const fetchTechnicalTestDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/qcmQuestions/getAllQcmQuestionsByTestID/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      localStorage.setItem('questionsNbrTech', response.data.length);
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
 
  const fetchTechnicalCodingTestDetails = async (testId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/codingQuestions/getAllCodingQuestionsByTestID/${testId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      return response.data;
    } catch (error) {
      console.error("Error fetching test details:", error);
      return null;
    }
  };
  const loadQCMDetails = async () => {
    try {
      const details = [];
      for (const testId of selectedJobRoleTestsTech) {
        const testDetail = await fetchTestDetailsTech(testId);
        if (testDetail && testDetail.techQCM && testDetail.techQCM.length > 0) {
          details.push(testDetail.techQCM);
        }
      }
      setTestDetailsQCM(details);
    } catch (error) {
      console.error("Error fetching QCM test details:", error);
    }
  };
 
  const loadCodingDetails = async () => {
    try {
      const details = [];
      for (const testId of selectedJobRoleTestsTech) {
        const testDetail = await fetchTestDetailsTech(testId);
        if (testDetail && testDetail.codingTest && testDetail.codingTest.length > 0) {
          details.push(testDetail.codingTest);
        }
      }
      setTestDetailsCoding(details);
    } catch (error) {
      console.error("Error fetching Coding test details:", error);
    }
  };

 
 
 
  const handleNext = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };
 
  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
 
  const handleSubmitTest = async() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const localId = userDetails ? userDetails.localId : null;
 
    try {
      const compId= await getUserCompanyID()
      const storedLang = localStorage.getItem('selectedLang');
      let langue = "English";
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);
         langue=parsedLang.language
        }

      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobCandiatefinal/${localId}/${idjob}/${compId}/${langue}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
     localStorage.setItem("currentStep", 1)
 
      history.push("/page-test-completed");
    } catch (error) {
      console.error("Error executing the request:", error);
    }
  };
 
 
  useEffect(() => {
    if (selectedJobRoleTests.length > 0) {
      loadTestDetails();
    }
  }, [selectedJobRoleTests]);
 
  useEffect(() => {
    if (selectedJobRoleTestsPer.length > 0) {
      loadTestPerDetails();
    }
  }, [selectedJobRoleTestsPer]);
  useEffect(() => {
    if (selectedJobRoleTestsTech.length > 0) {
      loadTesTechDetails();
      loadQCMDetails()
      loadCodingDetails()
    }
  }, [selectedJobRoleTestsTech]);
  useEffect(() => {
    const pathname = window.location.pathname;
    const idjob = pathname.split("/").pop();
    const userDetailsString = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(userDetailsString);
    const localId = userDetails ? userDetails.localId : null;
 
    const showJobRoleTestsTech = async (idjob) => {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestIds/${localId}/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
 
        const missingTestIds = response1.data.missingTestIds;
        setMissingIdsQCM(missingTestIds);
 
        const response2 = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findMissingTestIdsCoding/${localId}/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
 
        const missingTestIdsCoding = response2.data.missingTestIds;
 
        setMissingIdsCoding(missingTestIdsCoding);
 
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobroleByIdTech/${idjob}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const tests = response.data.testTechIds;
        setSelectedJobRoleTestsTech(tests);
      } catch (error) {
        console.error("Error fetching jobRole tests:", error);
      }
    };
 
    showJobRoleTestsTech(idjob);
  }, []);
 
 
  const determineSteps = () => {
    let steps = [];
    if (testPerDetails && testPerDetails.length > 0) {
      steps.push({ label: lang.menu.PersonalityTest, key: 'personality' });
    }
    if (testDetails && testDetails.length > 0) {
      steps.push({ label: lang.menu.testPsychoTechnique, key: 'psycho' });
    }
 
    if (tesTechDetails && tesTechDetails.length > 0) {
      steps.push({ label: lang.menu.testTechnique, key: 'technical' });
    }

    if (assignments && assignments.length > 0) {
      steps.push({ label: lang.menu.Assignment, key: 'assignment' });
    }

    return steps;
  };
 
  
  useEffect(()=>{
    setSteps(determineSteps());
  },[testPerDetails, testDetails, tesTechDetails, assignments]);
  
  //const steps = determineSteps();
 
  const renderSteps = () => {
   
    return <p>${steps}</p>
  };
 
  useEffect(() => {
    if((tesTechDetails ) &&
    (testDetails ) &&
    (testPerDetails ) && 
    (assignments)){
    setAllTestsCompleted(allTestsPassed && qcmTestsDisabled && codingTestsDisabled);
 
  }
  }, [allTestsPassed, qcmTestsDisabled, codingTestsDisabled]);


  
  //handle start the assignment to check if the time does not exceed deadline
  const [submitAssignmentButton, setSubmitAssignmentButton] = useState(true);
  const [AssignmentPassed, setAssignmentPassed] = useState(false);

  const [assignmentStarted, setAssignmentStarted]= useState(false);
  const [allowCompressedFile, setAllowCompressedFile]= useState(false);
  useEffect(()=>{
    const startAssignment = async ()=>{
      try {

        const pathname = window.location.pathname;
        const idjob = pathname.split("/").pop();
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;

        //correct this
        if(assignments.length >0){
          const assignment_id = assignments[0].id;
        
        
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/start/${userId}/${idjob}/${assignment_id}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setSubmitAssignmentButton(!response.data.deadline);
        }
      } catch (error) {
        console.error(error);
      }
    }

    const checkUploadCompressedFile = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setAllowCompressedFile(
            data.some(
              (permission) =>
                permission.title === "assignment-compressed-file"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    checkUploadCompressedFile();
   

    if(!assignmentStarted && (steps.length >= currentStep )&& (steps[currentStep - 1].key === "assignment") ){

      setAssignmentStarted(true);
      startAssignment();
    }

  },[ steps])

    //handle assignment submit
    const [assignmentURL, setAssignmentURL] = useState();
    const [commentAssignment, setCommentAssignment]= useState(null);
    const [importAssignment, setImportAssignment] = useState(null);

  const handleFileSelection = (e) => {
    setAssignmentURL(null);
    setImportAssignment(e.target.files[0]);
  };

  const validateURL = (str) => {
    const pattern = new RegExp(
        '^https://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*\\(\\),]|' +
        '(?:%[0-9a-fA-F][0-9a-fA-F]))+$'
    );
    return pattern.test(str);
};

const validateFile = (file) => {
  const validExtensions = ['.zip', '.rar', '.tar', '.gz'];
  const fileExtension = file.name.split('.').pop().toLowerCase();
  const isValidFile = validExtensions.includes(`.${fileExtension}`);
  return isValidFile;
};

  const handleAssignmentURL = (e) =>{
    setImportAssignment(null);
    setAssignmentURL(e.target.value);
  }

  const submitAssignment = async (assignment_id)=>{
    try {

      const pathname = window.location.pathname;
      const idjob = pathname.split("/").pop();
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      const formData = new FormData();
      if (assignmentURL && !validateURL(assignmentURL)) {
        swal("Please Enter a correct URL", {
          icon: "error",
        });
        return;
    }
      if(importAssignment && !validateFile(importAssignment)){
        swal("Please Enter a compressed file", {
          icon: "error",
        });
        return;
      }
      if(importAssignment && assignmentURL){
        swal('Please enter one type of assignment.', {
          icon: "error",
        });
        return;
      }else if(importAssignment){
        formData.append("assignment", importAssignment);
      }else if(assignmentURL){
        formData.append("assignmentUrl", assignmentURL);
      }else{
        swal('Please enter the assignment.', {
          icon: "error",
        });
        return; 
      }
      
      formData.append("comment", commentAssignment);
      setAssignmentPassed(true)
      setSubmitAssignmentButton(false);

      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/submit/${userId}/${idjob}/${assignment_id}`, formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});

    } catch (error) {
      console.error(error);
    }
  }
  const renderAssignment = () => (
    <>
{assignments.length > 0  && assignments.map((assignment, index) => (
      
          <Card key={index} style={{ border: "2px solid #b0caf2", fontFamily: "Gabarito" }}>
            <Card.Header style={{ justifyContent: "center" }}>
              <h2 style={{ color: "#8CD6C8" }}>
                <strong>{assignment.name}</strong>
              </h2>
            </Card.Header>
            <Card.Body style={{ textAlign: "left" }}>
              <Card.Text>
                <strong style={{ color: "purple" }}>{lang.menu.description}:</strong> {assignment.description.length <= 1000 ? assignment.description : `${assignment.description.substring(0, 1000)}...`}
              </Card.Text>
              <Row style={{ marginTop: "2%" }}>
                <Col>
                  <strong style={{ color: "purple" }}>{lang.menu.level}: </strong> {assignment.difficulty}
                </Col>
                <Col>
                  <strong style={{ color: "purple" }}>{lang.menu.assignmentDuration}: </strong> {assignment.duration}
                </Col>
              </Row>
              {assignment.repository_recruiter ? <>
                <Row style={{ marginTop: "2%" }}>
                <input 
                  type="text"  
                  value={assignmentURL}
                  className="input-search form-control"
                  onChange={handleAssignmentURL}
                  />
              </Row>
              </> : (<>
              <Row style={{ marginTop: "2%" }}>
                <input 
                  type="text"  
                  value={assignmentURL}
                  className="input-search form-control"
                  onChange={handleAssignmentURL}
                  />
              </Row>
              <Row>
              <input className="input-search form-control" 
                type='file' 
                name="assignment" 
                accept=".zip,.rar,.tar,.gz"
                onChange={handleFileSelection} />
              </Row>
              </>)}
              <Row>
              <strong style={{ color: "purple" }}>{lang.menu.comment}: </strong>

              <textarea
                      required
                      onChange={(e)=>{
                        setCommentAssignment(e.target.value);
                      }}
                      type="text"
                      name="comment"
                      value={commentAssignment}
                      className={`form-control`}
                      placeholder={lang.menu.comment}
                      maxLength={1000}
                    />

              
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={!submitAssignmentButton}
                variant="primary"
                onClick={() => {
                  submitAssignment(assignment.id);
                  
                }}
              >
                {submitAssignmentButton ? lang.menu.submit : lang.menu.testPassed}
              </Button>
            </Card.Footer>
          </Card>
    ))}
    </>
  );



  return (
    <div>
      <div className="form-wizard">
    <Stepper className="nav-wizard"  activeStep={currentStep - 1}>
      {steps.length > 0 && steps.map((step, index) => (
        <Step className="nav-link" key={index} label={step.label} />
      ))}
    </Stepper>

    </div>
 
    {currentStep === 1 && (
      <>
 
        {
         (testPerDetails && testPerDetails.length !== 0) ? (<>
          <Row>{renderTestPersonality()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
            disabled={!allTestsPerPassed}
 
      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
          </>
 
 
        ) : (testDetails ) ? (<>
          <Row>{renderTestPsycho()}</Row>
          <div className="mt-3">

      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
      disabled={!allTestsPassed}
      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) : (tesTechDetails && tesTechDetails.length !== 0 ) ? (<>
          <Row>{renderTestTech()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) :(assignments && assignments.length !== 0 ) ? (<>
          <Row>{renderAssignment()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) : null}
      </>
    )}
 
 
 {currentStep === 2 && (
      <>
       {    localStorage.setItem("currentStep", currentStep)}
      
        {
         (stepsF.length!=0 && stepsF[1].label==="psycho"&& testDetails && testDetails.length !== 0 ) ? (<>
          <Row>{renderTestPsycho()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
            disabled={!allTestsPassed}
 
      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
          </>
 
 
        
        ) :
        (tesTechDetails && tesTechDetails.length !== 0 && stepsF.length!=0 && stepsF[1].label==="tech"  ) ? (<>
          <Row>{renderTestTech()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
        disabled={!(qcmTestsDisabled && codingTestsDisabled)}
        onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) : (assignments && assignments.length !== 0&& stepsF.length!=0 && stepsF[1].label==="assignment" ) ? (<>
          <Row>{renderAssignment()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button 
        disabled={!AssignmentPassed}

        onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) : null}
      </>
    )}

    {currentStep === 3 && (
      <>
      {    localStorage.setItem("currentStep", currentStep)}
        {(tesTechDetails ) &&
         (testDetails ) &&
         (testPerDetails ) &&
         (tesTechDetails.length !== 0 && stepsF.length!=0 && stepsF[2].label==="tech")? (<>
          <Row>
           
 
            {renderTestTech()}</Row>
            <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
      disabled={!(qcmTestsDisabled && codingTestsDisabled)}
      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div></>
        ) : (assignments && assignments.length !== 0 ) ? (<>
          <Row>{renderAssignment()}</Row>
          <div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
       disabled={!AssignmentPassed}

      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>
        ) : null}
      </>
    )}

    {currentStep === 4 && <>
    <Row>{    localStorage.setItem("currentStep", 4)}
{renderAssignment()}</Row>
<div className="mt-3">
      {currentStep > 1 && (
        <Button onClick={handlePrevious}>{lang.menu.previous}</Button>
      )}
      <Button
                 disabled={!AssignmentPassed}

 
      onClick={currentStep === steps.length ? handleSubmitTest : handleNext}>
        {currentStep === steps.length ? lang.menu.submit : lang.menu.next}
      </Button>
    </div>
    </>}
 


   
 
    <Modal 
  show={showAccessDeniedModal} 
  onHide={() => setShowAccessDeniedModal(false)} 
  backdrop="static"  
  keyboard={false}   
>
  <Modal.Header>
    <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {lang.menu.YoudontHavePerm}
  </Modal.Body>
</Modal>

  </div>
 
  );
}
 
export default JobRoleTests;
