import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

import {
  Button,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,Modal
} from "react-bootstrap";
import Switch from "../../../layouts/nav/Switch";
import swal from "sweetalert";
import { FcInvite } from "react-icons/fc";

import PageTitle from "../../../layouts/PageTitle";
import ReactPaginate from "react-paginate";
import translations from "./mulitlingue";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillGithub, AiFillLinkedin, AiOutlineMail } from "react-icons/ai";
import { PiSortAscendingLight } from "react-icons/pi";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { AiOutlinePieChart } from "react-icons/ai";

const CandidateList = () => {
  const [candidatePer, setCandidatePer] = useState([]);
  const token=localStorage.getItem("token");
  const [companyID, setCompanyID] = useState("");
  const [currentCandidates, setCurrentCandidates] = useState( process.env.REACT_APP_PAGINATION);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidatePer(
            data.some((permission) => permission.title === "candidate-list")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false); 

  useEffect(() => {
    if (!candidatePer) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidatePer]);
  const [jobRoles, setJobRoles] = useState([]);
  const [jobRolesIds, setJobRolesIds] = useState([]);
  const [jobIdAndTitle, setJobIdAndTitle] = useState([]);

  const [jobNames, setJobNames] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterStatus, setFilterStatus] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [candidates, setCandidates] = useState([]);
  const [jobInfo, setJobInfo] = useState();
  const [jobTitles, setJobTitles] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [selectedData, setSelectedData] = useState("");

  const handleShowRejectionModal = (data) => {
    setSelectedData(data); 
    setShowRejectionModal(true); 
  };
const handleCloseRejectionModal = () => setShowRejectionModal(false);
const handleRejectionReasonChange = (event) => {
  setRejectionReason(event.target.value);
};

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [status, setStatus] = useState(lang.menu.chooseStatus);

  const [jobRoleName, setJobRoleName] = useState(lang.menu.jobRole);
  const [show,setShow]=useState(true)
  const [subscriptionData,setSubscriptionData]=useState(true)
  const [quotaMax, setQuotaMax] = useState("");

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    console.log("currentUser :",currentUser)
    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    getUserCompanyID().then((compId) => {
      setCompanyID(compId)
      axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async response => {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;    
          if (compId!=1) {
            const filteredJobs = response.data.filter(job => job.companyid === compId && job.archived === 0);
            setJobRoles(filteredJobs);
            const ids = filteredJobs.map(job => job.id);
            const jobsss = filteredJobs.map(job => ({
              id: job.id,
              jobTitle: job.JobTitle 
            }));
                        setJobIdAndTitle(jobsss)       

            //setJobNameID(filteredJobs.map(job => {job.id,job.jobTitle}))
            setJobRolesIds(ids)
          } else {
             let filteredJobs
            if(currentUser.userRole==1){
            filteredJobs = response.data.filter(job => job.archived === 0);
          }else if (currentUser.userRole==3){
             filteredJobs = response.data.filter(job => job.archived === 0 && job.creator_uid === currentUser.localId);

          }
           console.log("filteredJobs: ", filteredJobs);

            
            setJobRoles(filteredJobs);
            const ids = filteredJobs.map(job => job.id);
            const jobsss = filteredJobs.map(job => ({
              id: job.id,
              jobTitle: job.JobTitle
            }));

            setJobIdAndTitle(jobsss)       
            setJobRolesIds(ids)
           
          }
          let subscriptionResponse="";
          let subscriptionData="";
                 
          if(compId!==1){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
                setQuotaMax(subscriptionData[4]); 
              }
          else if(compId===1&& currentUser.userRole===3 ){
                subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                subscriptionData = await subscriptionResponse.json();
                setQuotaMax(subscriptionData[4]); 
                      }
console.log("sub data:",subscriptionData)

                      setSubscriptionData(subscriptionData)
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des emplois:', error);
        });
    });
  }, []);


  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  

  const handleDropdownSelect3 = (value) => {
    setJobRoleName(value);
  };

  const handleDropdownSelect = (value) => {
    let stat
    if(value===lang.menu.accepted){
      stat="accepted"
    }else if(value===lang.menu.refused){
      stat="refused"
    }else if(value===lang.menu.pending){
      stat="pending"
    }else if(value==="all"){
      stat="all"
    }
    setStatus(stat);
  };
  const fetchCandidate = async () => {
    try {
      setLoading(true); 
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/allCandidatesPagin/${currentCandidates}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Assurez-vous que `token` est correctement défini
          }}
      );

      const filteredCandidate = response.data.filter(cand => jobRolesIds.includes(cand[15]));
      setCandidates(filteredCandidate);
      
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchJobTitles = () => {
      setLoading(true);
      const titles = {};
      for (const jobId of jobRolesIds) {
        const job = jobIdAndTitle.find(job => job.id === jobId);
        if (job) {
          titles[jobId] = job.jobTitle;
        }
      }
      setJobTitles(titles);
      setLoading(false);
    };
  
    fetchCandidate();
    fetchJobTitles();
    if(currentCandidates!=="All"){
      setCurrentCandidates("All")
    }
  }, [jobRolesIds,currentCandidates]);
  
  useEffect(() => {
    if (jobRoleName !== "Job Role" && jobRoleName !== "All" ) {
      axios
        .get(
          `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/ByJobRoleName?jobRoleName=` +
            jobRoleName, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
        )
        .then((res) => {
          setJobInfo(res.data);
        });
    }
    else if (jobRoleName === "All" ){
      fetchCandidate()

    }
  }, [jobRoleName]);
  const [jobRoleId, setJobRoleId] = useState(null);
  useEffect(() => {
    if (jobRoleName !== lang.menu.jobRole && jobRoleName !== "All") {
      axios
        .get(
          `${process.env.REACT_APP_APP_DOMAIN}/flask/jobRole/get_IdByJobRoleName?jobRoleName=` +
            jobRoleName, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
        )
        .then((res) => {
          setJobRoleId(res.data.jobRoleId);
        });
    }
  }, [jobRoleName]);

  const [isSelected, setIsSelected] = useState(false);

  const toggler=()=>{
    setIsSelected(!isSelected);

   show?setShow(false):setShow(true)
  }

  const [candidatesFilter, setCandidatesFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const perPage = process.env.REACT_APP_PAGINATION;
  const [displayedCandidates, setDisplayedCandidates] = useState([]);

  useEffect(() => {
    let filteredCandidates = displayedCandidates.length === 0 ? candidates : displayedCandidates;
  
    if (status !== "all" && status !== lang.menu.chooseStatus) {
      let statusFilter;
      switch (status) {
        case "en attente":
          statusFilter = "pending";
          break;
        case "accepté":
          statusFilter = "accepted";
          break;
        case "refusé":
          statusFilter = "refused";
          break;
        default:
          statusFilter = status;
          break;
      }
      filteredCandidates = filteredCandidates.filter(
        (candidate) => candidate[14] === statusFilter
      );
    }
  
    if (jobRoleName !== "All" && jobRoleName !== lang.menu.jobRole) {
      filteredCandidates = filteredCandidates.filter(
        (candidate) => candidate[15] === jobRoleId
      );
    }
  
    if (searchQuery !== "") {
      filteredCandidates = filteredCandidates.filter((candidate) =>
        candidate.toString().toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
  
    setCandidatesFilter(filteredCandidates);
  }, [status, jobRoleId, candidates, searchQuery, setLang]);
  
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const start = currentPage * perPage;
    const end = start + perPage;
    setDisplayedCandidates(candidatesFilter.slice(start, end));
  }, [currentPage, candidatesFilter, perPage]);

  const displayCandidates = displayedCandidates.length
    ? displayedCandidates
    : candidatesFilter;
    console.log("displayCandidates:",displayCandidates)

  const handleRejection = (userId,jobid) => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const localId = currentUser.localId;
    const storedLang = localStorage.getItem("selectedLang");
    let langue = "English";
    console.log("rejectionReason:",rejectionReason)
      if (storedLang) {
        const parsedLang = JSON.parse(storedLang);
         langue=parsedLang.language
        }    axios
      .post(
        `${process.env.REACT_APP_APP_DOMAIN}/flask/processRejection/${userId}/${localId}/${jobid}/${langue}`, 
      `motif=${encodeURIComponent(rejectionReason)}`,
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      )
      .then((response) => {
        toast.success(
          <div>
            <img
              src="/reject.jpg"
              alt="Rejection Image"
              width="100"
              height="50"
            />
            <p>{lang.menu.rejectMess}</p>
          </div>
        );
        fetchCandidate();
        handleCloseRejectionModal();
        const sortedCandidates = [...candidatesFilter];
        sortedCandidates.sort((a, b) => {
          if (sortOrder === "asc") {
            return a[6] - b[6]; // croissant
          } else {
            return b[6] - a[6]; // décroissant
          }
        });
        if (filterStatus === "all") {
          setCandidatesFilter([...sortedCandidates]);
        } else {
          const filtered = sortedCandidates.filter(
            (candidate) =>
              candidate[21] === (filterStatus === "treated" ? 1 :null)
          );
        setCandidatesFilter(filtered);
        }
       
      })
      .catch((error) => {
        swal(lang.menu.errorRejection, {
          icon: "error",
        });
      });
  };
  const handleAccept = (userId, email, jobId) => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const localId = currentUser.localId;
    const baseUrl = `${process.env.REACT_APP_APP_DOMAIN}/api`;
   
    const preselectionUrl = `${process.env.REACT_APP_APP_DOMAIN}/flask/processPreselection/${userId}/${jobId}/${localId}`;
    const getUserByEmailUrl = `${baseUrl}/users/local/getUserByEmail/${email}`;
    const storedLang = localStorage.getItem('selectedLang');
    let langue = "English";
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
       langue=parsedLang.language
      } 

    axios
      .get(getUserByEmailUrl, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async(getUserResponse) => {
          if(currentUser.userRole===1){
            if (getUserResponse) {
              // Appeler d'abord l'API pour créer un candidat
              axios
                  .post(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/create-candidate/${email}/${jobId}`, {}, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  })
                 
                          .then((preselectionResponse) => {
                          
                              axios
                                  .post(`${baseUrl}/candidateExt/sendEmail`, {
                                      email: email,
                                      jobId: jobId,
                                      subject: lang.menu.presTitle,
                                      lang: langue,
                                  }, {
                                      headers: {
                                          Authorization: `Bearer ${token}`,
                                      },
                                  })
                                  .then((createCandidateResponse) => {
                                     
                                      axios
                                          .put(`${baseUrl}/candidateExt/updateEmailSend/${jobId}/${email}`, {}, {
                                              headers: {
                                                  Authorization: `Bearer ${token}`,
                                              },
                                          })
                                          .then(() => {
                                              toast.success(
                                                  <div>
                                                      <img
                                                          src="/accept.png"
                                                          alt="Accept Image"
                                                          width="100"
                                                          height="50"
                                                      />
                                                      <p>{lang.menu.AcceptMess}</p>
                                                  </div>
                                              );
                                              axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/processPreselection/${userId}/${jobId}/${localId}`, 
                                                  {}, 
                                                  {
                                                      headers: {
                                                          Authorization: `Bearer ${token}`
                                                      }
                                                  })
                                                  .then(() => {
                                                      fetchCandidate();
                                                      const sortedCandidates = [...candidatesFilter];
                                                      sortedCandidates.sort((a, b) => {
                                                        if (sortOrder === "asc") {
                                                          return a[6] - b[6]; // croissant
                                                        } else {
                                                          return b[6] - a[6]; // décroissant
                                                        }
                                                      });
                                                  
                                                      if (filterStatus === "all") {
                                                        setCandidatesFilter([...sortedCandidates]);
                                                      } else {
                                                        const filtered = sortedCandidates.filter(
                                                          (candidate) =>
                                                            candidate[21] === (filterStatus === "treated" ? 1 :null)
                                                        );
                                                      setCandidatesFilter(filtered);
                                                      }
                                                  })
                                                  .catch((preselectionError) => {
                                                      console.error("Error processing preselection");
                                                      swal(lang.menu.errorPreselection, {
                                                          icon: "error",
                                                      });
                                                  });
                                          })
                                          .catch((preselectionError) => {
                                              console.error("Error Updating status", preselectionError);
                                              swal(lang.menu.errorUpdateStatus, {
                                                  icon: "error",
                                              });
                                          });
                                  })
                                
                          .catch((preselectionError) => {
                              console.error("Error processing preselection", preselectionError);
                              swal(lang.menu.errorPreselection, {
                                  icon: "error",
                              });
                          });
                  })
                  .catch((createCandidateError) => {
                      console.error("Error creating candidate", createCandidateError);
                      swal(lang.menu.errorPreselection, {
                          icon: "error",
                      });
                  });
          }
          }else{
            if(companyID!==1&& (currentUser.userRole===3 || currentUser.userRole===2) && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){
              await axios.put(
                `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${companyID}`,
                { fieldName:"quotaParcours",
                  "incrementValue":1,
                   quota_max:subscriptionData[4].numberquota_max,
                   quota_used:subscriptionData[4].quotaParcours }, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  }}
              );
              if (getUserResponse) {
                // Appeler d'abord l'API pour créer un candidat
                axios
                    .post(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/create-candidate/${email}/${jobId}`, {}, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                   
                            .then((preselectionResponse) => {
                            
                                axios
                                    .post(`${baseUrl}/candidateExt/sendEmail`, {
                                        email: email,
                                        jobId: jobId,
                                        subject: lang.menu.presTitle,
                                        lang: langue,
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then((createCandidateResponse) => {
                                       
                                        axios
                                            .put(`${baseUrl}/candidateExt/updateEmailSend/${jobId}/${email}`, {}, {
                                                headers: {
                                                    Authorization: `Bearer ${token}`,
                                                },
                                            })
                                            .then(() => {
                                                toast.success(
                                                    <div>
                                                        <img
                                                            src="/accept.png"
                                                            alt="Accept Image"
                                                            width="100"
                                                            height="50"
                                                        />
                                                        <p>{lang.menu.AcceptMess}</p>
                                                    </div>
                                                );
                                                axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/processPreselection/${userId}/${jobId}/${localId}`, 
                                                    {}, 
                                                    {
                                                        headers: {
                                                            Authorization: `Bearer ${token}`
                                                        }
                                                    })
                                                    .then(() => {
                                                        fetchCandidate();
                                                        const sortedCandidates = [...candidatesFilter];
                                                        sortedCandidates.sort((a, b) => {
                                                          if (sortOrder === "asc") {
                                                            return a[6] - b[6]; // croissant
                                                          } else {
                                                            return b[6] - a[6]; // décroissant
                                                          }
                                                        });
                                                    
                                                        if (filterStatus === "all") {
                                                          setCandidatesFilter([...sortedCandidates]);
                                                        } else {
                                                          const filtered = sortedCandidates.filter(
                                                            (candidate) =>
                                                              candidate[21] === (filterStatus === "treated" ? 1 :null)
                                                          );
                                                        setCandidatesFilter(filtered);
                                                        }
                                                    })
                                                    .catch((preselectionError) => {
                                                        console.error("Error processing preselection");
                                                        swal(lang.menu.errorPreselection, {
                                                            icon: "error",
                                                        });
                                                    });
                                            })
                                            .catch((preselectionError) => {
                                                console.error("Error Updating status", preselectionError);
                                                swal(lang.menu.errorUpdateStatus, {
                                                    icon: "error",
                                                });
                                            });
                                    })
                                  
                            .catch((preselectionError) => {
                                console.error("Error processing preselection", preselectionError);
                                swal(lang.menu.errorPreselection, {
                                    icon: "error",
                                });
                            });
                    })
                    .catch((createCandidateError) => {
                        console.error("Error creating candidate", createCandidateError);
                        swal(lang.menu.errorPreselection, {
                            icon: "error",
                        });
                    });
            }
          }else if(companyID===1 &&currentUser.userRole===3 && subscriptionData[4].numberquota_max>subscriptionData[4].quotaParcours){
            await axios.put(
              `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
              { fieldName:"quotaParcours","incrementValue":1, quota_max:subscriptionData[4].numberquota_max,quota_used:subscriptionData[4].quotaParcours }, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }}
            );
            if (getUserResponse) {
              // Appeler d'abord l'API pour créer un candidat
              axios
                  .post(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/create-candidate/${email}/${jobId}`, {}, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  })
                 
                          .then((preselectionResponse) => {
                          
                              axios
                                  .post(`${baseUrl}/candidateExt/sendEmail`, {
                                      email: email,
                                      jobId: jobId,
                                      subject: lang.menu.presTitle,
                                      lang: langue,
                                  }, {
                                      headers: {
                                          Authorization: `Bearer ${token}`,
                                      },
                                  })
                                  .then((createCandidateResponse) => {
                                     
                                      axios
                                          .put(`${baseUrl}/candidateExt/updateEmailSend/${jobId}/${email}`, {}, {
                                              headers: {
                                                  Authorization: `Bearer ${token}`,
                                              },
                                          })
                                          .then(() => {
                                              toast.success(
                                                  <div>
                                                      <img
                                                          src="/accept.png"
                                                          alt="Accept Image"
                                                          width="100"
                                                          height="50"
                                                      />
                                                      <p>{lang.menu.AcceptMess}</p>
                                                  </div>
                                              );
                                              axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/processPreselection/${userId}/${jobId}/${localId}`, 
                                                  {}, 
                                                  {
                                                      headers: {
                                                          Authorization: `Bearer ${token}`
                                                      }
                                                  })
                                                  .then(() => {
                                                      fetchCandidate();
                                                      const sortedCandidates = [...candidatesFilter];
                                                      sortedCandidates.sort((a, b) => {
                                                        if (sortOrder === "asc") {
                                                          return a[6] - b[6]; // croissant
                                                        } else {
                                                          return b[6] - a[6]; // décroissant
                                                        }
                                                      });
                                                  
                                                      if (filterStatus === "all") {
                                                        setCandidatesFilter([...sortedCandidates]);
                                                      } else {
                                                        const filtered = sortedCandidates.filter(
                                                          (candidate) =>
                                                            candidate[21] === (filterStatus === "treated" ? 1 :null)
                                                        );
                                                      setCandidatesFilter(filtered);
                                                      }
                                                  })
                                                  .catch((preselectionError) => {
                                                      console.error("Error processing preselection");
                                                      swal(lang.menu.errorPreselection, {
                                                          icon: "error",
                                                      });
                                                  });
                                          })
                                          .catch((preselectionError) => {
                                              console.error("Error Updating status", preselectionError);
                                              swal(lang.menu.errorUpdateStatus, {
                                                  icon: "error",
                                              });
                                          });
                                  })
                                
                          .catch((preselectionError) => {
                              console.error("Error processing preselection", preselectionError);
                              swal(lang.menu.errorPreselection, {
                                  icon: "error",
                              });
                          });
                  })
                  .catch((createCandidateError) => {
                      console.error("Error creating candidate", createCandidateError);
                      swal(lang.menu.errorPreselection, {
                          icon: "error",
                      });
                  });
          }
          }
        }
      })
      
      .catch((getUserError) => {
  
        const createTemporaryUserUrl = `${baseUrl}/users/local/createTemporaryUser/${email}`;
        axios
          .post(createTemporaryUserUrl, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((createTemporaryUserResponse) => {
              // Appeler l'API pour créer un candidat
              const createCandidateUrl = `${baseUrl}/jobrole/create-candidate/${email}/${jobId}`;
              axios
                  .post(createCandidateUrl, {}, {
                      headers: {
                          Authorization: `Bearer ${token}`,
                      },
                  })
                  .then(() => {
                      // Ensuite, envoyer l'email temporaire
                      axios
                          .post(`${baseUrl}/candidateExt/sendEmailTempo`, {
                              email: email,
                              jobId: jobId,
                              password: createTemporaryUserResponse.data.password,
                              subject: lang.menu.presTitle,
                              lang: langue,
                          }, {
                              headers: {
                                  Authorization: `Bearer ${token}`,
                              },
                          })
                          .then((preselectionResponse) => {
                              // Mettre à jour le statut
                              axios
                                  .put(`${baseUrl}/candidateExt/updateEmailSend/${jobId}/${email}`, {}, {
                                      headers: {
                                          Authorization: `Bearer ${token}`,
                                      },
                                  })
                                  .then(() => {
                                      toast.success(
                                          <div>
                                              <img
                                                  src="/accept.png"
                                                  alt="Accept Image"
                                                  width="100"
                                                  height="50"
                                              />
                                              <p>{lang.menu.AcceptMess}</p>
                                          </div>
                                      );
                                      // Processus de présélection
                                      axios
                                          .post(`${process.env.REACT_APP_APP_DOMAIN}/flask/processPreselection/${userId}/${jobId}/${localId}`, 
                                              {}, 
                                              {
                                                  headers: {
                                                      Authorization: `Bearer ${token}`,
                                                  },
                                              })
                                          .then(() => {
                                              fetchCandidate();
                                              const sortedCandidates = [...candidatesFilter];
                                              sortedCandidates.sort((a, b) => {
                                                if (sortOrder === "asc") {
                                                  return a[6] - b[6]; // croissant
                                                } else {
                                                  return b[6] - a[6]; // décroissant
                                                }
                                              });
                                          
                                             
                                              if (filterStatus === "all") {
                                                setCandidatesFilter([...sortedCandidates]);
                                              } else {
                                                const filtered = sortedCandidates.filter(
                                                  (candidate) =>
                                                    candidate[21] === (filterStatus === "treated" ? 1 :null)
                                                );
                                              setCandidatesFilter(filtered);
                                              }
                                          })
                                          .catch((preselectionError) => {
                                              console.error("Error processing preselection");
                                              swal(lang.menu.errorPreselection, {
                                                  icon: "error",
                                              });
                                          });
                                  })
                                  .catch((preselectionError) => {
                                      console.error("Error Updating status", preselectionError);
                                      swal(lang.menu.errorUpdateStatus, {
                                          icon: "error",
                                      });
                                  });
                          })
                          .catch((preselectionError) => {
                              console.error("Error processing preselection", preselectionError);
                              swal(lang.menu.errorPreselection, {
                                  icon: "error",
                              });
                          });
                  })
                  .catch((createCandidateError) => {
                      console.error("Error creating candidate", createCandidateError);
                      swal(lang.menu.errorPreselection, {
                          icon: "error",
                      });
                  });
          })          
          .catch((createTemporaryUserError) => {
            console.error("Error creating temporary user", createTemporaryUserError);
            swal(lang.menu.errorPreselection, {
              icon: "error",
            });          });
      });
  };
  
  const sortTableByScore = () => {
    console.log("current page:",currentPage)
    const sortedCandidates = [...candidatesFilter];
    sortedCandidates.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[6] - b[6]; // croissant
      } else {
        return b[6] - a[6]; // décroissant
      }
    });
    if (filterStatus === "all") {
      setCandidatesFilter([...sortedCandidates]);
    } else {
      const filtered = sortedCandidates.filter(
        (candidate) =>
          candidate[21] === (filterStatus === "treated" ? 1 :null)
      );
    setCandidatesFilter(filtered);
    }
   
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); //  tri inverser
  };

  const formatSkills = (skillsString) => {
    try {
      const skillsData = JSON.parse(skillsString);
      return Object.entries(skillsData)
        .map(([skill, { occurence }]) => `${skill}, occurence:${occurence}`)
        .join("\n");
    } catch (error) {
      console.error("Error parsing skills data:", error);
      return "";
    }
  };

  const formatExperience = (expString) => {
    try {
      const expData = JSON.parse(expString);
      return Object.entries(expData)
        .map(([nbr, { exp }]) => `${nbr},${exp}`)
        .join("\n");
    } catch (error) {
      console.error("Error parsing exp data:", error);
      return "";
    }
  };
  const processData = (prData) => {
    const prLevels = [];

    let startPos = prData.indexOf("'");
    let endPos;

    while (startPos !== -1) {
      endPos = prData.indexOf("'", startPos + 1);
      if (endPos !== -1) {
        prLevels.push(prData.substring(startPos + 1, endPos));
        startPos = prData.indexOf("'", endPos + 1);
      } else {
        break;
      }
    }

    return prLevels;
  };

  const exportToExcel = () => {
    const sheetData = candidatesFilter.map((candidate) => {
      return {
        "#": candidate[0],
        Name: candidate[1],
        Phone: candidate[2],
        Email: candidate[3],
        Language: processData(candidate[8]).join(", "),
        Country: candidate[11],
        Speciality: candidate[12],
        Skills: formatSkills(candidate[4]),
        Education: processData(candidate[5]).join(", "),
        Experience: formatExperience(candidate[7]),
        LinkedIn: candidate[9],
        GitHub: candidate[10],
        Certificates: candidate[13],
        Score: candidate[6],
        Status:
          candidate[14] === "accepted"
            ? lang.menu.accepted
            : candidate[14] === "pending"
            ? lang.menu.pending
            : lang.menu.refused,
      };
    });

    const sheet = XLSX.utils.json_to_sheet(sheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Candidates");
    XLSX.writeFile(workbook, "candidates.xlsx");
  };
  

  const getPdf = async (filePath) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/flask/get_pdf`, {
        params: {
          filePath: filePath,
        },
        responseType: 'blob', 
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank');  
      return 'Success';
    } catch (error) {
      console.error('Error fetching PDF:', error);
      setShowErrorModal(true);
      return 'Error fetching PDF';
    }
  };
  const locale = lang.language === 'Français' ? 'fr-FR' : 'en-US';
  const handleEmailStatusFilter = (status) => {
    if (status === "all") {
      setDisplayedCandidates([...candidatesFilter]); 
    } else {
      const filtered = candidatesFilter.filter(
        (candidate) =>
          candidate[21] === (status === "treated" ? 1 :null)
      );
      setDisplayedCandidates(filtered);
    }
    setFilterStatus(status)
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);  
  };

  const handleOptionClick = (value) => {
    handleEmailStatusFilter(value);
    setIsOpen(false);  
  };
//Multiple selection
const [selectedCandidates, setSelectedCandidates] = useState([]);
const handleSelectCandidate = (candidateId) => {
  setSelectedCandidates((prevSelected) =>
    prevSelected.includes(candidateId)
      ? prevSelected.filter((id) => id !== candidateId) 
      : [...prevSelected, candidateId] 
  );
};

const handleAcceptSelectedCandidates = () => {
  selectedCandidates.forEach((candidateId) => {
    const candidate = candidates.find((c) => c[0] === candidateId);
    if (candidate) {
      handleAccept(candidate[0], candidate[3], candidate[15]);
    }
  });
};
  return (
    <> {candidatePer ? (
    <Fragment style={{ fontFamily: "Poppins" }}>
      <PageTitle
        activeMenu={lang.menu.candidateList}
        motherMenu={lang.menu.dashboard}
        pageContent="Candidate"
      />


      <div className="row">
        <div className="col-lg-12">

                <Card>              
                  <Card.Header>
                    <Button
                      className=" btn-rounded fs-16"
                      onClick={exportToExcel}
                    >
                       {lang.menu.exportToExcel}
                    </Button> 
                    {quotaMax ? (
              <Badge className="d-flex align-items-center justify-content-center badge-rounded me-2" 
              variant="outline-danger"      style={{ color: quotaMax.quotaParcours >=  quotaMax.numberquota_max ? 'red' : '#74188D' }}>

                <AiOutlinePieChart size={20} />{' '}
                {lang.menu.quotaUsed} : {quotaMax.quotaParcours}/{quotaMax.numberquota_max}
              </Badge>
            ) : null}
                    <div class="form-check form-switch">
                    <Switch 
                    className=" fs-16"

                    onChange={toggler}
                     isSelected={isSelected} 
                     style={{ flex: 1 }} >
                         {lang.menu.viewMore}
                      </Switch> 
                      
</div>
                  </Card.Header>
                 
                  <Card.Body style={{ fontFamily: "Poppins" }}>
                  {loading ? (
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%" }}
                  ></div>
                </div>
              ) : (
                <>
                    <div className="d-flex align-items-center flex-wrap search-job bg-white px-0 mb-4 fs-16">
  <div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 search-dropdown d-flex align-items-center">
    <Dropdown className="form-control border-0 style-1 h-auto">
      <Dropdown.Toggle
        as="div"
        className="fs-16 font-w500 text-black justify-content-between d-flex align-items-center i-false"
      >
      
      {status === "pending" && lang.menu.pending}
  {status === "refused" && lang.menu.refused}
  {status === "accepted" && lang.menu.accepted}
  {status === "all" && lang.menu.all}
  {status === lang.menu.chooseStatus && lang.menu.chooseStatus}

        <i className="fas fa-angle-down text-black scale5 ms-3"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
      <Dropdown.Item
  className="text-black fs-16" 
  onClick={() => handleDropdownSelect("all")}
>
 {lang.menu.all}
</Dropdown.Item>

        <Dropdown.Item
          className="text-black fs-16" 
          onClick={() => handleDropdownSelect(lang.menu.accepted)}
        >
          {lang.menu.accepted}
        </Dropdown.Item>
        <Dropdown.Item
          className="text-black fs-16" 
          onClick={() => handleDropdownSelect(lang.menu.pending)}
        >
          {lang.menu.pending}
        </Dropdown.Item>
        <Dropdown.Item
          className="text-black fs-16" 
          onClick={() => handleDropdownSelect(lang.menu.refused)}
        >
          {lang.menu.refused}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
  <div className="col-xl-2 col-xxl-3 col-lg-3 col-sm-6 col-12 search-dropdown d-flex align-items-center">
    <Dropdown className="form-control border-0 style-1 h-auto">
      <Dropdown.Toggle
        as="div"
        className="fs-16 font-w500 text-black justify-content-between d-flex align-items-center i-false"
      >
        {jobRoleName}
        <i className="fas fa-angle-down text-black scale5 ms-3"></i>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu dropdown-menu-end mt-1">
      <Dropdown.Item
  className="text-black fs-16" 
  onClick={() => handleDropdownSelect3("All")}
>
{lang.menu.all}
</Dropdown.Item>
        {jobRoles.map((jobRole, index) => (
        

          <Dropdown.Item
            className="text-black fs-16"  // Ensure consistent font size
            key={index}
            onClick={() => handleDropdownSelect3(jobRole.JobTitle)}
          >
            {jobRole.JobTitle}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  </div>
  <div className="col-xl-8 col-xxl-6 col-lg-6 col-12 d-md-flex job-title-search pe-0">
    <div className="input-group search-area">
      <input
        type="text"
        className="form-control h-auto fs-16"  // Ensure consistent font size
        placeholder={lang.menu.search}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  </div>
</div>

        <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              
              {show?<>
              <thead style={{ backgroundColor: "#8cd6c8"}}>
                        <tr>
                        <th>
                        <div className="d-flex align-items-center justify-content-between mb-3">
  {/* Case à cocher de sélection globale */}
  <div className="d-flex align-items-center">
    <input
      type="checkbox"
      onChange={(e) =>
        setSelectedCandidates(
          e.target.checked ? displayCandidates.map((c) => c[0]) : []
        )
      }
      style={{
        width: "20px",
        height: "20px",
        marginRight: "10px",
        cursor: "pointer",
      }}
    />
  </div>

  {/* Bouton pour accepter les candidats sélectionnés */}
  <button
    className="btn btn-primary"
    onClick={handleAcceptSelectedCandidates}
    disabled={selectedCandidates.length === 0}
    style={{
      backgroundColor: 'transparent', 
      border: '2px solid transparent',   
      borderRadius: '5px',           
      padding: '5px',                
      cursor: selectedCandidates.length === 0 ? 'not-allowed' : 'pointer', 
    }}
  >
      <FcInvite size={30} />
  </button>
</div>

            </th>
                        <th className="width80">
                        {lang.menu.jobRole}
                          </th>
                          <th>
                            {lang.menu.name}
                          </th>
                          <th style={{textAlign:"center"}}>
                            {lang.menu.phone}
                          </th>
                          <th>
                          {lang.menu.email}
                          </th>
                          <th style={{textAlign:"center"}}>
                            {lang.menu.speciality}
                          </th>
                          <th title={lang.menu.msgPreEXP}>
                          Prequalified score/10
                           
                          </th>
                          <th>
                           Date
                          </th>
                          <th>
                           {lang.menu.status}
                          </th>
                          <th>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
        <span
          onClick={toggleDropdown}
          style={{
         
            fontWeight: "500",
            
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {lang.menu.emailStatus}
          <span style={{ fontSize: "18px", color: "#666" }}>▼</span>
        </span>

        {isOpen && (
          <div style={{
            position: "absolute", 
            top: "30px", 
            left: "0", 
            width: "180px", 
            backgroundColor: "#fff", 
            border: "1px solid #ccc", 
            borderRadius: "8px", 
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
            zIndex: 10
          }}>
            
            <div 
              onClick={() => handleOptionClick('all')}
              style={{
                padding: "10px 12px", 
                fontSize: "14px", 
                cursor: "pointer", 
                borderBottom: "1px solid #f1f1f1",
                backgroundColor: "#fff",
                color: "#555",
                transition: "background-color 0.3s ease",
              }}
            >
              {lang.menu.all}
            </div>
            <div 
              onClick={() => handleOptionClick('treated')}
              style={{
                padding: "10px 12px", 
                fontSize: "14px", 
                cursor: "pointer", 
                borderBottom: "1px solid #f1f1f1",
                backgroundColor: "#fff",
                color: "#555",
                transition: "background-color 0.3s ease",
              }}
            >
              {lang.menu.treated}
            </div>
            <div 
              onClick={() => handleOptionClick('not_treated')}
              style={{
                padding: "10px 12px", 
                fontSize: "14px", 
                cursor: "pointer", 
                backgroundColor: "#fff",
                color: "#555",
                transition: "background-color 0.3s ease",
              }}
            >
              {lang.menu.untreated}
            </div>
          </div>
        )}
      </div>
    </th>


                          <th>
                            <button
                              className="tri-btn "
                              onClick={sortTableByScore}
                            >
                              <PiSortAscendingLight />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayCandidates?.map((data, index) => {
                          return (
                            <tr key={index} >
                                <td>
                <input
                  type="checkbox"
                  checked={selectedCandidates.includes(data[0])}
                  onChange={() => handleSelectCandidate(data[0])}
                />
              </td>
                             <td style={{ color: "#43DAC2" }}>
  <Link to={`/details/${data[15]}`} style={{ color: "#43DAC2" }}>
    {jobTitles[data[15]]}
  </Link>
</td>

                              <td >
                              <Link to={`/User-Profile-Candidate/${data[0]}`}> {data[1]}</Link>
                              </td>
                              <td style={{textAlign:"center"}} className={data[2] ? "" : "bg-light"}>
                                {data[2]}
                              </td>
                              <td className={data[3] ? "" : "bg-light"}>
                                {data[3]}
                              </td>
                              <td  style={{textAlign:"center"}} className={data[12] ? "" : "bg-light"}>
                                {data[12]}
                              </td>
                              <td style={{textAlign:"center"}}>{data[6]}</td>
                              <td  style={{textAlign:"center"}} className={data[12] ? "" : "bg-light"}>
                              {new Date(data[23]).toLocaleString(locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                              </td>
                              <td className={data[14] ? "" : "bg-light"}>
                                {data[14] === "accepted" ? (
                                  <Badge variant="success light">
                                    <span className="ms-1 fa fa-check" /> {" "}
                                    {lang.menu.accepted}
                                    
                                  </Badge>
                                ) : data[14] === "pending" ? (
                                  <Badge variant="warning light">
                                    <span className="ms-1 fa fa-redo" /> {" "}
                                    {lang.menu.pending}
                                    
                                  </Badge>
                                ) : (
                                  <Badge variant="danger light">
                                    <span className="ms-1 fa fa-ban" /> {" "}
                                    {lang.menu.refused}
                                    
                                  </Badge>
                                )}
                              </td>
                              <td>
                              {data[21] === 1 
                                ? lang.menu.envoieEmail
                                : lang.menu.nonEnvoieEmail}
                            </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="light sharp i-false"
                                  >
                                    <AiOutlineMail />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                    disabled={data[21] === 1}

                                    onClick={() => handleShowRejectionModal(data)} 
                                    >
                      {lang.menu.Reject}

                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={data[21] === 1}

                                      onClick={() =>
                                        handleAccept(data[0], data[3], data[15])
                                      }
                                    >
                                     {lang.menu.Accept}

                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody></>:<>
                      <thead style={{ backgroundColor: "#8cd6c8"}}>
                        <tr>
                          <th className="width80">
                          {lang.menu.jobRole}

                          </th>
                          <th>
                          {lang.menu.name}
                          </th>
                          <th>
                            {lang.menu.phone}
                          </th>
                          <th>
                            Email
                          </th>
                          <th>
                            {lang.menu.language}
                          </th>
                          <th>
                            {lang.menu.country}
                          </th>
                          <th>
                            {lang.menu.speciality}
                          </th>
                          <th>
                           {lang.menu.skills}
                          </th>
                          <th>
                          {lang.menu.education}
                          </th>
                          <th>
                          {lang.menu.experience}
                          </th>
                          <th>
                          LinkedIn
                          </th>
                          <th>
                           GitHub
                          </th>
                          <th>
                           {lang.menu.certificats}
                          </th>
                          <th title={lang.menu.msgPreEXP}>
                           Prequalified score/10
                          </th>
                          <th>
                            Date
                          </th>
                         

                          <th>
                           {lang.menu.status}
                          </th>

                          <th>
                            {lang.menu.emailStatus} 
                          </th>
                          <th>PDF</th>
                          <th>
                            <button
                              className="tri-btn "
                              onClick={sortTableByScore}
                            >
                              {" "}
                              <PiSortAscendingLight />
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {" "}
                        {displayCandidates.map((data, index) => {
                          const candidateNumber = index + 1;
                          return (
                            <tr key={index}>
                             <td style={{ color: "#43DAC2" }}>
                              <Link to={`/details/${data[15]}`} style={{ color: "#43DAC2" }}>
                                {jobTitles[data[15]]}
                              </Link>
                            </td>

                              <td className={data[1] ? "" : "bg-light"}>
                              <Link to={`/User-Profile-Candidate/${data[0]}`}> <strong> {data[1]}</strong></Link>
                              </td>
                              <td className={data[2] ? "" : "bg-light"}>
                                {data[2]}
                              </td>
                              <td className={data[3] ? "" : "bg-light"}>
                                {data[3]}
                              </td>
                              <td className={data[8] ? "" : "bg-light"}>
                                {processData(data[8]).map((prLevel, index) => (
                                  <span key={index}>
                                    {index > 0 ? ", " : ""}
                                    {prLevel.trim()}
                                  </span>
                                ))}
                              </td>
                              <td className={data[11] ? "" : "bg-light"}>
                                {data[11]}
                              </td>
                              <td className={data[12] ? "" : "bg-light"}>
                                {data[12]}
                              </td>
                              <td className={data[4] ? "" : "bg-light"}>
                                {formatSkills(data[4])}
                              </td>
                              <td className={data[1] ? "" : "bg-light"}>
                                {processData(data[5]).map((prLevel, index) => (
                                  <span key={index}>
                                    {index > 0 ? ", " : ""}
                                    {prLevel.trim()}
                                  </span>
                                ))}
                              </td>

                              <td className={data[7] ? "" : "bg-light"}>
                                {data[7]}
                              </td>
                              <td style={{textAlign:"center"}} className={data[9] ? "" : "bg-light"}>
                                {data[9] ? (
                                  <a
                                    href={
                                      data[9].startsWith("http")
                                        ? data[9]
                                        : `https://${data[9]}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <AiFillLinkedin/>
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td style={{textAlign:"center"}} className={data[10] ? "" : "bg-light"}>
                                {data[10] ? (
                                  <a
                                    href={
                                      data[10].startsWith("http")
                                        ? data[10]
                                        : `https://${data[10]}`
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <AiFillGithub/>
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>

                              <td className={data[13] ? "" : "bg-light"}>
                                {data[13]}
                              </td>

                              <td>{data[6]}</td>
                              <td className={data[16] ? "" : "bg-light"}>
                              {new Date(data[23]).toLocaleString(locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                              </td>
                          

                              <td className={data[14] ? "" : "bg-light"}>
                                {data[14] === "accepted" ? (
                                  <Badge variant="success light">
                                    <span className="ms-1 fa fa-check" /> {" "}
                                    {lang.menu.accepted}
                                    
                                  </Badge>
                                ) : data[14] === "pending" ? (
                                  <Badge variant="warning light">
                                    <span className="ms-1 fa fa-redo" /> {" "}
                                    {lang.menu.pending}
                                    
                                  </Badge>
                                ) : (
                                  <Badge variant="danger light">
                                    <span className="ms-1 fa fa-ban" /> {" "}
                                    {lang.menu.refused}
                                    
                                  </Badge>
                                )}
                              </td>
                              <td>
                              {data[21] === 1 
                                ? lang.menu.envoieEmail
                                : lang.menu.nonEnvoieEmail}
                            </td>
                              <td>
  <Button
   variant="success light"
    onClick={() => getPdf(data[22])} 
  >
    <BsFileEarmarkPdf size={15}/>

  </Button>
</td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    className="light sharp i-false"
                                  >
                                    <AiOutlineMail size={15} />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                    disabled={data[21] === 1}

                                    onClick={() => handleShowRejectionModal(data)} 
                                    >         
                                       {lang.menu.Reject}

                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        disabled={data[21] === 1}

                                      onClick={() =>
                                        handleAccept(data[0], data[3], data[15])
                                      }
                                    >
                                       {lang.menu.Accept}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                          
                            </tr>
                          );
                        })}
                      </tbody></>} 
            
            </table>
         
          </div>
        </div>
        </div>

                    <ReactPaginate
                      previousLabel={lang.menu.previous}
                      nextLabel={lang.menu.next}
                      pageCount={Math.ceil(candidatesFilter.length / perPage)}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      activeClassName={"active"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />

                    {candidatesFilter.length === 0 && (
                      <p> {lang.menu.NoCandidate}</p>
                    )}
                             </>
              )}
                  </Card.Body>
                </Card>
              
            
          
        </div>
        <Modal show={showRejectionModal} onHide={handleCloseRejectionModal}>
        <Modal.Header closeButton>
          <Modal.Title>{lang.menu.motifRefus}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Motif de refus"
            value={rejectionReason}
            onChange={handleRejectionReasonChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRejectionModal}>
            {lang.menu.cancel}
          </Button>
          <Button
            variant="primary"
            onClick={() => handleRejection(selectedData[0], selectedData[15])}
          >
            {lang.menu.submit}
          </Button>
        </Modal.Footer>
      </Modal>
        <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
  <Modal.Header closeButton>
    <Modal.Title>Error</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  {lang.menu.CVPDFMessage}  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleCloseErrorModal}>
    {lang.menu.cancel}
    </Button>
  </Modal.Footer>
</Modal>
        <ToastContainer />
      </div>
    </Fragment>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title> {lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
             {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default CandidateList;