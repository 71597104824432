import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row,Col, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import translations from "../../AppsMenu/Candidate/mulitlingue";

import swal from "sweetalert";

const ListSkills = () => {
  const [skills, setSkills] = useState([]);
  const [createskill, setCreateskill] = useState([]);
  const token=localStorage.getItem("token");
  const [connectedUser, setConnectedUser] = useState("");
  const [companieId, setCompanieId] = useState("");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setConnectedUser(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateskill(
            data.some((permission) => permission.title === "create-skill")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("create");
  const [selectedSkill, setSelectedSkill] = useState(null);

  const [name, setName] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
 
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getAllSkills`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      console.log("Skill;",response.data)
      setSkills(response.data);
      const compId = await getUserCompanyID();
      setCompanieId(compId)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    

    fetchData();
  }, []);

  const handleModalOpen = () => {
    setShowModal(true);
    setModalType("create")
    setSelectedSkill(null);
    setName(null);

  };
  const handleModalOpenUpdate = (skillSelect) => {
    setSelectedSkill(skillSelect);
    setName(skillSelect.name);
    setShowModal(true);
    setModalType("update");
  };
  
  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const createdSkill = async (newSkill) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/skill/createSkill`,
        newSkill, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const createdSkill = response.data;
      setSkills([...skills, createdSkill]);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkSkillNameExists = (name) => {
    console.log("name :", name.length);
  
    // Normaliser le nom pour enlever les accents et les caractères spéciaux
    const cleanedName = name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    console.log("cleanedName :", cleanedName.length);
  
    return skills.some((skill) => 
      skill.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === cleanedName
    );
  };
  
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  const handleFormSubmit = async(e) => {

    e.preventDefault();
  
    if (modalType === "update" && selectedSkill && selectedSkill.name.toLowerCase() === name.toLowerCase()) {
      updateSkill({
        name,
      });
    } else if (checkSkillNameExists(name)) {
      swal(lang.menu.duplicateNameError, {
        icon: "error",
      });
      return;
    } else {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const userId=currentUser.localId
      const compId =await getUserCompanyID()
      const skillData = {
        name,
        userId,
        compId
      };
  
      if (modalType === "update") {
        updateSkill(skillData);
      } else {
        createdSkill(skillData);
      }
    }
  
    setName("");
  };
  

  

  //search for skill
  const [searchWord, setSearchWord] = useState("");
 
    const searchForSkill = (skill)=>{
      return skill.name.toLowerCase().includes(searchWord.toLowerCase());
    }

  const onChangeSearchWord = (e) =>{
    setSearchWord(e.target.value);
  }
  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteText,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        axios
          .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/skill/deleteSkillById/` + id, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }})
          .then((res) => {
            if (res.data.message === "Skill was deleted successfully!") {
              console.log(res);
              swal(lang.menu.deleteSuccess, {
                icon: "success",
              });
              setSkills((prevSkill) =>
            prevSkill.filter((skilllll) => skilllll.id !== id)
          );
              // Add any additional logic here after successful delete
            } else {
             swal(lang.menu.deleteTitleWrong,lang.menu.deleteWrong, "error");            }
          })
          .catch((err) => {
            swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            console.log(err);
          });
      } else {
        // User canceled the delete action
        swal("Skill deletion canceled!", {
          icon: "info",
        });
      }
    });
  };
  const updateSkill = async (updatedSkill) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/skill/updateSkill/${selectedSkill.id}`,
        updatedSkill, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const updatedSkillData = response.data;
      setSkills(skills.map(skill => skill.id === updatedSkillData.id ? updatedSkillData : skill));
      setShowModal(false);
      setSelectedSkill(null);
      swal(lang.menu.updateMsgSuccSkill, {
        icon: "success",
      });
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_PAGINATION 
  const filteredSkills = skills.filter(skill => searchForSkill(skill));
  const totalPages = Math.ceil(filteredSkills.length / itemsPerPage);

  const paginatedSkills = filteredSkills.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div style={{ fontFamily: "Poppins" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{lang.menu.skillAvailable} </h4>
        <div>
        <Form.Control 
          type="search"
          
          className="left-align-container smallscreen"
          placeholder={lang.menu.Search}
          onChange={onChangeSearchWord} />
        </div>
        <div>
          
          
          {createskill ? (
            
            <Button
              variant="primary"
              className="me-3 btn-sm"
              onClick={handleModalOpen}
              style={{ marginLeft: "5%" }}
            >
              <i className="fas fa-plus me-2"></i>
              {lang.menu.newSkill}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {paginatedSkills.filter(skill => searchForSkill(skill)).map((item, index) => (
              <div className="col-xl-4" key={index}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div className="d-flex">
                        <div>
                        <h4 className="fs-20 mb-1" style={{ textAlign: 'center' }}>{item.name}</h4>
                        </div>
                      </div>
                     
                    </div>
                    <Row >
               
                        {(connectedUser.userRole === 1 || (item.companyID === companieId && companieId !== 1) || (connectedUser.localId === item.creator_uid && companieId === 1)) && (
<>
<Col>
                        <Link
                          to={`/QuestionOfSkill/${item.id}`}
                          className="btn btn-outline-primary btn-rounded"
                          style={{ marginRight: "5%" }}
                        >
                                          <i class="fas fa-eye"></i>    
                </Link>
                        </Col>
                        {"   "}

<Col>
      <Button
                  variant="danger light"
                  onClick={() => handleDelete(item.id)}
                >
                    <i className="fas fa-trash-alt fa-lg"></i>
                  </Button>
                  </Col>
                  {"   "}
                  <Col>
      <Button
                  variant="danger light"
                  onClick={() => handleModalOpenUpdate(item)}
                >
                  <i class="fas fa-pencil-alt"></i>
                  </Button></Col>
</> 
                        )}
                      </Row>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center mt-4">
  <Button
    variant="primary"
    disabled={currentPage === 1}
    onClick={() => handlePageChange(currentPage - 1)}
  >
    {lang.menu.previous}
  </Button>
  <span className="mx-3">
    Page {currentPage} {lang.menu.sur} {totalPages}
  </span>
  <Button
    variant="primary"
    disabled={currentPage === totalPages}
    onClick={() => handlePageChange(currentPage + 1)}
  >
    {lang.menu.next}
  </Button>
</div>

      </div>

      <Modal show={showModal} onHide={handleModalClose}>
      <Modal.Header>
  <Modal.Title>{modalType === "update" ? lang.menu.Update : lang.menu.createNewSkill}</Modal.Title>
  <Button
    variant=""
    className="btn-close"
    onClick={() => setShowModal(false)}
  ></Button>
</Modal.Header>
<Modal.Body>
  <Form onSubmit={handleFormSubmit}>
    <Form.Group controlId="formCategoryName">
      <Form.Label>{lang.menu.name}</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter name"
        value={name}
        maxLength={60}
        onChange={handleNameChange}
        required
      />
    </Form.Group>

    <br />
    <Button variant="primary" type="submit">
      {modalType === "update" ? lang.menu.Update : lang.menu.createSkill}
    </Button>
  </Form>
</Modal.Body>

      </Modal>
    </div>
  );
};

export default ListSkills;
