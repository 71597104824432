import React, { useState, useEffect } from "react";
import "./CookieConsent.css";
import translations from "../AppsMenu/Candidate/mulitlingue";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("consentement");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const consentGiven = localStorage.getItem("cookie-consent");
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookie-consent", "true");
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookie-consent", "false");
    setIsVisible(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (!isVisible) return null;

  return (
 <div className="cookie-consent-banner">
  <div className="cookie-header">
    <h3>{lang.menu.cookieConsentHeader}</h3>
    <p>{lang.menu.cookieMessage}</p>
  </div>

  <div className="cookie-tabs">
    <button
      className={`tab-button ${activeTab === "consentement" ? "active" : ""}`}
      onClick={() => handleTabChange("consentement")}
    >
      {lang.menu.Consentement}
    </button>
    <button
      className={`tab-button ${activeTab === "details" ? "active" : ""}`}
      onClick={() => handleTabChange("details")}
    >
      {lang.menu.details}
    </button>
    <button
      className={`tab-button ${activeTab === "about" ? "active" : ""}`}
      onClick={() => handleTabChange("about")}
    >
      {lang.menu.cookiesApropos}
    </button>
  </div>

  <div className="cookie-content">
  {activeTab === "consentement" && (
  <div className="cookie-consent-content">
    <div className="cookie-info">
      <h4>{lang.menu.cookieConsentHeader}</h4>
      <ul className="cookie-list">
        <li>
          <i className="cookie-icon fa fa-check-circle"></i> {lang.menu.cookieAnalytics}
        </li>
        <li>
          <i className="cookie-icon fa fa-check-circle"></i> {lang.menu.cookiePersonalization}
        </li>
        <li>
          <i className="cookie-icon fa fa-check-circle"></i> {lang.menu.cookieMatching}
        </li>
        <li>
          <i className="cookie-icon fa fa-check-circle"></i> {lang.menu.cookieCommunication}
        </li>
      </ul>
      <p className="cookie-more-info">
        {lang.menu.cookieMoreInfo}{" "}
        <a href="/PolitiqueConfidentialite" target="_blank">
          {lang.menu.privacyPolicy}
        </a>.
      </p>
    </div>

    <div className="data-usage-section">
      <h4>{lang.menu.collecteDetails}</h4>
      <ul className="data-usage-list">
        <li>{lang.menu.informationInscription}</li>
        <li>{lang.menu.informationCV}</li>
        <li>{lang.menu.informationTests}</li>
        <li>{lang.menu.donneesNavigation}</li>
      </ul>
    </div>
  </div>
)}


    {activeTab === "details" && (
      <div>
        <h4>{lang.menu.cookieCategories}</h4>
        <div className="cookie-category">
          <h5>{lang.menu.cookieNecessary}</h5>
          <p>{lang.menu.cookieNecessaryDesc}</p>
        </div>
        <div className="cookie-category">
          <h5>{lang.menu.cookiePreferences}</h5>
          <p>{lang.menu.cookiePreferencesDesc}</p>
        </div>
        <div className="cookie-category">
          <h5>{lang.menu.cookieStatistics}</h5>
          <p>{lang.menu.cookieStatisticsDesc}</p>
        </div>
      </div>
    )}

    {activeTab === "about" && (
      <div>
        <h4>{lang.menu.aboutCookies}</h4>
        <p>{lang.menu.cookiesExplanation}</p>
        <p>{lang.menu.cookiesModify}</p>
      </div>
    )}
  </div>

  <div className="cookie-buttons">
    <button className="accept-btn" onClick={handleAccept}>
      {lang.menu.acceptAll}
    </button>
    <button className="decline-btn" onClick={handleDecline}>
      {lang.menu.refuseAll}
    </button>
  </div>
</div>

  );
};

export default CookieConsent;
