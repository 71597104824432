import React, { lazy,useContext,useEffect,useState } from "react";
import CookieConsentBanner from './layouts/CookieBanner';
/// React router dom
import { Switch, Route,useLocation,useHistory  } from "react-router-dom";
 
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
 
/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";
import CookieBanner from "./layouts/CookieBanner";
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Jobs from "./components/Dashboard/Jobs";
import Applications from "./components/Dashboard/Applications";
import MyProfile from "./components/Dashboard/MyProfile";
import Profile from "./components/Dashboard/Profile";
import Statistics from "./components/Dashboard/Statistics";
import Companies from "./components/Dashboard/Companies";
import Task from "./components/Dashboard/Task";
import Admin_dashboard from "./components/Dashboard/Admin_dashboard";
import Roles_managment from "./components/Dashboard/Roles_managment";
import Company from "./components/Dashboard/Company";
 import NotFound from "./components/Dashboard/NotFound";

//Jobs
import PersonalityTest from "./components/Jobs/PersonalityTest";
import PostJob from "./components/Jobs/PostJob";
import JobLists from "./components/Jobs/JobLists";
import JobView from "./components/Jobs/JobView";
import JobApplication from "./components/Jobs/JobApplication";
import ApplyJob from "./components/Jobs/ApplyJob";
import NewJob from "./components/Jobs/NewJob";
import UserProfile from "./components/Jobs/UserProfile";
import JobRoleListApp from "./components/Jobs/JobRoleList";
import JobRoleDetails  from "./components/Jobs/JobRoleDetails";
import SendJobEmail  from "./components/Jobs/SendJobEmail";
 
 
/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
 
/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";
 
/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";
 
/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";
 
/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";
 
//Redux
import Todo from "./pages/Todo";
//import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
//import WizardForm from "./components/Forms/ReduxWizard/Index";
 
/// Widget
import Widget from "./pages/Widget";
 
//  Candidate
import CvUpload from "./components/AppsMenu/Candidate/CvUpload";
import CandidateList from "./components/AppsMenu/Candidate/CandidateList";
import CVsDataExtraction from "./components/AppsMenu/Candidate/CVsDataExtraction";
import ListAllCandidates from "./components/AppsMenu/Candidate/ListAllCandidates";
import ChatGPT from "./components/AppsMenu/Candidate/chatgpt";
import CandidatProfile from "./components/AppsMenu/Candidate/CandidatProfile";
import CandidateData from "./components/AppsMenu/Candidate/CandidateData";
import CandidateEval from "./components/AppsMenu/Candidate/CandidateEval";
import CvManager from "./components/AppsMenu/Candidate/CvManager";
 
import UserList from "./components/AppsMenu/Candidate/UserList";
import CvDataExtractionForum from "./components/AppsMenu/Candidate/CvDataExtractionForum";
 
//Test PsychoTechnicalTest
 
import ListCategories from "./components/PsychoTechnicalTest/ListCategories";
 
import ExcelTable from "./components/PsychoTechnicalTest/ExcelTable";
 
import QuestionOfCategory from "./components/PsychoTechnicalTest/QuestionOfCategory";
 
import TestPsychotechnique from "./components/PsychoTechnicalTest/TestPsychotechnique";
import TestPsychotechniqueList from "./components/PsychoTechnicalTest/TestPsychotechniqueList";
import TestPsychoTchniqueDetails from "./components/PsychoTechnicalTest/TestPsychoTchniqueDetails";
import JobRoleList from "./components/PsychoTechnicalTest/JobRoleList";
import TestPsychotechniqueCandidat from "./components/PsychoTechnicalTest/TestPsychotechniqueCandidat";
import TestPsychotechniqueForRecruter from "./components/PsychoTechnicalTest/TestPsychotechniqueForRecruter";
import UpdateTestPsychotechnique from "./components/PsychoTechnicalTest/UpdateTestPsychotechnique";
 
import Rapport from "./components/PsychoTechnicalTest/Rapport";
import QuestionTable from "./components/PsychoTechnicalTest/questionEcelFile";
import RadarPsychoComponent from "./components/PsychoTechnicalTest/RadarPsychoComponent";
import RadarGlobal from "./components/PsychoTechnicalTest/RadarGlobale";
 
//Test Technique
 
//import IDE from "./components/TechnicalTest/IDE";
import TopicsByCandidate from "./components/TechnicalTest/TopicsByCandidate";
import Tech from "./components/TechnicalTest/technical";
import ExcelTableTech from "./components/TechnicalTest/QCM/ExcelTable";
import ListSkills from "./components/TechnicalTest/QCM/ListSkills";
import SkillsDashboard from "./components/TechnicalTest/QCM/SkillsDashboard";

import QuestionOfSkill from "./components/TechnicalTest/QCM/QuestionOfSkill";
import TechQCMList from "./components/TechnicalTest/QCM/TechQCMList";
import TechQCM from "./components/TechnicalTest/QCM/TechQCM";
import TechQCMForRecruter from "./components/TechnicalTest/QCM/TechQCMForRecruter";
 
import TechQCMDetails from "./components/TechnicalTest/QCM/TechQCMDetails";
import JobRoleListTech from "./components/TechnicalTest/QCM/JobRoleListTech";
import TestTechniqueCandidat from "./components/TechnicalTest/QCM/TestTechniqueCandidat";
import RapportTech from "./components/TechnicalTest/QCM/RapportTech";
import QuestionTableTech from "./components/TechnicalTest/QCM/QuestionTableTech";
import RadarTechComponent from "./components/TechnicalTest/QCM/RadarTechComponent";
import TestTechCompleted from "./components/TechnicalTest/Components/TestTechComplited";
import CreateTestForm from "./components/TechnicalTest/CreateTestTechForm";
import TechList from "./components/TechnicalTest/TechList";
import ListTestTechCoding from "./components/TechnicalTest/ListTestTechCoding";
import TechRes from "./components/TechnicalTest/technicalResult";
import UpdateTestTechniques from "./components/TechnicalTest/QCM/UpdateTestTechnique";

import TechRecruiter from "./components/TechnicalTest/topicForRecruiter";
import TechRecruiter2 from "./components/Jobs/OneCompiler/topicForRecruiter2";
 
//Tests
import JobRoleTests from "./components/tests/JobRoleTests";
import JobRoleListTest from "./components/tests/JobRoleListTest";
//UserLogs
import UserLogs from "./components/user/UserLogs";
import CandidatesPerApp from "./components/user/CandidatesPerApp";
import JobAssessment from "./components/user/JobAssessment";
import JobDetails from "./components/user/jobDetails";
import UserProfileCandidate  from "./components/user/UserProfileCandidate";
import CandidateDetails  from "./components/user/CandidateDetails ";
 //Interviews
 import InterviewList  from "./components/Interviews/InterviewList";

 import MeetScheduler  from "./components/Interviews/MeetScheduler";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";
 
/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";
 
/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import ReadJob from "./components/Jobs/ReadJob";
import UpdateJob from "./components/Jobs/UpdateJob";
import UploadTest from "./components/Jobs/UploadTest";
import NewTest from "./components/Jobs/NewTest";
import TestList from "./components/Jobs/TestList";
import UpdateCoefficient from "./components/Jobs/UpdateCoefficient";
import TestView from "./components/Jobs/TestView";
import TestCandidate from "./pages/TestCandidate";
import TestCandidateView from "./pages/TestCandidateView";
 
import PersonalityTestDetails from "./components/Jobs/PersonalityTestDetails";
import JobProfile from "./components/Jobs/JobProfile";
import SendPersonalityTest from "./components/Jobs/SendPersonalityTest";
import UpdateSkillsCoefficient from "./components/Jobs/UpdateSkillsCoefficient";
import JobProfileList from "./components/Jobs/JobProfileList";
import TestCompleted from "./components/Jobs/TestCompleted";
import UpdateJobProfile from "./components/Jobs/UpdateJobProfile";
import Tech2 from "./components/Jobs/OneCompiler/Technical2";
import Onboarding from "./components/Dashboard/onboarding/onboarding";
 
 
//Assignment
import CreateAssignmentForm from "./components/Assignment/CreateAssignmentForm";
import AssignmentList from "./components/Assignment/Assignment-list";
import UpdateAssignment from "./components/Assignment/UpdateAssignment";
import AssignmentResult from "./components/Assignment/AssignmentResult"
 
//Subscription
import PayeYourInvoice from "./components/subscription/PayeYourInvoice"
import SubscriptionAndInvoices from "./components/subscription/SubscriptionAndInvoices"
 //Resume template
 import UserResumes from "./components/ResumeBuilder/UserResumes"
 import Resume from "./components/ResumeBuilder/Resume"
//Cookies
import CookieConsent from "../../src/jsx/components/cookies/CookieConsent"
import PolitiqueConfidentialite from "../../src/jsx/components/cookies/PolitiqueConfidentialite";
import PolitiqueCookies from "../../src/jsx/components/cookies/PolitiqueCookies";
const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const location = useLocation();
  const payed = localStorage.getItem('NotPayed') !== 'true';
  const history = useHistory();
  const redirectPath = localStorage.getItem('redirectPath');

  useEffect(() => {
    if (!payed) {
        history.push("/PayeYourInvoice");
    } else {
      
        if (location.pathname !== "/cv-upload") {
            localStorage.removeItem("appliedJobId");
        }
       

    }
}, [location.pathname, payed, history]);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    { url: "search-jobs", component: Jobs },
    { url: "applications", component: Applications },
    { url: "my-profile", component: MyProfile },
    { url: "statistics", component: Statistics },
    { url: "companies", component: Companies },
    { url: "task", component: Task },
    { url: "profile", component: Profile },
    { url: "admin-dashboard/:activeTab", component: Admin_dashboard },
    { url: "roles-managment", component: Roles_managment },
    { url: "company", component: Company },

    { url: "NotFound", component: NotFound },
    //Jobs
    { url: "new-test", component: NewTest },
    { url: "personality-test", component: PersonalityTest },
    { url: "send-personality-test", component: SendPersonalityTest },
    { url: "post-job", component: PostJob },
    { url: "job-list", component: JobLists },
    { url: "job-list/read/:id", component: ReadJob },
    { url: "job-list/update/:id", component: UpdateJob },
    { url: "upload-test/update/:id", component: UpdateCoefficient },
    { url: "test-list", component: TestList },
    { url: "peronality-test-result", component: TestView },
    { url: "personality-test-details/:id", component: PersonalityTestDetails },
    { url: "job-view", component: JobView },
    { url: "job-application", component: JobApplication },
    { url: "apply-job", component: ApplyJob },
    { url: "new-job", component: NewJob },
    { url: "user-profile", component: UserProfile },
    { url: "upload-test", component: UploadTest },
    { url: "job-profile", component: JobProfile },
    { url: "update-coefficient/:id", component:UpdateSkillsCoefficient  },
    { url: "update-job-profile/:id", component:UpdateJobProfile  },
    { url: "job-profile-list", component:JobProfileList  },
    { url: "page-test-completed", component:TestCompleted  },
    { url: "job-role-list", component: JobRoleListApp },
    { url: "details/:id", component: JobRoleDetails },
    { url: "Send-Job-Email", component: SendJobEmail },
 
    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "email-compose", component: Compose },
    { url: "email-inbox", component: Inbox },
    { url: "email-read", component: Read },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },
 
    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },
 
    /// Bootstrap
    { url: "ui-alert", component: UiAlert },
    { url: "ui-badge", component: UiBadge },
    { url: "ui-button", component: UiButton },
    { url: "ui-modal", component: UiModal },
    { url: "ui-button-group", component: UiButtonGroup },
    { url: "ui-accordion", component: UiAccordion },
    { url: "ui-list-group", component: UiListGroup },
    { url: "ui-media-object", component: UiMediaObject },
    { url: "ui-card", component: UiCards },
    { url: "ui-carousel", component: UiCarousel },
    { url: "ui-dropdown", component: UiDropDown },
    { url: "ui-popover", component: UiPopOver },
    { url: "ui-progressbar", component: UiProgressBar },
    { url: "ui-tab", component: UiTab },
    { url: "ui-pagination", component: UiPagination },
    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },
 
    /// Plugin
    { url: "uc-select2", component: Select2 },
    //{ url: "uc-nestable", component: Nestable },
    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: MainSweetAlert },
    { url: "uc-toastr", component: Toastr },
    { url: "map-jqvmap", component: JqvMap },
    { url: "uc-lightgallery", component: Lightgallery },
 
    ///Redux
    { url: "todo", component: Todo },
    //{ url: "redux-form", component: ReduxForm },
    // { url: "redux-wizard", component: WizardForm },
 
    /// Widget
    { url: "widget-basic", component: Widget },
 
    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-invoice", component: Invoice },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },
 
    /// Form
    { url: "form-element", component: Element },
    { url: "form-wizard", component: Wizard },
    { url: "form-editor-summernote", component: SummerNote },
    { url: "form-pickers", component: Pickers },
    { url: "form-validation-jquery", component: jQueryValidation },
 
    /// table
    { url: "table-filtering", component: FilteringTable },
    { url: "table-sorting", component: SortingTable },
    { url: "table-datatable-basic", component: DataTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },
    { url: "/dashboard", component: Home },
    { url: "dashboard-dark", component: DashboardDark },
    
 
 
    /// Candidates
  
    { url: "cv-upload", component: CvUpload },
   
    { url: "candidateList", component: CandidateList },
    { url: "CVsDataExtraction", component: CVsDataExtraction },
    { url: "ListAllCandidates", component: ListAllCandidates },
    { url: "CandidatProfile/:id", component: CandidatProfile },
    { url: "ChatGPT", component: ChatGPT },
 
    { url: "CandidateData", component: CandidateData},
    { url: "UserList", component: UserList},
  
    { url: "CandidateEval", component: CandidateEval},
 
    { url: "CvManager", component: CvManager},
 
    { url: "Cv-Extraction-Forum", component: CvDataExtractionForum },
 
  ///Test PsychoTechnicalTest
  { url: "ListCategories", component: ListCategories },
  { url: "library-Psycho-MCQ-Questions", component: ExcelTable },
  { url: "QuestionOfCategory/:id", component: QuestionOfCategory },
  { url: "TestPsychotechnique", component: TestPsychotechnique },
  { url: "TestPsychotechniqueList", component: TestPsychotechniqueList },
  { url: "TestPsychoTchniqueDetails/:id", component: TestPsychoTchniqueDetails },
  { url: "Your-Psycho-Technical-tests", component: JobRoleList },
  { url: "TestPsychotechniqueCandidat/:id", component: TestPsychotechniqueCandidat },
  { url: "Test-Psychotechnique-For-Recruter/:id", component: TestPsychotechniqueForRecruter },
  { url: "UpdateTestPsychotechnique/:id", component: UpdateTestPsychotechnique },
 
  { url: "rapport", component: Rapport },
  { url: "QuestionTable", component: QuestionTable },
  { url: "RadarPsycho", component: RadarPsychoComponent },
  { url: "RadarGlobal", component: RadarGlobal },
 
  ///Test Techique
 
  { url: "TopicsByCandidate", component: TopicsByCandidate },
  { url: "technical/:id", component: Tech },
  { url: "technical2/:id", component: Tech2 },
  { url: "library-Technique-MCQ-Questions", component: ExcelTableTech },
  { url: "ListSkills", component: ListSkills },
  { url: "QuestionOfSkill/:id", component: QuestionOfSkill },
  { url: "TechQCMList", component: TechQCMList },
  { url: "TechQCM", component: TechQCM },
  { url: "SkillsDashboard", component: SkillsDashboard },

  { url: "TechQCMDetails/:id", component: TechQCMDetails },
  { url: "Test-QCM-For-Recruter/:id", component: TechQCMForRecruter },
 
  { url: "Your-Technical-tests", component: JobRoleListTech },
  { url: "TestTechniqueCandidat/:id", component: TestTechniqueCandidat },
  { url: "rapportTech", component: RapportTech },
  { url: "rapport", component: Rapport },
  { url: "QuestionTableTech", component: QuestionTableTech },
  { url: "RadarTech", component: RadarTechComponent },
  { url: "TestTechComplited", component: TestTechCompleted },
  { url: "CreateTestForm", component: CreateTestForm },
  { url: "TechList", component: TechList },
  { url: "ListTestTechCoding", component: ListTestTechCoding },
  { url: "technicalRes/:id", component: TechRes },
  { url: "UpdateTestTechniques/:id", component: UpdateTestTechniques },

  { url: "topic-for-recruiter/:id", component: TechRecruiter },
  { url: "topic-for-recruiter2/:id", component: TechRecruiter2 },

  
 
//Tests
{ url: "JobRoleTests/:id", component: JobRoleTests },
{ url: "JobRoleListTest", component: JobRoleListTest },
//UserLogs
{ url: "UserLogs", component: UserLogs },
{ url: "CandidatesPerApp", component: CandidatesPerApp },
{ url: "JobAssessment", component: JobAssessment },
{ url: "Job-Details/:id", component: JobDetails },
{ url: "User-Profile-Candidate/:id", component: UserProfileCandidate },
{ url: "candidat/:id", component: CandidateDetails },

//Inbterviews
{ url: "InterviewList", component: InterviewList },
{ url: "MeetScheduler", component: MeetScheduler },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
    { url: "page-test-candidate/:id", component: TestCandidate },
    { url: "page-test-candidateView/:id", component: TestCandidateView },
    //Assignment
    { url: "create-assignment", component: CreateAssignmentForm },
    { url: "assignment-list", component: AssignmentList },
    { url: "update-assignment/:id", component: UpdateAssignment },
    { url: "assignment-result", component: AssignmentResult },
//subscription
{ url: "PayeYourInvoice", component: PayeYourInvoice },
{ url: "SubscriptionAndInvoices", component: SubscriptionAndInvoices },
 //ResumeBuilder
{ url: "UserResumes", component: UserResumes },
{ url: "Resume", component: Resume },
 //Cookies
 { url: "PolitiqueConfidentialite", component: PolitiqueConfidentialite},
 { url: "PolitiqueCookies", component: PolitiqueCookies },
  ];
  let pagePath1 = window.location.pathname;
  let pagePath2 = window.location.pathname;
  
  let testCandidatePattern = /^\/page-test-candidate\/\d+$/;
  let technicalPattern = /^\/technical2\/\d+$/;
  let testCandidateViewPattern = /^\/page-test-candidateView\/\d+$/;
 
 
  let isTestCandidatePage = testCandidatePattern.test(pagePath1);
  let isTestCandidateViewPage = testCandidateViewPattern.test(pagePath1);
  let isTechnicalPage = technicalPattern.test(pagePath2);
 
 
  pagePath2 = pagePath2.split("/");
  pagePath2 = pagePath2[pagePath2.length - 1];
  
  let pagePath = pagePath2.split("-").includes("page") || isTestCandidatePage || isTechnicalPage || isTestCandidateViewPage ;
  const [tech,setTech]= useState(false);
 
 
  //ensure the full screen for the technical test
    let pagePathCurrent = window.location.pathname;
    let technicalPattern1 =/^\/technical\/\d+$/;
    let topicRecruiterPattern1 =/^\/topic-for-recruiter\/\d+$/;
    let topicRecruiterPattern2 =/^\/topic-for-recruiter2\/\d+$/;
    
    let dashboardPattern =/^\/onboarding/;
    let subscriptionPattern = /^\/PayeYourInvoice/;
    let signUpPattern = /^\/page-register/;
 
    let isOnboarding = dashboardPattern.test(pagePathCurrent);
    let isSub = subscriptionPattern.test(pagePathCurrent);
    let isSignUp = signUpPattern.test(pagePathCurrent);
 
 
    let technicalPatternQCM = /^\/TestTechniqueCandidat\/\d+$/;
    let psychotchPattern = /^\/TestPsychotechniqueCandidat\/\d+$/;
    let psychotchRecruterPattern = /^\/Test-Psychotechnique-For-Recruter\/\d+$/;
    let technicalForRecruterPatternQCM = /^\/Test-QCM-For-Recruter\/\d+$/;
 
    //let allTestPattern = /^\/JobRoleTests\/\d+$/;
    
    let isTechnicalPage1 = technicalPattern1.test(pagePathCurrent) || technicalPatternQCM.test(pagePathCurrent) || psychotchPattern.test(pagePathCurrent)|| psychotchRecruterPattern.test(pagePathCurrent)|| technicalForRecruterPatternQCM.test(pagePathCurrent) || topicRecruiterPattern1.test(pagePathCurrent) || topicRecruiterPattern2.test(pagePathCurrent);
 
 
  const [toggle, setToggle] = useState("");
  const [showContent, setShowContent] = useState(false); // State pour gérer l'affichage du contenu
/*
  useEffect(() => {
    const checkCookies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/check-cookies`, {
          credentials: 'include'
        });
    
        const data = await response.json();
        console.log(data);
    
        if (data.message === 'Cookies accepted.') { // Vérifier si les cookies sont acceptés
          setShowContent(true); // Afficher le contenu si les cookies sont acceptés
        }
      } catch (error) {
        console.error('Error checking cookies:', error);
      }
    };
  
    checkCookies();
  }, []);*/
  return (
    <>
    
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
             <CookieConsent />
          {isSignUp &&
          <Route exact path='/page-register' component={Registration} />
          }
        {!isTechnicalPage1 && !isOnboarding && !isSub && !isSignUp &&
      <div>
        {!pagePath  && <Nav />}
     
        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >  
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
             
          </div>
        </div>
        {!pagePath &&<Footer />}
      </div>
        }
        
        {isTechnicalPage1 &&
        <Switch>
        {routes.map((data, i) => (
          <Route
            key={i}
            exact
            path={`/${data.url}`}
            component={data.component}
          />
        ))}
      </Switch>
       
        }
         
         {isOnboarding &&
          <Route exact path='/onboarding' component={Onboarding} />
          }
   {isSub &&
          <Route exact path='/PayeYourInvoice' component={PayeYourInvoice} />
          }
      </div>
      
      <Setting />
      <ScrollToTop />
    
    </>
  );
};
 
export default Markup;
