import React, { useEffect, useState,useCallback,Fragment } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { logout } from '../../../store/actions/AuthActions';
import translations from "../AppsMenu/Candidate/mulitlingue";
import {
    Row,
    Col,
    Card,
    Button,
    Form,
    ProgressBar,
    Modal,
} from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { Icon } from "@iconify/react";
import { FaArrowRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system'
import { CiLogout } from "react-icons/ci";



  const subscriptionLinks = {
    Free: "https://buy.stripe.com/test_cN2eYt5Gi4tl8LufZ5",
    "Solo Starter": "https://buy.stripe.com/test_6oE4jP7Oq8JBaTCbIJ",
    "Solo Pro": "https://buy.stripe.com/test_8wMaId4Ce9NF9Py7su",
    "Solo Premium": "https://buy.stripe.com/test_6oE7w10lY4tle5OeUX",
    "Entreprise Starter": "https://buy.stripe.com/test_28o4jP4Ce7Fx8Lu9AE",
    "Entreprise Pro": "https://buy.stripe.com/test_9AQbMhd8K8JBgdW7sx",
    "Entreprise Premium": "https://buy.stripe.com/test_14k6rX1q23phf9SbIO",
    "Pay as you go": "https://buy.stripe.com/test_bIYdUp0lYf7Z3ra144"
  };

const PayeYourInvoice = () => {
  const token=localStorage.getItem("token");
const [subscription, setSubscription] = useState([]);

    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const history = useHistory();
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const dispatch = useDispatch();
    const [features, setFeatures] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [enterpriseSubscriptions, setEnterpriseSubscriptions] = useState([]);
    const [soloSubscriptions, setSoloSubscriptions] = useState([]);
    const [allSubscriptions, setAllSubscriptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState("TND");
    const [selectedBilling, setSelectedBilling] = useState("monthly");
    const [closestSubscription, setClosestSubscription] = useState(null);
    const [exchangeRates, setExchangeRates] = useState({});
    const [exchangeRatesPayme, setExchangeRatesPayme] = useState({});
    useEffect(async() => {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/exchanges/getAll`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
          console.log("exchanges:",response.data)
          const ratesData = response.data;
    
          const commercialRate = ratesData.find(rate => rate.ExchangeType === "Commercial rate");
          const referenceConversionRate = ratesData.find(rate => rate.ExchangeType === "Reference Conversion Rate");
      
          if (commercialRate) {
          setExchangeRates({
            EUR: commercialRate.EUR,
            USD: commercialRate.USD,
            TND: commercialRate.TND,
            GBP: commercialRate.GBP
          });
          }
      
          if (referenceConversionRate) {
          setExchangeRatesPayme({
            EUR: referenceConversionRate.EUR,
            USD: referenceConversionRate.USD,
            TND: referenceConversionRate.TND,
            GBP: referenceConversionRate.GBP
          });
        }
    }, [])
    async function detectCurrency() {
      try {
          // Essayer avec une API
          const response = await fetch('https://api.ipgeolocation.io/ipgeo?apiKey=9e3b45451ec84066bbe6ab79f68749c1');
          if (!response.ok) {
              throw new Error('API indisponible');
          }
  
          const data = await response.json();
          return data.currency ? data.currency.code : null;
      } catch {
          // Fallback sur les paramètres du navigateur
          console.warn('API indisponible, fallback sur la locale');
          return detectCurrencyByLocale();
      }
  }
  
  function detectCurrencyByLocale() {
      const language = navigator.language || navigator.userLanguage;
      const currencyMapping = {
          'fr-FR': 'EUR',
          'fr-TN': 'TND',
          'en-US': 'USD',
      };
      return currencyMapping[language] || 'EUR';
  }
 
  useEffect(() => {
    detectCurrency().then(currency => {
      console.log("Detected currency:", currency);
      setSelectedCurrency(currency);
    });
  
  }, []);
  const calculateConvertedPrice = (price, currency) => {
    if (!price) return "0.00"; 
  
    const cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; 

    

    return (cleanedPrice * exchangeRates[currency] ).toFixed(2);
  }; 
  const calculateConvertedPricereduction = (price, currency,promoPercentage) => {
    if (!price) return "0.00"; 
  
    let cleanedPrice = parseFloat(
      price.replace(/[^\d.,]/g, "").replace(",", ".")
    );

    if (isNaN(cleanedPrice)) return "0.00"; 
    let rapportPercentage=(100-promoPercentage)/100
    cleanedPrice=cleanedPrice*rapportPercentage

    return (cleanedPrice * exchangeRates[currency] ).toFixed(2);
  };
    const convertToTND = (price, currency) => {
      if (!exchangeRatesPayme[currency]) {
        throw new Error(`Unsupported currency: ${currency}`);
      }
      const exchangeRate = exchangeRatesPayme['TND'] / exchangeRatesPayme[currency];
      return (parseFloat(price) * exchangeRate).toFixed(2);
      };
    useEffect(() => {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        localStorage.setItem("NotPayed", true);

        const fetchInvoiceData = async () => {
            try {
              if(currentUser.userRole===3 || currentUser.userRole===2){

              const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${userId}`, 
                {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  },
                });
                setInvoiceData(response.data);
                setLoading(false);}
            } catch (error) {
                setError('Failed to fetch invoice data');
                setLoading(false);
            }
        };

        fetchInvoiceData();
    }, []);
    const showFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionFeature/getAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
          const featureData = response.data;

          setFeatures(featureData);

          const response2 = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/subscriptionfeaturespertypes/findAllWithTypeSub`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const sub = response2.data;
          setSubscriptionTypes(sub);

          const featureOrder = [
              'Candidate Profiles',
              'Candidate extraction',
              'Create job profile',
              'Create job role',
              'Parcours'
          ];

          const sortFeatures = (features) => {
              return features.sort((a, b) => {
                  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
              });
          };

          const soloSubs = {};
          const enterpriseSubs = {};

          for (const [key, value] of Object.entries(sub)) {
              const sortedValue = [...value].sort((a, b) => {
                  return featureOrder.indexOf(a.feature_name) - featureOrder.indexOf(b.feature_name);
              });

              if (key.startsWith("Solo") || key === "Free" || key === "Pay as you go") {
                  soloSubs[key] = sortedValue;
              }
              if (key.startsWith("Entreprise") || key === "Free" || key === "Pay as you go") {
                  enterpriseSubs[key] = sortedValue;
              }
          }

          setSoloSubscriptions(soloSubs);
          setEnterpriseSubscriptions(enterpriseSubs);
          console.log("enterpriseSubs:",enterpriseSubs)

          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
       const role = currentUser.userRole;
          let allSubscriptions = [];
          let SUB = null;

          if(role===3){
            SUB = soloSubs;


          }else if(role===2){
            SUB = enterpriseSubs;

          }

          Object.entries(SUB).forEach(([typeName, features]) => {
            let priceType="";
            let idProduct = "";
		let promoPercentage = "";
		let isPromo = "";
    let Periodicity = "";

            features.forEach((feature) => {
              priceType=feature.type_monthly_price;
              idProduct = feature.type_id;
              isPromo = feature.type_isPromo;
              promoPercentage = feature.type_promoPercentage;
              Periodicity=feature.Periodicity;

            });
        
            
        
            allSubscriptions.push({ typeName, features,priceType , idProduct ,isPromo,promoPercentage,Periodicity});
          });
      
          setAllSubscriptions(allSubscriptions);

      } catch (error) {
          console.error("Error fetching features:", error);
      }
  };
    useEffect(() => {
      

        showFeatures();
    }, []);

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem("selectedLang");
        if (storedLang) {
            const parsedLang = JSON.parse(storedLang);
            if (parsedLang.language === "Français") {
                return translations.fr;
            }
        }
        return translations.en;
    });
    const [companyID, setCompanyID] = useState("");

    const fetchCompany = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
    
        const getUserCompanyID = async () => {
          const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await responseC.json();
          return data[0].companyID;
        };
    
        const compId = await getUserCompanyID();
        setCompanyID(compId)
      } catch (error) {
        console.error('Erreur lors de la récupération des emplois:', error);
      }
    };
    useEffect(()=>{
      fetchCompany()
    }, []);
    const handleLogoutConfirm = () => {
        setShowModal(true);
        dispatch(logout(history));
    };

    const handleLogout = () => {
        setShowLogoutPopup(true);
    };

    const handleCloseModal = () => {
      setClosestSubscription(null);
    };
    const handleClose = () => {
        setShowLogoutPopup(false);
    };
   
    const ModalPayme = ({ subscriptionType,userEmail,selectedCurrency,selectedPeriodicity }) => {
      const [showModal, setShowModal] = useState(false);
      const [token, setToken] = useState("");
      const amountPayme= selectedPeriodicity.isPromo ===0 ? calculateConvertedPrice(selectedPeriodicity.prix, selectedCurrency):calculateConvertedPricereduction(selectedPeriodicity.prix, selectedCurrency,selectedPeriodicity.PromoPercentage)
    
      const [formData, setFormData] = useState({
        amount: amountPayme,
        note: `${subscriptionType.idProduct}`,
        first_name: '',
        last_name: '',
        email: userEmail,
        phone: '50719929',
        order_id: `${subscriptionType.idProduct}`,
         monthAnnuel:selectedPeriodicity.periode
      });
      
      
      
      const [gateway, setGatway] = useState(false)
        const [formError, setFormError] = useState(null)
        const [error, setError] = useState(null)
        const [iframeURL, setIframeURL] = useState('')
        const { t } = useTranslation()
        const [phone, setPhone] = useState('50719929')
        const [amount, setAmount] = useState(amountPayme)
        const [email, setEmail] = useState(userEmail)
        const [firstName, setFirstName] = useState("")
        const [lastName, setLastName] = useState("")
          const [monthAnnuel, setmonthAnnuel] =useState(selectedPeriodicity.periode);
        useEffect(() => {
        setmonthAnnuel(selectedPeriodicity.periode); // Synchronise avec la périodicité sélectionnée par défaut
        setFormData((prevData) => ({
          ...prevData,
          monthAnnuel: selectedPeriodicity.periode,
          amount: amountPayme, // Calcul en fonction de la périodicité actuelle
        }));
        setAmount(amountPayme)
        }, [selectedPeriodicity, amountPayme]);
        const handelClick = async () => {
        try {
          const res = await axios.get(
            `https://sandbox.paymee.tn/api/v1/accounts/${phone}/account-number`,
            {
              headers: {
                Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
              },
            }
          )
      
          if (!res.data.status) {
            setFormError(res.data.message)
            setGatway(false)
          } else {
            const formDataPayme = new FormData();
            formDataPayme.append("email", formData.email);
            formDataPayme.append("first_name", formData.first_name);
            formDataPayme.append("last_name", formData.last_name);
      
       const finalAmount = selectedCurrency === "TND"
       ? amount
       : convertToTND(amount, selectedCurrency);
     
    
            const res2 = await axios.post(
              `https://sandbox.paymee.tn/api/v2/payments/create`,
              {
                order_id:subscriptionType.idProduct,
                phone: phone,
                amount: finalAmount ,
                note: subscriptionType.idProduct,
                first_name:firstName,
                last_name:lastName,
                email:email,
                return_url: "https://app.hirecue.com",
                cancel_url: "https://app.hirecue.com",
                webhook_url: "https://www.webhook_url.tn"
                
              },
              {
                headers: {
                  Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
                },
              }
            )
      
            if (res2.data.status) {
              setToken(res2.data.data.token)
              setIframeURL(
                `https://sandbox.paymee.tn/gateway/${res2.data.data.token}`
              )
      
              setGatway(true)
              setFormError(null)
      
            } else {
      
              setGatway(false)
              setFormError(res2.data.message)
            }
          }
        } catch (error) {
          console.log(error)
          setError(error)
          setGatway(false)
        }
      }
      const [paymentStatus, setPaymentStatus] = useState(null);
      
      const checkToken = useCallback(async () => {
        if (!token) {
        return;
        }
      
        console.log("Checking token:", token);
      
        try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/payMe/payments/${token}/check/v2`, {
          headers: {
          "Authorization-Bearer": `Bearer ${token}`,
          "Authorization": `Token ${process.env.REACT_APP_PAYME_API_KEY}`
          },
        });
      
        const { data } = response.data;
      
        if (data && data.payment_status) {
          console.log("Payment completed successfully");
          setPaymentStatus('completed');
          setShowModal(false);
          handleCloseModal()
          const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    
          const subscriptionData = {
          userId: currentUser.localId,
          amount: data.amount,
          note: data.note,
          };
        
          try {
          const subscriptionDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/createSubscription`, {
            method: "POST",
            headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            },
            body: JSON.stringify(subscriptionData),
          });
        
          if (!subscriptionDone.ok) {
            throw new Error('Failed to create subscription');
          }
        
          const dataSub = await subscriptionDone.json();
          const storedLang = localStorage.getItem("selectedLang");
          const parsedLang = JSON.parse(storedLang);
      
          const invoiceData = {
            sub_id: dataSub.sub_id,
            customer_id: dataSub.customer_id,
            email: data.email,
            received_amount: data.received_amount,
            amount: data.amount, 
            payment_status: data.payment_status,
            firstname: data.firstname,
            lang: (parsedLang && parsedLang.language) ? parsedLang.language : "Français" ,
                monthAnnuel:monthAnnuel,
                currency:selectedCurrency
          };
        
          const invoiceDone = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/invoice/createinvoice`, {
            method: "POST",
            headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            },
            body: JSON.stringify(invoiceData),
          });
        
          if (!invoiceDone.ok) {
            throw new Error('Failed to create invoice');
          }
          
          showFeatures();
      
          console.log("Subscription and invoice creation completed successfully");
       
        
          } catch (error) {
          console.error("Error:", error);
          }
        }
        else {
          console.log("Payment not completed or unsuccessful");
          setPaymentStatus('incomplete');
        }
        } catch (error) {
        console.error("Error checking payment status:", error);
        setPaymentStatus('error');
        }
      }, [token]);
      
      const handlePaymeeComplete = useCallback((event) => {
        console.log('Paymee payment completed:', event.detail);
        setPaymentStatus('completed');
        setShowModal(false);
        // Perform further actions like updating the UI or notifying the user
      }, []);
      
      useEffect(() => {
        if (paymentStatus == 'completed') {
        console.log('Payment already completed, skipping check.');
        //handleLogout()
        return;
        }
      
        const intervalId = setInterval(checkToken, 1000);
      
        window.addEventListener('paymee.complete', handlePaymeeComplete);
        
        return () => {
         
        clearInterval(intervalId);
        window.removeEventListener('paymee.complete', handlePaymeeComplete);
        
        };
      }, [checkToken, handlePaymeeComplete, paymentStatus]);
      
      useEffect(() => {
        console.log("Hello",token)
        window.addEventListener(
          'message',
          function (event) {
            if (event.data.event_id === 'paymee.complete') {
              //Execute Step 3
              // window.location.replace()
              // https://sandbox.paymee.tn/api/v1/payments/{token}/check
              axios
                .get(
                  `https://sandbox.paymee.tn/api/v2/payments/${token}/check`,
      
                  {
                    headers: {
                      Authorization: `Token ${process.env.REACT_APP_PAYME_API_KEY}`,
                    },
                  }
                )
                .then((res) => {
                  console.log("res.data:",res.data)
                  if (res.data.data && res.data.data.payment_status) {
                    setIframeURL(
                      'https://sandbox.paymee.tn/paylink/success'
                    )
                  } else {
                    setIframeURL(
                      'https://sandbox.paymee.tn/paylink/failure'
                    )
                  }
                })
                .catch((err) => {
                  console.log(err)
                  setError(err)
                })
            }
          },
          false
        )
      }, [token])
      const handleChangemonthAnnuel = (event) => {
        const value = event.target.value;
        setmonthAnnuel(value); 
        };
      return (
        <>
        <Button color="primary" onClick={() => setShowModal(true)} className="btn-soft-primary rounded-pill">
          {lang.menu.purchaseNow}<FaArrowRight />
        </Button>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header >
          <Modal.Title>{lang.menu.purchaseNow}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Card>
                <Card.Header>
                  <h3>{t('Online payment')}</h3>
                </Card.Header>
                <Box overflow="auto">
                <Form>
        {!gateway ? (
          <Fragment>
           <Form.Group className="mb-3" controlId="formAmount">
            <Form.Label>{lang.menu.amount} ({selectedCurrency})</Form.Label>
            <Form.Control type="text" placeholder="Enter amount" name="amount" value={amountPayme}     onChange={(event) => {
                setAmount(event.target.value.toString());
              }} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount">
            <Form.Label>{lang.menu.email}</Form.Label>
            <Form.Control type="email" placeholder="Enter email" name="email" value={userEmail}     onChange={(event) => {
                setEmail(event.target.value.toString());
              }}  />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount">
            <Form.Label>{lang.menu.firstName}</Form.Label>
            <Form.Control type="text" placeholder="Enter first_name" name="first_name" value={firstName}     onChange={(event) => {
                setFirstName(event.target.value.toString());
              }}  />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount">
            <Form.Label>{lang.menu.lastName}</Form.Label>
            <Form.Control type="text" placeholder="Enter last_name" name="last_name" value={lastName}     onChange={(event) => {
                setLastName(event.target.value.toString());
              }}  />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount" hidden>
            <Form.Label>{lang.menu.phone}</Form.Label>
            <Form.Control type="text" placeholder="Enter phone" name="phone" value={formData.phone}  onChange={(event) => {
                setPhone(event.target.value.toString());
              }} />
            </Form.Group>
      
            <div className="mb-3">
          <label htmlFor="formFrequency" className="form-label">
            {lang.menu.frequency}
          </label>
        <select
         id="formFrequency" 
            name="frequency"
                   className="form-select"
                    onChange={handleChangemonthAnnuel}
                    value={monthAnnuel}
            disabled
                  >
                    {subscriptionType.Periodicity.map((period) => (
                      <option key={period.periode} value={period.periode}>
                        {period.periode}
                      </option>
                    ))}
                  </select>
    
    
    {/*       <select
            id="formFrequency" 
            name="frequency"
            value={monthAnnuel} 
            onChange={handleChangemonthAnnuel} 
            className="form-select"
          >
            <option value="Monthly"> {lang.menu.Mensuel}</option>
            <option value="Annuel"> {lang.menu.Annuel}</option>
          </select> */}
        </div>
      
      
          
           
            <br />
            {formError && (
              <p sx={{ color: 'red', my: 1 }}>
                {formError}
              </p>
            )}
            <Button onClick={handelClick}>
              {t('Submit')}
            </Button>
          </Fragment>
        ) : (
          <iframe
            title="gateway payment"
            width="500px"
            height="400px"
            src={iframeURL}
          />
        )}
      </Form>
      
                
                </Box>
              </Card>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>{lang.menu.retour }</Button>
          </Modal.Footer>
        </Modal>
        </>
      );
      };

  const PurchaseLink = ({ subscriptionType,selectedPeriodicity }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalLink, setModalLink] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("userDetails"));
  const userEmail=currentUser.email
    const link = `${subscriptionLinks[subscriptionType.typeName]}?prefilled_email=${userEmail}`;
  
    const handlePurchaseClick = (link) => {
      if (selectedCurrency !== "TND") {
       // setModalLink(link);
       // setShowModal(true);
       window.location.href = link; 
        handleLogout()
      } else {
        //window.open(link, "_blank");
      }
    };
  
 
    return selectedCurrency === "TND" ? (
      <ModalPayme subscriptionType={subscriptionType} userEmail={userEmail} selectedCurrency={selectedCurrency} selectedPeriodicity={selectedPeriodicity} />
    ) : (
      <>
            <ModalPayme subscriptionType={subscriptionType} userEmail={userEmail} selectedCurrency={selectedCurrency} selectedPeriodicity={selectedPeriodicity}/>

       {/*  <Button
          onClick={() => handlePurchaseClick(link)}
          className="btn-soft-primary rounded-pill"
        >
         {lang.menu.purchaseNow} <FaArrowRight />
        </Button> */}
        {/* <PurchaseModal show={showModal} onHide={() => setShowModal(false)} link={modalLink} /> */}
      </>
    );
  };
    const SubscriptionCard = ({ subscription, selectedCurrency }) => {
    const [selectedPeriodicity, setSelectedPeriodicity] = useState(subscription.Periodicity[0]);
    
    const handlePeriodChange = (event) => {
      const newPeriod = subscription.Periodicity.find(
      (period) => period.periode === event.target.value
      );
      setSelectedPeriodicity(newPeriod);
    };
    
    const originalPrice = calculateConvertedPrice(selectedPeriodicity.prix, selectedCurrency);
    const isPromo = selectedPeriodicity.isPromo;
    const promoPercentage = selectedPeriodicity.PromoPercentage;
    const discountedPrice = isPromo
      ? calculateConvertedPricereduction(selectedPeriodicity.prix, selectedCurrency, promoPercentage)
      : originalPrice;
    
    return (
      <Col md={4}>
      <Card className="pricing-box h-100 shadow-sm">
        <Card.Body className="p-4 px-lg-5 position-relative">
        {isPromo && (
          <div className="badge-promo bg-danger text-white position-absolute top-0 end-0 px-3 py-1 rounded-start">
         -{promoPercentage }%
          </div>
        )}
        <div className="pricing-name mt-4 pt-2 text-center">
          <h4 className="fs-18 text-primary">
          <div className="pricing-icon rounded-circle icons-md mx-auto mb-4">
            <Icon icon="uim-telegram-alt" className="text-primary" />
            {subscription.typeName === "Pay as you go"
            ? "Customized offer"
            : subscription.typeName}
          </div>
          </h4>
    
          <select
          className="form-select mb-3"
          onChange={handlePeriodChange}
          value={selectedPeriodicity.periode}
          >
          {subscription.Periodicity.map((period) => (
            <option key={period.periode} value={period.periode}>
            {period.periode}
            </option>
          ))}
          </select>
    
          <div>
          {isPromo ? (
            <div>
            <h4 className="text-muted fw-light text-decoration-line-through">
              {originalPrice} <small className="fs-16 text-muted">{selectedCurrency}</small>
            </h4>
            <h2 className="text-primary fw-bold">
              {discountedPrice} <small className="fs-16 text-muted">{selectedCurrency}</small>
            </h2>
            </div>
          ) : (
            <h4 className="text-primary fw-bold">
            {originalPrice} <small className="fs-16 text-muted">{selectedCurrency}</small>
            </h4>
          )}
          </div>
        </div>
    
        <ul className="list-unstyled mt-3 mb-4">
          {subscription.features
          .filter((feature) => feature.feature_quota_max !== null)
          .map((feature) => (
            <li key={feature.feature_id} className="my-2">
            <strong>{feature.feature_name}:</strong> {feature.feature_quota_max}
            </li>
          ))}
        </ul>
        <PurchaseLink
          subscriptionType={subscription}
          selectedPeriodicity={selectedPeriodicity}
          className="btn btn-primary btn-block"
        />
        </Card.Body>
      </Card>
      </Col>
    );
    };
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Card style={{ backgroundColor: "#8cd6c8d0", marginTop: "8%", marginLeft: "8%", marginRight: "8%" }}>
            <Card.Header>
                <h1 className='text-center'>Your Subscription Has Expired</h1>
                <h4 className='single-line' onClick={handleLogout} style={{ color: 'red', cursor: "pointer" }}>
                    <CiLogout />{'  '}{lang.menu.Logout}
                </h4>
            </Card.Header>
            <Card.Body>
                <p>Your subscription ended on: {new Date(invoiceData.period_end).toLocaleDateString()}</p>
                <p>To continue using our services, please pay your outstanding invoice.</p>
                <Button onClick={() => setShowModal(true)}>
                    {lang.menu.purchaseNow}
                </Button>
                {showLogoutPopup && (
                    <>
                        <div className="overlay" onClick={handleClose}></div>
                        <div
                            style={{
                                position: "fixed",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "white",
                                padding: "20px",
                                width: "40%",
                                minWidth: "180px",
                                maxWidth: "100%",
                                textAlign: "center",
                                border: "1px solid rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
                                zIndex: 999
                            }}
                        >
                            <h3>{lang.menu.aysywtl}</h3>
                            <center>
                                <Row>
                                    <Col>
                                        <Button style={{ width: "50%", marginInline: "20%" }} onClick={handleLogoutConfirm}>
                                            {lang.menu.Confirm}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button style={{ width: "50%", marginInline: "20%", backgroundColor: "#bcebe4", color: "#74188D" }} onClick={handleClose}>
                                            {lang.menu.Cancel}
                                        </Button>
                                    </Col>
                                </Row>
                            </center>
                        </div>
                       
                    </>
                )} 
                <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-100w">
                <Modal.Header>
                    <Modal.Title>{lang.menu.OffreClosest}</Modal.Title>
                </Modal.Header>
            <Modal.Body>
           
            
          <Row>
           {allSubscriptions.map((subscription) => (
             <SubscriptionCard key={subscription.typeName} subscription={subscription} selectedCurrency={selectedCurrency} />
           ))}
         </Row>
          
          
                </Modal.Body>
            </Modal>
            </Card.Body>
        </Card>
    );
};

export default PayeYourInvoice;
