import React, { useState, useEffect } from "react";
import { countries } from "countries-list";
import translations from "../../../components/AppsMenu/Candidate/mulitlingue";

const countryNames = Object.values(countries).map((country) => country.name);

function Exp({user,updateUser}) {
  const [experiences, setExperiences] = useState([
    { post: "", company: "", location: "", startDate: "", endDate: "" },
  ]);
  useEffect(() => {

    
    let storedData 
    if (user.experience) {

      storedData = user.experience;
      
      while (typeof(storedData) === "string") {
      storedData = JSON.parse(storedData);

    }
    
  }
      if (storedData) {
    
        setExperiences(storedData);
      }
    
    
  }, []);
  useEffect(() => {
    updateUser(experiences);
  }, [experiences]);

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      { post: "", company: "", location: "", startDate: "", endDate: "" },
    ]);
  };

  const handleRemoveExperience = (index) => {
    const newExperiences = [...experiences];
    newExperiences.splice(index, 1);
    setExperiences(newExperiences);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newExperiences = [...experiences];
    newExperiences[index][name] = value;
    setExperiences(newExperiences);
  };

  return (
    <div>
      <div className="basic-form">
        <form onSubmit={(e) => e.preventDefault()} className="p-3">
          {experiences.map((experience, index) => (
            <div
              key={index}
              style={{
                border: "2px solid #86E6D7",
                marginBottom: "30px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 5px",
              }}
            >
              <svg
                onClick={() => handleRemoveExperience(index)}
                height="30px"
                width="30px"
                xmlns="http://www.w3.org/2000/svg"
                fill="#74188D"
                viewBox="0 0 24 24"
                stroke="#74188D"
                style={{
                  position: "relative",
                  top: 0,
                  right: -1000,
                  cursor: "pointer",
                  margin: "10px",
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <div style={{ display: "flex", "flex-direction": "row" }}>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                  <label className="my-label">{lang.menu.ypd}</label>
                  <input
                    className="form-control"
                    style={{ marginTop: "3px" }}
                    type="text"
                    name="post"
                    value={experience.post}
                    onChange={(e) => handleChange(index, e)}
                    placeholder={lang.menu.ypd}
                  />
                </div>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                  <label className="my-label">{lang.menu.ComN}</label>
                  <input
                    className="form-control"
                    style={{ marginTop: "3px" }}
                    type="text"
                    name="company"
                    value={experience.company}
                    onChange={(e) => handleChange(index, e)}
                    placeholder={lang.menu.ComN}
                  />
                </div>
              </div>
              <div style={{ display: "flex", "flex-direction": "row" }}>
                <div className="form-group mb-3 col-md-6 my-form-padding">
                  <label className="my-label">{lang.menu.ComL}</label>
                  <select
                    style={{ marginTop: "3px" }}
                    className="form-control"
                    name="location"
                    value={experience.location}
                    onChange={(e) => handleChange(index, e)}
                  >
                    <option value="">{lang.menu.sac}</option>
                    {countryNames.map((country, i) => (
                      <option key={i} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{ display: "flex", "flex-direction": "row" }}>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Started}</label>
                    <input
                      className="form-control"
                      type="month"
                      name="startDate"
                      value={experience.startDate}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>

                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Ended}</label>
                    <input
                      className="form-control"
                      type="month"
                      name="endDate"
                      value={experience.endDate}
                      onChange={(e) => handleChange(index, e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <center>
            <svg
              cursor="pointer"
              width="40px"
              height="40px"
              onClick={handleAddExperience}
              stroke="#74188D"
              fill="#74188D"
              strokeWidth="0"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-add-circle-outline"
            >
              <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
            </svg>
          </center>
        </form>
      </div>
    </div>
  );
}


export default Exp;
