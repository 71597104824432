import React, { useRef, useEffect,useState } from 'react';
import html2canvas from 'html2canvas';

import {Row,Card,Col, Button, Carousel, Modal, Form,Table} from 'react-bootstrap';
import { MdOutlineEmail } from "react-icons/md";
import { Link,useHistory } from "react-router-dom";

import { IoChevronBack } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import { SlUserUnfollow } from "react-icons/sl";
import { MdOutlineAlternateEmail } from "react-icons/md";
import translations from"../AppsMenu/Candidate/mulitlingue";
 
import axios from 'axios';
import { FaAngleDown,FaUserCheck  } from "react-icons/fa6";
import { FcCheckmark , FcCancel} from "react-icons/fc";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BsFiletypePdf } from "react-icons/bs";
import { SiGooglemeet } from "react-icons/si";
import Select from "react-select";
import { VscRunAll } from 'react-icons/vsc';
import { MdOutlineFavorite } from "react-icons/md";
import { CgProfile } from "react-icons/cg";

import {
  Comment,
  CommentContent,
  CommentAvatar,
  CommentAuthor,
  Form as SemanticUIForm,
  CommentText,
  CommentMetadata,
  Header,
  CommentActions,
  CommentAction
} from 'semantic-ui-react';
import { ca } from 'date-fns/locale';
import swal from "sweetalert";
import { SlLike } from "react-icons/sl";
import { RiReplyLine } from "react-icons/ri";
import Swal from "sweetalert2";
import "./profile.css";

const CandidateDetails = () => {
    const candidateDetails = localStorage.getItem('candidateDetails');
    var candidate = JSON.parse(candidateDetails);
    const history = useHistory();
    const footerRef = useRef(null);
    const token=localStorage.getItem("token");

    const [showDetails, setShowDetails] = useState(false);
  const [testPassedTech, setTestPassedTech] = useState([]);
  const [testPassedCoding, setTestPassedCoding] = useState([]);
  const [stars, setStars] = useState(0);
  const [isContentFilled, setIsContentFilled] = useState(false);
  const [content, setContent] = useState('');
  const [testPassedPsycho, setTestPassedPsycho] = useState([]);
  const [showDetails2, setShowDetails2] = useState(false);
  const [showDetailsPer, setShowDetailsPer] = useState(false);

  const [reference, setReference] = useState([]);
  const [ratingsList, setRatingsList] = useState([]);
    const [selectedLang, setSelectedLang] = useState(null);
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyName, setCompanyName] = useState('');
    const [jobs, setjobs] = useState([]);
    const [techCoding, setTechCoding] = useState([]);
    const [techQCM, setTechQCM] = useState([]);
    const [isFavorite, setIsFavorite] = useState(candidate.IsFavorite);

    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);


          if (parsedLang.language === 'Français') {
            return translations.fr;
          }
        }
        return translations.en;
      });
      useEffect(() => {
        const fetchRef = async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getRefferenceByEmail/${candidate.email}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setReference(response.data.candidates[0])
      }
      
      }
        fetchRef()
      }, []);
      useEffect(() => {
        const getUserCompanyID = async () => {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await responseC.json();
          const compId = data[0].companyID;

          const foundCompany = companies.find(company => company.companyid === compId);
          if (foundCompany) {
            setCompanyName(foundCompany.companyName);
          }
        };
        getUserCompanyID();
      }, [companies]); 
      useEffect(() => {
        const fetchCompanyName = async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setCompanies(response.data)
      }
      
      }
      fetchCompanyName()
      }, []);
      useEffect(() => {
        const fetchTechQCM= async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getAllTests`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setTechQCM(response.data)
      }
      
      }
      fetchTechQCM()
      }, []);
      useEffect(() => {
        const fetchTechCoding= async () => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getAllTopics`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setTechCoding(response.data)
      }
      
      }
      fetchTechCoding()
      }, []);
      const getTesQCMNameById = (testId) => {
        const test = techQCM.find((item) => item.test_id === testId);
        return test ? test.test_name : "Test not found";
      };
      const getTesCodingNameById = (testId) => {
        const test = techCoding.find((item) => item.id === testId);
        return test ? test.name : "Test not found";
      };
      useEffect(() => {
        const fetchRef = async () => {
          const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
        setUsers(userResponse.data);
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/JobRole/findRatingById/${candidate.id_candidate}/${candidate.id_jobrole}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      if(response.data.length!=0){
        setRatingsList(response.data.data)
      }
      
      }
        fetchRef()
      }, []);
    useEffect(() => {
      const footerHeight = footerRef.current.offsetHeight;
    const textarea = document.getElementById('myTextarea');
      textarea.style.height = `${footerHeight}px`;
    }, []);
    useEffect(() => {
      const footerHeight = footerRef.current.offsetHeight;
    const textarea = document.getElementById('myTextarea');
      textarea.style.height = `${footerHeight}px`;
    }, []);
  
    const getCandAcceptStatus = (candAccept) => {
      switch (candAccept) {
        case 2:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-success me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.passUser}
            </div>
          );
        case 1:
          return (
            <div style={{fontSize: 'smaller'}}>
            <i className="fa fa-circle text-warning me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i><span > {lang.menu.pendingUser}</span>
          </div>
          
          );
        case 0:
          return (
            <div style={{fontSize: 'smaller'}}>
              <i className="fa fa-circle text-dark me-1" style={{fontSize: 'smaller', marginRight: '5px'}}></i> {lang.menu.appliedUser}
            </div>
          );
        default:
          return "";
      }
    };
  
    const getTestPsychoName = async (testId) => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${testId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});

        const testName = response.data.test_name;
        return testName
    }

    const handleResendTestOne = async (test) => {
      // Affiche l'alerte SweetAlert
      const result = await Swal.fire({
        title: lang.menu.areUSure, // Titre de l'alerte
        text: lang.menu.resendParcourMsg, // Message de confirmation
        icon: "warning", // Icône (warning)
        showCancelButton: true, // Bouton d'annulation
        confirmButtonColor: "#3085d6", // Couleur du bouton de confirmation
        cancelButtonColor: "#d33", // Couleur du bouton d'annulation
        confirmButtonText: "OK", // Texte du bouton de confirmation
        cancelButtonText: lang.menu.cancel, // Texte du bouton d'annulation
      });
    
      // Vérifie si l'utilisateur a confirmé l'action
      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${process.env.REACT_APP_APP_DOMAIN}/api/technical/resendOneTest/${test.id_user}/${test.id_job}/${test.id_test_content}/${test.techTestType}/${test.id_test}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          // Affiche un message de succès après la suppression
          if (response.status === 200) {
            Swal.fire({
              title: "Success",
              text: "The test has been resent successfully.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "The test could not be resent. Please try again.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error resending the test:", error);
          Swal.fire({
            title: "Error",
            text: "An error occurred while resending the test.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } else {
        // Action annulée par l'utilisateur
        Swal.fire({
          title: "Cancelled",
          text: "The test resend operation was cancelled.",
          icon: "info",
          confirmButtonText: "OK",
        });
      }
    };
    const handleButtonClick1 = (noteTech,noteCoding) => {
      setShowDetails(!showDetails);

  setTestPassedTech(noteTech)
  setTestPassedCoding(noteCoding)
    };
    const handleButtonClick = (note) => {
      setShowDetails2(!showDetails2);

  setTestPassedPsycho(note)
    };

    const handleButtonClick2 = (noteTech) => {
    setShowDetails(!showDetails);
  setTestPassedTech(noteTech)

    };
    const handleButtonClickPer = (notePer) => {
      setShowDetailsPer(!showDetailsPer);
  
      };
    const handleButtonClick3 = (noteCoding) => {
      setShowDetails(!showDetails);

  setTestPassedCoding(noteCoding)
    };
    const handleSubmitRating = async () => {
      try {
        if (!isContentFilled) return;
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
        const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateCandidateJobRating/${candidate.id_candidate}/${candidate.id_jobrole}`, {
          recruteurRate: userId,
          content: content,
          stars: stars
        }, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});  
        swal("Rating submitted successfully", {
          icon: "success",
        });
      }
      catch{
        swal('Failed to submit the form', {
          icon: "error",
        });
       }
    }
    const ratingcandidateChanged = (newRating) => {
      setStars(newRating)
    };
    const handleContentChange = (e) => {
      setContent(e.target.value);
      setIsContentFilled(!!e.target.value); 
    };
    const handlePDFDownload = () => {
      const element = document.querySelector('.CandidateJob'); 
  
      html2canvas(element, {
          scale: 2,
          logging: true, 
      }).then(canvas => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4'); 
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
  
          const imgWidth = pdfWidth;
          const imgHeight = (canvasHeight * pdfWidth) / canvasWidth; 
  
          let position = 0;
  
          if (imgHeight > pdfHeight) {
              while (position < imgHeight) {
                  pdf.addImage(imgData, 'PNG', 0, position - imgHeight + pdfHeight, imgWidth, imgHeight);
                  position += pdfHeight;
                  if (position < imgHeight) {
                      pdf.addPage();
                  }
              }
          } else {
              pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          }
  
          pdf.save('download.pdf');
      });
  };
  
  const sendEmailToCandidate = async () => {
    try {
      const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailFinal`, {
        email: candidate.email,
        jobName: candidate.jobName,
        lname:candidate.LastName,
        fname:candidate.FirstName,
        comp:companyName,             
         lang:parsedLang.language

      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
  
      if (response.status === 200) {
        swal('Email sent successfully', {
          icon: "success",
        });
      } else {
        swal('Failed to send email', {
          icon: "error",
        });
      }
    } catch (error) {
      swal('Error sending email:', error, {
        icon: "error",
      });
    }
  };
  const sendEmailToCandidateReject = async () => {
    try {
      const storedLang = localStorage.getItem("selectedLang");
    const parsedLang = JSON.parse(storedLang);
      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/sendEmailFinalRejct`, {
        email: candidate.email,
        jobName: candidate.jobName,
        lname:candidate.LastName,
        fname:candidate.FirstName,
        comp:companyName,
        lang:parsedLang.language

      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});
  
      if (response.status === 200) {
        swal(lang.menu.emailsentSucc, {
          icon: "success",
        });
        const responseReject = await axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/RejectCandidate/${candidate.id_candidate}/${candidate.id_jobrole}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      } else {
        swal(lang.menu.emailsentfailed, {
          icon: "error",
        });

      }
    } catch (error) {
      swal(lang.menu.emailsentfailed, {
        icon: "error",
      });
    }
  };

  const [techTestAntiCheatingReport, setTechTestAntiCheatingReport]= useState([]);
  const [testTech, setTestTech]= useState();
  useEffect(()=>{
    const getTechnicalTestResult= async ()=>{
    try {
      const candidateid= candidate.id_candidate;
      const jobid=candidate.id_jobrole;
      const response= await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/technicalTest/${candidateid}/${jobid}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      setTechTestAntiCheatingReport(response.data);
      setTestTech(response.data.technicalTest[0]);
    } catch (error) {
      console.log(error);
    }
    }

    getTechnicalTestResult();


},[]);

const [displaySnapshots, setDisplaySnapshots]= useState(true);
const [imageUrls, setImageUrls]= useState([]);

const fetchSnapShots = async (index) => {
  try {

      // Ensure that the necessary data is present before accessing properties
      if (
          techTestAntiCheatingReport &&
          Array.isArray(techTestAntiCheatingReport.technicalTest) &&
          techTestAntiCheatingReport.technicalTest.length > index && // Check that index is within bounds
          techTestAntiCheatingReport.technicalTest[index] && // Ensure the element at index exists
          techTestAntiCheatingReport.technicalTest[index].TestTech && // Ensure TestTech exists
          techTestAntiCheatingReport.technicalTest[index].TestTech.takeSnapShots // Check takeSnapShots is true
      ) {

          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/getSnapShots/${techTestAntiCheatingReport.technicalTest[index].id_user}/${techTestAntiCheatingReport.technicalTest[index].id_job}/${techTestAntiCheatingReport.technicalTest[index].id_test}/${techTestAntiCheatingReport.technicalTest[index].techTestType}`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );

          setImageUrls(response.data);
      } else {
          console.error('No snapshots available for this test or required properties are missing.');
      }
  } catch (error) {
      console.error('Error fetching images from directory:', error);
  }
};



const fetchScreenShots = async (index) => {
  try {
  

      // Ensure that the necessary data is present before accessing properties
      if (
          techTestAntiCheatingReport &&
          Array.isArray(techTestAntiCheatingReport.technicalTest) &&
          techTestAntiCheatingReport.technicalTest.length > index && // Check that index is within bounds
          techTestAntiCheatingReport.technicalTest[index] && // Ensure the element at index exists
          techTestAntiCheatingReport.technicalTest[index].TestTech && // Ensure TestTech exists
          techTestAntiCheatingReport.technicalTest[index].TestTech.takeScreenShots // Check takeScreenShots is true
      ) {

          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/getScreenshots/${techTestAntiCheatingReport.technicalTest[index].id_user}/${techTestAntiCheatingReport.technicalTest[index].id_job}/${techTestAntiCheatingReport.technicalTest[index].id_test}/${techTestAntiCheatingReport.technicalTest[index].techTestType}`,
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );

          setImageUrls(response.data);
      } else {
          console.error('No screenshots available for this test or required properties are missing.');
      }
  } catch (error) {
      console.error('Error fetching images from directory:', error);
  }
};



  const [currentIndex, setCurrentIndex]= useState(0);
  useEffect(() => {

    if (techTestAntiCheatingReport 
        && techTestAntiCheatingReport.technicalTest 
        && Array.isArray(techTestAntiCheatingReport.technicalTest) 
        && techTestAntiCheatingReport.technicalTest.length > 0) {

        // Loop through each test and fetch snapshots or screenshots accordingly
        techTestAntiCheatingReport.technicalTest.forEach((test, idx) => {
            if (test?.TestTech?.takeSnapShots) {
                fetchSnapShots(idx);
            } else if (test?.TestTech?.takeScreenShots) {
                fetchScreenShots(idx);
            } else {
                console.log(`No snapshots or screenshots available for test at index ${idx}`);
            }
        });
    } else {
    }
}, [techTestAntiCheatingReport]);



const updateCurrentIndex = (e) => {
  try {
      let index = currentIndex;

      if (e.target.id === "next") {
          // Chercher le prochain test de type "Coding" à partir de l'index actuel
          const nextCodingIndex = techTestAntiCheatingReport.technicalTest.findIndex(
              (test, i) => i > currentIndex && test.techTestType === "Coding"
          );
          // Si on trouve un test "Coding", on met à jour l'index, sinon on passe au suivant normal
          index = nextCodingIndex !== -1 ? nextCodingIndex : (currentIndex + 1) % techTestAntiCheatingReport.technicalTest.length;
      } else if (e.target.id === "prev") {
          // Chercher le test "Coding" précédent à partir de l'index actuel
          const previousCodingIndex = [...techTestAntiCheatingReport.technicalTest].reverse().findIndex(
              (test, i) => (techTestAntiCheatingReport.technicalTest.length - 1 - i) < currentIndex && test.techTestType === "Coding"
          );
          index = previousCodingIndex !== -1
              ? techTestAntiCheatingReport.technicalTest.length - 1 - previousCodingIndex
              : (currentIndex - 1 + techTestAntiCheatingReport.technicalTest.length) % techTestAntiCheatingReport.technicalTest.length;
      }

      setCurrentIndex(index);
      setTestTech(techTestAntiCheatingReport.technicalTest[index]);
      techTestAntiCheatingReport.technicalTest[index].TestTech.takeSnapShots
          ? fetchSnapShots(index)
          : fetchScreenShots(index);

      setDisplaySnapshots(true);
  } catch (error) {
      console.error(error);
  }
};


  const [sent, setSent]=useState(false);
  const resendTest = async () => {
    try {
      const result = await Swal.fire({
        title: lang.menu.areUSure,
        text: lang.menu.resendAssessmentMsg,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "OK",
        cancelButtonText: lang.menu.cancel,
      });
  
      if (result.isConfirmed) {
        const candidateid = candidate.id_candidate;
        const jobid = candidate.id_jobrole;
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const recruiterid = currentUser.localId;
        const testid = testTech.id_test;
        const testType = testTech.techTestType;
  
        const response = await axios.put(
          `${process.env.REACT_APP_APP_DOMAIN}/api/technical/retakeTest/${recruiterid}/${candidateid}/${jobid}/${testid}/${testType}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
  
        setSent(true);
      } else {
      }
    } catch (error) {
      console.error("Erreur :", error);
    }
  };
  


  const [currentIndexImage, setCurrentIndexImage]= useState(0);

  const [showModal, setShowModal] = useState(false);

  const handleModal = ()=>{
    setShowModal(!showModal);
  }
  
  const updateCurrentIndexImage=(index)=>{
    
    setCurrentIndexImage(index);
  }

  const handleChangeImages= (e)=>{
    try {

    setDisplaySnapshots(e.target.id === "snapshots" ? true : false);
    if(e.target.id === "snapshots"){
      fetchSnapShots(currentIndex);
    }else if(e.target.id === "screenshots"){
      fetchScreenShots(currentIndex);
    }
    } catch (error) {
      console.error(error);
    }

  }

  function formatCalendarDate(date1) {
    const dateObj = new Date(date1);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = dateObj.toLocaleDateString('en-GB', options);
    return formattedDate;
  }
  
  //assignment
  var assignments_scores = candidate.detailed_assignment_score ? JSON.parse(candidate.detailed_assignment_score): {};
  const [hasAssignment, setHasAssignment] = useState(false);
  const [assignments, setAssignments]= useState([]);
  const [showDetailsAssignment, setShowDetailsAssignment]= useState(false);
  useEffect(()=>{
    const getHasAssignment = async()=>{
      try {
        const jobid = candidate.id_jobrole;
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/hasAssignment/${jobid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        setHasAssignment(response.data.hasAssignment);
        if(response.data.hasAssignment){
          setAssignments(response.data.assignment);
          
        }

        
      } catch (error) {
        console.error(error);
      }
    }

    getHasAssignment();
  }, [])

  //update assignment score
  const [updateAssignment, setUpdateAssignment]= useState();
  const [assignmentScore, setAssignmentScore]= useState(0);
  const handleChangeAssignmentScore = async (assignmentId)=>{
    try {
 
      const candidateid= candidate.id_candidate;
      const jobid=candidate.id_jobrole;
      if((assignmentScore > 100) || (assignmentScore < 0 )){
        swal(lang.menu.scoreCorrect, {
          icon: "error",
        });
        return;
      }
      const response = await axios.put(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/score/${candidateid}/${jobid}/${assignmentId}`, {
        assignmentScore : parseFloat(assignmentScore)
      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }})

      if(response.data.candidate){
        assignments_scores = response.data.candidate.detailed_assignment_score;
        candidate.assignment_score = parseFloat(response.data.candidate.assignment_score);
        candidate.detailed_assignment_score = assignments_scores;
        localStorage.setItem('candidateDetails',  JSON.stringify(candidate));
      }

      setUpdateAssignment(null);
      setAssignmentScore(0);

    } catch (error) {
      console.error(error);
    }
  }


  //Interview Management
  const [recruiters, setRecruiters] = useState([]);
  const [showModalResendTest, setShowModalResendTest] = useState(false); 

  const [showModalInterview, setShowModalInterview] = useState(false); 
  const [selectedRecruteurs, setSelectedRecruteurs] = useState([]);
  const [dateTime, setDateTime] = useState('');
  const [meetLink, setMeetLink] = useState('');

  const handleOpenForm = () => setShowModalInterview(true);
  const handleCloseForm = () => setShowModalInterview(false);
  const handleModalOpen = () => setShowModalResendTest(true);
  const handleCloseResend = () => setShowModalResendTest(false);
  useEffect(async()=>{
    try{
      const responsejob = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/${candidate.id_jobrole}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
const jobDetails=responsejob.data[0]

      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
          const allUsers=response.data
          const filterRecruiter=allUsers.filter(user =>user.companyID===jobDetails.companyid && (user.userRoleID===3 ||user.userRoleID===2||user.userRoleID===1))
          setRecruiters(filterRecruiter)

    }
    catch(error){
      console.error(error);
    }
  },[])

  const handleRecruteurChange = (selectedOptions) => {
    // Met à jour `selectedRecruteurs` avec les IDs des recruteurs sélectionnés
    setSelectedRecruteurs(selectedOptions ? selectedOptions.map((option) => option.value) : []);
  };
  
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log('Recruteurs sélectionnés:', selectedRecruteurs);
    console.log('Date et heure de la réunion:', dateTime);
    console.log('Lien du Google Meet:', meetLink);

   const dataInterview={
    candidate_id:candidate.id_candidate,
    recruiter_ids:selectedRecruteurs,
    date_time:dateTime,
    jobrole_id:candidate.id_jobrole,
    status:"pending",
    lien:meetLink,
   }
   console.log('dataInterview:', dataInterview);
   const response = await axios.post(
    `${process.env.REACT_APP_APP_DOMAIN}/api/interview/Createinterviews`,
    dataInterview, 
    {
      headers: {
      Authorization: `Bearer ${token}`,
      }}
  );
    handleCloseForm();
  };
    const [resultDescription, setResultDescription] = useState("");
  
  useEffect(async()=>{
    if (!candidate) return; 
  if(candidate.Personnality && candidate.ResPerso === null){
    let testPer=Number(candidate.notePer[0].idtest)
    axios
    .get(
      `${process.env.REACT_APP_APP_DOMAIN}/api/test-view/${candidate.email}/${testPer}/${candidate.id_jobrole}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }}
    )
    .then(async(response) => {
      const { scores, testName } = response.data;
      const onlyScores = scores.map(scoreObject => {
        switch (scoreObject.score) {
            case 1:
                return -2;
            case 2:
                return -1;
            case 3:
                return 0;
            case 4:
                return 1;
            case 5:
                return 2;
            default:
                return scoreObject.score; 
        }
    });
      const updatedTraitScores = {};
      const job= jobs.filter(job => job.id === candidate.id_jobrole );

      let JobDescription=job[0].JobDescription
     

          if(testPer===1){
           
            const response = await axios.post(
              `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/generate/16p`, 
              {
                features: onlyScores,
                language:lang.language,
                job:JobDescription,
                host:process.env.LLM_ENV
              },
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );

          const resultDescription = (
     

<div >
<h3> {testPer === 1 ? "16 Personalities" : testPer === 2 ? "BIG 5" : "UNKNOWN"}</h3>

<p className="personality-type">
<strong>{lang.menu.persoType}:</strong>
{` ${response.data.overall_summary}`}
</p>
<p className="personality-type">
<strong>{lang.menu.overallscore}:</strong>
{` ${response.data.compatibility_score}`}%
</p>


</div>

          )
          setResultDescription(resultDescription)

          }else if(testPer===2){
            const response = await axios.post(
              `${process.env.REACT_APP_APP_DOMAIN}/flask/personalities/generate/ocean`, 
              {
                features: onlyScores,
                language:lang.language,
                job:JobDescription,
                host:process.env.LLM_ENV
              },
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  }
              }
          );
      /*     const dataAI = {
            labels: [
              lang.menu.Neuroticism, 
              lang.menu.Agreeableness, 
              lang.menu.Extraversion, 
              lang.menu.Conscientiousness, 
              lang.menu.Openness
            ],
            datasets: [{
              label: "BIG 5",
              data: [
                response.data.Neuroticism, 
                response.data.Agreeableness, 
                response.data.Extraversion, 
                response.data.Conscientiousness, 
                response.data.Openness
              ],
              backgroundColor: 'rgba(34, 202, 236, 0.2)',
              borderColor: 'rgba(34, 202, 236, 1)',
              borderWidth: 2,
              pointBackgroundColor: 'rgba(34, 202, 236, 1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(34, 202, 236, 1)'
            }]
          };
        
          const optionsAI = {
            scale: {
              ticks: { beginAtZero: true, min: 0, max: 100 },
              pointLabels: { fontSize: 14 }
            },
            responsive: true,
            maintainAspectRatio: false
          }; */
const resultDescription = (
<div>
<h3> {testPer === 1 ? "16 Personalities" : testPer === 2 ? "BIG 5" : "UNKNOWN"}</h3>

<p className="personality-type">
<strong>{lang.menu.persoType}:</strong>
{` ${response.data.compatibility.overall_summary}`}
</p>
<p className="personality-type">
<strong>{lang.menu.overallscore}:</strong>
{` ${response.data.compatibility.compatibility_score}`}%
</p>


</div>

          )
          setResultDescription(resultDescription)
          }

      
     
})
    .catch((error) => {
      console.error("Error fetching candidate scores:", error);
    });
  }else if(candidate.ResPerso !== null){
    let testPer=Number(candidate.notePer[0].idtest)
    if(testPer===1){
      const resultDescription = (
     

        <div >
        <h3> {testPer === 1 ? "16 Personalities" : testPer === 2 ? "BIG 5" : "UNKNOWN"}</h3>
        
        <p className="personality-type">
        <strong>{lang.menu.persoType}:</strong>
        {` ${candidate.ResPerso.overall_summary}`}
        </p>
        <p className="personality-type">
        <strong>{lang.menu.overallscore}:</strong>
        {` ${candidate.ResPerso.compatibility_score}`}%
        </p>
        
        
        </div>
        
                  )
                  setResultDescription(resultDescription)
    } else if(testPer===2){
      const resultDescription = (
        <div>
        <h3> {testPer === 1 ? "16 Personalities" : testPer === 2 ? "BIG 5" : "UNKNOWN"}</h3>
        
        <p className="personality-type">
        <strong>{lang.menu.persoType}:</strong>
        {` ${candidate.ResPerso.compatibility.overall_summary}`}
        </p>
        <p className="personality-type">
        <strong>{lang.menu.overallscore}:</strong>
        {` ${candidate.ResPerso.compatibility.compatibility_score}`}%
        </p>
        
        
        </div>
        
                  )
                  setResultDescription(resultDescription)
    }
  }
  },[candidate.Personnality, jobs])


  const updateIsFavorite = async (isFavorite) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateIsFavorite/${candidate.id_candidate}/${candidate.id_jobrole}/${isFavorite}`, {},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      candidate.IsFavorite = isFavorite; 
      localStorage.setItem('candidateDetails', JSON.stringify(candidate));
    } catch (error) {
      console.error("Error updating isFavorite :", error);
    }
  };
  const toggleFavorite = async () => {
    const updatedIsFavorite = isFavorite ? 0 : 1;
    setIsFavorite(updatedIsFavorite);
    await updateIsFavorite( updatedIsFavorite);
    candidate.IsFavorite = updatedIsFavorite;
  };
  const [showProfile, setShowProfile] = useState(false);
  const [profile, setProfile] = useState("");

  const handleProfileView = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getCandidateInfo/${candidate.id_jobrole}/${candidate.email}`, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});  
     setShowProfile(true)
     console.log("profile:",response.data)
     setProfile(response.data)
    }
    catch{
     
     }
  }
  const handleCloseProfile = () => setShowProfile(false);
  const [status, setStatus] = useState(candidate.InterviewStatus);
  const updateInterviewStatus = async ( status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateInterviewStatus/${candidate.id_candidate}/${candidate.id_jobrole}/${status}`, {},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      candidate.InterviewStatus = status; 
      localStorage.setItem('candidateDetails', JSON.stringify(candidate));
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const handleInterviewStatusChange = async (event) => {
    const updatedStatus = event.target.value === lang.menu.Invited ? 1 : 0;
    setStatus(updatedStatus); 
    await updateInterviewStatus(updatedStatus);
    candidate.InterviewStatus = updatedStatus; 
  };
  return (
    <div className='CandidateJob'>
         <Card className='testet'>
         <Card.Body>
         <Row className="d-flex align-items-center">
  {/* Colonne avec bouton Retour et email */}
  <Col xl={3} className="d-flex align-items-center">
    <button
      className="back-button"
      onClick={() => handleProfileView()}
      style={{ height: "100%", width: "auto" }}
    >
      <span style={{ marginRight: "5px" }}>
      <CgProfile />

      </span>
    </button>
    
    <div className="ms-2">
      {candidate.FirstName !== "N/A" ? (
        <strong style={{ fontSize: "180%" }}>
          {candidate.FirstName} {candidate.LastName}
        </strong>
      ) : (
        <></>
      )}
      <br />
      <span>
        <MdOutlineAlternateEmail size={17} /> {candidate.email}
      </span>
    </div>
  </Col>
  
  {/* Colonnes avec les trois autres boutons alignés à droite */}
  <Col xl={9} className="d-flex justify-content-end">
    <Button
      style={{ backgroundColor: "transparent", border: "0", color: "black", marginRight: "10px" }}
      onClick={() => handlePDFDownload()}
      title={`Download results PDF`}
    >
      <BsFiletypePdf size={23} />
    </Button>

  {/*   <Button
    onClick={handleOpenForm}
      style={{ backgroundColor: "transparent", border: "0", color: "black", marginRight: "10px" }}
    >
      <SiGooglemeet size={23} />
    </Button> */}
 <button
              onClick={toggleFavorite}
              style={{ border: "none", background: "none" }}
            >
              <MdOutlineFavorite 
                color={isFavorite ? "red" : "gray"} 
                size={32}
              />
            </button>
   
  </Col>
</Row>


            
         
            
          </Card.Body>
        </Card>
        <Card>
            <Card.Body >
            <Row>
  <Col xl={2}>
    <strong style={{ fontSize: '160%' }}>{lang.menu.assessment}</strong> 
    <ReactStars
      count={5}
      value={Math.round(parseFloat(candidate.ratingJobSum))} 
      size={24}
      activeColor="#74188D"
      half={true}
      edit={false}
    />
  </Col>
  <Col xl={2}>
    <h4> {candidate.jobName} </h4>
  </Col>
  <Col xl={6} className="ms-auto d-flex justify-content-end">
    <Button
      className="border-0 me-2"
      style={{ backgroundColor: "#8cd6c8d0", color: "white" }}
      title={`Accept`}
      onClick={sendEmailToCandidate}
    >
      <FaUserCheck  size={23} />
    </Button>
    <Button
      className="border-0"
      style={{ backgroundColor: "#FFB3B3", color: "white" }}
      title={`Reject`}
      onClick={sendEmailToCandidateReject}
    >
      <SlUserUnfollow size={23} />
    </Button>
  </Col>
</Row>


<br/><br/>
<Row>
    <Col xl={3}>
        <h4>Invited</h4>
        <p>{formatCalendarDate(candidate.createdAt)}</p>
        {candidate.notePer.length > 0 ? (
  <>
    <h4>Personnality</h4>
    {typeof candidate.notePer[0]?.notePer === "object" && candidate.notePer[0]?.notePer !== null ? (
      <ul>
        {Object.entries(candidate.notePer[0].notePer).map(([key, value]) => (
          <li key={key}>
            <strong>{key}:</strong> {value}
          </li>
        ))}
      </ul>
    ) : (
      <p>{candidate.notePer[0]?.notePer}</p>  
    )}
  </>
) : (
  <></>
)}

<h4>{lang.menu.hiringStage}  </h4>
<p>
  {getCandAcceptStatus(candidate.candAccept)}
</p>
<h4>{lang.menu.InterviewStatus}  </h4>
<p>
<select
            className="form-select"
            value={candidate.InterviewStatus ? lang.menu.Invited : lang.menu.notInvited}
            onChange={handleInterviewStatusChange}
          >
            <option value={lang.menu.Invited}>{lang.menu.Invited}</option>
            <option value={lang.menu.notInvited}>{lang.menu.notInvited}</option>
          </select>
</p>

{reference && reference.comment!=null ?(
< div>
  <h4>Reference </h4>
<strong>{lang.menu.ref} : </strong><p>{reference.nameRef}</p>
<strong>Note :  </strong><p>{reference.note}</p>

<strong>{lang.menu.comment} : </strong><p>{reference.comment}</p>
</div>)  : (
    <></>
  )}
    </Col>
    <Col xl={5}>
    
                
        <Card style={{backgroundColor:"#8cd6c8d0"}}>
            <Card.Body>
            {candidate.note.length>0 || (candidate.noteTech.length>0 || candidate.noteCoding.length>0) ||  candidate.assignment_score > 0||  candidate.Personnality  ?(
              <>
                            <Row>
        <Col xl={11}>
{lang.menu.scoringMeth}
<h5>{lang.menu.Percentile}</h5>
</Col>
                </Row>
            
 <span style={{ fontSize: '90%' ,fontWeight: 'bold'}}>{lang.menu.Testscores}</span>
 {resultDescription && (
  <>
    <Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: "black" }}
    onClick={() => handleButtonClickPer(candidate)}>
    <span style={{ textAlign: "left" }}><FaAngleDown />{lang.menu.perTest}</span>

  </Button>
  <br />
  {showDetailsPer && (
          <Card style={{height:"45%"}}>
            <Card.Body>
            {resultDescription && (
          <card >
         
            {resultDescription}
          </card>
        )}
            </Card.Body>
          </Card>
        )}
  </>
)}
 {candidate.note ? (
  candidate.note.map((techPsycho) => {
    const codingTest = techPsycho.note;
    const totalTechNote = (codingTest * 100) / 10;
    const roundedTotalTechNote = Math.round(totalTechNote); 

    const testName = getTestPsychoName(techPsycho.idtest);

    return (
      <div key={techPsycho.idtest}>
        <Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: "black" }}
          onClick={() => handleButtonClick(candidate.note)}>
          <span style={{ textAlign: "left" }}><FaAngleDown />{lang.menu.psychoResult}</span>
          <span style={{ textAlign: "right" }}>{roundedTotalTechNote} %</span>
        </Button>
        <br />
        {showDetails2 && (
          <Card>
            <Card.Body>
              {testPassedPsycho.map(test => (
                <Row key={test.testName}>  
      <Col xl={8}><strong>Test :</strong> <Link to={`/RadarPsycho?email=${candidate.email}&jobTitle=${candidate.jobName}`}>{test.testName}</Link></Col>
          <Col><strong>Note :</strong> {Math.round(test.note * 10)} % </Col> 
                </Row>
              ))}
            </Card.Body>
          </Card>
        )}
      </div>
    );
  })
) : (
  <></>
)}
<br/>
{(candidate.noteTech.length > 0 || candidate.noteCoding.length > 0) && (
  <div style={{ marginBottom: '20px', maxHeight: '100%' }}>
   {candidate.noteTech.length > 0 && candidate.noteCoding.length > 0 ? (
  <>
    {(() => {
      const totalTechNote = candidate.noteTech.reduce((acc, techTest) => {
        return acc + (parseFloat(techTest.noteTech) || 0);
      }, 0);

      const totalCodingNote = candidate.noteCoding.reduce((acc, codingTest) => {
        return acc + (parseFloat(codingTest.noteCoding) || 0);
      }, 0);

      const averageGlobalNote = ((totalTechNote/candidate.noteTech.length + totalCodingNote/candidate.noteCoding.length) / 2) * 100 / 10;
      const roundedGlobalNote = Math.round(averageGlobalNote);

      return (
        <div>
          <Button
            style={{
              backgroundColor: "#ffffff",
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: "black"
            }}
            onClick={() => handleButtonClick1(candidate.noteTech, candidate.noteCoding)}
          >
            <span style={{ textAlign: "left" }}>
              <FaAngleDown /> {lang.menu.techResult}
            </span>
            <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
          </Button>
          <br />
          {showDetails && (
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalMCQ} :</strong></Col>
                  <Col><strong>{Math.round((totalTechNote/candidate.noteTech.length)*100/10)} %</strong></Col>
                </Row>
                {Array.isArray(testPassedTech) &&testPassedTech.map(test => (
                  <Card style={{backgroundColor:"#8cd6c8d0"}}>
            <Card.Body> <Row key={test.testName}>
                 <Col xl={8}><strong>Test :</strong> <Link to={`/RadarTech?email=${candidate.email}&jobTitle=${candidate.jobName}`}> {test.testName}</Link></Col>
                 <Col><strong>Note :</strong>{Math.round((test.noteTech * 100 / 10))} %</Col>
               </Row>
               {techTestAntiCheatingReport && techTestAntiCheatingReport.technicalTest.map(testANT => (
                    <>
              
                      {parseInt(test.idtest, 10) === testANT.id_test_content && testANT.techTestType === "QCM" && (
                        <>
                        <Col> {testANT.isCameraOpen ? <FcCheckmark /> : <FcCancel/>}{lang.menu.webcamEnable}</Col>

                        <Col>{testANT.mouseAlwaysInWindow ? <FcCheckmark /> : <FcCancel />} {lang.menu.mouseEnable}</Col>
{!testANT.mouseAlwaysInWindow && (
  <Col>{lang.menu.mouseoccurenceTime}<strong> {testANT.mouse_occurence}</strong> {lang.menu.fois}</Col>
)}

                        
                       
                        </>
                      )}
                    </>
                  ))}
                    </Card.Body>
                </Card>
              
                ))}
                <br/>
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalCoding}:</strong></Col>
                  <Col><strong>{Math.round((totalCodingNote/candidate.noteCoding.length)*100/10)} %</strong></Col>
                </Row>
                {testPassedCoding.map(test => (
                  <Card style={{backgroundColor:"#8cd6c8d0"}}>
            <Card.Body> <Row key={test.testName}>
                    <Col xl={8}><strong>Test :</strong> <Link to={`/ListTestTechCoding?email=${candidate.email}&jobTitle=${candidate.jobName}`}> {test.testName}</Link></Col>
                    <Col><strong>Note :</strong>{Math.round((test.noteCoding * 100 / 10))} %</Col>
                  </Row>
                  {techTestAntiCheatingReport && techTestAntiCheatingReport.technicalTest.map(testANT => (
                    <>
              
                      {parseInt(test.idtest, 10) === testANT.id_test_content && testANT.techTestType === "Coding" && (
                        <>
                        <Col> {testANT.isCameraOpen ? <FcCheckmark /> : <FcCancel/>}{lang.menu.webcamEnable}</Col>

                        <Col>{testANT.mouseAlwaysInWindow ? <FcCheckmark /> : <FcCancel />} {lang.menu.mouseEnable}</Col>
{!testANT.mouseAlwaysInWindow && (
  <Col>{lang.menu.mouseoccurenceTime}<strong> {testANT.mouse_occurence}</strong> {lang.menu.fois}</Col>
)}

                        
                       
                        </>
                      )}
                    </>
                  ))}
                </Card.Body>
                </Card>
                ))}
              </Card.Body>
            </Card>
          )}
        </div>
      );
    })()}
  </>
): candidate.noteTech.length > 0 ? (
  <>
  {(() => {
    const totalTechNote = candidate.noteTech.reduce((acc, techTest) => {
      return acc + (parseFloat(techTest.noteTech) || 0);
    }, 0);

   

    const averageGlobalNote = ((totalTechNote/candidate.noteTech.length) * 100) / 10;
    const roundedGlobalNote = Math.round(averageGlobalNote);

    return (
      <div>
        <Button
          style={{
            backgroundColor: "#ffffff",
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: "black"
          }}
          onClick={() => handleButtonClick2(candidate.noteTech)}
        >
          <span style={{ textAlign: "left" }}>
            <FaAngleDown /> {lang.menu.techResult}
          </span>
          <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
        </Button>
        <br />
        {showDetails && (
          <Card>
            <Card.Body>
              <Row>
                <Col xl={8}><strong>{lang.menu.globalMCQ} :</strong></Col>
                <Col><strong>{Math.round((totalTechNote/candidate.noteTech.length)*100/10)} %</strong></Col>
              </Row>
              {testPassedTech.map(test => (
                 < Card style={{backgroundColor:"#8cd6c8d0"}}>
                  <Card.Body>
                <Row key={test.testName}>

                              
                  <Col xl={8}><strong>Test :</strong> <Link to={`/RadarTech?email=${candidate.email}&jobTitle=${candidate.jobName}`}> {test.testName}</Link></Col>
                  <Col><strong>Note :</strong>{Math.round((test.noteTech * 100 / 10))} %</Col>
                 
                </Row>
                  {techTestAntiCheatingReport && techTestAntiCheatingReport.technicalTest.map(testANT => (
                    <>
              
                      {parseInt(test.idtest, 10) === testANT.id_test_content && testANT.techTestType === "QCM" && (
                        <>
                        <Col> {testANT.isCameraOpen ? <FcCheckmark /> : <FcCancel/>}{lang.menu.webcamEnable}</Col>

                        <Col>{testANT.mouseAlwaysInWindow ? <FcCheckmark /> : <FcCancel />} {lang.menu.mouseEnable}</Col>
{!testANT.mouseAlwaysInWindow && (
  <Col>{lang.menu.mouseoccurenceTime}<strong> {testANT.mouse_occurence}</strong> {lang.menu.fois}</Col>
)}

                        
                       
                        </>
                      )}
                    </>
                  ))}
                  </Card.Body>
                  </Card>
              ))}
              <br/>
             
            </Card.Body>
          </Card>
        )}
      </div>
    );
  })()}</>
    ) : (
      // Affichage des résultats de codage seulement
     <>
     
     {(() => {
     

      const totalCodingNote = candidate.noteCoding.reduce((acc, codingTest) => {
        return acc + (parseFloat(codingTest.noteCoding) || 0);
      }, 0);

      const averageGlobalNote = (totalCodingNote/candidate.noteCoding.length) * 100 / 10;
      const roundedGlobalNote = Math.round(averageGlobalNote);

      return (
        <div>
          <Button
            style={{
              backgroundColor: "#ffffff",
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: "black"
            }}
            onClick={() => handleButtonClick3(candidate.noteCoding)}
          >
            <span style={{ textAlign: "left" }}>
              <FaAngleDown /> {lang.menu.techResult}
            </span>
            <span style={{ textAlign: "right" }}>{roundedGlobalNote} %</span>
          </Button>
          <br />
          {showDetails && (
            <Card>
              <Card.Body>
                
                <Row>
                  <Col xl={8}><strong>{lang.menu.globalCoding}:</strong></Col>
                  <Col><strong>{Math.round((totalCodingNote/candidate.noteCoding.length)*100/10)} %</strong></Col>
                </Row>
                {testPassedCoding.map(test => (
                  < Card style={{backgroundColor:"#8cd6c8d0"}}>
                    <Card.Body>
                  <Row key={test.testName}>

                    <Col xl={8}><strong>Test :</strong> <Link to={`/ListTestTechCoding?email=${candidate.email}&jobTitle=${candidate.jobName}`}> {test.testName}</Link></Col>
                    <Col><strong>Note :</strong>{Math.round((test.noteCoding * 100 / 10))} %</Col>
                  </Row>
                  {techTestAntiCheatingReport && techTestAntiCheatingReport.technicalTest.map(testANT => (
                    <>
              
                      {parseInt(test.idtest, 10) === testANT.id_test_content && testANT.techTestType === "Coding" && (
                        <>
                        <Col> {testANT.isCameraOpen ? <FcCheckmark /> : <FcCancel/>}{lang.menu.webcamEnable}</Col>

                        <Col>{testANT.mouseAlwaysInWindow ? <FcCheckmark /> : <FcCancel />} {lang.menu.mouseEnable}</Col>
{!testANT.mouseAlwaysInWindow && (
  <Col>{lang.menu.mouseoccurenceTime}<strong> {testANT.mouse_occurence}</strong> {lang.menu.fois}</Col>
)}

                        
                       
                        </>
                      )}
                    </>
                  ))}
                  </Card.Body>

</Card>
                ))}
              </Card.Body>
            </Card>
          )}
        </div>
      );
    })()}
     
     </>
    )}
  </div>
)}




  {hasAssignment && (
    <div style={{ marginBottom: '20px', maxHeight: '100%' }}>
    <Button style={{ backgroundColor: "#ffffff", width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,color:"black"}}
    onClick={(e)=>{
      setShowDetailsAssignment(!showDetailsAssignment);
    }}>
<span style={{ textAlign: "left" }}><FaAngleDown /> {lang.menu.AssignmentResult}</span>
<span style={{ textAlign: "right" }} >{candidate.assignment_score ? (Math.round(candidate.assignment_score) + "%") : ("---")} </span>

</Button>
<br/>
{showDetailsAssignment && 
        assignments.length > 0 &&  assignments.map((assignment, index) =>( 
                 <Card> 
                   <Card.Body> 
                    
                   
                   <div key={index}>
                   <Row >
                      <Col xl={8}><strong>Test :</strong> {assignment.name}</Col>
                      {!(updateAssignment === index) ? <Col><strong>Note :</strong>{(assignments_scores && assignments_scores[assignment.id]) ? (Math.round(assignments_scores[assignment.id]) + "%") : ("---")}   </Col>
                      : <Col><strong>Note :</strong>
                      <input
                        className="input-search form-control"
                       value={assignmentScore} onChange={(e)=>{
                        setAssignmentScore(e.target.value)
                      }}/>
                      </Col>}
                   </Row>
                   <br/>
                   <Row style={{textAlign: "center"}}>
                   <div className="test-card-icons">
                  
                
                  { !(updateAssignment === index) ? 
                  <button 
                    style={{ border: "none" }}
                    onClick={()=>{
                      setAssignmentScore((assignments_scores && assignments_scores[assignment.id]) ? assignments_scores[assignment.id] : 0);
                    setUpdateAssignment(index);
                  }}>
                    <i className="fas fa-pencil-alt fa-lg" ></i>
                  </button>
                  
                :  <button 
                   style={{ border: "none" }}
                    onClick={()=>handleChangeAssignmentScore(assignment.id)}>
                  <i className="fas fa-check fa-lg" ></i>
                  </button>
                }
                
                <Link to={`/assignment-result?email=${candidate.email}&jobTitle=${candidate.jobName}`} style={{ border: "none" }}>
                <i className="fa fa-file-code fa-lg" ></i>
                  </Link>
               </div>
               

                   </Row>
                   
                   </div>
                                   
                </Card.Body> 
            </Card>
          )
         )}
</div>
    
  )}



  </> ) : (
    <div className='text-center' ><p>{lang.menu.notyet}</p>
    <p>{lang.menu.noTestRes}</p>
    </div>
  )}
            </Card.Body>
        </Card>
    </Col>
    <Col xl={4}>
    <div style={{position:"relative"}} >

    
      {((techTestAntiCheatingReport.length != 0) && testTech) ? 
      (
        <div >
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
{(techTestAntiCheatingReport.technicalTest.length > 1 && 
  techTestAntiCheatingReport.technicalTest.some(test => test.techTestType === "Coding")) && (
  <Button 
    id='prev' 
    variant='success' 
    style={{ position: "absolute", top: "0", left: "0" }} 
    onClick={updateCurrentIndex}
  >
    &lt;
  </Button>
)}


              <h4 style={{textAlign:"center"}}>{lang.menu.anticheetingMon}</h4>

              {(techTestAntiCheatingReport.technicalTest.length > 1 && 
  techTestAntiCheatingReport.technicalTest.some(test => test.techTestType === "Coding")) && <Button id='next' 
                  variant='success' 
                  style={{ position:"absolute", top:"0", right:"0"}} 
                  onClick={updateCurrentIndex}>
                     &gt; 
                </Button> }
      </div>
      <div key={currentIndex} style={{paddingTop: "10%"}}>
        <Row>
      <Col xl={5}>  <span>{lang.menu.TestName}</span></Col>
      <Col>
    <strong>{testTech.TestTech.name}</strong>
      </Col>
    </Row>
    <Row>
      <Col xl={5}>  <span>{lang.menu.testType}</span></Col>
      <Col>
    <strong>{(testTech.techTestType === "QCM") ? lang.menu.QCM : testTech.techTestType }</strong>
      </Col>
    </Row>
      <Row>
      <Col xl={5}>  <span>{lang.menu.deviceused}</span></Col>
      <Col>
    <strong>{testTech.deviceType}</strong>
      </Col>
    </Row>
   
    <Row>
      <Col xl={5}>  <span>{lang.menu.location}</span></Col>
      <Col>
      { (testTech.city && testTech.country) ? (
      <strong>{testTech.city + "," + testTech.country}
</strong> ):(
  <div className='text-center' ><p>{lang.menu.accessdisabled}</p>    </div>
     ) }
      </Col>
      
    </Row>
     
    <Row>
      <Col xl={10}>  <span>{lang.menu.ipAdress}</span></Col>
      <Col>
      {!testTech.Attempt_to_access_from_another_IP_address ? <FcCheckmark /> : <FcCancel/>}
</Col>
</Row>
    <Row>
      <Col xl={10}>  <span>{lang.menu.webcamEnable}</span></Col>
      <Col>
      {testTech.isCameraOpen ? <FcCheckmark /> : <FcCancel/>} 
</Col>
    </Row>
    <Row>
      <Col xl={10}>  <span>{lang.menu.mouseEnable}</span></Col>
      <Col>  {testTech.mouseAlwaysInWindow ? <FcCancel/>:<FcCheckmark />  }
      </Col>
    </Row>
    {/*<Row>
      <Col  xl={10}>  <span>{lang.menu.fullScreenEnable}</span></Col>
      <Col>  <FcCheckmark /></Col>
    </Row>*/}
    
{/* <img className='p-1 ' src="https://images.tou.tv/v1/synps-src/show/perso/src_lacandidate_ott_program_v01.jpg?impolicy=ott&im=Resize=1200&quality=75" width="100%" height="45%"/>
*/}   
{((imageUrls.length > 0) && (testTech.TestTech.takeScreenShots) && (testTech.TestTech.takeSnapShots)) && 
<Form >
        <div key={`inline-type`} className="mb-3" style={{paddingTop:"5%"}}>
          <Form.Check
            inline
            label="SnapShots"
            name="ImageType"
            type='radio'
            id="snapshots"
            checked={displaySnapshots}
            onChange={handleChangeImages}
          />
          <Form.Check
            inline
            label="ScreenShots"
            name="ImageType"
            type='radio'
            id="screenshots"
            checked={!displaySnapshots}
            onChange={handleChangeImages}
          />
          
        </div>
    </Form>}

    <div  >
    <Carousel  activeIndex={Number(currentIndexImage)} onSelect={updateCurrentIndexImage} variant="dark">
      {imageUrls.map((imageUrl, indexImage) => (
        
          <Carousel.Item key={indexImage}>
            
                  <img onClick={handleModal} className='p-1 '  src={`${process.env.REACT_APP_APP_DOMAIN}/api${imageUrl}`} alt={`Image ${indexImage}`} width="100%" height="45%"/>

          </Carousel.Item>
    ))}
      </Carousel>
      </div>
<br/>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button 
         onClick={() => handleModalOpen()} 
        disabled={testTech.retakeTheTest || sent}
        >
          {lang.menu.resendTest}
        </Button>
        </div>
   </div>
    </div>) : (
      <div className='text-center' ><p>          {lang.menu.notyet}
      </p>
      <p>          {lang.menu.resendTest}
</p>
      </div>
    )
    
    }
    </div>
    </Col>
</Row>

            </Card.Body>
            <Card.Footer ref={footerRef}>
  <Row>
    <Col className="ml-6">
      <h4>{lang.menu.urrating}</h4>
      <p>{lang.menu.ratingRec}</p>
      <ReactStars
        count={5}
        onChange={ratingcandidateChanged}
        size={24}
        activeColor="#74188D"
      />
    </Col>
    <Col>
   <textarea id="myTextarea" className="form-control" placeholder="Add your private notes here (auto-saved) ...." onChange={handleContentChange}></textarea>
     </Col>
     
  </Row>
  <Button
      type="submit"
      className="btn btn-primary btn sweet-confirm"
      onClick={handleSubmitRating}
      disabled={!isContentFilled}
    >
      {lang.menu.submit}
    </Button>
    
</Card.Footer>

        </Card>
        {ratingsList && ratingsList.recruteurRateArray != null ? (
  <Card>
    <Card.Body className="ui mini comments">
  <h2>{lang.menu.commentRecruter}:</h2>

  <Comment.Group>
    {ratingsList &&
      ratingsList.contentArray &&
      ratingsList.contentArray.map((comment, index) => (
        <Comment key={index}>
          <Row  style={{
                  backgroundColor: '#8cd6c8', 
                  borderRadius: '10px',
                  padding: '10px' 
                }}>
     <Col xl={1}>
  <img
    src="https://react.semantic-ui.com/images/avatar/small/steve.jpg"
    style={{ width: '100%', height: '100%', borderRadius: '50%' }}
    alt="Avatar"
  />
</Col>


            <Col>
              <CommentContent>
                <div  style={{
                  backgroundColor: '#ffffff',
                  borderRadius: '15px',
                  padding: '10px'
                }}>
                <CommentAuthor as="a">
                  <strong>
                    {users.find(
                      (user) => user.uid === ratingsList.recruteurRateArray[index]
                    )?.FirstName}{' '}
                    {
                      users.find(
                        (user) => user.uid === ratingsList.recruteurRateArray[index]
                      )?.LastName || users.find(
                        (user) => user.uid === ratingsList.recruteurRateArray[index]
                      )?.email
                    }
                  </strong>
                </CommentAuthor>
                <CommentMetadata>
                  <span style={{ color: '#65676B', fontSize: '12px', marginLeft: '10px' }}>
                    {lang.menu.todayTime} 5:42PM
                  </span>
                </CommentMetadata>
                <CommentText style={{ marginTop: '5px', fontSize: '14px' }}>
                  {comment}
                </CommentText>
                </div>
                <CommentActions>
                  <span style={{ color: '#65676B', marginRight: '15px', cursor: 'pointer' }}>
                  <SlLike /> {"  "}
                  {lang.menu.like}
                  </span>
                  <span style={{ color: '#65676B', cursor: 'pointer' }}>
                  <RiReplyLine /> {"  "}

                    {lang.menu.Repondre}
                  </span>
                </CommentActions>
              </CommentContent>
            </Col>
          </Row>
          <br/>
        </Comment>
      ))}
  </Comment.Group>
</Card.Body>

  </Card>
) : null}

        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


        <Modal show={showModal} onHide={handleModal} centered>
        
        <Modal.Body>
        <Carousel  activeIndex={Number(currentIndexImage)} onSelect={updateCurrentIndexImage} >
      {imageUrls.map((imageUrl, indexImage) => (
        
          <Carousel.Item key={indexImage}>
            
                  <img  className='p-1 '  src={`${process.env.REACT_APP_APP_DOMAIN}/api${imageUrl}`} alt={`Image ${indexImage}`} width="100%" height="100%"/>

          </Carousel.Item>
    ))}
      </Carousel>
        </Modal.Body>
        
      </Modal>

      <Modal show={showModalInterview} onHide={handleCloseForm}>
  <Modal.Header closeButton>
    <Modal.Title>Planifier une réunion Google Meet</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleSubmit}>
      {/* Sélectionner les recruteurs */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Sélectionner les recruteurs
        </Form.Label>
        

        <Select
  isMulti
  placeholder="Select recruiter"
  value={recruiters
    .filter((recruiter) => selectedRecruteurs.includes(recruiter.uid))
    .map((recruiter) => ({
      value: recruiter.uid,
      label: recruiter.email,
    }))}
  onChange={handleRecruteurChange}
  options={recruiters.map((recruiter) => ({
    value: recruiter.uid,
    label: recruiter.email,
  }))}
  styles={{
    control: (provided) => ({
      ...provided,
      minHeight: "40px",
      lineHeight: "40px",
      borderRadius: "0",
    }),
  }}
/>

    
      </Form.Group>

      {/* Date et Heure de la réunion */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Date et Heure de la réunion
        </Form.Label>
        <Form.Control
          type="datetime-local"
          value={dateTime}
          onChange={(e) => setDateTime(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ced4da',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: '15px',
            color: '#495057',
          }}
        />
      </Form.Group>

      {/* Lien du Google Meet */}
      <Form.Group className="mb-4">
        <Form.Label style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Lien du Google Meet
        </Form.Label>
        <Form.Control
          type="url"
          placeholder="Entrez le lien Google Meet"
          value={meetLink}
          onChange={(e) => setMeetLink(e.target.value)}
          style={{
            padding: '10px',
            borderRadius: '8px',
            border: '1px solid #ced4da',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            fontSize: '15px',
            color: '#495057',
          }}
        />
      </Form.Group>

      {/* Bouton d'enregistrement */}
      <div className="d-flex justify-content-center">
        <Button
          variant="primary"
          type="submit"
          style={{
            backgroundColor: '#6f42c1',
            borderColor: '#6f42c1',
            padding: '10px 20px',
            borderRadius: '25px',
            fontSize: '16px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          }}
        >
          Enregistrer la réunion
        </Button>
      </div>
    </Form>
  </Modal.Body>
</Modal>
<Modal show={showModalResendTest} onHide={handleCloseResend} dialogClassName="modal-100w">
  <Modal.Header >
    <Modal.Title> {lang.menu.resendTest}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  {techTestAntiCheatingReport?.technicalTest?.length > 0 ? (

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{lang.menu.TestName}</th>
            <th>{lang.menu.testType}</th>
            <th>{lang.menu.mouseEnable}</th>
            <th>{lang.menu.mouseoccurenceTime}</th>
            <th>{lang.menu.webcamEnable}</th>
            <th>Attempt From Another IP</th>
            <th>{lang.menu.resendTestone}</th>
          </tr>
        </thead>
        <tbody>
          {techTestAntiCheatingReport.technicalTest.map((test, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td> {test.techTestType === "QCM"
        ? getTesQCMNameById(test.id_test_content) || "N/A"
        : test.techTestType === "Coding"
        ? getTesCodingNameById(test.id_test_content) || "N/A"
        : "Unknown Test Type"}</td>
              <td>{test.techTestType || "N/A"}</td>
            
              <td>{test.mouseAlwaysInWindow ? "Yes" : "No"}</td>
              <td>{test.mouse_occurence}</td>
              <td>{test.isCameraOpen ? "Yes" : "No"}</td>
              <td>{test.Attempt_to_access_from_another_IP_address ? "Yes" : "No"}</td>
              <td>
                     <Button
                                variant="success light"
                                onClick={()=>handleResendTestOne(test)}
                              >
                                <VscRunAll size={16} />
                              </Button>
               </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>No data available for the anti-cheating report.</p>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseResend}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
<Modal show={showProfile} onHide={handleCloseProfile} dialogClassName="modal-lg">
  <Modal.Header closeButton>
    <Modal.Title>{lang.menu.Profile}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {profile && Object.keys(profile).length > 0 && showProfile && (
      <div className="profile-card">
        
        {/* Profile Header Section */}
        <div className="profile-header">
          <h2 className="profile-name">  {profile.name ? profile.name : `${profile.FirstName || ''} ${profile.LastName || ''}`.trim()}
          </h2>
          <p className="profile-speciality">{profile.speciality}</p>
          <div className="contact-info">
            <p><i className="fas fa-envelope"></i> <strong>{lang.menu.email}:</strong> {profile.email}</p>
            <p><i className="fas fa-phone"></i> <strong>{lang.menu.phone}:</strong> {profile.phone ?profile.phone:profile.PhoneNumber }</p>
            {profile.linkedIn && ( <p>
              <i className="fab fa-linkedin"></i>
              <a href={profile.linkedIn} target="_blank" rel="noopener noreferrer"> LinkedIn Profile</a>
            </p>)}
            {profile.github && (
              <p>
                <i className="fab fa-github"></i>
                <a href={profile.github} target="_blank" rel="noopener noreferrer"> {lang.menu.GitHub}</a>
              </p>
            )}
          </div>
        </div>

        {/* Skills Section */}
        {profile.skills && (
          <div className="profile-section">
            <h3>{lang.menu.skills}</h3>
            <ul>
              {(() => {
                try {
                  const skills = JSON.parse(profile.skills);
                  return Object.entries(skills).map(([skill, details]) => (
                    <li key={skill}>
                      <strong>{skill}</strong> - Occurrences: {details.occurence}
                    </li>
                  ));
                } catch {
                  return <li>Invalid skill data</li>;
                }
              })()}
            </ul>
          </div>
        )}

        {/* Experience Section */}
        {profile.experience && (
          <div className="profile-section">
            <h3>{lang.menu.Experience}</h3>
            <ul>
              {(() => {
                try {
                  const correctedExperience = profile.experience
                    .replace(/'/g, '"')
                    .replace(/(\d+):/g, '"$1":');
                  const experience = JSON.parse(correctedExperience);

                  return Object.keys(experience).map((key) => {
                    const exp = experience[key];
                    return (
                      <li key={key}>
                        <strong>{exp.poste}</strong> ({exp.annee_debut} - {exp.annee_fin})
                      </li>
                    );
                  });
                } catch (error) {
                  return <li>Invalid experience data</li>;
                }
              })()}
            </ul>
          </div>
        )}

        {/* Education Section */}
        {profile.education && (
          <div className="profile-section">
            <h3>{lang.menu.Education}</h3>
            <p>{(() => {
              try {
                return JSON.parse(profile.education.replace(/'/g, '"')).join(', ');
              } catch {
                return 'Invalid education data';
              }
            })()}</p>
          </div>
        )}

        {/* Language Section */}
        {profile.language && (
          <div className="profile-section">
            <h3>{lang.menu.Language}</h3>
            <p>{(() => {
              try {
                return JSON.parse(profile.language.replace(/'/g, '"')).join(', ');
              } catch {
                return 'Invalid language data';
              }
            })()}</p>
          </div>
        )}

        {/* Certifications Section */}
        {profile.certif && (
          <div className="profile-section">
            <h3>{lang.menu.certificats}</h3>
            <p>{profile.certif || 'None'}</p>
          </div>
        )}

      </div>
    )}
  </Modal.Body>
</Modal>




    </div>
  );
};

export default CandidateDetails;