import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import Swal from "sweetalert2"; 
import translations from"../jsx/components/AppsMenu/Candidate/mulitlingue";
let lang
const storedLang = localStorage.getItem('selectedLang');
if (storedLang) {
  const parsedLang = JSON.parse(storedLang);


  if (parsedLang.language === 'Français') {
    lang= translations.fr;

  }
  else{
    lang= translations.en;

  }
}else{
    lang= translations.en;

  }

//added by me
export function resetPassword(email) {
  //axios call
  const postData = {
    email,
    requestType: 'PASSWORD_RESET',
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
    postData,
  );
}

export async function signUp(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
    emailVerified: false,
  };

  try {
    // Vérification de l'email
    if (email !== "admin@admin.admin") {
      const verifyEmailUrl = `${process.env.REACT_APP_APP_DOMAIN}/api/SendEmail/verify-email/${email}`;
      
      try {
        const isEmailValid = await axios.post(verifyEmailUrl);
        console.log("isEmailValid :", isEmailValid);

        if (isEmailValid.data.valid === false) {
          Swal.fire({
            icon: 'error',
            title: 'Invalid Email',
            text: 'The provided email address is not valid.'
          });
          return;
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        // Handle the error if necessary, for example by showing a notification to the user
        // If you want to stop execution in case of an error, uncomment the following lines:
        // Swal.fire({
        //   icon: 'error',
        //   title: 'Error Verifying Email',
        //   text: 'There was an error verifying the email address. Please try again later.'
        // });
        // return;
      }
    }

    // Inscription
    const response = await axios.post(
      `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      postData
    );

    // Envoi de l'email de vérification
    await sendEmailVerification(response);
    return response;
  } catch (error) {
    console.error('Sign up failed:', error);
    Swal.fire({
      icon: 'error',
      title: 'Sign Up Failed',
      text: 'An error occurred during the sign-up process.'
    });
    throw error;
  }
}

   function sendEmailVerification(response) {
    const userEmail = response.data.email;
    const userIdToken = response.data.idToken;
    const postData = {
      email: userEmail,
      idToken : userIdToken,
      requestType: 'VERIFY_EMAIL',
    }
     axios.post(
      `https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      postData,
      )
      .then(() => {
        console.log('Email verification sent');
        return response;
      })
      .catch(error => {
        console.error('Failed to send email verification:', error);
        throw error;
      });
      return response;
  } 

export function login(email, password) {
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
      return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        postData,
    )
}
export function loginWithCustomToken(tk) {
  const { token } = tk;
  const postData = {
    token,
    returnSecureToken: true,
};       
          // Sign in with the custom token
          axios.post(
            `https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
            postData,
        )

}

export function formatError(errorResponse) {
    switch (errorResponse.error.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", lang.menu.emailExists, "error");
            break;
        case 'INVALID_LOGIN_CREDENTIALS':
            //return 'Email not found';
           swal("Oops", lang.menu.invalidEmailOrPass, "error",{ button: lang.menu.tryAgain,});
           break;
        case 'INVALID_PASSWORD':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password", "error",{ button: lang.menu.tryAgain,});
            break;
        case 'USER_DISABLED':
            return lang.menu.userDisabled;

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
  tokenDetails.expiresIn=3600*8
  console.log("date:",new Date())

    tokenDetails.expireDate = new Date(
        new Date().getTime() + tokenDetails.expiresIn * 1000,
    );
    console.log("tokenDetails.expireDate:",tokenDetails.expireDate)

    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}
export function saveJWTTokenInLocalStorage(token) {
  localStorage.setItem('token', token);
}
export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    const EXCLUDED_PATHS = [
      '/page-register'
  ];
  const currentPath = history.location.pathname;
    let tokenDetails = '';
    if (!tokenDetailsString) {
      if (!EXCLUDED_PATHS.includes(currentPath)) {
        dispatch(logout(history));
    }
    return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
     let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    console.log("expireDate:",expireDate)
   
    if (todaysDate > expireDate) {
        dispatch(logout(history));
        return;
    } 
    dispatch(loginConfirmedAction(tokenDetails));

    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, history); 
}
