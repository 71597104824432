import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card,Dropdown, Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import "../Jobs/PersonalityTest.css";

const ListCategories = () => {
  const [categoryPer, setCategoryPer] = useState([]);
  const token=localStorage.getItem("token");
  const [connectedUser, setConnectedUser] = useState("");
  const [companieId, setCompanieId] = useState("");
   const [currentPage, setCurrentPage] = useState(1);
   const itemsPerPage = process.env.REACT_APP_PAGINATION ;
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setConnectedUser(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
         
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCategoryPer(
            data.some((permission) => permission.title === "list-categories")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!categoryPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [categoryPer]);
  const [categories, setCategories] = useState([]);
  const [createCat, setCreateCat] = useState([]);
  const [modalType, setModalType] = useState("create");
  const [nameFolder, setNameFolder] = useState("");

  const [selectedSkill, setSelectedSkill] = useState(null);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateCat(
            data.some((permission) => permission.title === "create-category")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/getAllCategories`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setCategories(response.data);
      const compId = await getUserCompanyID();
      setCompanieId(compId)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    

    fetchData();
  }, []);
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  useEffect(() => {
    const fetchQuestionCount = async () => {
      try {
        const updatedCategories = await Promise.all(
          categories.map(async (category) => {
            const response = await axios.get(
              `${process.env.REACT_APP_APP_DOMAIN}/api/question1/count/${category.id}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                  Pragma: "no-cache",
                },
              }
            );
            const questionCount = response.data.count;
            return { ...category, questionCount };
          })
        );
        setCategories(updatedCategories);
      } catch (error) {
        console.log(error);
      }
    };

    fetchQuestionCount();
  }, []);

  const handleModalOpen = () => {
    setShowModal(true);
    setModalType("create")
    setSelectedSkill(null);
    setName(null);
    setDescription(null)

  };
  const handleModalOpenUpdate = (skillSelect) => {
    setSelectedSkill(skillSelect);
    setName(skillSelect.name);
    setNameFolder(skillSelect.name)
    setDescription(skillSelect.description);
    setShowModal(true);
    setModalType("update");
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const createCategory = async (newCategory) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const response = await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/createCategorie`,
        newCategory, 
        
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const createdCategory = response.data;
      setCategories([...categories, createdCategory]);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkCategoryNameExists = (name) => {
    console.log("name :",name.length)

    const cleanedName = name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
console.log("cleanedName :",cleanedName.length)
    return categories.some((category) =>
       category.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === cleanedName
  );
  };
  
   const handleFormSubmit = async(e) => {
    e.preventDefault();
  
    if (modalType === "update" && selectedSkill && selectedSkill.name.toLowerCase() === name.toLowerCase()) {
      updateCategorie({
        name,
        description,
      });
    } else if (checkCategoryNameExists(name)) {
      swal(lang.menu.duplicateNameError, {
        icon: "error",
      });
      return;
    } else {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const userId=currentUser.localId
      const compId =await getUserCompanyID()
      const newCategory = {
        name,
        description,
        userId,
        compId

      };
  
      if (modalType === "update") {
        updateCategorie(newCategory);
      } else {
        createCategory(newCategory);
      }
    }
  
    setName("");
    setDescription("");
  };
  
  const [searchTerm, setSearchTerm] = useState('');
  const filterByTitle = () => {
    return categories.filter(cat => cat.name.toLowerCase().includes(searchTerm.toLowerCase()));
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
    // Gestion de la pagination
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCat = filterByTitle().slice(
      indexOfFirstItem,
      indexOfLastItem
    );
  
    const totalPages = Math.ceil(filterByTitle().length / itemsPerPage);
  
  const handleDelete = (id) => {
    // Show a confirmation dialog using SweetAlert
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteTextCategory,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User confirmed, perform the delete action
        axios
          .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/categories/deleteCategorieById/` + id, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }})
          .then((res) => {
            if (res.data.message === "Categorie was deleted successfully!") {
              console.log(res);
              swal(lang.menu.deleteSuccessCategory, {
                icon: "success",
              });
              setCategories((prevCat) =>
                prevCat.filter((cat) => cat.id !== id)
          );
              // Add any additional logic here after successful delete
            } else {
             swal(lang.menu.deleteTitleWrong,lang.menu.deleteWrong, "error");            }
          })
          .catch((err) => {
            swal(lang.menu.deleteTitleWrong, lang.menu.deleteWrong, "error");
            console.log(err);
          });
      } else {
        // User canceled the delete action
        swal(lang.menu.categoryDeleteCancel, {
          icon: "info",
        });
      }
    });
  };
  const updateCategorie = async (updatedCcat) => {
    try {
      const updatedCategory = {
        ...updatedCcat,
        nameFolder: nameFolder
      };
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/updateCategorie/${selectedSkill.id}`,
        updatedCategory, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      const updatedCcatData = response.data;
      setCategories(categories.map(cat => cat.id === updatedCcatData.id ? updatedCcatData : cat));
      setShowModal(false);
      setSelectedSkill(null);
      swal(lang.menu.updateMsgSucccat, {
        icon: "success",
      });
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <> {categoryPer ? (  
      <div style={{ fontFamily: "Poppins" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">
          {lang.menu.categorieAvailable}{" "}
        </h4>
       
      </div>
      <Row>
        <Col xs={8}>
        <Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
        </Col>
        <Col xs={4} className="d-flex align-items-end justify-content-end">
        {createCat ? (
            <Button
              variant="primary"
              className="btn btn-primary test-list-button"
              onClick={handleModalOpen}
            >
              <i className="fas fa-plus me-2"></i>
              {lang.menu.newCategorie}
            </Button>
          ) : (
            <></>
          )}</Col>
      </Row>
      <br />

      <div className="test-card-list">
       
  {currentCat.map((item, index) => (
             <div className="test-card" key={index}>
            <div className="test-card-content">

            <h2 className="test-card-title">{item.name}</h2>
     
            <div className="d-flex">
              <p className="test-card-description">{item.description}

              {showFullDescription
                  ? item.description
                  : `${item.description.substring(0, 100)}...`}
                {item.description.length > 100 && (
                  <span className="read-more-link" onClick={toggleDescription}>
                    {showFullDescription ? "Read Less" : "Read More"}
                  </span>
                )}


              </p>
            </div>
          
       
          <div className="test-card-icons">
          
              <Link to={`/QuestionOfCategory/${item.id}`} className="test-details-link">
                <i className="fas fa-eye"></i>
              </Link>
              {(connectedUser.userRole === 1 || (item.companyID === companieId && companieId !== 1) || (connectedUser.localId === item.creator_uid && companieId === 1)) && (
<>
<button  style={{ border: "none" }} onClick={() => handleModalOpenUpdate(item)}>
                <i className="fas fa-pencil-alt"></i>
              </button>
           
              <button  style={{ border: "none" }} onClick={() => handleDelete(item.id)}>
                <i className="fas fa-trash-alt fa-lg"></i>
              </button>
          
</>)}
             
        </div>
</div>
        </div>
  ))}

        </div>
    
         <div className="d-flex justify-content-end align-items-center mt-4">
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              {lang.menu.previous}
            </Button>
            <span className="mx-3">
              Page {currentPage} {lang.menu.sur} {totalPages}
            </span>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              {lang.menu.next}
            </Button>
          </div>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title> 
            
          {modalType === "update" ? lang.menu.Update : lang.menu.createNewCat}
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>{lang.menu.name} </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={name}
                maxLength={60} 

                onChange={handleNameChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label>{lang.menu.description} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter category description"
                value={description}
                maxLength={1000} 

                onChange={handleDescriptionChange}
                rows={3}
                required
              />
            </Form.Group>
            <br />
            <Button variant="primary" type="submit">
              
      {modalType === "update" ? lang.menu.Update : lang.menu.createCat}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
    ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default ListCategories;
