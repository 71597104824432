import React, { useState, useEffect, Fragment, useMemo } from 'react';
import axios from 'axios';
import { Col, Row, Modal, Badge, Form, Pagination } from 'react-bootstrap';
import { RiVerifiedBadgeLine } from 'react-icons/ri';
import { AiFillCloseCircle } from 'react-icons/ai';
import translations from "../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { useTable, useSortBy, usePagination, useFilters, useGlobalFilter } from 'react-table';
import { FaSort } from "react-icons/fa";

const JobAssessment = () => {
  const [candidateAssessment, setCandidateAssessment] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidateAssessment(
            data.some(
              (permission) => permission.title === "candidate-assessment"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidateAssessment) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateAssessment]);

  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;

    const getUserCompanyID = async () => {
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      return data[0].companyID;
    };

    const fetchData = async () => {
      const compId = await getUserCompanyID();
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let filteredJobs = response.data;
      if (compId !== 1) {
        filteredJobs = response.data.filter((job) => job.companyid === compId);
      }

      let updatedJobs = [];
      await Promise.all(filteredJobs.map(async (job) => {
        const candidates = await getCandidates(job.id);
        const acceptedCandidates = candidates.filter(candidate => candidate.candAccept === 1 || candidate.candAccept === 2);

        job.acceptedCandidates = acceptedCandidates.length;
        updatedJobs.push(job);
      }));

      setJobs(updatedJobs);
    };

    fetchData().catch((error) => {
      console.error('Erreur lors de la récupération des emplois:', error);
    });
  }, []);
  
  const getCandidates = async (jobId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findCandidatesByJobRoleId2/${jobId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data;
    } catch (error) {
      console.error(`Error fetching job skills for ID ${jobId}:`, error);
      return [];
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: lang.menu.name,
        accessor: 'JobTitle',
        Cell: ({ row }) => (
          <Link to={`/Job-Details/${row.original.id}`}>
            {row.original.JobTitle}
          </Link>
        ),
      },
      {
        Header: lang.menu.candidates,
        accessor: 'Applicant',
        Cell: ({ value }) => value || 0, 
      },
      {
        Header: lang.menu.pendingUser,
        accessor: 'acceptedCandidates',
      },
      {
        Header: 'Nature',
        accessor: 'TypeOfQualification',
      },
      {
        Header: lang.menu.postDate,
        accessor: 'PostedDate',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: lang.menu.closeDate,
        accessor: 'CloseDate',
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
      },
      {
        Header: lang.menu.status,
        accessor: 'Status',
        Cell: ({ value }) => (
          value === 'Active' ? (
            <Badge variant="primary-Sshade">
              <RiVerifiedBadgeLine size={20} color='#219e8ae6' /> <span style={{ color: 'black' }}>{value}</span>
            </Badge>
          ) : (
            <Badge variant="primary-Sshade">
              <AiFillCloseCircle size={20} color='red' /><span style={{ color: 'black' }}>{value}</span>
            </Badge>
          )
        ),
      },
    ],
    [lang, searchTerm]
  );

  const filteredJobs = useMemo(() => {
    return jobs.filter((job) => {
      return job.JobTitle.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [searchTerm, jobs]);

  const pageSize = process.env.REACT_APP_PAGINATION;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { globalFilter, pageIndex },
    canNextPage,
    canPreviousPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data: filteredJobs,
      initialState: { pageIndex: 0, pageSize: Number(pageSize) },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  
  const paginatedRows = useMemo(() => {
    const startRow = pageIndex * Number(pageSize);
    const endRow = startRow + Number(pageSize);
    return rows.slice(startRow, endRow);
  }, [rows, pageIndex, pageSize]);

  return (
    <>
      {candidateAssessment ? (
        <Fragment>
          <PageTitle
            activeMenu={lang.menu.jobAssessment}
            motherMenu={lang.menu.dashboard}
          />
          <Row>
          
              <Form.Group controlId="searchForm.Control">
                <Form.Control
                  type="text"
                  placeholder={lang.menu.Search}
                  value={globalFilter || searchTerm}
                  onChange={(e) => setGlobalFilter(e.target.value || undefined)}
                />
              </Form.Group>
            
          </Row>
<br/>
          <Row>
            <Col>
              <div className="table-responsive">
                <table {...getTableProps()} className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table">
                  <thead style={{ backgroundColor: "#8cd6c8" }}>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                            <span>
                              {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                            </span>
                                 {!(column.isSorted) && (column.Header !== lang.menu.name )&&
                                                  <FaSort />}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {paginatedRows.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <Pagination className="justify-content-end">
                <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
                {pageOptions.map(page => (
                  <Pagination.Item
                    key={page}
                    active={pageIndex === page}
                    onClick={() => gotoPage(page)}
                  >
                    {page + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
              </Pagination>
            </Col>
          </Row>
        </Fragment>
      ) : (
        <Modal show={showAccessDeniedModal}>
          <Modal.Header>
            <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {lang.menu.YoudontHavePerm}
          </Modal.Body>
          <Modal.Footer />
        </Modal>
      )}
    </>
  );
};

export default JobAssessment;
