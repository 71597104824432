import {
  formatError,
  login,
  loginWithCustomToken,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  resetPassword,
  saveJWTTokenInLocalStorage
} from "../../services/AuthService";
import axios from "axios";
import Swal from "sweetalert2"; 
import translations from"../../jsx/components/AppsMenu/Candidate/mulitlingue";

export const SET_USER_ROLE = "[update action] update role";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";
export const RESET_PASSWORD_CONFIRMED_ACTION =
  "[reset password action] confirmed reset password";
export const RESET_PASSWORD_FAILED_ACTION =
  "[reset password action] failed reset password";
  let lang
  const storedLang = localStorage.getItem('selectedLang');
  if (storedLang) {
    const parsedLang = JSON.parse(storedLang);


    if (parsedLang.language === 'Français') {
      lang= translations.fr;

    }
    else{
      lang= translations.en;

    }
  }else{
      lang= translations.en;

    }
  export function signupAction(firstname, lastname, email, password, history) {
    return async (dispatch) => {
      try {
        const response = await signUp(email, password);
  
        if (!response) {
          // La vérification de l'email a échoué, une alerte est déjà affichée.
          return;
        }
  
        const user = {
          uid: response.data.localId,
          firstname: firstname,
          lastname: lastname,
        };
  
        await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/saveUser`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
  
        history.push("/login");
      } catch (error) {
        const errorMessage = error.response ? error.response.data : error.message;
        dispatch(signupFailedAction(errorMessage));
        Swal.fire({
          icon: 'error',
          title: lang.menu.signUpFailed,
          text: errorMessage,
        });
      }
    };
  }
export function signupActionForLinkedin(firstname, lastname, email, password, history) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        /*saveTokenInLocalStorage(response.data);
        runLogoutTimer(
            dispatch,
            response.data.expiresIn * 1000,
            history,
        );
        dispatch(confirmedSignupAction(response.data)); */
        const user = {
          uid: response.data.localId,
          firstname: firstname,
          lastname: lastname,
        };
        fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/saveUser`, {

          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        });
        history.push("/login");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}
export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT_ACTION,
  };
}

//updated by me
export function loginAction(email, password, history) {
  return async (dispatch) => {
    console.log("langgg:",lang)


      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/syncSub/${email}`);
        
        if (!response.ok) {
          const errorData = await response.json();
          if (response.status === 300) {
            console.error("Error 300: ", errorData.error);
            localStorage.setItem("NotPayed", true);
          } else {
            console.error("Unexpected error:", errorData.error);
          }
        } else {
          localStorage.setItem("NotPayed", false);
        }
      } catch (error) {
        console.error("Error syncing subscription:", error);
        localStorage.setItem("NotPayed", true);
      }
      

    login(email, password)
      .then(async (response) => {
        const param = response.data;
        const userId = param.localId;
        const redirectPath = localStorage.getItem('redirectPath');

        try {
          let languagedec=JSON.parse(localStorage.getItem("selectedLang"))
          if(languagedec){
            let language=languagedec.language
            if(languagedec){
            if (language === "English") {
              lang = translations.en;
            } else if (language === "Français") {
              lang = translations.fr;
            }}
            else{
              lang = translations.en;
            }
          }else{
            lang = translations.en;
          }
          
          if (lang) {
            localStorage.setItem("selectedLang", JSON.stringify(lang));}
            
          const userResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
          const data = await userResponse.json();
console.log("test :",data)
          if (data[0].emailVerified) {
            param.userRole = data[0].userRole;
            param.adminVerified = data[0].adminVerified;
            param.datain = data[0].datain;

            saveTokenInLocalStorage(param);
            saveJWTTokenInLocalStorage(data.token)
            dispatch(loginConfirmedAction(param));


            if (data[0].adminVerified === 1) {
              console.log("data[0]", data[0]);

              if (data[0].datain === 0 && data[0].userRole === 4) {
                localStorage.removeItem('GeneralInfo');
    localStorage.removeItem('Education');
    localStorage.removeItem('Experiences');
    localStorage.removeItem('TechnicalSkills');
    localStorage.removeItem('SoftSkills');
    localStorage.removeItem('AreaOfExpertise');
                history.push("/onboarding");
              } else if (data[0].userRole === 2 || (data[0].companyID ===1 && data[0].userRole === 3)) {
                try {
                  const token=localStorage.getItem("token");

                  const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/Subscription/getInvoiceByUserId/${data[0].uid}`, 
                    {
                      headers: {
                      Authorization: `Bearer ${token}`,
                      }});
                  console.log("response :", response.data);
                  const now = new Date();
                  const periodEnd = new Date(response.data.period_end);

                  if (response.data && periodEnd > now) {
                    console.log("response.data :",response.data,"periodEnd:",periodEnd,"now:",now)
                    if(redirectPath && redirectPath !== '/login' && redirectPath !== '/page-register'&& redirectPath !== '/dashboard'){
                      console.log("redirectPathindex: ",redirectPath)
                      history.push(redirectPath);
                      localStorage.removeItem('redirectPath');
                    }else{
                      history.push("/dashboard");
                    }
                  } else {
                    history.push("/PayeYourInvoice");
                  }
                } catch (invoiceError) {
                  console.error("Error fetching invoice:", invoiceError);
                }
              } else {
                if(redirectPath && redirectPath !== '/login' && redirectPath !== '/page-register'&& redirectPath !== '/dashboard'){
                  console.log("redirectPathindex: ",redirectPath)
                  history.push(redirectPath);
                  localStorage.removeItem('redirectPath');
                }else{
                  history.push("/dashboard");
                }
              }
            } else {

              console.log("data[0].PaymentControl:", data[0]?.PaymentControl);

              if (data[0]?.PaymentControl === 0) {
                console.log("Redirecting to /pick-role");
                history.push("/pick-role");
              } else {
                console.log("Redirecting to /pick-role2");
                history.push("/pick-role2");
              }
            }
          } else {
            console.log("testsss")
            dispatch(loginFailedAction(lang.menu.emailVerif));
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch(loginFailedAction(lang.menu.fetchDataFailed));
        }
      })
      .catch((error) => {
        console.log("testsss1",error)
        console.log("error.response.data",error.response.data)

        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
  };
}


export function loginActionForLinkedin(token,history) {
  return async (dispatch) => {
    const redirectPath = localStorage.getItem('redirectPath');
    localStorage.setItem('currentStep', '1');
    //await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/0`);
    loginWithCustomToken(token)
      .then((response) => {
        const param = response.data;
        const userId = param.localId;
        fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`)
          .then((response) => response.json())
          .then(function (data) {
            if (data[0].emailVerified) {
              param.userRole = data[0].userRole;
              param.adminVerified = data[0].adminVerified;
              saveTokenInLocalStorage(param);
              //runLogoutTimer(dispatch, param.expiresIn * 5000, history);
              dispatch(loginConfirmedAction(param));
              if (data[0].adminVerified === 1) {
                if (data[0].datain === 1) {
                  history.push("/dashboard");
                } else {history.push("/onboarding");}
              } else {history.push("/pick-role");}
            } else {
              if(redirectPath && redirectPath !== '/login' && redirectPath !== '/page-register'&& redirectPath !== '/dashboard'){
                console.log("redirectPathindex: ",redirectPath)
                history.push(redirectPath);
                localStorage.removeItem('redirectPath');
              }else{
                history.push("/dashboard");
              }
              
              /* reject('Please verify your email address'); */
            }
          });
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(loginFailedAction(errorMessage));
      });
        
  };
}


export function resetPasswordAction(email, history) {
  return (dispatch) => {
    resetPassword(email)
      .then(() => {
        dispatch(resetPasswordConfirmedAction());
        history.push("/login");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(resetPasswordFailedAction(errorMessage));
      });
  };
}

export function loginFailedAction(data) {
  console.log("data :",data)
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function resetPasswordConfirmedAction() {
  return {
    type: RESET_PASSWORD_CONFIRMED_ACTION,
  };
}

export function resetPasswordFailedAction(message) {
  return {
    type: RESET_PASSWORD_FAILED_ACTION,
    payload: message,
  };
}
