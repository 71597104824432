import React, { useState ,useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadingToggleAction, loginAction } from '../../store/actions/AuthActions';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './login.css';
import logo from '../../images/logo-full.png';
import linkedin_logo from '../../images/socialmedia/linkedin_logo.svg.png';
import pic1 from '../../images/pic1.png';
import { LuLoader } from "react-icons/lu";
import translations from "../components/AppsMenu/Candidate/mulitlingue";
import { useLocation } from "react-router-dom";


function Login(props) {
    const location = useLocation();
  
  const [email, setEmail] = useState('');
    useEffect(async() => {
          // Analyse l'URL pour extraire les paramètres email et jobTitle
       
          const urlParams = new URLSearchParams(location.search);
          const emailParam = urlParams.get('email');
        
          if (emailParam) {
            window.location.href = `${process.env.REACT_APP_APP_DOMAIN}/page-register?email=${emailParam}`;
             
          }
        
         
      }, [location]);
  let errorsObj = { email: '', password: '' };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Email is Required';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Password is Required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setIsLoading(true); 
    dispatch(loginAction(email, password, props.history))
      .then(() => {
        setIsLoading(false); 
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  const handleLinkedInLogin = () => {
    // Handle LinkedIn import logic here
    console.log('Importing from LinkedIn...');
    localStorage.setItem('linkedinUse', 'login');
    const client_id =process.env.REACT_APP_LINKEDIN_CLIENT_ID || "78rgom6c3nciik"
    const redirect =process.env.REACT_APP_LINKEDIN_REDIRECT_LINK || "http%3A%2F%2Flocalhost%3A3000%2Ffetchlinkedin"
    console.log("client id: " + client_id)
    console.log("redirect: " + redirect)
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=997755331&scope=openid,profile,email&client_id=78rgom6c3nciik&redirect_uri=${redirect}`
  };

  return (
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <h1 className="mb-1">{lang.menu.welcomeBack}</h1>
          <p>{lang.menu.userExperience}</p>
        </div>
        <div className="aside-image" style={{ backgroundImage: `url(${pic1})` }}></div>
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form form-validation">
                  {props.errorMessage && (
                    <div className="error-message">
                      {props.errorMessage }
                    </div>
                  )}
                  {props.successMessage && (
                    <div className="success-message">
                      {props.successMessage}
                    </div>
                  )}
                  <div className="text-center mb-5 pt-5">
                    <img src={logo} alt="logo1" className='logologin' />
                  </div>
                  <form onSubmit={onLogin} className="form-validate">
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>{lang.menu.email}</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          maxLength={60}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder={lang.menu.typeEmail}
                        />
                      </div>
                      {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                    </div>
                    <div className="form-group mb-3">
                      <label className="mb-1">
                        <strong>{lang.menu.password}</strong>
                      </label>
                      <div className="password-input-container">
                        <input
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          maxLength={60}
                          type={showPassword ? "text" : "password"}
                          placeholder={lang.menu.password}
                        />
                        <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </div>
                      {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                    </div>
                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                      <div className="form-group mb-3">
                        <div className="custom-control custom-checkbox ml-1">
                         {/*  <input
                            type="checkbox"
                            className="form-check-input"
                            id="basic_checkbox_1"
                          
                            checked={rememberMe}
                            onChange={toggleRememberMe}
                          />
                          <label className="form-check-label" htmlFor="basic_checkbox_1">
                          {lang.menu.rememberme}
                          </label> */}
                          <Link to="/page-forgot-password" className="text-login" style={{ marginLeft: '160px' }}>
                          {lang.menu.forgetPassword}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="text-center form-group mb-3">
                      <button type="submit" className="btn btn-primary btn-block">
                        {isLoading ? <LuLoader /> : lang.menu.signIn}
                      </button>
                    </div>
                    <div className="new-account mt-3">
                      <p>
                      {lang.menu.dontHaveAccount}

 <Link className="text-login" to="./page-register"> {lang.menu.Signup}
 </Link>
                    </p>
                    
                  </div>
                 
                  </form>
                 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Login);
