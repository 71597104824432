
import React, { Fragment, useState, useEffect } from "react";
import axios from 'axios';
import translations from "../../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../../layouts/PageTitle";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import swal from "sweetalert";

function TechQCM() {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const [durationQuestionQCM, setDurationQuestionQCM] = useState("");
  const token=localStorage.getItem("token");

  const history = useHistory();
  
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
      const fetchData = async () => {
        try {
          const currentUser = JSON.parse(localStorage.getItem('userDetails'));
          const userId = currentUser.localId;
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const data = await response.json();
          setDurationQuestionQCM(data[0].DurationQuestionQCM);
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchData();
    }, []);
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);

  const [minQuestionsPerSkill, setMinQuestionsPerSkill] = useState(0); 

 
 
 /* useEffect(() => {
    const fetchMinQuestionsPerSkill = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/minQuestionsPerSkill`
        );
        setMinQuestionsPerSkill(response.data.minQuestions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMinQuestionsPerSkill();
  }, []);
  */
  useEffect(() => {
    const fetchskills = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/skill/getAllSkillQuest`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        
        setSkills(response.data.filter(skill => skill.level));
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchskills();
  }, []);
  const handleSkillChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions);
  };
  
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };

  //add minqusetion control
  const handleSubmit = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      const testName = document.querySelector("#name").value; 
      const testDescription = document.querySelector("#description").value; 
      const questionNbre = document.querySelector("#questionNbre").value; 
      let timePerQuestion = document.querySelector("#timePerQuestion").value; 
      //const skillIds = selectedSkills.map(skill => skill.value); 
      const skillIds = selectedSkills; 
      
      for (let obj of selectedSkills){
        if (!obj.level) {
          swal(lang.menu.selectSkillLevelCreation, {
            icon: "error",
          });
          return;
        }
      }
      
      if (!testName.trim() || !testDescription.trim() || !questionNbre.trim()) {
        swal(lang.menu.requiredFields, {
          icon: "error",
        });
        return;
      }
      // Validate skillIds
      if (skillIds.length === 0) {
        swal("Please select at least one skill.", {
          icon: "error",
        });
        return;
      }
        if (!timePerQuestion) {
        timePerQuestion = durationQuestionQCM;
      }

      if(questionNbre > minQuestionsPerSkill){
        swal('Please reduce the number of questions.', {
          icon: "error",
        });
        return;
      }
	      if(questionNbre <1){
        swal(`The nbre of questions must be positive`, {
          icon: "error",
        });
        return;
      }   
	      if(timePerQuestion <1){
        swal(`The time questions must be positive`, {
          icon: "error",
        });
        return;
      }  
      const companyID = await getUserCompanyID();

      const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/createTestTech`, {
        name: testName,
        description: testDescription,
        questionNbre: questionNbre,
        timePerQuestion: timePerQuestion,
        companyID: companyID,
        skillIds: skillIds,
        userId: userId
      }, 
      {
        headers: {
        Authorization: `Bearer ${token}`,
        }});  
      swal("Test submitted successfully.", {
        icon: "success",
      });
      history.push("/TechQCMList");
    } catch (error) {
      console.error(error);
      swal('Failed to submit the form.', {
        icon: "error",
      });
    }
  };

  


  //level 
  //const proficiencyLevelsOptions = ['Beginner','Intermediate','Advanced'];

  const isSelectedProficiency = (skill, level) => {
    return skill.level === level ? 'selected' : 'notselected';
  };

  const handleRemoveSkill = (skillToRemove) => {
    setSelectedSkills(selectedSkills.filter((skill) => skill !== skillToRemove));
  }

  // find the min
  useEffect(()=>{
    const findMin = ()=>{
      var min = 0;
      selectedSkills.forEach((selectedSkill)=>{
        if(selectedSkill.level && (min === 0 || selectedSkill.possibleLevels[selectedSkill.level] <  min)){
          min = selectedSkill.possibleLevels[selectedSkill.level];
        }
  
      })
      return min;
  
    }
  
    setMinQuestionsPerSkill(findMin());
  },[selectedSkills])
  

 

  return (
    <div >
      <div className="mb-3">
        <label htmlFor="name" className="form-label">{lang.menu.name}</label>
        <input type="text" className="form-control" placeholder="Name" id="name" maxLength={60} required/>
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">{lang.menu.description}</label>
        <textarea className="form-control" rows="3" placeholder="Description" id="description" maxLength={1000} required></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="questionNbre" className="form-label">{lang.menu.nbrQuestionS}</label>
        <input type="number" className="form-control" placeholder="Question Nbre" id="questionNbre"  required  min="1"/>
        <p className="form-text" style={{color:"red"}}>{lang.menu.nbSkill} {minQuestionsPerSkill}</p>
     
      </div>
      <div className="mb-3">
        <label htmlFor="timePerQuestion" className="form-label">{lang.menu.timePerQuestion}</label>
        <input type="number" className="form-control" placeholder={`Default time per question is ${durationQuestionQCM} Seconds`} id="timePerQuestion"  min="1" />
     
      </div>
      <div className="mb-3">
  <label htmlFor="skills" className="form-label">{lang.menu.skills}</label>
  <Select
    options={skills.map(skill => ({ value: skill.id, label: skill.name, possibleLevels : skill.level }))}
    isMulti
    onChange={handleSkillChange}
    value={selectedSkills}
  />
</div>
  <div className="mb-3">
  {selectedSkills.map((skill, index) => (
  <div
    style={{ display: "flex", flexDirection: "row", marginTop: "6px" }}
    key={index}
  >
    <h4 style={{ margin: 0, width: "50%" }}>{skill.label}</h4>

    {["Beginner", "Intermediate", "Advanced"].map((level) => {
      if (skill.possibleLevels[level]) {
        return (
          <button
            key={level}
            style={{ paddingLeft: "3px", paddingRight: "3px", marginLeft: "3px" }}
            className={isSelectedProficiency(skill, level)}
            onClick={(e) => {
              e.preventDefault();
              setSelectedSkills(
                selectedSkills.map((obj) => {
                  if (obj.label === skill.label) {
                    return { ...obj, level: level };  
                  }
                  return obj;
                })
              );
            }}
          >
            {level}
          </button>
        );
      }
      return null; 
    })}

    <button
      style={{
        paddingLeft: "5px",
        paddingRight: "5px",
        marginLeft: "2%",
        color: "white",
        background: "#74188D",
        borderRadius: "10px",
      }}
      onClick={() => handleRemoveSkill(skill)}
    >
      <b>X</b>
    </button>
  </div>
))}

  </div>
   

<div className="col-12">
<button onClick={handleSubmit} className="btn btn-outline-primary btn-rounded">{lang.menu.submit}</button>

  </div>
  <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default TechQCM;
