import React, { Fragment,useState,useEffect } from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
import "./candidateCSS.css"
import ProgressBar from "./CandidateProgress";
import FileUploader from "./FileUploader";
import {
    Col,Row,Card,OverlayTrigger, Tooltip,Badge
    
  } from "react-bootstrap";
  import translations from"./mulitlingue"
import { Modal } from 'react-bootstrap';
import Select from "react-select";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlinePieChart } from "react-icons/ai";
import swal from "sweetalert";

const CvDataExtractionForum = () => {
  const [candidateExt, setCandidateExt] = useState([]);
  const [quotaMax, setQuotaMax] = useState("");
  const [userConnect, setUserConnect] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDisable, setIsDisable] = useState("");
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setUserConnect(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidateExt(
            data.some((permission) => permission.title === "cvs-extraction")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 
  const [companyID, setCompanyID] = useState("");

  useEffect(() => {
    if (!candidateExt) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidateExt]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;

      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const data = await responseC.json();
        return data[0].companyID;
      };

      try {
        const compId = await getUserCompanyID();
        setCompanyID(compId)
        const jobResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const jobData = jobResponse.data;

        let filteredJobs;
        let subscriptionResponse;
        let subscriptionData;

        if (compId !== 1) {
          filteredJobs = jobData.filter(job => job.companyid === compId && job.Status==="Active" && job.archived===0);
          subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${compId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
        } else if (compId === 1 && currentUser.userRole === 3) {
          filteredJobs = jobData.filter(job => job.companyid === compId && job.Status==="Active" && job.archived===0 && job.creator_uid === currentUser.localId);
          subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
        } else {
          
          filteredJobs = jobData.filter(job =>  job.Status==="Active" && job.archived===0);;
        }

        const jobList = filteredJobs.map(job => ({
          value: job.id,
          label: job.JobTitle,
          companyLogo:job.companyid
        }));

        const enrichedJobs = await enrichJobsWithLogos(jobList);

        setJobs(enrichedJobs);

        if (subscriptionResponse) {
          subscriptionData = await subscriptionResponse.json();
          if (subscriptionResponse.status === 200) {
            setQuotaMax(subscriptionData[1]);
            const isDisabled = subscriptionData[1].numberquota_max <= subscriptionData[1].quotaExtraction;
            setIsDisable(isDisabled);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des emplois:', error);
      }
    };

    fetchData();
  }, []);
  const enrichJobsWithLogos = async (jobList) => {
    const enrichedJobs = await Promise.all(
      jobList.map(async (job) => {
        const companyLogo = job.companyLogo ? await getCompanyLogo(job.companyLogo) : null;
        return {
          ...job,
          companyLogo,
        };
      })
    );
    return enrichedJobs;
  };
  
  const getCompanyLogo = async (companyId) => {
    if (!companyId) return null;
    const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const dataCmp = await response.json();
    const company = dataCmp.find((comp) => comp.companyid === companyId);
    return company ? `${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}` : null;
  };
	const [selectedLang, setSelectedLang] = useState(null);
	const [requiredFieldsSelected, setRequiredFieldsSelected] = useState(false);
    const [filesSelected, setFilesSelected] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [selectedNumberDays, setSelectedNumberDays] = useState(null);
   
    const [uploadPop, setUploadPop] = useState(false);
	const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
	const [modalIsOpen, setModalIsOpen] = useState(false);
	
	const [guideModalOpen, setGuideModalOpen] = useState(false);

	const [jobRoleName, setJobRoleName] = useState(lang.menu.jobRole);
	const [numSaved, setNumSaved] = useState(0);
	const [numNotSaved, setNumNotSaved] = useState(0);
	const [totalCandidatesEn, setTotalCandidatesEn] = useState(0);
	const [loading, setLoading] = useState(false);
  const [skillsForJob, setSkillsForJob] = useState("");
    const [PT1, setPT1] = useState(0);

    const [PT2, setPT2] = useState(0);
    const [maxOccurence, setMaxOccurence] = useState(0);
	const [error, setError] = useState(null);

  const optionsNumberDays = [
    { value: 1, label: `1 ${lang.menu.jour}` },
    { value: 2, label: `2 ${lang.menu.jour}s` },
    { value: 3, label: `3 ${lang.menu.jour}s` },
    { value: 4, label: `4 ${lang.menu.jour}s` },
    { value: 5, label: `5 ${lang.menu.jour}s` },
    { value: 6, label: `6 ${lang.menu.jour}s` },
    { value: 7, label: `1 ${lang.menu.semaine}` },
    { value: 14, label: `2 ${lang.menu.semaine}s`},
    { value: 21, label: `3 ${lang.menu.semaine}s`},
    { value: 30, label: `1 ${lang.menu.mois}` },
    { value: 60, label: `2 ${lang.menu.mois}` },
    { value: 90, label: `3 ${lang.menu.mois}` }
  ];
  
	useEffect(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  setSelectedLang(JSON.parse(storedLang));
		}
	  }, []);
      useEffect(() => {
        checkRequiredFields();
      }, [jobRoleName,filesSelected]);
	  
  

    
  
      const handleDropdownSelect3 = async (selectedOption) => {
        setSelectedJob(selectedOption);
    
        const selectedJob = jobs.find((job) => job.value === selectedOption.value);
    
        if (selectedJob) {
          try {
            setJobRoleName(selectedJob.label)
            const jobSkills = await getJobSkills(selectedJob);
            setPT1(jobSkills.PT1)
            setPT2(jobSkills.PT2)
            setMaxOccurence(jobSkills.maxOccurence)
            setSkillsForJob(jobSkills.skills);
          } catch (error) {
            console.error("Error fetching job skills:", error);
          }
        }
    
        checkRequiredFields();
      };

	const checkRequiredFields = () => {
		if (jobRoleName != lang.menu.jobRole) {
			setRequiredFieldsSelected(true);
		} else {
			setRequiredFieldsSelected(false);
		}
	};
  const handleNumberDaysSelect = (selectedOption) => {
    setSelectedNumberDays(selectedOption.value);
  };


	const postCandidates = async() => {
		setModalIsOpen(true); 
		
		const data = new URLSearchParams();
	
		data.append('jobRoleName', jobRoleName);
	
      if (selectedNumberDays != null) {
        data.append("NumberDays", selectedNumberDays);
      }
		setLoading(true);
		const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      	const userId = currentUser.localId;
         let subscriptionResponse="";
          let subscriptionData=""
          if(companyID!=1){
             subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByCompanyId/${companyID}`, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
             subscriptionData = await subscriptionResponse.json();
          }else if(companyID===1 && currentUser.userRole===3){
            subscriptionResponse = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/getSubscriptionFeaturesByRecruiterId/${userId}`, 
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
            subscriptionData = await subscriptionResponse.json();
         }
    
		data.append("userId", userId);
    if(companyID!=1 && subscriptionData[1].numberquota_max>subscriptionData[1].quotaExtraction){
		axios({
			method: 'post',
			url: `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates100`,
			data: data,
			headers: {
        Authorization: `Bearer ${token}`,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.then(async(response)=> {
				
				setNumSaved(response.data.num_saved); 
				setNumNotSaved(response.data.num_not_saved);
				setTotalCandidatesEn(response.data.num_files_to_extract);
				
				setLoading(false);
				setModalIsOpen(false);
        swal("Candidates added successfully!", {
          icon: "success",
        });
        console.log("nb;",response.data.num_files_to_extract)
        await axios.put(
          `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateCompanyField/${companyID}`,
          { fieldName: "quotaExtraction", 
            "incrementValue": response.data.num_files_to_extract, 
            quota_max: subscriptionData[1].numberquota_max, 
            quota_used: subscriptionData[1].quotaExtraction },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
			})
			.catch(error => {
				setError(error.message);
				
				setLoading(false);
				setModalIsOpen(false);
				toast.error("Error adding candidates!", {
					position: toast.POSITION.BOTTOM_CENTER,
					limit: 1,
				  });
			});
    }
    else if(companyID===1&& currentUser.userRole===3 && subscriptionData[1].numberquota_max>subscriptionData[1].quotaExtraction){
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates100`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(async(response)=> {
          
          setNumSaved(response.data.num_saved); 
          setNumNotSaved(response.data.num_not_saved);
          setTotalCandidatesEn(response.data.num_files_to_extract);
          
          setLoading(false);
          setModalIsOpen(false);
          swal("Candidates added successfully!", {
            icon: "success",
          });
  
          await axios.put(
            `${process.env.REACT_APP_APP_DOMAIN}/api/companies/updateRecruiterField/${userId}`,
            { fieldName: "quotaExtraction", 
              "incrementValue": response.data.num_files_to_extract, 
              quota_max: subscriptionData[1].numberquota_max, 
              quota_used: subscriptionData[1].quotaExtraction },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
           
        })
        .catch(error => {
          setError(error.message);
          
          setLoading(false);
          setModalIsOpen(false);
          toast.error("Error adding candidates!", {
            position: toast.POSITION.BOTTOM_CENTER,
            limit: 1,
            });
        });
      }
    else{
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates100`,
        data: data,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(response => {
          
          setNumSaved(response.data.num_saved); 
          setNumNotSaved(response.data.num_not_saved);
          setTotalCandidatesEn(response.data.num_files_to_extract);
          
          setLoading(false);
          setModalIsOpen(false);
          swal("Candidates added successfully!", {
            icon: "success",
          });
          swal("Candidates added successfully!", {
            icon: "success",
          });
          
        })
        .catch(error => {
          setError(error.message);
          
          setLoading(false);
          setModalIsOpen(false);
          swal("Error adding candidates!", {
            icon: "error",
          });
        });
    }
	};
  const handleUploadPop = (isOpen) => {
    setUploadPop(isOpen);
    setModalIsOpen(isOpen); 
  };
	const uploadCV = () => {
		try {
			postCandidates();
		} catch (error) {
			console.error(error);
		}
	};

 
  const getJobSkills = async (jobId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/GetSkillsByJobid/${jobId.value}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      const jobskills = response.data;
      return jobskills;
    } catch (error) {
      console.error(`Error fetching job skills for ID ${jobId.value}:`, error);
      return "";
    }
  };





     
      
     
      const parseSkillsString = (skillsString) => {
        const skillsArray = skillsString
          .replace(/\(/g, '[')
          .replace(/\)/g, ']')
          .replace(/'/g, '"');
      
        try {
          return JSON.parse(skillsArray);
        } catch (error) {
          console.error("Error parsing skills string:", error);
          return [];
        }
      };
      const formatOptionLabel = ({ label, companyLogo }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {companyLogo && (
            <img
              src={companyLogo}
              alt="Company Logo"
              style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
            />
          )}
          <span>{label}</span>
        </div>
      );
    return (
      <> {candidateExt ? (
  <div>
    <Card>
    <Card.Header className="d-flex justify-content-between align-items-center">
  <Card.Title>{lang.menu.cvDex}</Card.Title>
  <div className="d-flex align-items-center">
    {quotaMax ? (
      <Badge className="d-flex align-items-center justify-content-center badge-rounded me-2 "
       variant="outline-danger"      style={{ color: quotaMax.quotaExtraction >=   quotaMax.numberquota_max ? 'red' : '#74188D' }}>
        <AiOutlinePieChart size={20} />{' '}
        {lang.menu.quotaUsed} : {quotaMax.quotaExtraction}/{quotaMax.numberquota_max}
      </Badge>
    ) : null}
    <button className="btn btn-info" onClick={() => setGuideModalOpen(true)}>
      Guide
    </button>
  </div>
</Card.Header>

                <Card.Body>
   
                <div className="form-group">
                    <label><strong>{lang.menu.jobRole} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                    <br/>
                    <Select
      value={selectedJob}
      onChange={handleDropdownSelect3}
      options={jobs}
      formatOptionLabel={formatOptionLabel} 
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: "40px",
          lineHeight: "40px",
          borderRadius: "0",
        }),
      }}
    />
    
    
    <br/>
                    
                    </div>
                    <div>
  {skillsForJob && skillsForJob.length > 0 && (
    <div className="form-group">
    <label>
      <strong>{lang.menu.SkillSelected}</strong>
    </label>
    <ul>
      {parseSkillsString(skillsForJob).map((skill, index) => (
        <Badge key={index} style={{ margin: "1%", backgroundColor: "#43DAC2" }}>
          <span style={{ textAlign: "center" }}>{`${skill[0]} : ${skill[1]}`}</span>
        </Badge>
      ))}
    </ul></div>
  )}
</div>
{PT1!=0 && (
  <Row>
  <Col>
  <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt1}</Tooltip>}
          >
            <label><strong>{lang.menu.pt1}  :</strong> </label>
          </OverlayTrigger>
  <input 
  type="text"
  id="PT1"
  className="form-control"
  value={`${PT1*100}%`}

  readOnly/>
  </Col>
  <Col>
  <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgpt2}</Tooltip>}
          >
            <label><strong>{lang.menu.pt2} :</strong> </label>
          </OverlayTrigger>
  <input 
  type="text"
  id="PT2"
  className="form-control"
  value={`${PT2*100}%`}
  readOnly/>
  </Col>
  <Col>
  <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgOc}</Tooltip>}
    >
                    <label><strong>{lang.menu.toc} :</strong> </label>
                    </OverlayTrigger>
  <input 
  type="text"
  id="maxOccurence"
  className="form-control"
  value={maxOccurence}
  readOnly/>
  </Col>

</Row>
)}
                    <br/>

                   
                    <div className="form-group">
                    <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-pt1">{lang.menu.msgDays}</Tooltip>}
    >
  <label><strong>{lang.menu.nbreDays}:</strong> </label>
  </OverlayTrigger>
  <br/>
  <Select
    value={optionsNumberDays.find(option => option.value === selectedNumberDays)}
    onChange={handleNumberDaysSelect}
    options={optionsNumberDays}
    styles={{
      control: (provided) => ({
        ...provided,
        minHeight: '40px',
        lineHeight: '40px',
        borderRadius: '0',
      }),
    }}
  />
  <br/>
</div>


                    <Card style={{ border: '2px solid black' }}>
                        <Card.Body>
                    <label><strong>{lang.menu.chooseFile} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                    <br/>
                    <FileUploader
        jobRoleName={jobRoleName}
        onFilesSelected={(value) => setFilesSelected(value)}
        onUploadSuccess={() => setUploadSuccess(true)}
        onUploadPop={(isOpen) => handleUploadPop(isOpen)}
        maxFiles={
          quotaMax?.quotaExtraction && quotaMax?.numberquota_max
            ? Math.min(100, quotaMax.numberquota_max - quotaMax.quotaExtraction)
            : 100
        }
      />
                    </Card.Body>
                    </Card>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                    {companyID && companyID!=1 ? (
     
    <button  
    className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
    onClick={uploadCV} disabled={ !requiredFieldsSelected || !filesSelected || !uploadSuccess|| isDisable } >
    {lang.menu.ExtractButton}
    </button>):
    companyID && companyID===1 && userConnect.userRole===3 ? (
     
      <button  
      className={`btn btn-primary me-3 btn-sm ${isDisable ? "disabled" : ""}`}
      onClick={uploadCV} disabled={ !requiredFieldsSelected || !filesSelected || !uploadSuccess|| isDisable } >
      {lang.menu.ExtractButton}
      </button>)
    
    
    :(
 <button className="btn btn-primary" onClick={uploadCV} disabled={!requiredFieldsSelected || !filesSelected || !uploadSuccess} >
 {lang.menu.ExtractButton}
 </button>

    )}
	</div>
    <Modal show={modalIsOpen || uploadPop} onHide={() => setModalIsOpen(false) || handleUploadPop(false) }>
      <Modal.Header>
        <Modal.Title>{loading ? lang.menu.cvEx : lang.menu.cvUpload}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{lang.menu.plz}</p>
      </Modal.Body>
    </Modal>
    <Modal show={guideModalOpen} onHide={() => setGuideModalOpen(false)}>
  <Modal.Header closeButton>
    <Modal.Title>{lang.menu.guide}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>{lang.menu.step0}</p>
    <ol>
      <li>{lang.menu.step2}</li>
      <li>{lang.menu.step3}</li>
      <li>{lang.menu.step4}</li>
    </ol>
    <p>{lang.menu.step5}</p>
  </Modal.Body>
</Modal>
    {totalCandidatesEn > 0 && <ProgressBar numNotSaved={numNotSaved}
											numSaved={numSaved}
											totalCandidatesEn={totalCandidatesEn}
											lang={lang}
											/>}
											<ToastContainer/>
        </Card.Body>
        </Card>
  </div>
     ) : (
      <Modal show={showAccessDeniedModal} >
      <Modal.Header>
        <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You don't have permission to view this page.
      </Modal.Body>
      <Modal.Footer>
  
      </Modal.Footer>
    </Modal>
   )}</>
        );
    };
    
    export default CvDataExtractionForum;
  