import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,Card,
 
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Radar } from "react-chartjs-2";
import translations from "../../AppsMenu/Candidate/mulitlingue";

function RapportTech(props) {
  const { user_id, jobRoleId, nbreQuest,categoryCorrectCounts,nbr } = props.location.state;
  const [jobCandidateDetails, setJobCandidateDetails] = useState();


  const [userEmail, setUserEmail] = useState(null); 
  const [jobName, setJobName] = useState(null);
  const token=localStorage.getItem("token");
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
        const parsedLang = JSON.parse(storedLang);
        if (parsedLang.language === 'Français') {
            return translations.fr;
        }
    }
    return translations.en;
});

  useEffect(() => {
    const fetchJobCandidateDetails = async () => {
      try {
        const storedLang = localStorage.getItem('selectedLang');
        let langue = "English";
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
           langue=parsedLang.language
          }        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobCandiateTech/${user_id}/${jobRoleId}/${langue}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setJobCandidateDetails(response.data.Job_Candidate);
      } catch (error) {
        console.error(error);
      }
    };

    fetchJobCandidateDetails();
  }, []);
 
  const radarData = {
    labels: Object.keys(categoryCorrectCounts),
    datasets: [
      {
        label: "Category Answers",
        data: Object.values(categoryCorrectCounts),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "#74188D", 
        pointBackgroundColor: "#74188D", 
        pointBorderColor: "#000", 
        pointHoverBackgroundColor: "#000", 
        pointHoverBorderColor: "#74188D", 
      },
    ],
  };
  const radarOptions = {
    scales: {
      r: {
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          max: nbr, 
        },max:nbr,min:0
      },
    },
  };
  
  const numAxes = Object.keys(categoryCorrectCounts).length;
  for (let i = 0; i < numAxes; i++) {
    radarOptions.scales[`r${i}`] = {
      ticks: {
        beginAtZero: true,
        stepSize: 1,
        max: nbr,
      },max:nbr,min:0
    };
  }
  

  useEffect(() => {
    const fetchUserEmail = () => {
      try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails && userDetails.email) {
          const userEmail = userDetails.email;
          console.log("Email de l'utilisateur :", userEmail);
          setUserEmail(userEmail);
        } else {
          console.log("Aucun email d'utilisateur trouvé dans les données de l'utilisateur.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'email de l'utilisateur :", error);
      }
    };

    fetchUserEmail();
  }, []);
  useEffect(() => {
    const fetchJobName = async () => {
      try {
        console.log("jobRoleId :",jobRoleId)
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobById/${jobRoleId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        if (response.data) {
          const jobName = response.data;
          console.log("Nom du job :", jobName);
          setJobName(jobName);
        } else {
          console.log("Aucun nom de job trouvé pour l'ID de job spécifié.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du nom du job :", error);
      }
    };

    fetchJobName();
  }, [jobRoleId]); 
  function formatCalendarDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    
    };
    return new Date(date).toLocaleDateString("en-US", options);
  }

  useEffect(()=>{
    console.log("categoryCorrectCounts", categoryCorrectCounts);
  },[])
 
  return (
    <Card>
    <div className="container">
    <div className="d-flex align-items-center mb-4 flex-wrap">
    <h2 className="fs-20 font-w800  me-auto"></h2>
    <Link to={`/JobRoleTests/${jobRoleId}`}>
              <button className="btn btn-secondary">{lang.menu.retour}</button>
            </Link></div>
      <div className="row">
        
        <div style={{marginBottom: "40px"}}>
          <h2 style={{textAlign: "center"}}>{lang.menu.TestResult}</h2>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12">
          {categoryCorrectCounts && (
            <div style={{ maxWidth: "100%", margin: "0 auto" }}>
              <Radar data={radarData} options={radarOptions} />
            </div>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="product-detail-content">
            <div style={{ fontSize:"120%",fontFamily:"Poppins" }}>
           
            <p>
              <span style={{color:"#74188D"}}>{lang.menu.email} :</span>{userEmail}
            </p>
            <p>
              <span style={{color:"#74188D"}}>{lang.menu.JobRoleName} :</span> {jobName}
            </p>
            <p>
              <span style={{color:"#74188D"}}>{lang.menu.nbrQuestionS}:</span> {Object.keys(categoryCorrectCounts).length*nbr}
            </p>
            <p>
              <span style={{color:"#74188D"}}>{lang.menu.BonneReponse} :</span> {Object.values(categoryCorrectCounts).reduce((acc, val) => acc + val, 0) + "/" + Object.keys(categoryCorrectCounts).length*nbr} 
            </p>
            <p>
              <span style={{color:"#74188D"}}>Score :</span> {(Object.values(categoryCorrectCounts).reduce((acc, val) => acc + val, 0) * 100) / (Object.keys(categoryCorrectCounts).length*nbr) + "%"}
            </p>

             
            {jobCandidateDetails && (
              <div>
                <p>
                  <span style={{color:"#74188D"}}> <i className="fa fa-calendar" />  </span>{" "}
                  {formatCalendarDate(jobCandidateDetails.date2)}
                </p>
                
              </div>
            )}</div>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          {categoryCorrectCounts && (
            <Card>
              <Table>
                <thead>
                  <tr>
                    <th>{lang.menu.skill}</th>
                    <th>{lang.menu.BonneReponse}</th>
                    <th>{lang.menu.nbQuestionPerSkill}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(categoryCorrectCounts).map((skillName) => (
                    <tr key={skillName}>
                      <td>{skillName}</td>
                      <td>{categoryCorrectCounts[skillName]}</td>
                      <td>{nbr}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          )}
        </div>
      </div>
    </div>
  </Card>
  );
}

export default RapportTech;
