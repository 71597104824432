import React, { useState ,useEffect} from "react";
import { Card, Table, Modal, Button, Badge,Dropdown,InputGroup, FormControl ,Row,Col,Image} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { VscRunAll } from "react-icons/vsc";
import { TfiTimer } from "react-icons/tfi";
import { SiLevelsdotfyi } from "react-icons/si";
import { FaRegEye } from "react-icons/fa";
import axios from "axios";

const FilterableTable = ({
  lang,
  items, 
  itemsPerPage, 
  handlePageClick,
  connectedUser,
  companieId,
  handleEditTopic,
  handleDelete,
  handleRunTopic,
  getLanguageName,
  getLanguageName1,
  getColorByLevel,
}) => {
  const [filters, setFilters] = useState({
    name: "",
    language: "",
    level: "",
    timer: "",
    compiler: "",
  });

  const [currentPage, setCurrentPage] = useState(0); 

  const handleFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setCurrentPage(0); 
  };

  const filteredItems = items.filter((topic) => {
    return (
      (filters.name === "" ||
        topic.name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.language === "" ||
        (topic.compiler === "oneCompiler"
          ? getLanguageName1(topic.LanguageId)
          : getLanguageName(topic.LanguageId))
          .toLowerCase()
          .includes(filters.language.toLowerCase())) &&
      (filters.level === "" ||
        topic.level.toLowerCase().includes(filters.level.toLowerCase())) &&
      (filters.timer === "" || topic.Timer.toString().includes(filters.timer)) &&
      (filters.compiler === "" ||
        topic.compiler.toString().includes(filters.compiler))
    );
  });
  const [companies, setCompanies] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const dataCmp = await response.json();
        console.log("dataCmp:",dataCmp)

        setCompanies(dataCmp);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  const startIndex = currentPage * itemsPerPage;
  const paginatedItems = filteredItems.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const [showDetails, setShowDetails] = useState(false); 
  const [selectedTopic, setSelectedTopic] = useState(null); 
  const handleShowDetails = (topic) => {
    setSelectedTopic(topic);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setSelectedTopic(null);
    setShowDetails(false);
  };
    
    const getCompanyLogo = (companyId) => {
      const company = companies.find((comp) => comp.companyid === companyId);
      console.log("company:",company)
      let pathImage=`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
      return company
        ? pathImage
        : null;
    };
  return (
    <div className="table-responsive">
      <Table bordered hover className="mb-4">
        <thead style={{ backgroundColor: "#8cd6c8" }}>
          <tr>
            <th>#</th>
            <th>
              
              {lang.menu.name}</th>
            <th>{lang.menu.language}</th>
            <th>{lang.menu.level}</th>
            <th>{lang.menu.timer}</th>
            <th>{lang.menu.compiler}</th>
            <th>{lang.menu.actions}</th>
          </tr>
          <tr>
            <th></th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder={`Filter ${lang.menu.name}`}
                  value={filters.name}
                  onChange={(e) => handleFilterChange("name", e.target.value)}
                />
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder={`Filter ${lang.menu.language}`}
                  value={filters.language}
                  onChange={(e) => handleFilterChange("language", e.target.value)}
                />
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder={`Filter ${lang.menu.level}`}
                  value={filters.level}
                  onChange={(e) => handleFilterChange("level", e.target.value)}
                />
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder={`Filter ${lang.menu.timer}`}
                  value={filters.timer}
                  onChange={(e) => handleFilterChange("timer", e.target.value)}
                />
              </InputGroup>
            </th>
            <th>
              <InputGroup>
                <FormControl
                  placeholder={`Filter ${lang.menu.compiler}`}
                  value={filters.compiler}
                  onChange={(e) => handleFilterChange("compiler", e.target.value)}
                />
              </InputGroup>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedItems.map((topic, index) => (
            <tr key={topic.id}>
                        {console.log("top:",topic)}

              <td>{startIndex + index + 1}</td>
              <td>
             
                {topic.name}</td>
              <td>
                {topic.compiler === "oneCompiler"
                  ? getLanguageName1(topic.LanguageId)
                  : getLanguageName(topic.LanguageId)}
              </td>
              <td style={{ color: getColorByLevel(topic.level) }}>
                {topic.level}
              </td>
              <td> {topic.Timer >= 60
      ? `${Math.floor(topic.Timer / 60)} min`
      : ""}
    {topic.Timer % 60 > 0 || topic.Timer >= 60
      ? ` ${topic.Timer % 60} s`
      : ""}</td>
              <td>{topic.compiler}</td>
              <td>
              <div className="action-buttons ">
                            <Dropdown>
                              <Dropdown.Toggle
                                className="nav-link i-false c-pointer"
                                as="a"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={{ textAlign: "center" }}
                              ><i className="flaticon-032-ellipsis"></i></Dropdown.Toggle>
                              
                              <Dropdown.Menu className="profile-dropdown">
                              {(connectedUser.userRole === 1 || topic.companyID === companieId) && (
  <>
    {companieId === 1 ? (
      topic.creator_uid === connectedUser.localId || connectedUser.userRole === 1 ? (
        <>
          <DropdownItem onClick={() => handleEditTopic(topic)}>
            <Button
              className="btn btn-secondary light mr-2"
              title="Edit Topic"
            >
              <i className="fas fa-pencil-alt"></i>
            </Button>
            <span title="Edit">{lang.menu.edit}</span>
          </DropdownItem>
          <DropdownItem onClick={() => handleDelete(topic.id)}>
            <Button
              className="btn btn-danger light shadow btn-xs sharp me-1"
              title="Delete Topic"
            >
              <i className="fa fa-trash"></i>
            </Button>
            <span title="Delete">{lang.menu.delete}</span>
          </DropdownItem>
        </>
      ) : null
    ) : (
      <>
        <DropdownItem onClick={() => handleEditTopic(topic)}>
          <Button
            className="btn btn-secondary light mr-2"
            title="Edit Topic"
          >
            <i className="fas fa-pencil-alt"></i>
          </Button>
          <span title="Edit">{lang.menu.edit}</span>
        </DropdownItem>
        <DropdownItem onClick={() => handleDelete(topic.id)}>
          <Button
            className="btn btn-danger light shadow btn-xs sharp me-1"
            title="Delete Topic"
          >
            <i className="fa fa-trash"></i>
          </Button>
          <span title="Delete">{lang.menu.delete}</span>
        </DropdownItem>
      </>
    )}
  </>
)}


                                <DropdownItem onClick={() => handleRunTopic(topic)}>
                                  <Button
                                    
                                    className="btn btn-primary light mr-2"
                                    title="Run Topic"
                                   
                                  >
                                    <VscRunAll size={16} /> 
                                  </Button> 
                                  <span title="Run Topic"  > {lang.menu.runTopic}</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => handleShowDetails(topic)}>
                                  <Button
                                    
                                    className="btn btn-warning light mr-2"
                                    title="Run Topic"
                                   
                                  >
                                    <FaRegEye size={16} /> 
                                  </Button> 
                                  <span title="Run Topic"  > {lang.menu.details}</span>
                                </DropdownItem>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactPaginate
        previousLabel={lang.menu.previous}
        nextLabel={lang.menu.next}
        breakLabel={"..."}
        pageCount={pageCount}
        onPageChange={handlePageChange}
        containerClassName={"pagination justify-content-end"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        activeClassName={"active"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
      />
       {
      selectedTopic &&(
        <Modal show={showDetails} onHide={handleCloseDetails} centered dialogClassName="custom-modal">
        <Modal.Header >
          <Modal.Title>
          {selectedTopic.companyID != null && getCompanyLogo(selectedTopic.companyID) ? (
        <Image
          src={getCompanyLogo(selectedTopic.companyID)}
          alt="Company Logo"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ) : (
        <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>
      )}
            {selectedTopic.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card.Text><strong className=" text-primary">{lang.menu.description}:</strong>  <div
            className="experience-display"
            dangerouslySetInnerHTML={{
              __html: selectedTopic.description.replace(/\n/g, '<br />'),
            }}
          ></div></Card.Text>
          {console.log("selectedTopic:",selectedTopic)}
          <Row>
            <Col>
            <p><strong className=" text-primary">{lang.menu.language}:</strong> {selectedTopic.compiler === "oneCompiler"
            ? getLanguageName1(selectedTopic.LanguageId)
            : getLanguageName(selectedTopic.LanguageId)}</p>
            </Col>

            <Col>
            <p><strong className=" text-primary"><SiLevelsdotfyi />{"  "}{lang.menu.level}:</strong> {selectedTopic.level}</p>

            </Col>

            <Col>
            <p><strong className=" text-primary"><TfiTimer />{"  "}{lang.menu.timer}:</strong>  {selectedTopic.Timer >= 60
      ? `${Math.floor(selectedTopic.Timer / 60)} min`
      : ""}
    {selectedTopic.Timer % 60 > 0 || selectedTopic.Timer >= 60
      ? ` ${selectedTopic.Timer % 60} s`
      : ""}</p>

            </Col>
          </Row>
          <span className="accordion-header-text">
                          <Badge
                            variant="primary badge-xl light"
                            style={{ width: "100%", textAlign: "left" }}
                          > 
                            {lang.menu.exemple} :
                          </Badge>
                        </span>
                        <br/>   <br/>   <br/>
      <Table bordered hover  className="mb-6"
  style={{ width: "50%", fontSize: "12px", margin: "0 auto" }}>
        <thead style={{ backgroundColor: "#8cd6c8" }}>
        <th scope="col">{lang.menu.input}</th>
        <th scope="col"> {lang.menu.expectedOutPut}</th>
        </thead>
        <tbody>
        <tr>
      <td>{selectedTopic.input1}</td>
        <td>{selectedTopic.expectedOutput1}
       </td>
      </tr>
        <tr>
      <td>{selectedTopic.input2}</td>
        <td>{selectedTopic.expectedOutput2}
       </td>
      </tr>
      <tr>
      <td>{selectedTopic.input3}</td>
        <td>{selectedTopic.expectedOutput3}
       </td>
      </tr>
        </tbody>
      </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDetails}>
            {lang.menu.cancel}
          </Button>
        </Modal.Footer>
      </Modal>
      )
    }

    </div>
  );
};

export default FilterableTable;

