import React, { useState, useEffect } from "react";
import { Button, Card, ListGroup, Modal, Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import translations from "../../AppsMenu/Candidate/mulitlingue";

const QuestionOfSkill = () => {
  const [questions, setQuestions] = useState([]);
  const [groupedQuestions, setGroupedQuestions] = useState({});
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [skillName, setSkillName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [lang, setLang] = useState(translations.en);
  const [activeTab, setActiveTab] = useState(""); // Active tab for levels
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [permissionSkill,  setPermissionSkill] = useState(false);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(false); 

  const token = localStorage.getItem("token");
  const idCat = window.location.pathname.split("/").pop();
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${currentUser.localId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;

    return compId;
  };
  useEffect(() => {
    const fetchData = async () => {
      const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const compId = await getUserCompanyID();
      let skillsData = null;
  
      const fetchSkillName = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getSkillNameById/${idCat}`, 
            { headers: { Authorization: `Bearer ${token}` } }
          );
          skillsData = response.data;
          setSkillName(response.data.name);
        } catch (error) {
          console.error("Error fetching skill name:", error);
        }
      };
  
      const fetchQuestions = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/FindBySkill/${idCat}`, 
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setQuestions(response.data);
  
          // Group questions by level
          const grouped = response.data.reduce((acc, question) => {
            if (!acc[question.level]) {
              acc[question.level] = [];
            }
            acc[question.level].push(question);
            return acc;
          }, {});
          setGroupedQuestions(grouped);
  
          // Set the first level as the default active tab
          if (Object.keys(grouped).length > 0) {
            setActiveTab(Object.keys(grouped)[0]);
          }
        } catch (error) {
          console.error("Error fetching questions:", error);
        }
      };
  
      // Appeler fetchSkillName et attendre qu'il termine
      await fetchSkillName();
  
      if (skillsData !== null) {
        if (
          currentUser.userRole === 1 || 
          (skillsData.companyID === compId && compId !== 1) || 
          (currentUser.localId === skillsData.creator_uid && compId === 1)
        ) {
          await fetchQuestions();
          setPermissionSkill(true);
          
        } else {
          setPermissionSkill(false);
          setShowAccessDeniedModal(true); 
        }
      }
    };
  
    fetchData();
  }, [idCat, token]);
  

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredQuestions = (questions) =>
    questions.filter(
      (q) =>
        q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.level.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const openModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const isImage = (url) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const extension = url.substring(url.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(extension);
  };
  const getImageUrlFromServer = (imageName, skillName, id) => {
    return `${process.env.REACT_APP_APP_DOMAIN}/api/techniqueQCM/images/${skillName}/${id}/${imageName}`;
  };
  const getImageUrl = (imageRef, id) => {
    if (isImage(imageRef)) {
      const imageUrl = getImageUrlFromServer(imageRef, skillName, id);
      return imageUrl;
    }
    return null;
  };
  const renderContentRef = (content, id) => {
    const imageUrl = getImageUrl(content, id);
  
    const handleImageLoad = (e) => {
    };
  
    if (imageUrl) {
      return (
        <>
        <div className="frame center">
          {/* On utilise onLoad pour contrôler la taille après le chargement */}
          <img
            src={imageUrl}
            alt=" "
            onLoad={handleImageLoad}
            style={{ cursor: "pointer",maxWidth: "100%", maxHeight: "100%" }}
            onClick={() => openModal(imageUrl)}          />
        </div>
        <Modal show={showModal} onHide={closeModal} centered size="l">
          <Modal.Body className="p-0">
            <img
              src={modalImageUrl}
              alt=" "
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Modal.Body>
        </Modal>
        </>
      );
    } else {
      return <span style={{ fontSize: "14px" }}>{content}</span>;
    }
  };
  
  const renderQuestion = (question) => (
    <Card key={question.id} className="mb-4 shadow-sm border-0">
      <Card.Body>
        <Card.Title>{question.question}</Card.Title>
        {question.imageRef && renderContentRef(question.imageRef, question.id)}
        <ListGroup variant="flush" className="mt-3">
          {[question.choix1, question.choix2, question.choix3, question.choix4].map((choice, index) => {
            const isBonneReponse = question.BonneReponse === `choix${index + 1}`; 
            return (
              choice && (
                <ListGroup.Item
                  key={index}
                  className={`d-flex align-items-center ${isBonneReponse ? "bg-success text-white" : ""}`}
                >
                  <Form.Check 
                    type="radio" 
                    id={`question-${question.id}-choix-${index + 1}`} 
                    name={`question-${question.id}`} 
                    checked={isBonneReponse} 
                    readOnly 
                    className="me-2" 
                  />
                  {choice}
                </ListGroup.Item>
              )
            );
          })}
        </ListGroup>
       
      </Card.Body>
    </Card>
  );
  

  return (
    <div style={{ fontFamily: "Poppins" }}>
      {permissionSkill ? (
        <>
      <Row>
        <h2 className="fs-20 font-w800">{skillName}</h2>
      </Row>
      <Row>
        <Form.Control
          type="text"
          placeholder={lang.menu.serachQuestion}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Row>
      <Tabs
        id="questions-tabs"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mt-4"
      >
        {Object.keys(groupedQuestions).map((level) => (
          <Tab eventKey={level} title={level} key={level}>
            {filteredQuestions(groupedQuestions[level]).map(renderQuestion)}
          </Tab>
        ))}
      </Tabs>

      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body>
          <img
            src={modalImageUrl}
            alt="Question"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
      </Modal>


      </>
       ) : (

        <Modal show={showAccessDeniedModal} >
                    <Modal.Header>
                      <Modal.Title> {lang.menu.accessDenied}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {lang.menu.YoudontHavePerm}
                    </Modal.Body>
                    <Modal.Footer>
                
                    </Modal.Footer>
                  </Modal> 
       )}
    </div>
  );
};

export default QuestionOfSkill;
