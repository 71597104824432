import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, TablePagination, Box, Grid, Card, CardContent, TextField
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { deepPurple, indigo } from "@mui/material/colors";
import translations from "../../AppsMenu/Candidate/mulitlingue";
import { InputAdornment,IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Pie } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";


const theme = createTheme({
  palette: {
    primary: { main: indigo[500] },
    secondary: { main: deepPurple[300] },
  },
  typography: { fontFamily: "Roboto, Arial, sans-serif" },
});

const SkillsDashboard = () => {
  const [skillsData, setSkillsData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("token");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [compId , setCompId] = useState("");

  const [currentUser, setCurrentUser] = useState("");

const handleChartClick = () => {
  setIsFullScreen(true);
};
const handleClose = () => {
  setIsFullScreen(false);
};

  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    return storedLang && JSON.parse(storedLang).language === "Français"
      ? translations.fr
      : translations.en;
  });

  const filteredSkills = skillsData.filter((skill) =>
    skill.skillName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${currentUser.localId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;

    return compId;
  };
  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    setCurrentUser(currentUser)
        const compId = await getUserCompanyID();
setCompId(compId)
        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/skill/getSkillsSummary`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setSkillsData(response.data);
        prepareChartData(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    };
    fetchSkillsData();
  }, []);

  const prepareChartData = (data) => {
    const labels = data.map((skill) => skill.skillName);
    const beginner = data.map((skill) => skill.beginnerQuestions);
    const intermediate = data.map((skill) => skill.intermediateQuestions);
    const advanced = data.map((skill) => skill.advancedQuestions);

    setChartData({
      labels,
      datasets: [
        { label: lang.menu.Beginner, data: beginner, backgroundColor: "rgba(75, 192, 192, 0.6)" },
        { label: lang.menu.Intermediate, data: intermediate, backgroundColor: "rgba(153, 102, 255, 0.6)" },
        { label: lang.menu.Advanced, data: advanced, backgroundColor: "rgba(255, 99, 132, 0.6)" },
      ],
    });
  };
  const preparePieChartData = (data) => {
    console.log("data :", data);
  
    // Calcul des totaux pour chaque niveau de compétence en additionnant les questions de toutes les compétences
    const totalBeginner = data.reduce((sum, skill) => sum + (parseInt(skill.beginnerQuestions) || 0), 0);
    const totalIntermediate = data.reduce((sum, skill) => sum + (parseInt(skill.intermediateQuestions) || 0), 0);
    const totalAdvanced = data.reduce((sum, skill) => sum + (parseInt(skill.advancedQuestions) || 0), 0);
  
    console.log("totalBeginner :", totalBeginner, "totalIntermediate :", totalIntermediate, "totalAdvanced :", totalAdvanced);
  
    // Retourner les données sous forme d'objet pour le graphique circulaire
    return {
      labels: [lang.menu.Beginner, lang.menu.Intermediate, lang.menu.Advanced],
      datasets: [
        {
          data: [totalBeginner, totalIntermediate, totalAdvanced],  // Utilisation des totaux calculés
          backgroundColor: ["#4bc0c0", "#9966ff", "#ff6384"],  // Couleurs pour chaque secteur
          hoverBackgroundColor: ["#4bc0c0", "#9966ff", "#ff6384"],
        },
      ],
    };
  };
  
  const prepareLineChartData = (data) => ({
    labels: data.map((skill) => skill.skillName),
    datasets: [
      {
        label: lang.menu.Beginner,
        data: data.map((skill) => skill.beginnerQuestions),
        borderColor: "#4bc0c0",
        fill: false,
        tension: 0.4,
      },
      {
        label: lang.menu.Intermediate,
        data: data.map((skill) => skill.intermediateQuestions),
        borderColor: "#9966ff",
        fill: false,
        tension: 0.4,
      },
      {
        label: lang.menu.Advanced,
        data: data.map((skill) => skill.advancedQuestions),
        borderColor: "#ff6384",
        fill: false,
        tension: 0.4,
      },
    ],
  });
  
  const lineChartData = prepareLineChartData(skillsData);
  const pieChartData = preparePieChartData(skillsData);

  const displayedSkills = filteredSkills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Card>
      <Box sx={{ padding: "20px" }}>
      <div className="d-flex align-items-center mb-4 flex-wrap">
      <h4 className="fs-20 font-w600 me-auto"> {lang.menu.skillDash}</h4>
      </div>
        {/* Résumé des statistiques */}
        <Grid container spacing={3} sx={{ mb: 3, justifyContent: "center", alignItems: "center" }}>
  <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ boxShadow: 3, textAlign: "center", p: 2 }}>
      <Typography variant="h6">{lang.menu.totalSkills}</Typography>
      <Typography variant="h4">{skillsData.length}</Typography>
    </Card>
  </Grid>
  <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ boxShadow: 3, textAlign: "center", p: 2 }}>
      <Typography variant="h6">{lang.menu.totalQuestion}</Typography>
      <Typography variant="h4">
        {skillsData.reduce((sum, skill) => sum + skill.totalQuestions, 0)}
      </Typography>
    </Card>
  </Grid>
</Grid>
{/* Diagrammes côte à côte */}
<Grid container spacing={3} sx={{ mt: 5 }}>
<Grid item xs={12} md={6}>
          {chartData.labels && (
            <Box
              sx={{  width: "100%", height: "100%", margin: "0 auto" , cursor: "pointer" }}
              onClick={handleChartClick} 
            >
            <Bar
  data={chartData}
  options={{
    plugins: {
      legend: { position: "top" },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        title: { display: true, text: lang.menu.skills  },
        ticks: {
          maxRotation: 90,
          minRotation: 0,
        },
        grid: { display: false },
        min: 0,
        max: 25,
      },
      y: {
        stacked: true,
        title: { display: true, text: lang.menu.totalQuestions },
      },
    },
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      enabled: true,
      mode: "x",
    },
  }}
/>

            </Box>
          )}
        </Grid>
  <Grid item xs={12} md={6}>
    {pieChartData.labels && (
      <Box sx={{ width: "100%", height: "100%", margin: "0 auto" }}>
       <Pie
  data={pieChartData}
/>

      </Box>
    )}
  </Grid>
</Grid>

        {/* Barre de recherche */}
        <TextField
          label={lang.menu.searchSkill}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {/* Tableau des compétences */}
        <TableContainer component={Paper} sx={{ boxShadow: 3, mb: 4 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#8cd6c8" }}>
              <TableRow>
                <TableCell><b>{lang.menu.skill}</b></TableCell>
                <TableCell align="right"><b>{lang.menu.totalQuestion}</b></TableCell>
                <TableCell align="right"><b>{lang.menu.Beginner}</b></TableCell>
                <TableCell align="right"><b>{lang.menu.Intermediate}</b></TableCell>
                <TableCell align="right"><b>{lang.menu.Advanced}</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {displayedSkills.map((skill, index) => (
  <TableRow key={index} hover>
    <TableCell>
      {((currentUser.userRole===1)||(skill.companyID === compId && compId !== 1) || 
        (currentUser.localId === skill.creator_uid && compId === 1)) ? (
          <Link to={`/QuestionOfSkill/${skill.id}`}>
            {skill.skillName}
          </Link>
        ) : (
          skill.skillName
        )}
    </TableCell>
    <TableCell align="right">
      {((currentUser.userRole===1)||(skill.companyID === compId && compId !== 1) || 
        (currentUser.localId === skill.creator_uid && compId === 1)) ? (
          <Link to={`/QuestionOfSkill/${skill.id}`}>
            {skill.totalQuestions}
          </Link>
        ) : (
          skill.totalQuestions
        )}
    </TableCell>
    <TableCell align="right">
      {((currentUser.userRole===1)||(skill.companyID === compId && compId !== 1) || 
        (currentUser.localId === skill.creator_uid && compId === 1)) ? (
          <Link to={`/QuestionOfSkill/${skill.id}`}>
            {skill.beginnerQuestions}
          </Link>
        ) : (
          skill.beginnerQuestions
        )}
    </TableCell>
    <TableCell align="right">
      {((currentUser.userRole===1)||(skill.companyID === compId && compId !== 1) || 
        (currentUser.localId === skill.creator_uid && compId === 1)) ? (
          <Link to={`/QuestionOfSkill/${skill.id}`}>
            {skill.intermediateQuestions}
          </Link>
        ) : (
          skill.intermediateQuestions
        )}
    </TableCell>
    <TableCell align="right">
      {((currentUser.userRole===1)||(skill.companyID === compId && compId !== 1) || 
        (currentUser.localId === skill.creator_uid && compId === 1)) ? (
          <Link to={`/QuestionOfSkill/${skill.id}`}>
            {skill.advancedQuestions}
          </Link>
        ) : (
          skill.advancedQuestions
        )}
    </TableCell>
  </TableRow>
))}

            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredSkills.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />


{/* Ligne Chart */}
{/* <Box sx={{ mt: 4, width: "100%", height: "100%" }}>
  <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: true }} />
</Box> */}
    <Dialog open={isFullScreen} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>
          
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", height: "500px" }}>
            <Bar
              data={chartData}
              options={{
                plugins: { legend: { position: "top" } },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  x: { stacked: true, title: { display: true, text: lang.menu.skills  } },
                  y: { stacked: true, title: { display: true, text: lang.menu.totalQuestions } },
                },
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      </Box></Card>
    </ThemeProvider>
  );
};

export default SkillsDashboard;
