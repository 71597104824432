import React, { useState } from "react";
import "./PolitiqueConfidentialite.css";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { Link } from "react-router-dom";

const PolitiqueConfidentialiteContent = () => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  return (
    <div className="politique-confidentialite-container">
      <h1>{lang.menu.politiqueConfidentialite}</h1>

      <p>{lang.menu.engagementConfidentialite}</p>

      <h2>{lang.menu.collecteInformations}</h2>
      <p>{lang.menu.collecteDetails}</p>
      <ul>
        <li>{lang.menu.informationInscription}</li>
        <li>{lang.menu.informationCV}</li>
        <li>{lang.menu.informationTests}</li>
        <li>{lang.menu.donneesNavigation}</li>
      </ul>

      <h2>{lang.menu.utilisationInformations}</h2>
      <p>{lang.menu.utilisationDetails}</p>
      <ul>
        <li>{lang.menu.gestionComptes}</li>
        <li>{lang.menu.associationCompetences}</li>
        <li>{lang.menu.facilitationRecrutement}</li>
        <li>{lang.menu.personnalisationExperience}</li>
        <li>{lang.menu.ameliorationServices}</li>
      </ul>

      <h2>{lang.menu.protectionInformations}</h2>
      <p>{lang.menu.protectionDetails}</p>

      <h2>{lang.menu.partageInformations}</h2>
      <p>{lang.menu.partageDetails}</p>
      <ul>
        <li>{lang.menu.recruteursPartage}</li>
        <li>{lang.menu.prestatairesServices}</li>
        <li>{lang.menu.autoritesCompetentes}</li>
      </ul>

      <h2>{lang.menu.droitsUtilisateur}</h2>
      <p>{lang.menu.droitsDetails}</p>
      <ul>
        <li>{lang.menu.droitAcces}</li>
        <li>{lang.menu.droitRectification}</li>
        <li>{lang.menu.droitSuppression}</li>
        <li>{lang.menu.droitOpposition}</li>
      </ul>
      <p>{lang.menu.contactExerciceDroits}</p>

      <h2>{lang.menu.cookiesSuivi}</h2>
      <p>{lang.menu.cookiesSuiviDetails}  <Link to="/PolitiqueCookies">{lang.menu.politiqueCookies}</Link></p>

      <h2>{lang.menu.modificationsPolitique}</h2>
      <p>{lang.menu.modificationsDetails}</p>

      <h2>{lang.menu.contact}</h2>
      <p>{lang.menu.contactDetails}</p>
      <ul>
        <li>{lang.menu.contactEmail} <strong>contact@hirecue.com</strong></li>
        <li>{lang.menu.contactPhone} <strong>0021646516905 /0033689393094 </strong></li>
        <li>{lang.menu.contactAddress} <strong>14 D, Rue Emir Abdelkader, Tunis 1002, Tunisie</strong></li>
      </ul>

      <p>{lang.menu.lastUpdated} : 12-11-2024</p>
    </div>
  );
};

export default PolitiqueConfidentialiteContent;
