import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Card,Modal,Form,Button } from "react-bootstrap";
import { Link ,useHistory} from "react-router-dom";
import translations from"../../AppsMenu/Candidate/mulitlingue";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { MdOutlineAccessTime } from "react-icons/md";
import { TbMessageQuestion } from "react-icons/tb";
import { MdPreview } from "react-icons/md";

function TechQCMList() {
  const history = useHistory();

  const [listTechPer, setListTechPer] = useState([]);
  const token=localStorage.getItem("token");
  const currentUser1 = JSON.parse(localStorage.getItem("userDetails"));
  const userId1 = currentUser1.localId;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = process.env.REACT_APP_PAGINATION ;
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setListTechPer(
            data.some((permission) => permission.title === "list-tech-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!listTechPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [listTechPer]);
  const [tests, setTests] = useState([]);
  const [deletetest,setDeletetest] = useState([]);
  const [companyId,setCompanyId] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeletetest(
            data.some((permission) => permission.title === "delete-tech-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [createTest,setCreateTest] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setCreateTest(
            data.some((permission) => permission.title === "create-test")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [lang, setLang] = useState(() => {
		const storedLang = localStorage.getItem('selectedLang');
		if (storedLang) {
		  const parsedLang = JSON.parse(storedLang);
		  if (parsedLang.language === 'Français') {
			return translations.fr;
		  }
		}
		return translations.en;
	  });
    useEffect(() => {
  
      const selectedLang = JSON.parse(localStorage.getItem('selectedLang'));
      if (selectedLang) {
        setLang(selectedLang);
      }
    }, []);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
      const fetchCompanies = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const dataCmp = await response.json();
          setCompanies(dataCmp);
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      };
      fetchCompanies();
    }, []);
    const getCompanyLogo = (companyId) => {
      const company = companies.find((comp) => comp.companyid === companyId);
      let pathImage=`${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
      return company
        ? pathImage
        : null;
    };
    useEffect(async() => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
     /*  const responseAdmins = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/superAdmins`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
          console.log("responseAdmins:",responseAdmins.data) */
      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        const compId = data[0].companyID;
        return compId;
      };
    
      getUserCompanyID().then((compId) => {
        setCompanyId(compId)
        axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getAllTestsByComp/${compId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }})
          .then(response => {
            console.log("tests:",response.data)
            setTests(response.data);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des tests:', error);
          });
      });
    }, []);
  const handleDelete = (testId) => {
    swal({
      title:lang.menu.deleteTitle ,
      text: lang.menu.deleteText,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
    if (testId) {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios.delete(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/deleteTest/${testId}/${userId}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
        .then(response => {
          if(response.data.message ==="Impossible de supprimer ce test car il a été utilisé."){
            swal(lang.menu.suppImpo, {
              icon: "error",
            });
          }else{
            swal(lang.menu.deleteSuccessTest, {
              icon: "success",
            });
          setTests(prevTests => prevTests.filter(test => test.test_id !== testId));
        }

        })
        .catch(error => {
          console.error(error); 
        });
    }
  }else {
    // User canceled the delete action
    swal(lang.menu.testDeletMsgCancel, {
      icon: "info",
    });
  }
});
  };
  const handleIconClick = (timePerQuestion,test_id,nbre_question_globale) => {
    history.push(`/Test-QCM-For-Recruter/${test_id}`);

    localStorage.setItem('questionsNbr', nbre_question_globale);
    localStorage.setItem('timePerQuestion',timePerQuestion);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const filterTestByTitle = () => {
    return tests.filter(test => test.test_name.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
   // Gestion de la pagination
   const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTests = filterTestByTitle().slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filterTestByTitle().length / itemsPerPage);

  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");
    
    deleteButtons.forEach(deleteButton => {
      deleteButton.addEventListener('click', () => handleDelete(deleteButton.dataset.testId));
    });
    
    return () => {
      deleteButtons.forEach(deleteButton => {
        deleteButton.removeEventListener('click', () => handleDelete(deleteButton.dataset.testId));
      });
    };
  }, []);
  
  
  const renderTests = () => {
    const groupedTests = currentTests.reduce((result, test) => {
      const { test_id, test_name, test_description, skill_id, companyID, nbre_question_globale, timePerQuestion, creator_uid, creator_role } = test;
      if (!result[test_id]) {
        result[test_id] = {
          test_id,
          test_name,
          test_description,
          skills: [],
          companyID,
          timePerQuestion,
          nbre_question_globale,
          creator_uid
        };
      }
      result[test_id].skills.push({
        id: skill_id,
        test_name,
      });
      return result;
    }, {});
  
    return (
      <div>
      <div className="tests-grid" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      
        {Object.values(groupedTests).map((group) => {
          const { test_id, test_name, test_description, nbre_question_globale, timePerQuestion, creator_uid, companyID, creator_role } = group;
          const isDisabled = companyId !== 1
            ? companyID !== companyId && currentUser1.userRole !== 1
            : creator_uid !== currentUser1.localId && currentUser1.userRole !== 1;
          const temps_global_de_test = nbre_question_globale * timePerQuestion;
          const minutes = Math.floor(temps_global_de_test / 60); 
          const secondes = temps_global_de_test % 60; 
          const handleEditClick = (e) => {
            if (isDisabled) {
              e.preventDefault();
              swal(lang.menu.suppImpo, {
                icon: "error",
                title: lang.menu.accessDenied,
                text: lang.menu.updatePermission,
                confirmButtonText: 'OK'
              });
            }
          };
  
          return (
            <Card key={test_id} style={{ width: 'calc(50% - 10px)', fontFamily: "Poppins" }}>
              <Card.Header>
                
                <Card.Title>{test_name}</Card.Title>
                {getCompanyLogo(companyID) ? (
                <img
                  src={getCompanyLogo(companyID)}
                  alt="Company Logo"
                  style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                />
              ) : (
                <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>
              )}
              </Card.Header>
              <Card.Body>
                <Card.Text>{test_description}</Card.Text>
  
                {/* Affichage des badges */}
                <div className="badge-container" style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                  <span className="badge" style={{ background: '#43DAC2', padding: '5px 10px', borderRadius: '12px', fontSize: '0.9em' }}>
                  <MdOutlineAccessTime /> {" "}   {minutes > 0 && `${minutes} min `}
                  {secondes > 0 && `${secondes} s`}
                  </span>
                  <span className="badge" style={{ background: '#43DAC2', padding: '5px 10px', borderRadius: '12px', fontSize: '0.9em' }}>
                  <TbMessageQuestion /> {" "} 
                  {`${nbre_question_globale} questions`}
                  </span>
                </div>

                <br/>
              </Card.Body>
  
              <Card.Footer>
              <br/>
                <div className="test-card-icons">
                  <Link to={`/TechQCMDetails/${test_id}`}>
                    <i className="fas fa-eye test-details-icon fa-lg"></i>
                  </Link>
                  <Link
                    to={isDisabled ? '#' : `/UpdateTestTechniques/${test_id}`}
                    className={isDisabled ? 'link-disabled' : ''}
                    onClick={handleEditClick}
                  >
                    <i className="fas fa-edit fa-lg"></i>
                  </Link>
                  <Link to={`/Test-QCM-For-Recruter/${test_id}`}>
                      <MdPreview 
                                      size={25}
                                   onClick={() => handleIconClick(timePerQuestion, test_id, nbre_question_globale)}
                  />
              
                  </Link>
  
                  {deletetest ? (
                    <button
                      style={{ border: "none" }}
                      onClick={() => handleDelete(test_id)}
                      disabled={group.creator_uid !== currentUser1.localId && currentUser1.userRole !== 1}
                    >
                      <i className="fas fa-trash-alt fa-lg"></i>
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </Card.Footer>
            </Card>
          );
        })}
        </div>
       <div className="d-flex justify-content-end align-items-center mt-4">
  <Button
    variant="primary"
    disabled={currentPage === 1}
    onClick={() => handlePageChange(currentPage - 1)}
  >
    {lang.menu.previous}
  </Button>
  <span className="mx-3">
    Page {currentPage} {lang.menu.sur} {totalPages}
  </span>
  <Button
    variant="primary"
    disabled={currentPage === totalPages}
    onClick={() => handlePageChange(currentPage + 1)}
  >
    {lang.menu.next}
  </Button>
</div>

      </div>

    );
  };
  
  

  return (
    
    <> 
    
    {listTechPer ? (  
    <div>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto"><strong>{filterTestByTitle().length}</strong> {lang.menu.testsAvailable}</h4>
        { createTest ? (  <Link to="/TechQCM" className="btn btn-primary">
          <i className="fas fa-plus me-2"></i>{lang.menu.newTest}
        </Link>) : <></>}
      </div>
      <div>
      <Form.Group controlId="formBasicSearch">
        <Form.Control
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Form.Group>
      <br/>
      
        {renderTests()}</div>
    </div>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default TechQCMList;
