import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import {
    Col, Row, Dropdown, Card, Table, Button,Modal,FormControl
} from "react-bootstrap";
import translations from "../AppsMenu/Candidate/mulitlingue";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

const RadarPsychoComponent = ({ candidateId, jobRoleId }) => {
  const [psychoResultPer, setPsychoResultPer] = useState([]);
  const token=localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setPsychoResultPer(
            data.some(
              (permission) => permission.title === "result-psycho-tests"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!psychoResultPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [psychoResultPer]);
    const [candidates, setCandidates] = useState([]);
    const [selectedCandidate, setSelectedCandidate] = useState('');
    const [selectedJobRole, setSelectedJobRole] = useState('');
    const [radarPsycho, setRadarPsycho] = useState([]);
    const [jobRoles, setJobRoles] = useState([]);
    const [radarData, setRadarData] = useState(null);
    const [questionNbre, setQuestionNbre] = useState(null);
    const [categoryCorrectCounts, setCategoryCorrectCounts] = useState({});
    const [selectedLang, setSelectedLang] = useState(null);
    const [filteredJobRoles, setFilteredJobRoles] = useState([]);
    const [jobTitle, setJobTitle] = useState('');
    const [candSelected, setCandSelected] = useState(false);
    const [jobsCand, setJobsCand] = useState([]);
    const [selectionMade, setSelectionMade] = useState(false);

    const getUserCompanyID = async () => {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
        const data = await responseC.json();
        const compId = data[0].companyID;
        return compId;
      };
    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
            const parsedLang = JSON.parse(storedLang);
            if (parsedLang.language === 'Français') {
                return translations.fr;
            }
        }
        return translations.en;
    });

    useEffect(() => {
      const fetchCandidates = async () => {
        try {
          const response2 = await axios.get(
            `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findCandidatesWhoInvitedToTest`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("candidate:", response2.data);
    
          // Remove duplicates based on 'uid'
          const uniqueCandidates = response2.data.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.uid === value.uid)
          );
    
          setCandidates(uniqueCandidates); // Update the state with unique candidates
          console.log("only invited users:", uniqueCandidates);
        } catch (error) {
          console.log('Erreur lors de la récupération des candidats :', error);
        }
      };
    
      fetchCandidates();
    }, []);
    

    useEffect(() => {
        const fetchJobRoles = async () => {
            try {
                const compId = await getUserCompanyID();

                const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }});
                if (compId!=1) {
                    const filteredTests = response.data.filter(job => job.companyid=== compId  || job.companyid === null);
                    setJobRoles(filteredTests);
                 } else {
                    setJobRoles(response.data);
                 }              
                } catch (error) {
                console.log('Erreur lors de la récupération des jobroles :', error);
            }
        };

        fetchJobRoles();
    }, []);

    const [email, setEmail] = useState('');
  
    useEffect(async() => {
        // Analyse l'URL pour extraire les paramètres email et jobTitle
      if(candidates.length!==0)
       {
        const urlParams = new URLSearchParams(location.search);
        const emailParam = urlParams.get('email');
        const jobTitleParam = urlParams.get('jobTitle');
      
        if (emailParam) {
            setEmail(emailParam);
            setSearchTerm(emailParam)
            setCandSelected(false)
          const selectedCandidateObj = candidates.find(candidate => candidate.email === emailParam);
  console.log("selectedCandidateObj:",selectedCandidateObj)
          setSelectedCandidate(selectedCandidateObj);
          const jobs= await getJobsForCandidate(selectedCandidateObj.uid)
          setJobsCand(jobs)
          const filteredRoles = jobRoles.filter((role) => jobs.includes(role.id));
          setFilteredJobRoles(filteredRoles);
          setCandSelected(true);
          setSelectionMade(false); 
           
        }
        if(jobRoles.length!==0)
          {
        if (jobTitleParam) {
            setJobTitle(jobTitleParam);
            setSearchTermJob(jobTitleParam)
           /*  setSelectedJobRole(jobTitleParam);
          const jobTitle=await getJobName(jobTitleParam)
          setJobTitle(jobTitle);
          setSelectionMade(true);  */
        }}
       }
    }, [location,jobRoles,candidates]);
    const getJobsForCandidate = async (candidateId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobroleByCandidate/${candidateId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const jobs = response.data.map(job => job.id_jobrole);
          return jobs;
        } catch (error) {
          console.error(`Error fetching jobs for ID ${candidateId}:`, error);
          return "";
        }
      };

    const handleCandidateSelect = async(eventKey) => {
        setCandSelected(false)
        const selectedCandidateObj = candidates.find(candidate => candidate.email === eventKey);

        setSelectedCandidate(selectedCandidateObj);
        const jobs= await getJobsForCandidate(selectedCandidateObj.uid)
        setJobsCand(jobs)
        const filteredRoles = jobRoles.filter((role) => jobs.includes(role.id));
        setFilteredJobRoles(filteredRoles);
        setCandSelected(true);
        setSelectionMade(false); 
      };
      const getJobName = async (jobId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${jobId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const jobName = response.data.JobTitle;
          console.log(jobName)

          console.log(jobName.JobTitle)
          return jobName;
        } catch (error) {
          console.error(`Error fetching job name for ID ${jobId}:`, error);
          return "Unknown Job";
        }
      };
      const handleJobRoleSelect = async(eventKey) => {
        setSelectedJobRole(eventKey);
        console.log("event:",eventKey)
        const jobTitle=await getJobName(eventKey)
        setJobTitle(jobTitle);
        setSelectionMade(true); 
      };
    const [testsData, setTestsData] = useState([]);

    useEffect(() => {

      fetchTests();
    }, []);
    const fetchTests = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getAllTests`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
  
        setTestsData(response.data);
      } catch (error) {
        console.log('Error fetching categories:', error);
      }
    };
    
    const getTestName = (testId) => {
      testId = parseInt(testId);
  
      const test = testsData.find((cat) => cat.test_id === testId);
      return test ? test.test_name : 'N/A';
    };
    

    const fetchQuestionNbre = async (jobRoleId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/questionNbreByTestPsycho/${jobRoleId}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
                console.log("response.dat:",response.data)
            return response.data.testTechCounts.questionNbre; 
        } catch (error) {
            console.error('Error fetching questionNbre:', error);
            return null;
        }
    };

    const radarOptions = {
        scales: {
            r: {
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                }, max:questionNbre,
            },
        },
    };

    const handleFetchRadarPsycho = async () => {
        if (selectedCandidate && selectedJobRole) {
            try {
                const questionNbreValue = await fetchQuestionNbre(selectedJobRole);
                
                if (questionNbreValue !== null) {
                    setQuestionNbre(questionNbreValue);
                  
                } else {
                  swal("Error fetching questionNbre.", {
                    icon: "error",
                  });
                    return;
                }
                axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getRadarPsycho/${selectedCandidate.uid}/${selectedJobRole}`, 
                  {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    }})
                    .then(response => {
                        const radarResult = JSON.parse(response.data.radarPsycho);

                        if (radarResult === null) {
                          swal("This test has not been taken yet.", {
                            icon: "info",
                          });
                            return;
                        }

                        setCategoryCorrectCounts(radarResult);
                        setRadarPsycho(radarResult);
                        
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 404) {
                          swal("Test not found for Candidate and JobRole.", {
                            icon: "error",
                          });
                        } else {
                          swal("An error occurred while fetching Test.", {
                            icon: "error",
                          });
                        }
                    });
            } catch (error) {
              swal("Error fetching questionNbre.", {
                icon: "error",
              });
            }
        }
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermJob, setSearchTermJob] = useState('');

    const filteredCandidates = candidates.filter((candidate) =>
      candidate.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const filteredRoles = filteredJobRoles.filter((jobRole) =>
      jobRole.JobTitle.toLowerCase().includes(searchTermJob.toLowerCase())
    );
    return (
      <> {psychoResultPer ? (  
        <div >
            <h2>{lang.menu.selJobCandidat}</h2>
            <Row>
                <Col xl={5}>
                <Dropdown onSelect={handleCandidateSelect}>
      <Dropdown.Toggle variant="primary light" id="dropdown-candidate" style={{ width: '80%' }}>
        <label htmlFor="dropdown-candidate"></label>
        {selectedCandidate ? selectedCandidate.email : lang.menu.selectCandidate}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
        <FormControl
          type="text"
          placeholder={lang.menu.search}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ margin: '10px 10px 10px 0', borderRadius: '5px' }}
        />
        
        {filteredCandidates.length > 0 ? (
          filteredCandidates.map((candidate) => (
            <Dropdown.Item key={candidate.uid} eventKey={candidate.email}>
              {candidate.email}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>
            {lang.menu.noCandidate}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>

     
    </Dropdown>
                </Col>
                <Col xl={5}>
                <Dropdown onSelect={handleJobRoleSelect}>
      <Dropdown.Toggle 
        variant="primary light" 
        id="dropdown-jobrole" 
        style={{ width: '80%' }} 
        disabled={!candSelected}
      >
        <label htmlFor="dropdown-jobrole"></label>
        {selectedJobRole ? jobTitle : lang.menu.selectJobRole}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
      
        <FormControl
          type="text"
          placeholder={lang.menu.serachJob}
          value={searchTermJob}
          onChange={(e) => setSearchTermJob(e.target.value)}
          style={{ margin: '10px 10px 10px 0', borderRadius: '5px' }}
        />
        
        {filteredRoles.length > 0 ? (
          filteredRoles.map((jobRole) => (
            <Dropdown.Item key={jobRole.id} eventKey={jobRole.id}>
              {jobRole.JobTitle}
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>
            {lang.menu.noJobRolesForCandidate}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>

    
    </Dropdown>
                </Col>
                <Col xl={2}>
                    <Button className='btn-outline-primary btn-rounded'    style={{ border: "none"}} onClick={handleFetchRadarPsycho}>{lang.menu.submit}</Button>
                </Col>
            </Row>
            <ToastContainer />
            <br />
            <div>
            {radarPsycho.map((testData, index) => (
  <div key={index} className="test-container">
    <div className="table-container">
  
      <Card>
      <h3>{getTestName(testData.idtest)}</h3>
      <div className="row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <Table>
          <thead>
            <tr>
              <th>{lang.menu.Categorie}</th>
              <th>{lang.menu.value}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(JSON.parse(testData.radarPsycho)).map(([categoryName, value]) => (
              <tr key={categoryName}>
                <td>{categoryName}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
    <div className="col-lg-6 col-md-6 col-sm-12">
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <Radar
        data={{
          labels: Object.keys(JSON.parse(testData.radarPsycho)),
          datasets: [
            {
              data: Object.values(JSON.parse(testData.radarPsycho)),
              label: 'Valeurs du radar',
              borderColor: 'rgba(75,192,192,1)',
              backgroundColor: 'rgba(75,192,192,0.2)',
            },
          ],
        }}
        options={radarOptions} 
      /></div>
    </div>
  </div>
 </Card>
 </div></div>
))}



            </div>
        </div> ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
    );
};

export default RadarPsychoComponent;
