import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import translations from "../AppsMenu/Candidate/mulitlingue";
import PageTitle from "../../layouts/PageTitle";
import { AiOutlineSend } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Dropdown,
  ProgressBar,
  Modal,
} from "react-bootstrap";
import swal from "sweetalert";

function ExcelTable() {
  
  const [importPsychoPer, setImportPsychoPer] = useState([]);
  const token=localStorage.getItem("token");

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setImportPsychoPer(
            data.some(
              (permission) => permission.title === "import-questions-testPsycho"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!importPsychoPer) {
      setShowAccessDeniedModal(true); 
    }
  }, [importPsychoPer]);
  const [data, setData] = useState([]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteAll, setDeleteAll] = useState([]);
  const downloadPsycho=localStorage.getItem('downloadPsycho');
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setDeleteAll(
            data.some(
              (permission) =>
                permission.title === "delete-all-questions-testPsycho"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [isExcelDownloaded, setIsExcelDownloaded] = useState(false);
  const [importFileName, setImportFileName] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    fetchData();
    fetchCategories();
  }, []);

  const fetchData = async () => {
    try {
      const importedFileName = localStorage.getItem("importedFileName");
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/question1/getAllQuestionsImg`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setData(response.data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/categories/getAllCategories`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      setCategoriesData(response.data);
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  const getCategoryName = (categoryId) => {
    const category = categoriesData.find((cat) => cat.id === categoryId);
    return category ? category.name : "N/A";
  };
  const handleFileUpload = (e, index, column) => {
    const file = e.target.files[0];
    console.log(file);
  };

  const sendData = async (index) => {
    const row = data[index];
    const formData = new FormData();

    formData.append("question", row.question);
    formData.append("BonneReponse", row.BonneReponse);
    formData.append("Categorie", getCategoryName(row.Categorie));
    formData.append("choix1", row.choix1);
    formData.append("choix2", row.choix2);
    formData.append("choix3", row.choix3);
    formData.append("choix4", row.choix4);

    if (row.imageRef === "img") {
      const fileInput = document.getElementById(`imageRef-${index}`);
      if (fileInput.files[0]) {
        formData.append("imageRef", fileInput.files[0]);
      }
    }
    if (row.choix1 === "img") {
      const fileInput = document.getElementById(`choix1-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix1", fileInput.files[0]);
      }
    }

    if (row.choix2 === "img") {
      const fileInput = document.getElementById(`choix2-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix2", fileInput.files[0]);
      }
    }

    if (row.choix3 === "img") {
      const fileInput = document.getElementById(`choix3-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix3", fileInput.files[0]);
      }
    }
    if (row.choix4 === "img") {
      const fileInput = document.getElementById(`choix4-${index}`);
      if (fileInput.files[0]) {
        formData.append("choix4", fileInput.files[0]);
      }
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/question1/createQuestion/${row.id}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      window.location.reload();
    } catch (error) {
      console.log("Error sending data:", error);
    }
  };

  const sendData2 = async (index) => {
    const row = data[index];
    const questionID = row.id;
    const formData = new FormData();
    formData.append("question", row.question);
    formData.append("BonneReponse", row.BonneReponse);
    formData.append("Categorie", getCategoryName(row.Categorie));
    formData.append("choix1", row.choix1);
    formData.append("choix2", row.choix2);
    formData.append("choix3", row.choix3);
    formData.append("choix4", row.choix4);

    if (row.imageRef === "img") {
      const fileInput = document.getElementById(`imageRef-${index}`);
      if (fileInput.files[0]) {
        formData.append("imageRef", fileInput.files[0]);
      }
    }
    try {
      await axios.post(
        `${process.env.REACT_APP_APP_DOMAIN}/api/question1/createQuestion2/${questionID}`,
        formData, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      window.location.reload();
    } catch (error) {
      console.log("Error sending data:", error);
    }
  };

  const truncateTable = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/question1/truncateTable`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }}
      );
      fetchData();
      swal("Table questionCat truncated successfully", {
        icon: "success",
      });
    } catch (error) {
      swal("Error truncating table:", error, {
        icon: "error",
      });
    }
  };
  const handleDelete = () => {
    truncateTable();
  };
  const [importFile, setImportFile] = useState(null);

  const handleFileSelection = (e) => {
    setImportFile(e.target.files[0]);
    setImportFileName(e.target.files[0].name);
  };
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  };
  const handleImport = async () => {
    if (importFile) {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
      const userId=currentUser.localId
      const compId =await getUserCompanyID()
        const formData = new FormData();
        formData.append("filePath", importFile);
        console.log("importFile:",importFile)
        formData.append("userId", userId);

        formData.append("compId", compId);
        const response = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/question1/questions/import-excel`,
          formData, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
				
        );
        const responseMessage = response.data;
        console.log("responseMessage:",responseMessage)

        fetchData();
        if (
          responseMessage.includes(
            "Import des questions à partir d'un fichier Excel n'a pas été effectué."
          )
        ) {
          swal(responseMessage, {
            icon: "error",
          });
        } else {
          swal(responseMessage, {
            icon: "success",
          });
          localStorage.setItem("importedFileName", importFile.name);
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_APP_DOMAIN}/api/question1/getAllQuestionsImg`,
              {}, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }}
            );
            setData(response.data);
          } catch (error) {
            console.log("Error fetching data:", error);
          }
        }
      } catch (error) {
        swal("Error Import table:", error, {
          icon: "error",
        });
      }
    } else {
      swal("Please select a file to import.", {
        icon: "error",
      });
    }
  };
  const handleFetchExcelFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APP_DOMAIN}/api/question1/download-excel-file`,
        {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`,
          }}
      );

      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      localStorage.setItem("downloadPsycho", true);

      setIsExcelDownloaded(true);

      setShowModal(true);
    } catch (error) {
      console.log("Error fetching the Excel file:", error);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <> {importPsychoPer ? (  
    <Fragment>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">{lang.menu.testPsychoTechnique} </h4>
        <div>
          <Link to={`/QuestionTable`} className="btn btn-primary">
            {lang.menu.listQuestion}
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
            <Row className="align-items-center justify-content-between">
  <Col lg="6" className="d-flex justify-content-start">
    <Button
      variant="primary"
      onClick={handleFetchExcelFile}
      className="me-3"
    >
      {lang.menu.downloadTemplate}
    </Button>
    <div>
      <input
        type="file"
        onChange={handleFileSelection}
        disabled={downloadPsycho !== "true"}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        style={{
          display: "inline-block",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "5px",
        }}
      />
    </div>
  </Col>
  <Col lg="6" className="d-flex justify-content-end">
    <Button
      className="me-3"
      variant="success"
      onClick={handleImport}
    >
      {lang.menu.importQuestion}
    </Button>
    {deleteAll && (
      <Button
        variant="danger"
        onClick={handleDelete}
      >
        {lang.menu.deleteQuestion}
      </Button>
    )}
  </Col>
</Row>

              <div className="">
                <Card>
                  <Card.Header>
                    <Card.Title>{lang.menu.questions}</Card.Title>
                  </Card.Header>
                  <Card.Body>
                  {/*<div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
             */}
              <Table responsive>
                      <thead style={{ backgroundColor: "#8cd6c8"}}>
                        <tr>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.Question}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.BonneReponse}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.Categorie}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.imageRef}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix1}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix2}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix3}</strong>
                          </th>
                          <th style={{ fontSize: "100%" }}>
                            <strong>{lang.menu.choix4}</strong>
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row, index) => (
                          <tr key={row.id}>
                            <td>{row.question}</td>
                            <td>{row.BonneReponse}</td>
                            <td>{getCategoryName(row.Categorie)}</td>
                            <td>
                              {row.imageRef === "img" ? (
                                <input
                                  type="file"
                                  id={`imageRef-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "imageRef")
                                  }
                                />
                              ) : (
                                row.imageRef
                              )}
                            </td>
                            <td>
                              {row.choix1 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix1-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix1")
                                  }
                                />
                              ) : (
                                row.choix1
                              )}
                            </td>
                            <td>
                              {row.choix2 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix2-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix2")
                                  }
                                />
                              ) : (
                                row.choix2
                              )}
                            </td>
                            <td>
                              {row.choix3 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix3-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix3")
                                  }
                                />
                              ) : (
                                row.choix3
                              )}
                            </td>
                            <td
                              style={{
                                backgroundColor: row.choix4 ? "" : "#B0C4DE",
                              }}
                            >
                              {row.choix4 === "img" ? (
                                <input
                                  type="file"
                                  id={`choix4-${index}`}
                                  onChange={(e) =>
                                    handleFileUpload(e, index, "choix4")
                                  }
                                />
                              ) : (
                                row.choix4
                              )}
                            </td>

                            <td>
                              {row.imageRef === "img" &&
                              row.choix1 === "img" &&
                              row.choix2 === "img" &&
                              row.choix3 === "img" &&
                              row.choix4 === "img" ? (
                                <button
                                  type="button"
                                  className="btn btn-submit"
                                  title="Send"
                                  onClick={() => sendData(index)}
                                >
                                  <AiOutlineSend size={16} />{" "}
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-submit"
                                  title="Send"
                                  onClick={() => sendData2(index)}
                                >
                                  <AiOutlineSend size={16} />
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/*</table>
          </div>
        </div>
        </div>*/}
        </Table>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{lang.menu.msgImport}</p>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </Fragment> ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
}

export default ExcelTable;