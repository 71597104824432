import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Table, ProgressBar, Badge, Pagination, Form, Modal } from "react-bootstrap";
import translations from "../../components/AppsMenu/Candidate/mulitlingue"

function UserLogs() {
  const [userLog, setUserLog] = useState([]);
  const token = localStorage.getItem("token");

  const [logRange, setLogRange] = useState("1month"); // New state for log range

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setUserLog(
            data.some(
              (permission) =>
                permission.title === "view-admin-dashoard"
            )
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);

  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!userLog) {
      setShowAccessDeniedModal(true); 
    }
  }, [userLog]);

  const [lang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const [userLogs, setUserLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(process.env.REACT_APP_PAGINATION);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  function convertNumericFields(users) {
    const convertedUsers = users.map((user) => {
      const newUser = { ...user };

      // Convert emailVerified
      if (newUser.emailVerified === 0) {
        newUser.emailVerified = "False";
      } else if (newUser.emailVerified === 1) {
        newUser.emailVerified = "True";
      }

      // Convert disabled
      if (newUser.disabled === 0) {
        newUser.disabled = "False";
      } else if (newUser.disabled === 1) {
        newUser.disabled = "True";
      }

      return newUser;
    });

    return convertedUsers;
  }
  useEffect(() => {
    fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        const convertedData = convertNumericFields(data);
        setUsers(convertedData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);
  const getUserEmail = async (userId) => {
    try {
      const responseC = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
      );
      const data = await responseC.json();

      if (data && data[0] && data[0].email) {
        const email = data[0].email;
        return email;
      } else {
        console.error(
          "Erreur lors de la récupération de l'email pour l'utilisateur :",
          userId
        );
        return "";
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
      return "";
    }
  };

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const userId = currentUser.localId;

    const getUserCompanyID = async () => {
      const responseC = await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
      );
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };

    const rangeMapping = {
      "1month": "1",
      "3months": "3",
      "6months": "6",
      "1year": "12",
    };
    if(users.length>0){
      axios
      .get(`${process.env.REACT_APP_APP_DOMAIN}/api/userLogs/getAllUserLogs/${rangeMapping[logRange]}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }})
      .then(async (response) => {
        console.log("response hiba :", response)
        const logsWithEmails = await Promise.all(
          response.data.map(async (log) => {
            const user =users.find(user => user.uid === log.userId)
            console.log("user:", user)

            let email=user?.email|| '' 
            return { ...log, userEmail: email };
          })
        );

        setUserLogs(logsWithEmails);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des logs:", error);
        setLoading(false);
      });
    }
  
  }, [users,logRange]); // Added logRange to dependencies

  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = userLogs
    .filter((log) =>
      Object.values(log)
        .join(" ")
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .slice(indexOfFirstLog, indexOfLastLog);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function formatCalendarDate(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  }

  const mapEntityToLabel = (entity) => {
    switch (entity) {
      case 'jobRole':
        return 'Job Role';
      case 'jobProfile':
        return 'Job Profile';
      case 'testPersonality':
        return 'Test Personality';
      case 'testPsychoTechnique':
        return 'Test PsychoTechnique';
      case 'testTech':
        return 'Test Tech';
      case 'testQCMTech':
        return 'Test QCM Tech';
      case 'Test':
        return 'Test';
      case 'testCodingTech':
        return 'Test Coding Tech';
      case 'Candidate':
        return 'Candidate';
      default:
        return entity;
    }
  };

  return (
    <> {userLog ? (  
    <Card>
      <Card.Header>
        <Card.Title>User Logs</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form.Group controlId="logRangeSelect">
          <Form.Label>Select Log Range</Form.Label>
          <Form.Control
            as="select"
            value={logRange}
            onChange={(e) => setLogRange(e.target.value)}
          >
            <option value="1month">Last Month</option>
            <option value="3months">Last 3 Months</option>
            <option value="6months">Last 6 Months</option>
            <option value="1year">Last Year</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="searchForm.Control">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
        <br/>
        {loading && <ProgressBar animated now={100} label={`Loading...`} />}
        {!loading && (
          <>
            <Table responsive className="header-border">
              <thead style={{ backgroundColor: "#8cd6c8"}}>
                <tr>
                  <th>
                    <strong>User Email</strong>
                  </th>
                  <th>
                    <strong>Action Id</strong>
                  </th>
                  <th>
                    <strong>Action Name</strong>
                  </th>
                  <th>
                    <strong>Date</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                  <th>
                    <strong>Entity</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentLogs.map((log) => (
                  <tr key={log.id}>
                    <td>{log.userEmail}</td>
                    <td>{log.actionId}</td>
                    <td>{log.actionName}</td>
                    <td>{formatCalendarDate(log.date)}</td>
                    <td>
                      {log.action === "add" && (
                        <Badge variant="primary">Add</Badge>
                      )}
                      {log.action === "delete" && (
                        <Badge variant="danger">Delete</Badge>
                      )}
                      {log.action === "update" && (
                        <Badge variant="warning">Update</Badge>
                      )}
                      {log.action === "Accept" && (
                        <Badge variant="success">Accept</Badge>
                      )}
                      {log.action === "reject" && (
                        <Badge variant="dark">Reject</Badge>
                      )}
                    </td>
                    <td> {mapEntityToLabel(log.entity)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination className="justify-content-end">
  <Pagination.Prev
    onClick={() =>
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage))
    }
  />
  {currentPage > 1 && <Pagination.Item onClick={() => paginate(1)}>1</Pagination.Item>}
  {currentPage > 3 && <Pagination.Ellipsis />}
  {Array.from({ length: 5 })
    .map((_, index) => {
      const pageNumber = currentPage - 2 + index;
      if (pageNumber > 1 && pageNumber < Math.ceil(userLogs.length / logsPerPage)) {
        return (
          <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => paginate(pageNumber)}
          >
            {pageNumber}
          </Pagination.Item>
        );
      }
      return null;
    })
    .filter(Boolean)}
  {currentPage < Math.ceil(userLogs.length / logsPerPage) - 2 && <Pagination.Ellipsis />}
  {currentPage < Math.ceil(userLogs.length / logsPerPage) && (
    <Pagination.Item onClick={() => paginate(Math.ceil(userLogs.length / logsPerPage))}>
      {Math.ceil(userLogs.length / logsPerPage)}
    </Pagination.Item>
  )}
  <Pagination.Next
    onClick={() =>
      setCurrentPage((prevPage) =>
        prevPage < Math.ceil(userLogs.length / logsPerPage) ? prevPage + 1 : prevPage
      )
    }
  />
</Pagination>

          </>
        )}
      </Card.Body>
    </Card>) : (
      <Modal show={showAccessDeniedModal} >
      <Modal.Header>
        <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {lang.menu.YoudontHavePerm}
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
     )}</>
  );
}

export default UserLogs;
