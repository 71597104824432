import React from "react";
import PolitiqueCookiesContent from "./PolitiqueCookiesContent";

const PolitiqueCookies = () => {
  document.title = "Politique de Cookies | HireCue  ";
  return (
    <React.Fragment>
 
      <PolitiqueCookiesContent />
    </React.Fragment>
  );
};

export default PolitiqueCookies;
