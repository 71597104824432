import React, { useState, useEffect ,useCallback } from "react";
import axios from "axios";
import { Card, Table, Modal, Button, Form,Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { Link } from "react-router-dom";
import { VscRunAll } from "react-icons/vsc";
import { languageOptions } from "./constants/languageOptions";
import { languageOptions1 } from '../Jobs/OneCompiler/constants/languageOptions1';

import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import FilterableTable from"./FilterableTable";

const TopicsByCandidate = () => {
  const [topicsList, setTopicsList] = useState([]);
  const token=localStorage.getItem("token");
  const [connectedUser, setConnectedUser] = useState("");
  const [currentPage, setCurrentPage] = useState(0); 
  const itemsPerPage = process.env.REACT_APP_PAGINATION; 
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setConnectedUser(currentUser)
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }}
          );
          const data = await response.json();
          setTopicsList(
            data.some((permission) => permission.title === "topics")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!topicsList) {
      setShowAccessDeniedModal(true); 
    }
  }, [topicsList]);
  const [selectedLang, setSelectedLang] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });
  useEffect(() => {
    const selectedLang = JSON.parse(localStorage.getItem("selectedLang"));
    if (selectedLang) {
      setLang(selectedLang);
    }
  }, []);

  const [topics, setTopics] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [level, setLevel] = useState("");

  const [input1, setInput1] = useState("");
  const [expectedOutput1, setExpectedOutput1] = useState("");
  const [input2, setInput2] = useState("");
  const [expectedOutput2, setExpectedOutput2] = useState("");
  const [input3, setInput3] = useState("");
  const [expectedOutput3, setExpectedOutput3] = useState("");
  const [LanguageId, setLanguageId] = useState("");
  const [Squelette, setSquelette] = useState("");
  const [ExtentionCode, setExtentionCode] = useState("");
  const [Timer, setTimer] = useState("");
  const [compiler, setCompiler] = useState("");

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [mode, setMode] = useState("create");
  const [durationCoding, setDurationCoding] = useState("");
  const [companieId, setCompanieId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/local/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        const data = await response.json();
        setDurationCoding(data[0].DurationCoding);
        console.log("data[0].DurationCoding : ", data[0].DurationCoding)
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const getUserCompanyID = async () => {
    const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
    const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
    const data = await responseC.json();
    const compId = data[0].companyID;
    return compId;
  }; 
  const handleModalOpen = () => {
    setShowModal(true);
    setSelectedTopic(null);
    setMode("create");
  };
  const fetchTopic = async () => {
    try {
      const compId = await getUserCompanyID();
setCompanieId(compId)
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getAllTopics`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      if (compId!=1) {
        const filteredTests = response.data.filter(test => test.companyID === compId || test.companyID === 1);
        setTopics(filteredTests);
      } else {
        setTopics(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditTopic = (topic) => {
    setSelectedTopic(topic);
    setMode("update");

    setShowModal(true);
    setName(topic.name);
    setDescription(topic.description);
    setLevel(topic.level);
    setInput1(topic.input1);
    setExpectedOutput1(topic.expectedOutput1);
    setInput2(topic.input2);
    setExpectedOutput2(topic.expectedOutput2);
    setInput3(topic.input3);
    setExpectedOutput3(topic.expectedOutput3);
    setLanguageId(topic.LanguageId);
    setSquelette(topic.Squelette);
    setExtentionCode(topic.ExtentionCode);
    setTimer(topic.Timer);
    setCompiler(topic.compiler);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setName("");
    setDescription("");
    setLevel("");
    setInput1("");
    setExpectedOutput1("");
    setInput2("");
    setExpectedOutput2("");
    setInput3("");
    setExpectedOutput3("");
    setLanguageId("");
    setSquelette("");
    setExtentionCode("");
    setTimer("");
    setCompiler("");
  };

  const createOrUpdateTopic = async (newTopic) => {
    try {
      const compId = await getUserCompanyID();
      if (mode === "create") {
        // Create a new topic
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        newTopic.companyID = compId;
        newTopic.userId = userId;
        console.log("newTopic :", newTopic)
        const response = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/createTopic`,
          newTopic, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const createdTopic = response.data;

        setTopics([...topics, createdTopic]);
        fetchTopic();
      } else if (mode === "update") {
        // Update an existing topic
        const currentUser = JSON.parse(localStorage.getItem('userDetails'));
        const userId = currentUser.localId;
        const response = await axios.put(
          `${process.env.REACT_APP_APP_DOMAIN}/api/topic/updateTopicById/${selectedTopic.id}/${userId}`,
          newTopic, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        const updatedTopic = response.data;
        setTopics((prevTopics) =>
          prevTopics.map((topic) =>
            topic.id === updatedTopic.id ? updatedTopic : topic
          )
        );
        fetchTopic();
      }

      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const op = languageOptions.find(lang => lang.id === LanguageId);

    if (op) {
      localStorage.setItem("squeletteEx", op.functionSkeleton);
      localStorage.setItem("ExtentionCodeEx", op.output);
      if (mode === "create") {
      setSquelette(op.functionSkeleton);
      setExtentionCode(op.output)
      }
    }
  }, [LanguageId]);



  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newTopic = {
      name,
      description,
      level,
      input1,
      expectedOutput1,
      input2,
      expectedOutput2,
      input3,
      expectedOutput3,
      LanguageId,
      Squelette,
      ExtentionCode,
      Timer: Timer || durationCoding,
      compiler,
    };

    createOrUpdateTopic(newTopic);
    setName("");
    setDescription("");
    setLevel("");
    setInput1("");
    setExpectedOutput1("");
    setInput2("");
    setExpectedOutput2("");
    setInput3("");
    setExpectedOutput3("");
    setLanguageId("");
    setSquelette("");
    setExtentionCode("");
    setTimer("");
    setCompiler("");

    setShowModal(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  
  const handleLevelChange = (e) => {
    setLevel(e.target.value);
  };
  const handleInput1Change = (e) => {
    setInput1(e.target.value);
  };

  const handleExpectedOutput1Change = (e) => {
    setExpectedOutput1(e.target.value);
  };
  const handleInput2Change = (e) => {
    setInput2(e.target.value);
  };

  const handleExpectedOutput2Change = (e) => {
    setExpectedOutput2(e.target.value);
  };
  const handleInput3Change = (e) => {
    setInput3(e.target.value);
  };

  const handleExpectedOutput3Change = (e) => {
    setExpectedOutput3(e.target.value);
  };
  const handleLanguageIdChange = (e) => {
    const languageId = parseInt(e.target.value, 10); 
    setLanguageId(languageId);
    
  
    const selectedLang = languageOptions.find((option) => option.id === languageId);
    
  
    if (selectedLang) {
      localStorage.setItem("squeletteEx", selectedLang.functionSkeleton);
      localStorage.setItem("ExtentionCodeEx", selectedLang.output);

      setSquelette(selectedLang.functionSkeleton);
      setExtentionCode(selectedLang.output)
    }
  };
  
  

  const handleSqueletteChange = (e) => {
    setSquelette(e.target.value);
  };
  const handleExtentionCodeChange = (e) => {
    setExtentionCode(e.target.value);
  };
  const handleTimerChange = (e) => {
    setTimer(e.target.value);
  };

  useEffect(() => {
    fetchTopic();
  }, []);
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Logique de pagination
  const offset = currentPage * itemsPerPage;
  const currentItems = topics.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(topics.length / itemsPerPage);
  useEffect(() => {
    if (!selectedTopic) {
      setName("");
      setDescription("");
      setLevel("");
      setInput1("");
      setExpectedOutput1("");
      setInput2("");
      setExpectedOutput2("");
      setInput3("");
      setExpectedOutput3("");
      setLanguageId("");
      setSquelette("");
      setExtentionCode("");
      setTimer("");
      setCompiler("");
    }
  }, [selectedTopic]);
  const [loading, setLoading] = useState(false);

  const handleDelete = (topicId) => {
    if (topicId && !loading) {
      setLoading(true);
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
      axios
        .delete(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/deleteTopicById/${topicId}/${userId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }})
        .then((response) => {
          console.log(response.data);

          if (response.data.message !== "Topic was deleted successfully!") {
            swal(lang.menu.suppImpo, { icon: "error" });
          } else {
            setTopics((prevTests) => prevTests.filter((topic) => topic.id !== topicId));
          }
          setLoading(false);
        })
        .catch((error) => {
          swal(lang.menu.supprimerError, { icon: "error" });
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const deleteButtons = document.querySelectorAll(".deleteButton");

    deleteButtons.forEach((deleteButton) => {
      const handleClick = () => handleDelete(deleteButton.dataset.id);
      deleteButton.addEventListener("click", handleClick);

      // Clean up the event listener
      return () => {
        deleteButton.removeEventListener("click", handleClick);
      };
    });
  }, []);
  // Function to get language name by languageId
  const getLanguageName = (languageId) => {

    const languageOption = languageOptions.find(
      (option) => option.id === languageId
    );


    return languageOption ? languageOption.name : "";
  };
  // Function to get language name by languageId
  const getLanguageName1 = (languageId) => {
    const languageOption = languageOptions1.find(
      (option) => option.id === languageId
    );
    return languageOption ? languageOption.label : "";
  };
  const handlePassTest = (topicId, TimerNum) => {
    localStorage.setItem("topicId", topicId);
    localStorage.setItem("questionsNbrCoding", TimerNum);
  };





  const [userRole, setuserRole] = useState([]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userId = currentUser.localId;
        const response = await fetch(
          `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`
        );
        const data = await response.json();
        setuserRole(data);
        console.log("user data " + data[0].adminComplier); // Log the correct value here
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  useEffect(() => {
    userRole[0]?.recruiterComplier !== null
      ? userRole[0]?.recruiterComplier === "1"
        ? setCompiler("oneCompiler")
        : setCompiler("judge0")
      : userRole[0]?.companyComplier !== null
        ? userRole[0]?.companyComplier === "1"
          ? setCompiler("oneCompiler")
          : setCompiler("judge0")
        : userRole[0]?.adminComplier === "1"
          ? setCompiler("oneCompiler")
          : setCompiler("judge0");
  }, [userRole]);
  useEffect(() => {
    if (compiler) {
      localStorage.setItem("compiler", compiler);
      const compilerStore = localStorage.getItem("compiler");

    }
  }, [compiler]);

  const handleRunTopic = (topic) => {
    // Your redirection logic here
    const redirectionPath =
      topic.compiler === 'oneCompiler'
        ? `topic-for-recruiter2/${topic.id}`
        : `topic-for-recruiter/${topic.id}`;

        

    window.location.href = redirectionPath;
    handlePassTest(topic.id, topic.Timer)

  };
  const getTextAreaValue = () => {
    if (mode === "update") {
      console.log("userRole[0]:",userRole[0])
     
      return compiler === "oneCompiler" ? "oneCompiler" : "judge0";
    }
    return localStorage.getItem("compiler");
  };
  function getColorByLevel(level) {
    if (!level || typeof level !== "string") {
      // Si level est indéfini, nul, ou non une chaîne, retournez une couleur par défaut
      return "#000000"; 
    }
  
    switch (level.toLowerCase()) {
      case "beginner":
        return "#00d4d4";
      case "intermediate":
        return "#ffaa00";
      case "advanced":
        return "#ff0000";
      default:
        return "#000000"; // Couleur par défaut
    }
  }
  
  
  return (
    
    <> {topicsList ? (  
    <>
      <Card>
        <Card.Header>
          <Card.Title>{lang.menu.listTopic}</Card.Title>
          <Button
            variant="primary"
            className="me-3 btn-sm"
            onClick={handleModalOpen}
          >
            <i className="fas fa-plus me-2"></i>{lang.menu.NewTopic}
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-xl-12">
              <div className="table-responsive">
              <FilterableTable 
            lang={lang} 
            items={topics} 
            itemsPerPage={itemsPerPage} 
            handlePageClick={handlePageClick} 
            connectedUser={connectedUser}
            companieId={companieId}
            handleEditTopic={handleEditTopic}
            handleDelete={handleDelete}
            handleRunTopic={handleRunTopic}
            getLanguageName={getLanguageName}
            getLanguageName1={getLanguageName1}

            getColorByLevel={getColorByLevel}
          />
           
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title>{mode === "update" ? lang.menu.UpdateTopic :lang.menu.CreateNewTopic }</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label>{lang.menu.name}</Form.Label>
           
                    
              <Form.Control
                type="text"
                placeholder={`${lang.menu.enterM} ${lang.menu.name}`}
                value={name}
                onChange={handleNameChange}
                maxLength={254}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.description}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.description}`}
                value={description}
                onChange={handleDescriptionChange}
                maxLength={1999}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryLevel">
            <Form.Label>      {lang.menu.Difficulty}</Form.Label>
            
                                
                                  <Form.Control
                                    as="select"
                                    value={level}
                                    name="difficulty"
                                    required
                                    placeholder= {lang.menu.Difficulty}
                                    onChange={handleLevelChange}
                                    >
                                        
                                        <option value="Beginner">{lang.menu.Beginner}</option>
                                        <option value="Intermediate">{lang.menu.Intermediate}</option>
                                        <option value="Advanced">{lang.menu.Advanced}</option>
                                    </Form.Control>
                                    </Form.Group>
                                    <br/>
                               
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 1 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 1`}
                value={input1}
                maxLength={60}
                onChange={handleInput1Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 1 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 1`}
                value={expectedOutput1}
                onChange={handleExpectedOutput1Change}
                maxLength={60}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 2 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 2`}
                value={input2}
                maxLength={60}
                onChange={handleInput2Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 2 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 2`}
                value={expectedOutput2}
                maxLength={60}
                onChange={handleExpectedOutput2Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.input} 3 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : "texte",</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p>
 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterF} ${lang.menu.input} 3`}
                value={input3}
                maxLength={60}
                onChange={handleInput3Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.expectedOutPut} 3 </Form.Label>
              <div className="tooltip-container">
                        <button
                          id="btn"
                          type="button"
                          className="informationScore">i
                        </button>
                        <div className="tooltip-message">
                        <p>Varchar : texte,</p>
                        <p> Int : 123, </p>
                        <p> Float : 1.23, </p>
                        <p>Booléen : true/false,</p>
                        <p> Date : YYYY-MM-DD, </p>
                        <p> Liste : ["item1", "item2"]</p> 
              </div>
                      </div>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.expectedOutPut} 3`}
                value={expectedOutput3}
                maxLength={60}
                onChange={handleExpectedOutput3Change}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> compiler </Form.Label>
              <Form.Control as="textarea" 
      value={getTextAreaValue()}
      rows={3} readOnly />
            </Form.Group>
            {mode === 'update' ? (
  <Form.Group controlId="formCategoryDescription">
    <Form.Label>{lang.menu.language}</Form.Label>
    <Form.Control
      as="select"
      value={LanguageId}
      onChange={handleLanguageIdChange}
      required
    >
      <option value="">Select Language</option>
      {console.log("userRole[0]?.compiler :",compiler )}
      {compiler === "oneCompiler"
        ? languageOptions1.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))
        : languageOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </Form.Control>
  </Form.Group>
) : (
  <Form.Group controlId="formCategoryDescription">
    <Form.Label>{lang.menu.language}</Form.Label>
    <Form.Control
      as="select"
      value={LanguageId}
      onChange={handleLanguageIdChange}
      required
    >
      <option value="">Select Language</option>
      {localStorage.getItem("compiler") === "oneCompiler"
        ? languageOptions1.map((option) => (
          <option key={option.id} value={option.id}>
            {option.label}
          </option>
        ))
        : languageOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
    </Form.Control>
  </Form.Group>
)}

            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.squelette} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.squelette}`}
                value={Squelette}
                maxLength={2000}
                onChange={handleSqueletteChange}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label> {lang.menu.codeExten} </Form.Label>
              <Form.Control
                as="textarea"
                placeholder={`${lang.menu.enterM} ${lang.menu.codeExten}`}
                value={ExtentionCode}
                maxLength={500}
                onChange={handleExtentionCodeChange}
                rows={3}
                required
              />
            </Form.Group>
            <br/>
            <Form.Group controlId="formCategoryDescription">
              <Form.Label>{lang.menu.timer}</Form.Label>
              <Form.Control
                type="number"
                value={Timer}
                onChange={handleTimerChange}
                rows={3}
                placeholder={`${lang.menu.tempPerDefault} ${durationCoding} ${lang.menu.seconds}`}
              />
            </Form.Group>

            <br />
            <Button variant="primary" type="submit">
              {selectedTopic ? lang.menu.UpdateTopic : lang.menu.CreateNewTopic}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default TopicsByCandidate;