import React, { useState,useEffect,useCallback } from 'react';
import axios from 'axios';
import {
  Badge,
Card,
Form,
Modal
  
} from "react-bootstrap";
import translations from"./mulitlingue"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdCheckboxOutline } from 'react-icons/io';
import swal from "sweetalert";
import Select from "react-select";
import { LuLoader } from "react-icons/lu";

const CvUpload = () => {
  const [candidatePerSend, setCandidatePerSend] = useState([]);
  const token=localStorage.getItem("token");
  const [selectedJob, setSelectedJob] = useState(null);
  const [isJobLocked, setIsJobLocked] = useState(false);
  const appliedJobId = localStorage.getItem("appliedJobId");
  const [isNoteLocked, setIsNoteLocked] = useState(false);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        if (currentUser && currentUser.localId) {
          const userId = currentUser.localId;
          const response = await fetch(
            `${process.env.REACT_APP_APP_DOMAIN}/api/users/permissions/${userId}`, 
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          setCandidatePerSend(
            data.some((permission) => permission.title === "send-cv")
          );
        }
      } catch (error) {
        console.error("Error fetching permissions details:", error);
      }
    };

    fetchUserPermissions();
  }, []);
  const [showAccessDeniedModal, setShowAccessDeniedModal] = useState(true); 

  useEffect(() => {
    if (!candidatePerSend) {
      setShowAccessDeniedModal(true); 
    }
  }, [candidatePerSend]);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;

      const getUserCompanyID = async () => {
        const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const data = await responseC.json();
        return data[0].companyID;
      };

      try {
        const compId = await getUserCompanyID();
        const jobResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const jobData = jobResponse.data;

        let filteredJobs;
        

        if (compId !== 1) {
          filteredJobs = jobData.filter(job =>  job.Status==="Active" && job.archived===0);
          
        } else if (compId === 1 && currentUser.userRole === 3) {
          filteredJobs = jobData.filter(job => job.companyid === compId && job.Status==="Active" && job.archived===0 && job.creator_uid === currentUser.localId);
         
        } else {
          filteredJobs = jobData.filter(job =>  job.Status==="Active" && job.archived===0);;
        }

        const jobList = filteredJobs.map(job => ({
          value: job.id,
          label: job.JobTitle,
          companyLogo:job.companyid
        }));

        const enrichedJobs = await enrichJobsWithLogos(jobList);
        setJobs(enrichedJobs);
        if (appliedJobId) {
          const appliedJob = jobList.find((job) => job.value === parseInt(appliedJobId));
          if (appliedJob) {
            setSelectedJob(appliedJob);
            setIsJobLocked(true); 
          }
        }
       
      } catch (error) {
        console.error('Erreur lors de la récupération des emplois:', error);
      }
    };

    fetchData();
  }, [appliedJobId, token]);
  const enrichJobsWithLogos = async (jobList) => {
    const enrichedJobs = await Promise.all(
      jobList.map(async (job) => {
        const companyLogo = job.companyLogo ? await getCompanyLogo(job.companyLogo) : null;
        return {
          ...job,
          companyLogo,
        };
      })
    );
    return enrichedJobs;
  };
  
  const getCompanyLogo = async (companyId) => {
    if (!companyId) return null;
    const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const dataCmp = await response.json();
    const company = dataCmp.find((comp) => comp.companyid === companyId);
    return company ? `${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}` : null;
  };
  const [selectedLang, setSelectedLang] = useState(null);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const currentUser = JSON.parse(localStorage.getItem('userDetails'));
  const useremail= currentUser.email;
    const [jobRoleName1, setJobRoleName1] = useState(lang.menu.jobRole);
    useEffect(() => {
      if (jobs.length > 0) {
        const appliedJobId = localStorage.getItem("appliedJobId");
    
        if (appliedJobId && jobs.some((job) => job.value === parseInt(appliedJobId))) {
          const defaultJob = jobs.find((job) => job.value === parseInt(appliedJobId));
          setSelectedJob(defaultJob);
          setJobRoleName1(defaultJob.label);
        } else {
          setJobRoleName1(lang.menu.jobRole);
        }
      }
    }, [jobs, lang.menu.jobRole]);
    
    

    const [buttonDisabled, setButtonDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [currentLang, setCurrentLang] = useState(lang);
  const [filename, setFilename] = useState("");
  const [emailRef, setEmailRef] = useState("");
  const [fullname, setFullname] = useState("");
  const [nameRef, setNameRef] = useState("");
  const [email, setEmail] = useState(useremail);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  useEffect(() => {
    const appliedJob = localStorage.getItem("appliedJob");

    if (appliedJob && jobs.includes(appliedJob)) {
      setJobRoleName1(appliedJob);
    }

  }, [jobs]);
  useEffect(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      setSelectedLang(JSON.parse(storedLang));
    }
  }, []);

  useEffect(() => {
    setCurrentLang(selectedLang);
  }, [selectedLang]);
  const MAX_FILE_SIZE = 2.5 * 1024 * 1024;

  const handleFileChange = async(event) => {
    const selectedFile = event.target.files[0];
    setFilename(selectedFile.name);
    setFile(selectedFile);
    setButtonDisabled(false);
    const formData = new FormData();
      
        formData.append('files', selectedFile);
  const largeFiles =  selectedFile.size > MAX_FILE_SIZE;

  if (largeFiles) {
    swal(lang.menu.filesize, {
      icon: "error",
    });
    return;
  }

      try {
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/uploadSingleFile`, formData,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        swal(lang.menu.filesuccess, {
          icon: "success",
        });
        setUploadSuccess(true)
      } catch (error) {
        console.log('Error uploading files. Please try again later.');
      }
  };

  /*   const handleFileUpload = async () => {
      const formData = new FormData();
      
        formData.append('files', file);
  const largeFiles =  file.size > MAX_FILE_SIZE;

  if (largeFiles) {
    swal(lang.menu.filesize, {
      icon: "error",
    });
    return;
  }

      try {
        const response = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/uploadSingleFile`, formData,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        swal(lang.menu.filesuccess, {
          icon: "success",
        });
        setUploadSuccess(true)
      } catch (error) {
        console.log('Error uploading files. Please try again later.');
      }
    };
    */


    
    const [isLoading, setIsLoading] = useState(false);

    const handleUploadAndAdd = async (event) => {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      formData.append('files', file);
      formData.append('filename', filename);
      formData.append('emailRef', emailRef);
      formData.append('fullname', fullname);
      formData.append('nameRef', nameRef);
      formData.append('email', email);
      const formData2 = new FormData();
      formData2.append('jobRoleName', selectedJob.value);
      formData2.append('files', file.name);
      formData2.append('email', email);
      formData2.append('name', fullname);
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
      const userId = currentUser.localId;
    console.log("form data 2:",formData2)
      try {
        // Adding candidate first
        const addResponse = await axios.post(`${process.env.REACT_APP_APP_DOMAIN}/flask/candidates/addCandidates1`, formData2, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        let finalAppliedJobId = appliedJobId;
        if (!finalAppliedJobId) {
          finalAppliedJobId = selectedJob.value;
        }
        // Uploading file after candidate is added
        formData.append('jobId', finalAppliedJobId);

        const uploadResponse = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/flask/upload`, 
          formData, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
              'filename': filename,
              'emailRef': emailRef,
              'fullname': fullname,
              'nameRef': nameRef,
              'email': email,
              'jobId': finalAppliedJobId
            }
          }
        );
        
        
        // Creating candidate in job role after candidate is added
        const createCandidateResponse = await axios.post(
          `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/create-candidate2/${email}/${finalAppliedJobId}`,{}, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
    
        localStorage.removeItem("appliedJob");
        swal(lang.menu.successMes2, {
          icon: "success",
        });
// Redirecting instead of reloading
   window.location.href = `${process.env.REACT_APP_APP_DOMAIN}`;
        } catch (error) {
        if (error.response) {
          setIsNoteLocked(true)
          swal(error.response.data.error|| 'The Uploaded  file is not compatible. Please upload a valid text PDF file.', {
            icon: "error",
          });
        } else if (error.request) {
          setIsNoteLocked(true)
          swal('No response received from the server', {
            icon: "error",
          });
        } else {
          setIsNoteLocked(true)
          swal('The Uploaded  file is not compatible. Please upload a valid text PDF file.', {
            icon: "error",
          });
        }
      }finally {
        setIsLoading(false); 
    }
    };
    
    
    const getJobSkills = async (jobId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/GetSkillsByJobid/${jobId.value}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        const jobskills = response.data;
        return jobskills;
      } catch (error) {
        console.error(`Error fetching job skills for ID ${jobId.value}:`, error);
        return "";
      }
    };
  
    
  const handleReferencesChange = (event) => {
    setEmailRef(event.target.value);
  };
  const handlefullname = (event) => {
    setFullname(event.target.value);
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handlenameRef = (event) => {
    setNameRef(event.target.value);
  };
 
 
  const handleJobChange = (selectedOption) => {
    if (!isJobLocked) {
      setSelectedJob(selectedOption);
    } else {
      swal("Vous ne pouvez pas changer le poste appliqué.", { icon: "warning" });
    }
  };
  const formatOptionLabel = ({ label, companyLogo }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {companyLogo && (
        <img
          src={companyLogo}
          alt="Company Logo"
          style={{ width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}
        />
      )}
      <span>{label}</span>
    </div>
  );
  return (
    <> {candidatePerSend ? (
    <div >

    <div className="container">
      
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            
          <Card>
                <Card.Header>
                  <Card.Title>{lang.menu.uploadCv}</Card.Title>
                  
                </Card.Header>
              </Card>
          

            <div className="card-body">
      {!isNoteLocked ?
        <div style={{ backgroundColor: "red", color: "white", padding: "10px", marginBottom: "10px" , borderRadius: "10px"}}>
          Note: {lang.menu.NB}
                  </div>:<></>
      }
              
               <form onSubmit={handleUploadAndAdd}>
                 
                 
                  <div class="input-group">
            <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"
             aria-label="Upload" 
             accept=".pdf" 
             onChange={handleFileChange} required />
        
</div>
                
            
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.fullname} <span style={{ color: 'red' }}>*</span> : </strong></label>
                    <br/>
                    <input type="text" name="fullname" className="form-control" value={fullname} onChange={handlefullname} required/>
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.email} <span style={{ color: 'red' }}>*</span> : </strong></label>
                    <br/>
                    <input type="text" name="email" className="form-control" value={email} onChange={handleEmail}  readOnly required/>
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.ref} </strong>({lang.menu.descRef}) : </label>
                    <br/>
                    <input type="text" name="nameRef" className="form-control" value={nameRef} onChange={handlenameRef} />
                  </div>
                  <div className="form-group">
                    <br/>
                    <label><strong>{lang.menu.mailref} :</strong> </label>
                    <br/>
                    <input type="text" name="references" className="form-control" value={emailRef} onChange={handleReferencesChange} />
                    </div>
                    <br/>




    <br/>

<div className="form-group">
                    <label><strong>{lang.menu.jobRole} <span style={{ color: 'red' }}>*</span> :</strong> </label>
                    <br/>
                    <Select
        options={jobs}
        value={selectedJob}
        onChange={handleJobChange}
        isDisabled={isJobLocked} 
        formatOptionLabel={formatOptionLabel} 
        placeholder="Sélectionnez un emploi"
      />
    
    
    <br/>
                    
                    </div>
                
                  
                  <div className="d-flex justify-content-center align-items-center flex-column">
          

                   <button 
    type="submit" 
    className="btn btn-primary center" 
    disabled={!uploadSuccess || isLoading}
>
    {isLoading ? <LuLoader /> : lang.menu.submit}
</button>

                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div><ToastContainer />
    </div>   ) : (
             <Modal show={showAccessDeniedModal} >
             <Modal.Header>
               <Modal.Title>{lang.menu.accessDenied}</Modal.Title>
             </Modal.Header>
             <Modal.Body>
 {lang.menu.YoudontHavePerm}
             </Modal.Body>
             <Modal.Footer>
         
             </Modal.Footer>
           </Modal>
          )}</>
  );
};

export default CvUpload;