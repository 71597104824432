import React, { useMemo, useState } from "react";
import { useTable, useFilters, useSortBy, useGlobalFilter,usePagination } from "react-table";
import "./Table.css";
import ReactStars from "react-rating-stars-component";
import { Button,Pagination } from 'react-bootstrap';
import { VscRunAll } from 'react-icons/vsc';
import translations from "../AppsMenu/Candidate/mulitlingue";
import { FaSort } from "react-icons/fa";
import { MdOutlineFavorite } from "react-icons/md";
import axios from "axios"; 
import { FcCheckmark , FcCancel} from "react-icons/fc";

const FilterTable = ({ candidates, handleCandidateClick, users, formatCalendarDate, getCandAcceptStatus, showTest,onFilterChange }) => {
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem('selectedLang');
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === 'Français') {
        return translations.fr;
      }
    }
    return translations.en;
  });
  const token = localStorage.getItem("token");
  const updateInterviewStatus = async (candidateId, jobRoleId, status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateInterviewStatus/${candidateId}/${jobRoleId}/${status}`, {},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Interview status updated successfully", response);
    } catch (error) {
      console.error("Error updating interview status:", error);
    }
  };
  const updateIsFavorite = async (candidateId, jobRoleId, isFavorite) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/updateIsFavorite/${candidateId}/${jobRoleId}/${isFavorite}`, {},{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("isFavorite status updated successfully", response); 
    } catch (error) {
      console.error("Error updating isFavorite :", error);
    }
  };
  const DateFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          onFilterChange( e.target.value);
        }}
        placeholder="Filtrer par date"
        style={{
          width: "100%",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      />
    );
  };

  const columns = useMemo(
    () => [
      {
        id: "favoriteColumn",  
        Header: <MdOutlineFavorite size={32} />,
        accessor: (row) => row.IsFavorite || [],
        Cell: ({ row }) => {
          const [isFavorite, setIsFavorite] = useState(row.original.IsFavorite);
          const candidateId = row.original.id_candidate;
          const jobRoleId = row.original.id_jobrole;
      
          const toggleFavorite = async () => {
            const updatedIsFavorite = isFavorite ? 0 : 1;
            setIsFavorite(updatedIsFavorite);
            await updateIsFavorite(candidateId, jobRoleId, updatedIsFavorite);
            row.original.IsFavorite = updatedIsFavorite;
          };
      
          return (
            <button
              onClick={toggleFavorite}
              style={{ border: "none", background: "none" }}
            >
              <MdOutlineFavorite 
                color={isFavorite ? "red" : "gray"} 
                size={32}
              />
            </button>
          );
        },
        sortType: "basic",
      },
      
      {
        Header: lang.menu.candidates,
        accessor: (row) => {
          const user = users.find((u) => u.uid === row.id_candidate);
          return `${user?.email || ""}`.trim();
        },
        Cell: ({ row }) => {
          const candidate = row.original;
          const user = users.find((u) => u.uid === candidate.id_candidate);
          const email = user?.email || "N/A";
          return (
            <button
              onClick={() => handleCandidateClick(candidate)}
              style={{ backgroundColor: "transparent", border: 0 }}
            >
              <strong>{email}</strong>
            </button>
          );
        },
        Filter: TextFilter,
        sortType: "alphanumeric",
      },
      {
        Header: lang.menu.lastActivity,
        accessor: (row) => formatCalendarDate(row.last_activity),
        Cell: ({ value }) => value, 
        Filter: DateFilter, 
        sortType: "basic",
      },
      {
        Header: lang.menu.hiringStage,
        accessor: "candAccept",
        Cell: ({ value }) => getCandAcceptStatus(value),
        Filter: SelectFilter,
      },
     
      {
        Header: lang.menu.perTest,
        accessor: (row) => row.notePer[0]?.notePer || [],
        Cell: ({ row }) => {
          const notePerValue = row.original.notePer[0]?.notePer || "";
      
          if (typeof notePerValue === "object" && notePerValue !== null) {
            return (
              <ul>
                {Object.entries(notePerValue).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
            );
          }
          return <p>{notePerValue}</p>; 
        },
        Filter: TextFilter,
      }
,      
      {
        Header: lang.menu.testPsychoTechnique,
        accessor: "note",
        Cell: ({ value }) =>
          value && value.length
            ? value.map((test) => <p key={test.idtest}>{Math.round((test.note * 100) / 10)}%</p>)
            : "",
        sortType: "basic",
      },
      {
        Header: lang.menu.testTechnique,
        accessor: (row) => row.overallScore || [],
        Cell: ({ row }) => {
          const candidate = row.original;
          if (candidate.overallScore!=null) {
            const OverAll = parseFloat(candidate.overallScore);
            let OverAllArround= Math.round(OverAll);
            return (
              <div >
                <p>{OverAllArround} %</p>
              </div>
            );
          }
          return <></>;
        },
        sortType: "basic",
      },

      {
        Header: lang.menu.CameraOpen,
        accessor: (row) => {
          return row.technicalTestDetails?.some(detail => !detail.isCameraOpen) ? lang.menu.No : lang.menu.Yes;
        },
        Cell: ({ row }) => {
          const details = row.original.technicalTestDetails || [];
          if (details.length === 0) {
            return <span style={{ color: 'gray' }}></span>;
          }
          const cameraIssues = details.some(
            (detail) => !detail.isCameraOpen
          );
          return (
            <span style={{ color: cameraIssues ? 'red' : 'green' }}>
               {cameraIssues ? (
        <>
          <FcCancel />  {lang.menu.No}
        </>
      ) : (
        <>
          <FcCheckmark /> {lang.menu.Yes}
        </>
      )}
            </span>
          
          );
        },
      }
,      
      {
        Header: lang.menu.mousseleft,
        accessor: (row) => {
          return row.technicalTestDetails?.some(detail => !detail.mouseAlwaysInWindow) ? lang.menu.Yes : lang.menu.No;
        },
        Cell: ({ row }) => {
          const details = row.original.technicalTestDetails || [];
          if (details.length === 0) {
            return <span style={{ color: 'gray' }}></span>;
          }
          const mouseLeftWindow = details.some(
            (detail) => !detail.mouseAlwaysInWindow
          );
          return (
            <span style={{ color: mouseLeftWindow ? 'red' : 'green' }}>
              {mouseLeftWindow ? (
        <>
           <FcCheckmark /> {lang.menu.Yes}
        </>
      ) : (
        <>
         <FcCancel /> {lang.menu.No}
        
        </>
      )}
            </span>
          );
        },
        sortType: "basic",
      }
      
      ,
      {
        Header: lang.menu.InterviewStatus,
        accessor:  (row) => {
          const status = row.InterviewStatus;
          return `${status ? lang.menu.Invited : lang.menu.notInvited}`;
        },
        Cell: ({ row }) => {
          const [status, setStatus] = useState(row.original.InterviewStatus);
          const candidateId = row.original.id_candidate;
          const jobRoleId = row.original.id_jobrole;
      
          const handleInterviewStatusChange = async (event) => {
            const updatedStatus = event.target.value === lang.menu.Invited ? 1 : 0;
            setStatus(updatedStatus); 
            await updateInterviewStatus(candidateId, jobRoleId, updatedStatus);
            row.original.InterviewStatus = updatedStatus; 
          };
      
          return (
            <select
            className="form-select"
            value={status ? lang.menu.Invited : lang.menu.notInvited}
            onChange={handleInterviewStatusChange}
          >
            <option value={lang.menu.Invited}>{lang.menu.Invited}</option>
            <option value={lang.menu.notInvited}>{lang.menu.notInvited}</option>
          </select>
          );
        },
        Filter: TextFilter, 
        filter: 'includes',
      },
      
      {
        Header: lang.menu.rating,
        accessor: row => {
          let lastRating = 0;
          if (row.stars) {
            try {
              const starsArray = JSON.parse(row.stars);
              if (starsArray.length > 0) {
                lastRating = starsArray[starsArray.length - 1];
              }
            } catch (error) {
              console.error("Erreur lors de l'analyse des étoiles:", error);
            }
          }
          return lastRating; 
        },
        Cell: ({ row }) => {
          const candidate = row.original;
          let lastRating = 0; 
      
          if (candidate.stars) {
            try {
              const starsArray = JSON.parse(candidate.stars);
              if (starsArray.length > 0) {
                lastRating = starsArray[starsArray.length - 1]; 
              }
            } catch (error) {
              console.error("Erreur lors de l'analyse des étoiles:", error);
            }
          }
      
          return (
            <td>
              <ReactStars
                count={5}
                value={lastRating} 
                size={24}
                activeColor="#74188D"
                edit={false}
              />
            </td>
          );
        },
        Filter: TextFilter
      }
      ,
      {
        Header: lang.menu.resendParcours,
        Cell: ({ row }) => {
          const candidate = row.original;
          return (
            <td>
              <Button
                variant="success light"
                onClick={() => { showTest(candidate.id_candidate) }}
              >
                <VscRunAll size={16} />
              </Button>
            </td>
          );
        }
      }
    ],
    [users, formatCalendarDate, getCandAcceptStatus, handleCandidateClick]
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const data = useMemo(() => {
    return candidates.sort((a, b) => {
      const userA = users.find((u) => u.uid === a.id_candidate);
      const userB = users.find((u) => u.uid === b.id_candidate);
      const emailA = userA?.email || "";
      const emailB = userB?.email || "";
      return emailA.localeCompare(emailB);
    });
  }, [candidates, users]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    canNextPage,
    canPreviousPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
  } = useTable(
    { columns, data, defaultColumn, initialState: { pageIndex: 0, pageSize:  process.env.REACT_APP_PAGINATION } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const paginatedRows = rows.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);



  function TextFilter({ column: { filterValue, setFilter } }) {
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          onFilterChange(e.target.value); 
        }}
                placeholder={lang.menu.Search}
        style={{
          width: "100%",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      />
    );
  }

  function SelectFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
    const options = useMemo(() => {
      const optionsSet = new Set();
      preFilteredRows.forEach((row) => {
        optionsSet.add(row.values[id]);
      });
      return [...optionsSet];
    }, [id, preFilteredRows]);

    return (
      <select
        className="form-select"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          onFilterChange( e.target.value);
        }}
      >
        <option value="">{lang.menu.all}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option === 0 ? lang.menu.appliedUser : 
             option === 2 ? lang.menu.passUser : 
             option === 1 ? lang.menu.pendingUser : 
             option}
          </option>
        ))}
      </select>
    );
  }

  function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
    return (
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
          onFilterChange( e.target.value);
        }}
        placeholder={lang.menu.Search}
        style={{
          width: "100%",
          padding: "5px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      />
    );
  }

  return (
    <div>
      <input
        type="text"
        placeholder={lang.menu.Search}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        style={{
          marginBottom: "10px",
          padding: "10px",
          width: "100%",
          borderRadius: "4px",
          border: "1px solid #ddd"
        }}
      />
      <br/>
      <div className="table-responsive">
        <table {...getTableProps()} className="table">
          <thead style={{ backgroundColor: "#8cd6c8" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {column.Header !== lang.menu.testTechnique && column.Header !== lang.menu.testPsychoTechnique &&column.id !== "favoriteColumn"&& 
                      <div>{column.canFilter && column.Filter ? column.render("Filter") : null}</div>}
                    {!(column.isSorted) && (column.Header === lang.menu.testTechnique || column.Header === lang.menu.testPsychoTechnique || column.id === "favoriteColumn") &&
                      <FaSort />}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {paginatedRows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination className="justify-content-end">
        <Pagination.Prev onClick={previousPage} disabled={!canPreviousPage} />
        {Array.from({ length: Math.ceil(rows.length / pageSize) }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={pageIndex === index}
            onClick={() => gotoPage(index)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={nextPage} disabled={!canNextPage} />
      </Pagination>
    </div>
  );
};

export default FilterTable;
