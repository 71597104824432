import { React, useState, useEffect } from "react";
import Rte from "./Rte";
import pic1 from "./../../../images/profile/small/pic1.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import codepen from "./codepen.svg"
import book from "./book.png"
import ex from "./exp.png"
import { Link, withRouter, useHistory } from "react-router-dom";

import swal from "sweetalert";
import translations from "../../components/AppsMenu/Candidate/mulitlingue";
import axios from "axios";
import Switch from "../../layouts/nav/Switch";
import { Form } from "react-bootstrap";
import TechSkill from "./profile_components/techSkill";
import SoftSkill from "./profile_components/softSkill";
import Educ from "./profile_components/education";
import JobPref from "./profile_components/jobPref";
import ConPref from "./profile_components/conPref";
import Exp from "./profile_components/experiences";
import Volunteer from "./profile_components/volunteer";
import thankYou from './thankYou .png';
import oops from './oops.png';



const Profile = () => {
  const [showSubmitSeccessPopup, setShowSubmitSeccessPopup] = useState(false);
  const [SubmitSeccess, setSubmitSeccess] = useState(false);
  const [showSubmitFailedPopup, setShowSubmitFailedPopup] = useState(false);
  const token=localStorage.getItem("token");

  const [user, setUser] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    emailVerified: 0,
    password: "",
    country: "",
    jobTitle: "",
    phoneNumber: "",
    language: "",
    avatar: "",
    cover: "",
    emailSignature: "",
    companyID: "",
    userRole: "",
    onboardid: "",
    adminVerified: 0,
    adminComplier: "",
    companyComplier: "",
    recruiterComplier: "",
    allowCopyPaste: false,
    takeScreenShots: false,
    takeSnapShots: false,
    numberOfScreenShots: 0,
    numberOfSnapShots: 0,
    disabled: 0,
    roleName: "",
    // Additional fields
    skills: "",
    education: "",
    experience: "",
    linkedIn: "",
    github: "",
    speciality: "",
    certif: "",
    Volunteerings: "",
    JobPreferences: "",
    ContractPreferences: ""
  });
  
  const role=JSON.parse(localStorage.getItem("userDetails")).userRole;


  const [lang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    if (storedLang) {
      const parsedLang = JSON.parse(storedLang);
      if (parsedLang.language === "Français") {
        return translations.fr;
      }
    }
    return translations.en;
  });

  const handleSaveData = async (userData) => {
    try {
      // Send user data to backend for saving
      await fetch(
        `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/saveUserImages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData), // Use the updated user data
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleSave = async () => {
    try {

      setUser({
        ...user,
        userId: JSON.parse(localStorage.getItem("userDetails")).localId
      })
console.log("user done:",user)
      // Send user data to backend for saving
      const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/onboarding`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      if (response.ok) {
        swal("", lang.menu.ProfileUpdate, "success");
        //window.location.reload();
        history.push("/profile")

      } else {
        swal("Oops", lang.menu.ProfileUpdateError, "error");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const updateSignature = (newSignature) => {
    setUser((prevUser) => ({
      ...prevUser,
      signature: newSignature,
    }));
  };

  const handleAvatarUpload = async (e) => {
    const avatarFile = e.target.files[0];
    const userId=JSON.parse(localStorage.getItem("userDetails")).localId;
    if (avatarFile) {
      try {
        const formData = new FormData();
        formData.append("avatar", avatarFile);
        formData.append("userId", userId);
        const response = await fetch(
          `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/uploadAvatar`,
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${token}`,
              }
          }
        );

        if (response.ok) {
          const data = await response.json();
          
          const newUser = {
            ...user,
            avatar: data.filename,
          };
          setUser(newUser);
          
          await handleSaveData(newUser);
          swal("", lang.menu.AvatarUpdate, "success");
          window.location.reload();

        } else {
          swal("Oops", lang.menu.AvatarUpdateError, "error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };

  const handleCoverUpload = async (e) => {
    const coverFile = e.target.files[0];

    if (coverFile) {
      try {
        const formData = new FormData();
        formData.append("cover", coverFile);

        const response = await fetch(
          `${process.env.REACT_APP_APP_DOMAIN}/api/users/local/uploadCover`,
          {
            method: "POST",
            body: formData,
          }, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );

        if (response.ok) {
          const data = await response.json();
          const newUser = {
            ...user,
            cover: data.filename,
          };
          setUser(newUser);
          await handleSaveData(newUser);
          swal("", lang.menu.CoverUpdate, "success");
          window.location.reload();

        } else {
          swal("Oops", lang.menu.CoverUpdateError, "error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  };
  const history = useHistory();
  const languages = [
    { name: "Français", code: "Fr" },
    { name: "English", code: "En" },
  ];

  const jobs = [
    { name: "Human Resources", code: "1" },
    { name: "Director of Human Resources", code: "2" },
    { name: "CTO / Senior dev / Lead engineer", code: "3" },
    { name: "CEO", code: "4" },
    { name: "Engineer / Developer", code: "5" },
    { name: "Student", code: "6" },
    { name: "Recruiter / Talent acquisition", code: "7" },
    { name: "Sales / Marketing", code: "8" },
    { name: "Other", code: "9" },
  ];

  const countries = [
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ];

  const avatarClasses = [
    "empty-avatar-red",
    "empty-avatar-green",
    "empty-avatar-pimary",
    "empty-avatar-yellow",
    "empty-avatar-purple",
  ];
  const randomAvatarClass =
    avatarClasses[Math.floor(Math.random() * avatarClasses.length)];

  const [advenced,setAdvenced]=useState(false)
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    if (currentUser && currentUser.localId) {
      const userId = currentUser.localId;
      fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`)
        .then((response) => response.json())
        .then((data) => {
          setUser({
            userId: userId,
            uid: userId,
            firstName: data[0].FirstName || "",
            lastName: data[0].LastName || "",
            email: data[0].email || "",
      emailVerified: data[0].emailVerified || 0,
            password: data[0].Password || "",
            country: data[0].Country || "",
            jobTitle: data[0].JobTitle || "",
            phoneNumber: data[0].PhoneNumber || "",
            language: data[0].Language || "",
            avatar: data[0].Avatar || "",
            cover: data[0].Cover || "",
      emailSignature: data[0].EmailSignature || "",
      companyID: data[0].companyID || "",
      userRole: data[0].userRole || "",
      onboardid: data[0].onboardid || "",
      adminVerified: data[0].adminVerified || 0,
      adminComplier: data[0].adminComplier || "",
      companyComplier: data[0].companyComplier || "",
      recruiterComplier: data[0].recruiterComplier || "",
      allowCopyPaste: data[0].allowCopyPaste || false,
      takeScreenShots: data[0].takeScreenShots || false,
      takeSnapShots: data[0].takeSnapShots || false,
      numberOfScreenShots: data[0].numberOfScreenShots || 0,
      numberOfSnapShots: data[0].numberOfSnapShots || 0,
      disabled: data[0].disabled || 0,
            roleName: data[0].roleName || "",
      // Additional fields
      skills: data[0].skills || "",
      education: data[0].education || "",
      experience: data[0].experience || "",
      linkedIn: data[0].linkedIn || "",
      github: data[0].github || "",
      speciality: data[0].speciality || "",
      certif: data[0].certif || "",
      Volunteerings: data[0].Volunteerings || "",
      JobPreferences: data[0].JobPreferences || "",
      ContractPreferences: data[0].ContractPreferences || ""
          });
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }
  }, []);


  // is it a recruiter
  const [isRecruiter, setIsRecruiter]= useState(false);
  //permissions
  const [allowCopyPaste,setAllowCopyPaste]= useState(true);
  const [takeScreenShots,setTakeScreenShots]= useState(true);
  const [takeSnapShots,setTakeSnapShots]= useState(true);
  const [numberOfScreenShots, setNumberOfScreenShots] =useState();
  const [numberOfSnapShots, setNumberOfSnapShots] = useState();


  //get permissions of the test
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const currentUser = JSON.parse(localStorage.getItem("userDetails"));
        const userid = currentUser.localId;

        const response = await axios.get(
          `${process.env.REACT_APP_APP_DOMAIN}/api/technical/permissions/getuser/${userid}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }}
        );
        setIsRecruiter(response.data.isRecruiter);
        if(response.data.isRecruiter){
          const permissions= response.data.permissionsTestTech;
      
        setAllowCopyPaste(permissions.allowCopyPaste);
        setTakeScreenShots(permissions.takeScreenShots);
        setTakeSnapShots(permissions.takeSnapShots);
        setNumberOfScreenShots(permissions.numberOfScreenShots);
        setNumberOfSnapShots(permissions.numberOfSnapShots);
      }

      } catch (error) {
        console.error(error);
      }
    };
 
    fetchPermissions();
  }, []);

const updateTechnicalTestPermissions = async ()=>{
  try {
    const currentUser = JSON.parse(localStorage.getItem("userDetails"));
    const userid = currentUser.localId;

    const body= {allowCopyPaste, takeScreenShots, takeSnapShots, numberOfScreenShots, numberOfSnapShots};

    const response = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/technical/upUserPermissionsTechTest/${userid}`,{
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          "Content-type": "application/json"
        },
       
        body: JSON.stringify(body)
      })


  } catch (error) {
    console.log(error);
  }
}

useEffect(()=>{
  updateTechnicalTestPermissions();

},[allowCopyPaste, takeScreenShots, takeSnapShots, numberOfSnapShots,numberOfScreenShots]);
  
 const handleChangeCopyPaste = ()=>{
  setAllowCopyPaste(!allowCopyPaste);
 }
 const handleAdvenced= ()=>{
  setAdvenced(!advenced);
 }

 const handleChangeTakeScreenShots= ()=>{
  setTakeScreenShots(!takeScreenShots);
 }

 const handleChangeTakeSnapShots = ()=>{
  setTakeSnapShots(!takeSnapShots);
 }

 const handleChangeNumberOfSnapShots = (e)=>{
  setNumberOfSnapShots(parseInt(e.target.value));
 }

 const handleChangeNumberOfScreenShots = (e)=>{
  setNumberOfScreenShots(parseInt(e.target.value));
 }









 const [selectedSkill, setSelectedSkill] = useState('');
 const [selectedSkills, setSelectedSkills] = useState([]);
 const [proficiencyLevels, setProficiencyLevels] = useState({});
 const proficiencyLevelsOptions = ['Junior', 'Mid', 'Senior'];
 const availableSkills = [
  { name: 'Angular', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'AR Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'AWS', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Astronomy', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Augmented Reality (AR)', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Automotive Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Azure', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Backbone.js', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Bioengineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Bioinformatics', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Biotechnology', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Blender', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Blockchain', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Blockchain Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Bootstrap', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'C#', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'C++', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'CSS3', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'CV Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Cybersecurity', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Dart', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Database Administration', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Data Analytics', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Data Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Data Science', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'DevOps', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Digital Marketing', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Docker', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Eclipse', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Electrical Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Ember.js', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Environmental Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'ExpressJs', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Figma', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Firebase', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Frontend Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Full Stack Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Game Development', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'GameMaker', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Git', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Go', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Google Cloud', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'GraphQL', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'HTML5', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Illustrator', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'InVision', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'IntelliJ IDEA', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'IoT (Internet of Things)', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Java', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'JavaScript', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Jenkins', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'JIRA', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'jQuery', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Kotlin', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Kubernetes', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Machine Learning', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Material Science', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Maya', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Mechanical Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'MongoDB', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'MySQL', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Nanotechnology', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Natural Language Processing', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'Network Engineering', proficiencyLevels: ['Junior', 'Mid', 'Senior'] },
  { name: 'NodeJs', proficiencyLevels: ['Junior', 'Mid', 'Senior'] }
];

const handleSkillChange = (e) => {
  const skill = e.target.value;
  setSelectedSkill(skill);
  if (skill && !selectedSkills.includes(skill)) {
    setSelectedSkills([...selectedSkills, skill]);
    setProficiencyLevels({ ...proficiencyLevels, [skill]: 'Junior' });
    setSelectedSkill(''); // Reset the dropdown value
  }
};

const handleProficiencyLevelClick = (skill, level) => {
  setProficiencyLevels({ ...proficiencyLevels, [skill]: level });
};

const handleRemoveSkill = (skillToRemove) => {
  setSelectedSkills(selectedSkills.filter((skill) => skill !== skillToRemove));
  setProficiencyLevels((prevLevels) => {
    const { [skillToRemove]: removedSkill, ...remainingSkills } = prevLevels;
    return remainingSkills;
  });
};

const isSelectedProficiency = (skill, level) => {
  return proficiencyLevels[skill] === level ? 'selected' : 'notselected';
};






const handleClose = () => {
  setShowSubmitFailedPopup(false);
  setShowSubmitSeccessPopup(false);

};
const updatedUserSoft = (data) => {
  let aux
  if(user.skills !=="") {
    aux=user.skills
    while(typeof(aux) === "string"){
      aux=JSON.parse(aux);
    }

  }else{
   aux={TechnicalSkills:{selectedSkills:[],proficiencyLevels:{}},SoftSkills:{selectedLanguages:[],proficiencyLevels:{}}}
}
  aux.SoftSkills=data;
  setUser({
    ...user,
    skills:JSON.stringify(aux),
  })

};
const updatedUserTech = (data) => {
  let aux
  if(user.skills !=="") {
    aux=user.skills
    while(typeof(aux) === "string"){
      aux=JSON.parse(aux);
    }

}else{
  aux={TechnicalSkills:{selectedSkills:[],proficiencyLevels:{}},SoftSkills:{selectedLanguages:[],proficiencyLevels:{}}}
}

  aux.TechnicalSkills=data;

  setUser({
    ...user,
    skills:JSON.stringify(aux),
  })

};

const updatedUserExp = (data) => {
  setUser({
    ...user,
    experience:data,
  })

};
const updatedUserjob = (data) => {
  setUser({
    ...user,
    JobPreferences:data,
  })

};
const updatedUsercont = (data) => {
  setUser({
    ...user,
    ContractPreferences:data,
  })

};
const updatedUserVolunteer = (data) => {
  setUser({
    ...user,
    Volunteerings:data,
  })

};
const updatedUserEduc = (data) => {
  setUser({
    ...user,
    education:data,
  })

};
































































  return (
    <>
      <div className="col-12">
        <div className="col-xl-12">
          <div className="profile-back">
            {user.cover ? (
              <img
                src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/covers/${user.cover}`}
                alt=""
              />
            ) : (
              <img src={pic1} alt="" />
            )}
            <div className="social-btn">
              <label className="btn btn-secondary">
                {lang.menu.UploadCover}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleCoverUpload}
                  style={{ display: "none" }}
                />
              </label>
              <label className="btn btn-primary">
                {lang.menu.UploadAvatar}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleAvatarUpload}
                  style={{ display: "none" }}
                />
              </label>
            </div>
          </div>
          <div className="profile-pic d-flex">
            {user.avatar ? (
              <img
                className="rounded-circle"
                src={`${process.env.REACT_APP_APP_DOMAIN}/api/images/avatars/${user.avatar}`}
                alt=""
              />
            ) : (
              <div
                className={`profile-pic-empty empty-avatar rounded-circle ${randomAvatarClass}`}
              >
                {user.firstName.charAt(0)}
                {user.lastName.charAt(0)}
              </div>
            )}
          </div>
        </div>
        <div className="card full-width">
          <div className="card-header">
            <h4 className="card-title">
              <FontAwesomeIcon icon={faUser} className="me-2" />
              {lang.menu.Profile}
            </h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()} className="p-3">
                <div className="row">
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.FirstName}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={user.firstName}
                      maxLength={16}
                      onChange={(e) =>
                        setUser((prevUser) => ({
                          ...prevUser,
                          firstName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.LastName}</label>
                    <input
                      type="text"
                      className="form-control"
                      maxLength={16}
                      value={user.lastName}
                      onChange={(e) =>
                        setUser((prevUser) => ({
                          ...prevUser,
                          lastName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Email}</label>
                    <input
                      type="email"
                      className="form-control"
                      maxLength={32}
                      value={user.email}
                      onChange={(e) =>
                        setUser((prevUser) => ({
                          ...prevUser,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>
                  {/* <div className="form-group mb-3 col-md-6 my-form-padding">
										<label className="my-label">{lang.menu.Password}</label>
										<input
											type="password"
											className="form-control"
											value={user.password}
											onChange={(e) =>
												setUser((prevUser) => ({
													...prevUser,
													password: e.target.value,
												}))
											} />
									</div> */}
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.role}</label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      value={user.roleName}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Country}</label>
                    <select
                      value={user.country}
                      onChange={(e) =>{
                        setUser((prevUser) => ({
                          ...prevUser,
                          country: e.target.value,
                        }))
                      }
                      }
                      className="form-control"
                    >
                      <option value="" disabled>
                        {lang.menu.Choose}
                      </option>
                      {countries.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.MyJobtitle}</label>
                    <select
                      value={user.jobTitle}
                      onChange={(e) =>
                        setUser((prevUser) => ({
                          ...prevUser,
                          jobTitle: e.target.value,
                        }))
                      }
                      className="form-control"
                    >
                      <option value="" disabled>
                        {lang.menu.Choose}
                      </option>
                      {jobs.map((job) => (
                        <option key={job.code} value={job.code}>
                          {job.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Phonenumber}</label>
                    <input
  type="text"
  className="form-control"
  maxLength={16}
  value={user.phoneNumber}
  onChange={(e) => {
    const value = e.target.value;
    const regex = /^\+?[0-9]*$/;
    if (regex.test(value)) {
      setUser((prevUser) => ({
        ...prevUser,
        phoneNumber: value,
      }));
    }
  }}
/>

                  </div>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                    <label className="my-label">{lang.menu.Language}</label>
                    <select
                      value={user.language}
                      onChange={(e) =>
                        setUser((prevUser) => ({
                          ...prevUser,
                          language: e.target.value,
                        }))
                      }
                      className="form-control"
                    >
                      <option value="" disabled>
                        {lang.menu.Choose}
                      </option>
                      {languages.map((lang) => (
                        <option key={lang.code} value={lang.code}>
                          {lang.name}
                        </option>
                      ))}
                    </select>

                  </div>


                  {role===4 && 
                  <>
                  <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">Linkedin</label>
                   <input
                     type="text"
                     className="form-control"
                     value={user.linkedIn}
                     maxLength={32}
                     onChange={(e) =>
                       setUser((prevUser) => ({
                         ...prevUser,
                         linkedin: e.target.value,
                       }))
                     }
                   />
                 </div>



                 <div className="form-group mb-3 col-md-6 my-form-padding">
                   <label className="my-label">Github</label>
                   <input
                     type="text"
                     className="form-control"
                     value={user.github}
                     maxLength={32}
                     onChange={(e) =>
                       setUser((prevUser) => ({
                         ...prevUser,
                         github: e.target.value,
                       }))
                     }
                   />
                 </div></>}





                  {isRecruiter && <div >
                  <label className="my-label">  {lang.menu.techPer}
                  </label>

                  <div style={{ display: 'flex' , gap: '20px'}}>
                    <div className="form-group mb-3 col-md-4 my-form-padding">
                      <Switch onChange={handleChangeCopyPaste} isSelected={allowCopyPaste} style={{ flex: 1 }} >
                                              {lang.menu.AllowCopyPaste}

                      </Switch>
                    </div>
                    <div className="form-group mb-3 col-md-4 my-form-padding" style={{display: "inline-block"}}>
                      <Switch onChange={handleChangeTakeScreenShots} isSelected={takeScreenShots} style={{ flex: 2 }} >
                      {lang.menu.takeScreenshots}

                      </Switch>
                      {takeScreenShots && (
                        <Form.Group controlId="numberOfScreenShots">
                        <Form.Label>                       {lang.menu.numberOfScreenShots}
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="numberOfScreenShots"
                          value={numberOfScreenShots}
                          onChange={handleChangeNumberOfScreenShots}
                          min="0"
      
                        />
                    </Form.Group>
                      )}
                    </div>
                    <div className="form-group mb-3 col-md-4 my-form-padding">
                      <Switch onChange={handleChangeTakeSnapShots} isSelected={takeSnapShots} style={{ flex: 3 }} >
                      {lang.menu.takeSnapshots}

                      </Switch>
                      
                      {takeSnapShots && <Form.Group controlId="numberOfSnapShots">
                        <Form.Label>                       {lang.menu.numberOfSnapShots}
                        </Form.Label>
                          <Form.Control
                            type="number"
                            name="numberOfSnapShots"
                            value={numberOfSnapShots}
                            onChange={handleChangeNumberOfSnapShots}
                            min="0"
                            
                          />
                      </Form.Group>}
                    </div>
                  </div>
                  </div>}
                </div>
              </form>
            </div>
          </div>



          
        </div>


{role===4 && <div className=" mb-4" style={{borderBottom: "2px solid #86E6D7"}}>
<div className="form-group mb-2 col-md-4 " style={{display:"flex",justifyContent:"end",width:"100%"}}>
                      <Switch onChange={handleAdvenced} isSelected={advenced} style={{ flex: 1 }} >
                        <b style={{color:"#86E6D7", fontSize:18}}>                       {lang.menu.Advanced}
                        </b>
                      </Switch>
                    </div>











                    {advenced && (
         <>
         
       <div className="card full-width">
         <div className="card-header">
           <h4 className="card-title" style={{display: "flex", "flex-direction": "row"}}>
           <img src={codepen} style={{height:"20px"}}  className="me-2 " />
             {lang.menu.skills}
           </h4>
         </div>
         <div className="card-body">
           <TechSkill user={user}  updateUser={updatedUserTech}/>
           <SoftSkill user={user} updateUser={updatedUserSoft}/>
         </div>
       </div>



       <div className="card full-width">
         <div className="card-header">
           <h4 className="card-title">
           <img src={book} style={{height:"20px"}}  className="me-2 " />
             {lang.menu.Education}
           </h4>
         </div>
         <div className="card-body">
           <Educ user={user}  updateUser={updatedUserEduc}/>
         </div>
       </div>

       <div className="card full-width">
         <div className="card-header">
           <h4 className="card-title">
           <img src={ex} style={{height:"25px"}}  className="me-2 " />
           {lang.menu.Experiences}
           </h4>
         </div>
         <div className="card-body">
           <Exp user={user}  updateUser={updatedUserExp}/>
         </div>
       </div>
       <div className="card full-width">
         <div className="card-header">
           <h4 className="card-title">
           <img src={ex} style={{height:"25px"}}  className="me-2 " />
           {lang.menu.Volunteerings}  
           </h4>
         </div>
         <div className="card-body">
           <Volunteer user={user}  updateUser={updatedUserVolunteer}/>
         </div>
       </div>


       <div className="card full-width">
         <div className="card-header">
           <h4 className="card-title">
           <img src={ex} style={{height:"25px"}}  className="me-2 " />
           {lang.menu.Preferences}  
           </h4>
         </div>
         <div className="card-body">
         <JobPref user={user}  updateUser={updatedUserjob}/>
         <ConPref user={user}  updateUser={updatedUsercont}/>
         </div>
       </div>

         </>
       )}








</div>}












































       
















































        <div className="card full-width">
          <div className="card-header">
            <h4 className="card-title">
              <FontAwesomeIcon icon={faUser} className="me-2" />
              {lang.menu.Emailsignature}
            </h4>
          </div>
          <div className="card-body">
            <div className="summernote">
              <Rte
                signature={user.signature}
                onUpdateSignature={updateSignature}
              />
            </div>
          </div>
        </div>
        <div className="form-group mb-3 col-md-12 my-button-padding">
          <button
            type="submit"
            className="btn btn-primary my-save-button"
            onClick={handleSave}
          >
            {lang.menu.SaveChanges}
          </button>
        </div>
      </div>

      {showSubmitSeccessPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
          <img src={thankYou} style={{width:"120px",height:"120px", margin:"15px"}}  />

          <h2>Your modifications have been saved successfully.</h2>          
        </div>
        </>
      )}








{showSubmitFailedPopup && (
        <>
        <div className="overlay" onClick={handleClose}></div>

        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            width: "40%",
            maxWidth: "100%",
            textAlign: "center",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 0 200px rgba(0, 0, 0, 0.3)",
            zIndex:999
          }}
        >
         <img src={oops} style={{width:"120px",height:"120px", margin:"15px"}}  />

          <h3>An error occurred while saving your informations, please try again!</h3>          
        </div>
        </>
      )}


    </>
  );
};
export default Profile;

