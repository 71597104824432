import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image, Badge, Card } from "react-bootstrap";
import { FaBriefcase, FaMapMarkerAlt, FaCalendarAlt, FaMoneyBillWave, FaCheckCircle, FaClock } from "react-icons/fa";
import translations from "../AppsMenu/Candidate/mulitlingue";
import './ReadJob.css';
import { GiSkills } from "react-icons/gi";
import { RiEqualizerLine } from "react-icons/ri";

function ReadJob() {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [job, setJob] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("selectedLang");
    return storedLang && JSON.parse(storedLang).language === "Français" ? translations.fr : translations.en;
  });

  useEffect(() => {
    // Fetch job details
    axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/job-list/read/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setJob(res.data[0]))
      .catch((err) => console.log(err));

    // Fetch companies list
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/companies/companies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCompanies(response.data);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, [id, token]);

  const getCompanyLogo = (companyId) => {
    if (!companyId) return null;
    const company = companies.find((comp) => comp.companyid === companyId);
    return company
      ? `${process.env.REACT_APP_APP_DOMAIN}/api/images/companyLogo/${company.companyid}/${company.logo}`
      : null;
  };

  const specialismsArray = job.Specialisms ? JSON.parse(job.Specialisms) : [];
  const specialismColors = ["badge-primary", "badge-success", "badge-danger", "badge-warning"];

  return (
    <div className="job-container container mt-4 mb-5">
      <Card className="shadow-sm border-0 rounded-lg p-4">
        <div className="job-header d-flex justify-content-between align-items-center mb-4">
          <h2 className="job-title font-weight-bold">{job.JobTitle}</h2>
          {console.log("job ",job)}
          {job.companyid!=null &&getCompanyLogo(job.companyid) ? (
                    <Image
                      src={getCompanyLogo(job.companyid)}
                      alt="Company Logo"
                      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  ) : (
                    <i className="fas fa-building" style={{ fontSize: "24px", color: "#ccc" }}></i>

                  )}
        </div>

        <Card.Body>
          <Card.Text className="mb-4 job-description">          <div
  className="experience-display"
  dangerouslySetInnerHTML={{
    __html: job.JobDescription
      ? job.JobDescription.replace(/\n/g, '<br />')
      : "",
  }}
></div></Card.Text>

          <div className="job-details">
  <div className="job-details row">
    <div className="col-md-6">
      <div className="job-info-item">
        <FaBriefcase className="job-icon" /> <strong>{lang.menu.jobType}:</strong> 
        {job.JobType === "Full Time" && (
             <> {lang.menu.fullTime}</>
              )}
              {job.JobType === "Part Time" && (
             <> {lang.menu.partTime}</>
            )}
              {job.JobType === "FreeLance" && (
             <> {lang.menu.FreeLance}</>
            )}
      </div>
      <div className="job-info-item">
        <FaClock className="job-icon" /> <strong>{lang.menu.experience}:</strong> 
        {job.Experience === "intern" && lang.menu.intern}
  {job.Experience === "Junior" && lang.menu.Junior}
  {job.Experience === "10 Years or more" && lang.menu.y10Yearsormore}
  {job.Experience === "5 Years or more" && lang.menu.y5Yearsormore}
  {job.Experience === "2 Years or more" && lang.menu.y2Yearsormore}
      </div>
      <div className="job-info-item">
        <FaMoneyBillWave className="job-icon" /> <strong>{lang.menu.OfferedSalary}:</strong> {job.OfferedSalary}
      </div>
      <div className="job-info-item">
        <FaMapMarkerAlt className="job-icon" /> <strong>{lang.menu.country}:</strong> {job.Country}
      </div>
    </div>
    <div className="col-md-6">
      <div className="job-info-item">
        <FaCalendarAlt className="job-icon" /> <strong>{lang.menu.postDate}:</strong> {new Date(job.PostedDate).toLocaleDateString()}
      </div>
      <div className="job-info-item">
        <FaCalendarAlt className="job-icon" /> <strong>{lang.menu.CloseDate}:</strong> {new Date(job.CloseDate).toLocaleDateString()}
      </div>
      <div className="job-info-item">
        <FaCheckCircle className="job-icon" /> <strong>{lang.menu.status}:</strong>
        <Badge variant={job.Status === "Active" ? "success" : "danger"} className="ml-2">{job.Status}</Badge>
      </div>
      <div className="job-info-item">
      <RiEqualizerLine  className="job-icon" />  <strong>{lang.menu.TypeOfQualification}:</strong> {job.TypeOfQualification}
      </div>
  

    </div>
    <div className="job-info-item">
    <GiSkills className="job-icon" /> <strong>{lang.menu.skills}:</strong> 
  {job.skills ? (
    job.skills
      .replace("[", "")
      .replace("]", "")
      .split("),")
      .map((skill, index) => {
        const skillParts = skill
          .replace("('", "")
          .replace("')", "")
          .split("',");
        return (
          <Badge key={index} variant="warning" style={{ margin: "1%" }}>
            <span style={{ textAlign: "center" }}>{skillParts[0]}</span>
          </Badge>
        );
      })
  ) : (
    <Badge variant="light">N/A</Badge>
  )}
</div>
  </div>
  <div>

  </div>
</div>

        </Card.Body>
      </Card>
    </div>
  );
}


export default ReadJob;
