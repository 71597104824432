import React, { useState, useEffect,Fragment } from 'react';
import axios from 'axios';
import { Radar } from 'react-chartjs-2';
import {
    Col,Row,Dropdown,Card,Table,Button, Badge
   
  } from "react-bootstrap";
  import translations from "../AppsMenu/Candidate/mulitlingue";
  import PageTitle from "../../layouts/PageTitle";
  import swal from "sweetalert";

  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  
  const RadarGlobal = () => {
    const token=localStorage.getItem("token");
    const [candidate, setCandidate] = useState("");

    const [candidates, setCandidates] = useState([]);
    const [resultData, setResultData] = useState(null);
    const [nbreTestPsycho, setNbreTestPsycho] = useState(null);
    const [nbreTestTechCoding, setNbreTestTechCoding] = useState(null);
    const [jobsCand, setJobsCand] = useState([]);
    const [selectionMade, setSelectionMade] = useState(false);
    const [nbreTestTech, setNbreTestTech] = useState(null);
    const [coefQ, setCoefQ] = useState(null);
    const [coefC, setcoefC] = useState(null);
    const [filteredJobRoles, setFilteredJobRoles] = useState([]);
    const [jobTitle, setJobTitle] = useState('');
    const [selectedCandidate, setSelectedCandidate] = useState('');
    const [selectedJobRole, setSelectedJobRole] = useState('');
    const [jobRoles, setJobRoles] = useState([]);
    const [NoteTechGlobale, setNoteTechGlobale] = useState(null);
    const [NoteTECHPsychoGlobale, setNoteTECHPsychoGlobale] = useState(null);
    const [Rapport, setRapport] = useState(null);

    const [techScore, setTechScore] = useState('');
    const [psychoScore, setPsychoScore] = useState('');
    const [tcodeScore, setTcodeScore] = useState('');
    const [users, setUsers] = useState([]);

    const [candidateResJob, setCandidateResJob] = useState('');

    const [NoteAssignmentGlobale, setNoteAssignmentGlobale] = useState(null);
    const [assignmentScore, setAssignmentScore] = useState('');
    const [nbreAssignment, setNbreAssignment] = useState(null);
//EXist Tests

const [existAssignment,setExistAssignment] = useState(0);
const [existPsychoTech, setExistPsychoTech] = useState(0);
const [existTech, setExistTech] = useState(0);
useEffect(() => {
  const fetchCandidates = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAllCandidate`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      setCandidates(response.data);
    } catch (error) {
      console.log('Erreur lors de la récupération des candidats :', error);
    }
  };

  fetchCandidates();
}, []);


    useEffect(() => {
      const fetchJobRoles = async () => {
        try {
          const userResponse = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/users`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
        setUsers(userResponse.data);
          const compId = await getUserCompanyID();

          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getAll`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          if (compId!=1) {
            
            const filteredTests = response.data.filter(job => job.companyid=== compId  || job.companyid === null );

            setJobRoles(filteredTests);
         } else {

            setJobRoles(response.data);
         }   
        } catch (error) {
          console.log('Erreur lors de la récupération des jobroles :', error);
        }
      };
  
      fetchJobRoles();
    }, [selectedJobRole,candidateResJob]);
    useEffect(async() => {
      if(jobRoles.length>0 && candidates.length>0){
      const emailcandidate = localStorage.getItem("emailSelected");
      const candidate = JSON.parse(emailcandidate);
      let Candid=candidates.find(ca => ca.email===candidate)
      setCandidate(Candid)
      setSelectedCandidate(candidate)
      const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobroleByCandidatePassed/${Candid?.uid}`, 
        {
          headers: {
          Authorization: `Bearer ${token}`,
          }});
      const jobs = response.data.map(job => job.id_jobrole);
      
      setJobsCand(jobs)
      const filteredRoles = jobRoles.filter((role) => jobs.includes(role.id));

      setFilteredJobRoles(filteredRoles);
      setSelectionMade(false); 
    }
      //localStorage.removeItem("appliedJobId");
    }, [jobRoles,candidates]);
    
    const getUserCompanyID = async () => {
      const currentUser = JSON.parse(localStorage.getItem('userDetails'));
    const userId = currentUser.localId;
      const responseC = await fetch(`${process.env.REACT_APP_APP_DOMAIN}/api/users/local/${userId}`);
      const data = await responseC.json();
      const compId = data[0].companyID;
      return compId;
    };
    const [lang, setLang] = useState(() => {
        const storedLang = localStorage.getItem('selectedLang');
        if (storedLang) {
          const parsedLang = JSON.parse(storedLang);
          if (parsedLang.language === 'Français') {
            return translations.fr;
          }
        }
        return translations.en;
      });
   
 


      const getJobName = async (jobId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findJobTitleById/${jobId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const jobName = response.data.JobTitle;
          return jobName;
        } catch (error) {
          console.error(`Error fetching job name for ID ${jobId}:`, error);
          return "Unknown Job";
        }
      };
      const handleJobRoleSelect = async(eventKey) => {
        setSelectedJobRole(eventKey);
        const jobTitle=await getJobName(eventKey)
        handleCandidateClick(eventKey)
        setJobTitle(jobTitle);
        setSelectionMade(true); 
      };
      const fetchScoresAndTestNbre = async (jobRole, email) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/candidateExt/getScoresAndTestNbre/${jobRole}/${email}`, 
              {
                headers: {
                Authorization: `Bearer ${token}`,
                }});
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                return null;
            } else {
                throw error;
            }
        }
    };
    
 
    const fetchTestIdTech = async (jobRole) => {
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/findTestIdTech/${jobRole}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
        return response.data;
    };
    
    const handleFetchRadarPsycho = async () => {
        if (selectedCandidate && selectedJobRole) {
            try {
                const scoresAndTestNbre = await fetchScoresAndTestNbre(selectedJobRole, selectedCandidate.email);
                
                if (scoresAndTestNbre === null) {
                    setResultData({
                        Ptscore: null,
                        testPsychoCount: null,
                        score: null,
                        OT: null,
                        Tscore: null,
                        Cscore: null,
                        Ascore: null,
                        
                    });
                    return;
                }
                
                setResultData(scoresAndTestNbre);
               
            } catch (error) {
              setResultData({
                Ptscore: null,
                testPsychoCount: null,
                score: null,
                OT: null,
                Tscore: null,
                Cscore: null
            });
            }
        } else {
          swal('Veuillez sélectionner un candidat et un jobrole avant de soumettre.', {
            icon: "warning",
          });
        }
    };
    
    
    useEffect(() => {
      const submitForm = async () => {
        if (selectionMade) {
          await handleFetchRadarPsycho();
        }
      };
    
      submitForm();
    }, [selectionMade]);
    const radarOptions = {
        scales: {
          r: {
            ticks: {
              beginAtZero: true,
              stepSize: 1,
            },max: 10,min:0,
          },
        },
      };  

    
      const getScoreDescription = (score) => {

        if (score >= 7 && score <= 10) {
          return lang.menu.Advanced;
        } else if (score >= 4 && score < 7) {
          return lang.menu.Intermediate;
        } else {
          return lang.menu.Beginner;
        }
      };
      const getTechnicalScoreDescription = (score) => {
        if (score === 10) {
          return {
            description:lang.menu.excellentdesc,
            reasoning: lang.menu.Excellent
          };
        } else if (score< 10 && score >= 8) {
          return {
            description: lang.menu.verygooddesc,
            reasoning: lang.menu.VeryGood
          };
        } else if (score< 8 && score >= 5) {
          return {
            description: lang.menu.gooddesc,
            reasoning: lang.menu.Good
          };
        } else if (score< 5 && score >= 2) {
          return {
            description: lang.menu.poordesc,
            reasoning: lang.menu.Insufficient
          };
        } else if (score< 2 && score >= 0) {
          return {
            description: lang.menu.verypoordesc,
            reasoning: lang.menu.Undefinedscore
          };
        } else {
          return {
            description: lang.menu.notyet,
            reasoning: lang.menu.notyet
          };
        }
      };
      const getPsychoTechnicalScoreDescription = (score) => {
        if (score === 10) {
          return {
            description: lang.menu.excellentdesc1,
            reasoning: lang.menu.Excellent
          };
        } else if (score < 10 && score >= 8) {
          return {
            description: lang.menu.verygooddesc1,
            reasoning: lang.menu.VeryGood
          };
        } else if (score < 8 && score >= 5) {
          return {
            description:lang.menu.gooddesc1,
            reasoning: lang.menu.Good
          };
        } else if (score < 5 && score >= 2) {
          return {
            description: lang.menu.poordesc1,
            reasoning: lang.menu.Insufficient
          };
        }  else if (score< 2 && score >= 0) {
          return {
            description: lang.menu.verypoordesc1,
            reasoning: lang.menu.Undefinedscore
          };
        } else {
          return {
            description: lang.menu.notyet,
            reasoning: lang.menu.notyet
          };
        }
      };
      const getAssignmentScoreDescription = (score) => {
        if (score === 100) {
          return {
            description:lang.menu.excellentdesc,
            reasoning: lang.menu.Excellent
          };
        } else if (score< 100 && score >= 80) {
          return {
            description: lang.menu.verygooddesc,
            reasoning: lang.menu.VeryGood
          };
        } else if (score< 80 && score >= 50) {
          return {
            description: lang.menu.gooddesc,
            reasoning: lang.menu.Good
          };
        } else if (score< 50 && score >= 20) {
          return {
            description: lang.menu.poordesc,
            reasoning: lang.menu.Insufficient
          };
        } else if (score< 20 && score >= 0) {
          return {
            description: lang.menu.verypoordesc,
            reasoning: lang.menu.Undefinedscore
          };
        } else {
          return {
            description: lang.menu.notyet,
            reasoning: lang.menu.notyet
          };
        }
      };
      const getSkillsScoreDescription = (score) => {
        const scorePre = score
        
        if (score < 10 && score >= 8) {
          return {
            description: lang.menu.scoreExcellentdDesc,
            reasoning: lang.menu.Excellent
          };
        } else if (scorePre < 10 && scorePre >= 8) {
          return {
            description: lang.menu.scoreVeryGoodDesc,
            reasoning: lang.menu.VeryGood
          };
        } else if (scorePre < 8 && scorePre >= 5) {
          return {
            description: lang.menu.scoreGoodDesc,
            reasoning: lang.menu.Good
          };
        } else if (scorePre < 5 && scorePre >= 2) {
          return {
            description: lang.menu.scoreInsufficientDesc,
            reasoning: lang.menu.Insufficient
          };
        } else {
          return {
            description: lang.menu.Undefinedscore,
            reasoning: lang.menu.Undefined
          };
        }
      };


      const getTestPsychoName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/testPsycho/getTestById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.test_name;
          return testName;
        } catch (error) {
          console.error(`Error fetching test psycho name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const getTestTechName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/techQCM/getTestById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.test_name;
  
          return testName;
        } catch (error) {
          console.error(`Error fetching test tech name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
      const getTestCodingName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/topic/getTopicById/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.name; 
      
          return testName;
        } catch (error) {
          console.error(`Error fetching test Coding name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };

      const getAssignmentName = async (testId) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/assignment/${testId}`, 
            {
              headers: {
              Authorization: `Bearer ${token}`,
              }});
          const testName = response.data.assignment.name; 
      
          return testName;
        } catch (error) {
          console.error(`Error fetching assignment name for ID ${testId}:`, error);
          return "Unknown Test";
        }
      };
    






      const handleCandidateClick = async (jobId) => {
        let candidateNew = {
          "id_candidate": candidate.uid,
          "FirstName": candidate.FirstName,
          "LastName": candidate.LastName,
          "email": candidate.email
        };
      
        const response = await axios.get(`${process.env.REACT_APP_APP_DOMAIN}/api/jobrole/getJobRoleDetailsForCandidates/${jobId}`, 
					{
					  headers: {
						Authorization: `Bearer ${token}`,
					  }});
      
        const filteredCandidates = response.data.filter(candidatetest => candidatetest.id_candidate === candidateNew.id_candidate);

        if (filteredCandidates.length === 1) {
          const updatedCandidate = filteredCandidates[0];

          const updatedNote = await Promise.all(JSON.parse(updatedCandidate.note || '[]').map(async (test) => {
            const testName = await getTestPsychoName(test.idtest);
            return { ...test, testName };
          }));
          const updatedNoteTech = await Promise.all(JSON.parse(updatedCandidate.noteTech || '[]').map(async (test) => {
            const testName = await getTestTechName(test.idtest);
            return { ...test, testName };
          }));
          const updatedNoteCoding = await Promise.all(JSON.parse(updatedCandidate.noteCoding || '[]').map(async (test) => {
            const testName = await getTestCodingName(test.idtest);
            return { ...test, testName };
          }));
          const updatedNoteAssignment = await Promise.all(Object.entries(JSON.parse(updatedCandidate.detailed_assignment_score || '{}')).map(async ([idAssignment, noteAssignment]) => {
            const assignmentName = await getAssignmentName(idAssignment);
            
            return { idAssignment, noteAssignment, assignmentName };
          }));
         
          
      
          const user = users.find(user => user.uid === candidateNew.id_candidate);
          const FirstName = user?.FirstName || 'N/A';
          const LastName = user?.LastName || 'N/A';
          const email = user?.email || 'N/A';
      
          const extendedCandidate = {
            ...candidateNew,
            note: updatedNote,
            noteTech: updatedNoteTech,
            noteCoding: updatedNoteCoding,
            PTscore: filteredCandidates[0].Ptscore,
            Tscore: filteredCandidates[0].Tscore,
            Cscore: filteredCandidates[0].Cscore,
            candAccept:filteredCandidates[0].candAccept,
            FirstName,
            LastName,
            email,
            assignment_score: filteredCandidates[0].assignment_score,
            detailed_assignment_score: updatedNoteAssignment,
          };
      
          localStorage.setItem("candidateDetails", JSON.stringify(extendedCandidate));
          setCandidateResJob(extendedCandidate)
          setNbreTestTech(extendedCandidate.noteTech.length);
          setNbreTestPsycho(extendedCandidate.note.length);
          setNbreTestTechCoding(extendedCandidate.noteCoding.length);
          setTechScore(extendedCandidate.Tscore);
          setNbreAssignment(extendedCandidate.detailed_assignment_score.length);
            setTcodeScore(extendedCandidate.Cscore);
            setPsychoScore(extendedCandidate.PTscore);
            setAssignmentScore(extendedCandidate.assignment_score);


let noteTechGlobale = null;
let noteTECHPsychoGlobale = null;
let rapport = null;

let noteAssignmentGlobale = null;
const testIdTechData = await fetchTestIdTech(jobId);
if (extendedCandidate.noteTech.length !== 0 && extendedCandidate.noteCoding.length !== 0) {
  
const coefQCM = testIdTechData.coefQCM;
const coefCoding = testIdTechData.coefCoding;
setcoefC(coefCoding);
setCoefQ(coefQCM);
    noteTechGlobale = ((extendedCandidate.Tscore * coefQCM)/extendedCandidate.noteTech.length + (extendedCandidate.Cscore * coefCoding)/extendedCandidate.noteCoding.length) / (coefQCM + coefCoding);
    rapport++
setExistTech(1)

} else if (extendedCandidate.noteTech.length !== 0 && extendedCandidate.noteCoding.length === 0) {
  const coefQCM = testIdTechData.coefQCM;
  setCoefQ(coefQCM);

    noteTechGlobale = extendedCandidate.Tscore*coefQCM/extendedCandidate.noteTech.length;
    rapport++
    setExistTech(1)
} else if (extendedCandidate.noteTech.length === 0 && extendedCandidate.noteCoding.length !== 0) {
  const coefCoding = testIdTechData.coefCoding;
setcoefC(coefCoding);
    noteTechGlobale = extendedCandidate.Cscore* coefCoding/extendedCandidate.noteCoding.length;
    rapport++
    setExistTech(1)

} else if (extendedCandidate.noteTech.length === 0 && extendedCandidate.noteCoding.length === 0) {
    noteTechGlobale = null;
    setExistTech(0)
    
}
setNoteTechGlobale(noteTechGlobale)
if(extendedCandidate.note.length !== 0){
  rapport++
  noteTECHPsychoGlobale=(noteTechGlobale+extendedCandidate.PTscore)
  setExistPsychoTech(1)
}
else{
  noteTECHPsychoGlobale=noteTechGlobale
  setExistPsychoTech(0)

}

if(extendedCandidate.detailed_assignment_score.length !== 0){
  rapport++;

  noteAssignmentGlobale=(noteTECHPsychoGlobale+(extendedCandidate.assignment_score/10));

  setExistAssignment(1)
}
else{

  noteAssignmentGlobale=noteTECHPsychoGlobale;

  setExistAssignment(0)

}
setNoteAssignmentGlobale(noteAssignmentGlobale)
setRapport(rapport)

        } else {
          console.error("Filtered candidates length is not equal to 1");
        }
      };
      


      


      const formatDataForRadar = (data) => {
        const labels = [];
        const scores = [];
        const tooltips = [];
    
        if (existAssignment === 1 && assignmentScore !== null) {
            labels.push('Ascore');
            scores.push(assignmentScore / 10);
            tooltips.push('Ascore: Assignment score');
        }
    
        if (existPsychoTech === 1 && psychoScore !== null && nbreTestPsycho !== null) {
            labels.push('Ptscore');
            scores.push(psychoScore);
            tooltips.push('Ptscore: Psycho-Technical score');
        }
    
        if (existTech === 1 && NoteTechGlobale !== null) {
            labels.push('Tscore');
            scores.push(NoteTechGlobale);
            tooltips.push('Tscore: Technical score');
        }
    
        if (!isNaN(resultData.score)) {
            labels.push('score');
            scores.push(resultData.score);
            tooltips.push('score: Preselection score from extraction');
        }
    
        return {
            labels: labels,
            datasets: [
                {
                    label: 'Radar Chart',
                    data: scores,
                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                    borderColor: "#74188D",
                    pointBackgroundColor: "#74188D",
                    pointBorderColor: "#000",
                    pointHoverBackgroundColor: "#000",
                    pointHoverBorderColor: "#74188D",
                },
            ],
            options: {
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return tooltips[tooltipItem.index];
                        }
                    }
                }
            }
        };
    };
    
    




      
      return (
        <Fragment>
          <PageTitle
        activeMenu={lang.menu.candidateAssessment}
        motherMenu={lang.menu.dashboard}
      />
        <div >
      <h3>{selectedCandidate ? selectedCandidate.email : lang.menu.selectCandidate}</h3>

        <Row>
   

          <Col xl={5}>
            
          <Dropdown onSelect={handleJobRoleSelect}>
  <Dropdown.Toggle variant="primary light" id="dropdown-jobrole" style={{ width: '80%' }} >
    <label htmlFor="dropdown-jobrole"></label>
    {selectedJobRole ? jobTitle : lang.menu.selectJobRole}
  </Dropdown.Toggle>
  <Dropdown.Menu style={{ maxWidth: '100%', maxHeight: '400%', overflowY: 'auto', width: '80%' }}>
    {filteredJobRoles.length > 0 ? (
      filteredJobRoles.map((jobRole) => (
        <Dropdown.Item key={jobRole.id} eventKey={jobRole.id}>
          {jobRole.JobTitle}
        </Dropdown.Item>
      ))
    ) : (
 
      <Dropdown.Item disabled>
        {lang.menu.noJobRolesForCandidate}
      </Dropdown.Item>
    )}
  </Dropdown.Menu>
</Dropdown>

          </Col>
          
       
        </Row>
        {resultData && resultData.length!=0 ? (
        <Row >
        <Card style={{ marginTop: "3%" }}>
        {candidateResJob.candAccept === 1 ? (
      <h3>{lang.menu.notyet}</h3>
    ) : candidateResJob.candAccept === 2 ? (<>
          {resultData && (
            <div>
              <h6
                style={{
                  textAlign: "center",
                  marginTop: "2%",
                  fontFamily: "Poppins",
                  color: "#74188D",
                }}
              >
                {lang.menu.overallscore}:{" "}
                <span
                  style={{
                  
                    fontSize: "1.2em",
                    color: "black",
                    padding: "0.2em 0.5em",
                    borderRadius: "0.25em",
                  }}
                >
         {
  resultData.score 

    ? `${isNaN(Math.round(((((NoteAssignmentGlobale )) + resultData.score) / (Rapport + 1)) * 10)) ? 0 : Math.round(((((NoteAssignmentGlobale)) + resultData.score) / (Rapport + 1)) * 10)}% (${getScoreDescription(((((NoteAssignmentGlobale)) + resultData.score) / (Rapport + 1)))})`
    : `${isNaN(Math.round((((NoteAssignmentGlobale )) / Rapport) * 10)) ? 0 : Math.round((((NoteAssignmentGlobale )) / Rapport) * 10)}% (${getScoreDescription((((NoteAssignmentGlobale)) / Rapport))})`
}

                </span>
              </h6>
              <Card.Body variant="primary light">
              <Row>
              {existAssignment==1 ? ( 
                  <Col >
                    <h6>{lang.menu.AssignmentScore}: {Math.round(assignmentScore )}%</h6>
                  </Col>):(<></>)}
                  {existTech==1 ? ( 
                  <Col >
                    <h6>{lang.menu.techScore}: {Math.round(NoteTechGlobale * 10)}%</h6>
                  </Col>):(<></>)}
                  {existPsychoTech==1 ? ( 
                  <Col >
                    <h6>{lang.menu.ptechScore}: {Math.round(psychoScore * 10)}%</h6>
                  </Col>):(<></>)}

                  {isNaN(resultData.score) ? ( 
               <></>):(   <Col >
                <h6>{lang.menu.Prequalified}: 
                  {isNaN(resultData.score) ? lang.menu.noScorePre : `${Math.round(resultData.score * 10)}%`}
                </h6>
              </Col>)}

                </Row>

                <Row>
                {existAssignment==1 ? ( 
                <Col >
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.description} : </h6>{getAssignmentScoreDescription(assignmentScore).description}</span>
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.Reasoning} :</h6> {getAssignmentScoreDescription(assignmentScore).reasoning}</span>
                  </Col>):(<></>)}

                  {existTech==1 ? ( 
                  <Col >
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.description} : </h6>{getTechnicalScoreDescription(NoteTechGlobale).description}</span>
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.Reasoning} :</h6> {getTechnicalScoreDescription(NoteTechGlobale).reasoning}</span>
                  </Col>):(<></>)}

                  {existPsychoTech==1 ? ( 
                  <Col >
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.description} :</h6> {getPsychoTechnicalScoreDescription(psychoScore).description}</span>
                    <span><h6 style={{color :"#74188D"}}>{lang.menu.Reasoning} :</h6> {getPsychoTechnicalScoreDescription(psychoScore).reasoning}</span>
                  </Col>):(<></>)}
                  {isNaN(resultData.score) ? ( 
               <></>):(  


                  <Col >
  <span>
    <h6 style={{color: "#74188D"}}>{lang.menu.description} :</h6> 
    {isNaN(resultData.score) ?  lang.menu.noScorePre : getSkillsScoreDescription(resultData.score).description}
  </span>
  <span>
    <h6 style={{color: "#74188D"}}>{lang.menu.Reasoning} :</h6> 
    {isNaN(resultData.score) ?  lang.menu.noScorePre : getSkillsScoreDescription(resultData.score).reasoning}
  </span>
</Col>)}

                </Row>
              </Card.Body>
              <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                <Radar data={formatDataForRadar(resultData)} options={radarOptions} />
              </div>
            </div>
          )}</>) : (
            <div></div> 
          )}
        </Card>
      </Row>): (
  <div className='text-center' ><p>{lang.menu.selectJob}</p>

</div>
)}
    </div>
      </Fragment>
      );
    };
    
    export default RadarGlobal;